import React from 'react'

export const DeleteProjectPopup = ({children}) => {
  return (
    <div className="deleteprojectcontainer">
      <div className="gridFourth">
        <div className="gridcontent">{children}</div>
      </div>
    </div>
  )
}

