import React from 'react'
import { Navigate } from "react-router-dom";

// To manage the DataSet Routes
export const DataSetRoute = ({ children }) => {
    return (

        JSON.parse(localStorage.getItem('statusDataSet')) === true ? (
            children
        ) : (
            <Navigate
                to="/dataSet"
                replace={true}
            />
        ))
}