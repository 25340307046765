import React, { useEffect, useState } from "react";
import { FramesNavBar } from "./HeadarNavbar/FramesNavbar";
import { useNavigate, Link } from "react-router-dom";
import services from "../../API_SERVICES/services";
const Notfound = {
  fontWeight: "bold",
  textAlign: "center",
};
const cardStyle = {
  borderRadius: "10px",
  backgroundColor: "#e5ff80",
  padding: "10px",
  width: "100%"
};
const imageStyle = {
  width: "100%",
  height: "145px",
  borderRadius: "10px",
};
const CreateFrames = () => {
  const navigate = useNavigate();
  const [privateframes, setprivateFrames] = useState();
  const [expand, setExpand] = useState(false);
  const [expandPrivate, setExpandPrivate] = useState(false);
  const [publicframes, setpublicFrames] = useState();
  const itemArray = [
    { name: "DISCOVER", value: "/decode" },
    { name: "FRAMES", value: "" },
    { name: "BLUEPRINT", value: "/Howinputtab" },
    { name: "PROTOTYPE", value: "/modelspage" },
    { name: "REFLECTION", value: "/reflection" },
  ];
  useEffect(() => {
    loadprivateframes();
    loadpublicframes();
  }, []);
  const loadprivateframes = () => {
    const value = "PRIVATE";
    const frames = true;
    const project = true;
    const apiname = "fetch-project-frames";
    services
      .get(apiname, project, frames, value)
      .then((response) => {
        setprivateFrames(response.data);
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };
  const loadpublicframes = () => {
 
    const apiname = "fetch-public-frames";
    services
    .getbasic(apiname)
      .then((response) => {
        setpublicFrames(response.data);
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };
  return (
    <>
      <FramesNavBar labels={itemArray} isActive={"FRAMES"} />
      <div className="createFramesMainContainer">
        <div className="createFrameTopRow">
          <div className="createFrameTopRowLeftCol">
            YOU CAN THINK OF FRAMES AS THE "LENSES" OF YOUR PROJECT. CRAFT YOUR
            FRAMES FROM THE VALUES, MOTIVATIONS, AND INTENTIONS THAT WILL BE
            IMPLICIT IN YOUR PROJECT.
          </div>
          <div className="createFrameTopRowRightCol">
            <p
              style={{
                fontSize: "16px",
                fontWeight: "500",
                textTransform: "uppercase",
              }}
            >
              Frames may be incredibly broad or very specific- you can draw on
              social abstractions, rights, and theory, to more technical,
              infrastructural, or material approaches. The box below holds some
              of our community created frames, and are there to give some
              examples that range from imaginary and playful, to the critical.
            </p>
          </div>
        </div>
        <div className="createFrameHeadingRow">
          <h2
            style={{
              color: "#4E4E4E",
              fontWeight: "normal",
              borderBottom: "1px solid #4E4E4E",
            }}
          >
            CREATE YOUR FRAME
          </h2>

          <button
            style={{
              border: "1px solid #4e4e4e",
              height: "45px",
              width: "45px",
              borderRadius: "25px",
              paddingBottom: 5,
              backgroundColor: "#C7CCB2",
            }}
            type="button"
            className="mb-2"
            onClick={() => navigate("/frames", { replace: true })}
          >
            +
          </button>
          {privateframes?.data?.length > 0 ? (
            <div className={!expandPrivate ? "projectList" : "expandCard"}>
              {privateframes?.data?.map((item, index) => (
                <PrivateCards
                  frames={privateframes?.data}
                  frame={item}
                  index={index}
                  setExpandPrivate={setExpandPrivate}
                  expandPrivate={expandPrivate}
                />
              ))}
            </div>
          ) : privateframes?.data?.length === 0 ? (
            <p className="text-center" style={Notfound}>
              <i className="fas fa-empty-set"></i> No private frames Found
            </p>
          ) : null}
        </div>
        <div className="createFrameHeadingRow">
          <h2
            style={{
              color: "#4E4E4E",
              fontWeight: "normal",
              borderBottom: "1px solid #4E4E4E",
            }}
          >
            BROWSE PUBLIC FRAMES
          </h2>
        </div>
        <div>
          {" "}
          {publicframes?.data?.length > 0 ? (
            <div className={!expand ? "projectList" : "expandCard"}>
              {publicframes?.data?.map((item, index) => (
                <PublicCards
                  frames={publicframes?.data}
                  frame={item}
                  index={index}
                  setExpand={setExpand}
                  expand={expand}
                />
              ))}
            </div>
          ) : publicframes?.data?.length === 0 ? (
            <p className="text-center" style={Notfound}>
              <i className="fas fa-empty-set"></i> No public frames Found
            </p>
          ) : null}
        </div>
      </div>
    </>
  );
};
export default CreateFrames;
const PrivateCards = ({
  frames,
  setExpandPrivate,
  expandPrivate,
  frame,
  index,
}) => {
  const [itemKey, setKey] = useState("");
  return (
    <>
      {expandPrivate === false ? (
        <div
          style={cardStyle}
          onClick={() => {
            setExpandPrivate(!expandPrivate);
            setKey(index);
          }}
        >
          <div key={index}>
            <h4 style={{ fontSize: "14px" }}>{frame.frame_name}</h4>
            <h4
              style={{
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                fontSize: "14px",
                textOverflow: "ellipsis",
              }}
            >
              {frame.frame_desc}
            </h4>

            <img src={frame.frame_image} style={imageStyle} alt="projimg" />
          </div>
        </div>
      ) : (
        <ExpandedView
          frames={frames[itemKey]}
          index={itemKey}
          setKey={setKey}
          setExpandPrivate={setExpandPrivate}
        />
      )}
    </>
  );
};
const PublicCards = ({ frames, frame, index, setExpand, expand, key }) => {
  const [itemKey, setKey] = useState("");
  return (
    <>
      {expand === false ? (
        <div
          style={cardStyle}
          onClick={() => {
            setExpand(!expand);
            setKey(index);
          }}
        >
          <div key={index}>
            <h4 style={{ fontSize: "14px" }}>{frame.frame_name}</h4>
            <h4
              style={{
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                fontSize: "14px",
                textOverflow: "ellipsis",
              }}
            >
              {frame.frame_desc}
            </h4>

            <img src={frame.frame_image} style={imageStyle} alt="projimg" />
          </div>
        </div>
      ) : (
        <ExpandedView
          frames={frames[itemKey]}
          index={itemKey}
          setKey={setKey}
          setExpand={setExpand}
        />
      )}
    </>
  );
};

const ExpandedView = ({
  frames,
  index,
  setExpand,
  setExpandPrivate,
  setKey,
}) => {
  return (
    <>
      {frames && (
        <>
          <div className="row " key={index}>
            <div className="col-3 justify-content-start">
              <p>{frames?.frame_name}</p>
            </div>
            <div className="col-3">
              <p>{frames?.frame_privacy}</p>
            </div>
            <div className="col-6">
             <Link to={'/editframes'}
                state={{data:frames}}> <i className="fas fa-pen w-100 text-end"></i></Link>
            </div>
          </div>
          <div className="ExpandGrid">
            <div>
              <h5>Description</h5>
              <p>{frames?.frame_desc}</p>
            </div>
            <div>
              <h5>Inspiration And Attributes</h5>
              <p>{frames?.frame_attr}</p>
            </div>
            <div>
              <h5>Contributions</h5>
            </div>
            <img
              src={frames?.frame_image}
              alt="frameimage"
              width="100%"
              style={{ borderRadius: "10px" }}
            />
          </div>

          {frames.frame_privacy === "PUBLIC" ? (
            <i
              className="fal fa-compress-arrows-alt w-100 text-end"
              onClick={() => {
                setExpand(false);
                setKey("");
              }}
            ></i>
          ) : (
            <i
              className="fal fa-compress-arrows-alt w-100 text-end"
              onClick={() => {
                setExpandPrivate(false);
                setKey("");
              }}
            ></i>
          )}
        </>
      )}
    </>
  );
};
