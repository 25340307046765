import React from 'react'

export const SelectedRowModal = ({children}) => {
  return (
    <div className="Rowoutercontainer">
    <div className="RowgridFourth">
      <div className="gridcontent" style={{margin:"0% 9%"}}>{children}</div>
    </div>
  </div>
  )
}

