import cookie from 'js-cookie';
import jwt from 'jsonwebtoken';
export const setCookie = (key, value) => {
  if (window !== undefined) {
    cookie.set(key, value, {
      expires: 2,
    });
  }
};
export const removeCookie = (key) => {
  if (window !== undefined) {
    cookie.remove(key, {
      expires: 2,
    });
  }
};
export const removeLocal = (key) => {
  if (window !== undefined) {
    localStorage.removeItem(key);
  }
};
export const getCookie = (key) => {
  if (window !== undefined) {
    return cookie.get(key);
  }
};
export const setLocal = (key, value) => {
  if (window !== undefined) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};
export const logger = (response, next) => {
  setCookie('token', response.data.token);
  setLocal('user', response.data.user);
  next();
};
export const isId = () => {
  if(window !== undefined){
    const checkSession = sessionStorage.getItem('identifier');
    if (checkSession) {
      return JSON.parse(checkSession);
    }
  } else {
    return false;
  }
}
export const logkey = (response,next) => {
  setLocal('status',response.data.data.logstat);
  next();
};
export const logkeymodel= (response,next) => {
  setLocal('statusmodel',response);
  next();
};
export const logKeyDataSet= (response,next) => {
  setLocal('statusDataSet',response);
  next();
};
export const sharedData= (response,next) => {
  setLocal('sharedDataKey',response);
  next();
};
export const sharedDataPassword= (response,next) => {
  setLocal('sharedDataPassword',response);
  next();
};
export const deepfaceKey= (response,next) => {
  setLocal('deepfaceStatus',response);
  next();
};
export const logkeyinvite = () => {
  setLocal('status',true);
};
export const isAuth = () => {
  if (window !== undefined) {
    const checkCookie = getCookie('token');
    const checkLocal = localStorage.getItem('user');
    if (checkCookie && checkLocal) {
      return JSON.parse(checkLocal);
    }
  } else {
    return false;
  }
};
export const isQues = () => {
  if (window !== undefined) {
    const checkSession = sessionStorage.getItem('question');
    if (checkSession) {
      return JSON.parse(checkSession);
    } else {
      return false;
    }
  }
};
export const TokenVerifier = () => {
  const checkCookie = getCookie('token');
  const secret = `${process.env.REACT_APP_JWT_SECRET}`
  jwt.verify(
    checkCookie,
    secret,
    (err, decode) => {
      if (err?.name === 'TokenExpiredError') {
        signout();
      } else {
      }
    }
  );
};

export const signout = () => {
  removeCookie('token');
  localStorage.clear();
  sessionStorage.clear();
  setTimeout(() => {
    window.location.reload();
  }, 200);
};
