import React, { useEffect, useRef, useState } from "react";
import { PopupModal } from "../DataPrivacy/PopupModal";
import Webcam from "react-webcam";
import services from "../../API_SERVICES/services";
import camerapic from "../../Assets/Images/camera.png";
import folderpic from "../../Assets/Images/folder.png";
import uploadpic from "../../Assets/Images/upload.png";
import deletepic from "../../Assets/Images/delete.png";
import downarrow from '../../Assets/Images/downarrow.png'
import downloadpic from '../../Assets/Images/download.png'
import linkicon from '../../Assets/Images/linkicon.png'
import path from "path";
import {isId } from "../../Storage(Methods)/storage";
import { toast } from "react-hot-toast";
// import exp from "constants";



const StyleGAN = () => {
  // const controller = new AbortController();

  // const [imagelist, setImagelist] = useState([]);
  const [imagelist] = useState([]);
  // const [file, setFile] = useState("");
  const [setFile] = useState("");
  const [activatecam, setActivatecam] = useState(false);
  const [dataview, setDataview] = useState(false);
  const [datalist, setDatalist] = useState("");
  const [estimatedtime, setEstimatedtime] = useState("");
  const [modelrun, setModelrun] = useState(false);
  const [modeltraining, setModeltraining] = useState(false);
  const [resultlist, setResultlist] = useState([]);
  const [stopconfirm, setStopconfirm] = useState(false);
  const [saveconfirm, setSaveconfirm] = useState(false);
  const [exportconfirm, setExportconfirm] = useState(false);
  const [trained, setTrained] = useState(false);

  const videoConstraints = {
    width: 410,
    height: 260,
    facingMode: "user",
  };
  useEffect(() => {
    loaddata();
  }, []);
  const loaddata = () => {
    let apiname = "fetch-project-data";
    const project = true;
    services
      .get(apiname, project)
      .then((response) => {
        setDatalist(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onFileChange = (e) => {
    e.preventDefault();
    for (let i = 0; i < e.target.files.length; i++) {
      imagelist.push({ data: e.target.files[i], type: "upload" })
    }
    setFile(e.target.files[0]);
  };
  const deleteImage = (index) => {
    imagelist.splice(index, 1);

  };
  const [items, setItems] = useState([]);

  const checkhandler = (e) => {
    setItems([...items, e.target.value]);
  };
  const [hoveredDel, setHoveredDel] = useState(-1);
  const webcamRef = useRef(null);
  const capture = () => {
    imagelist.push({ data: webcamRef.current.getScreenshot(), type: "camera" })
    setActivatecam(!activatecam);
  };
  const selectData = () => {
    for (let i = 0; i < items.length; i++) {
      imagelist.push({ data: items[i], type: "dataset" })
    }
    setDataview(!dataview);
    setItems([]);
  };

  const showdelete = (i) => {
    setHoveredDel(i);
  }

  const hidedelete = () => {
    setHoveredDel(-1)
  }
  const stopModel = () => {
    setEstimatedtime("");
    setModeltraining(false);
    setStopconfirm(false);
  }

  const trainModel = (e) => {
    setTimeout(() => {
    }, 5200);
    e.preventDefault();
    setTrained(false);
    setResultlist([]);
    const apiname = "/train_stylegan";
    var formData = new FormData();
    setModeltraining(true);
    for (let i = 0; i < imagelist.length; i++) {
      if (imagelist[i].type === "upload") {
        formData.append("arg1", imagelist[i].data);
      }
      if (imagelist[i].type === "camera") {
        formData.append("arg2", imagelist[i].data);
      }
      if (imagelist[i].type === "dataset") {
        formData.append("arg3", imagelist[i].data);
      }
    }
    let projectid = isId();
    formData.append("pId", projectid);
    setEstimatedtime("Estimated time - " + imagelist.length + " sec");
    services
      .post(apiname, formData)
      .then((response) => {
        toast.success("model trained successfully");

        setEstimatedtime("");
        setModeltraining(false);
        setTrained(true);
      })
      .catch((err) => {
        toast.error("Error in training model");
        console.log("Error is", err);
        setEstimatedtime("");
        setModeltraining(false);
      });
  };
  const saveModel = (e) => {
    e.preventDefault();
    setSaveconfirm(!saveconfirm);
    const apiname = "/save_stylegan";

    let projectid = isId();
    let data = { projectId: projectid }
    services
      .post(apiname, data)
      .then((response) => {
        toast.success("model saved successfully");
      })
      .catch((err) => {
        toast.error("Error in saving model");
        console.log("Error is", err);

      });
  }
  const runModel = (e) => {

    e.preventDefault();
    const apiname = "/run_stylegan";

    let projectid = isId();
    let data = { projectId: projectid }
    services
      .post(apiname, data)
      .then((response) => {
        toast.success("model run successfull");
        setResultlist(response.data.result);
        setModelrun(true)

      })
      .catch((err) => {
        toast.error("Error in running model");
        console.log("Error is", err);

      });


  }
  const downloadimage = (src) => {

   // e.preventDefault(); // prevent the link from opening in the browser

    fetch(src, {

      method: "GET",

      headers: {}

    })

      .then(response => {

        response.arrayBuffer().then(function (buffer) {

          const url = window.URL.createObjectURL(new Blob([buffer]));

          const link = document.createElement("a");

          link.href = url;

          link.setAttribute("download", "image.png"); //or any other extension

          link.click();

        });

      })

      .catch(err => {

        console.log(err);

      });
  }

  const exportModel = (e) => {
    e.preventDefault();
    const apiname = "/export_stylegan";
    setExportconfirm(!exportconfirm);
    let projectid = isId();
    let data = { projectId: projectid }
    services
      .post(apiname, data)
      .then((response) => {
        toast.success("model exported successfully");
      })
      .catch((err) => {
        toast.error("Error in exporting model");
        console.log("Error is", err);

      });
  }
  return (
    <>

      {stopconfirm === false ? null : (
        <div className="popgridGan"
        >
          <div style={{ textAlign: "center" }}>
            <h4 style={{ fontSize: "24px", fontFamily: "Roobert TRIAL" }}>Stop Training?</h4>
            <p style={{ fontSize: "16px", fontWeight: 'lighter' }}>Are you sure you want to stop training?</p>
            <div className='d-flex justify-content-between'>
              <button
                type='button'
                className='backstylganbtn'
                onClick={() => setStopconfirm(!stopconfirm)}>
                BACK
              </button>
              <button
                type='button'
                className='stylganbtn'
                onClick={stopModel}>
                STOP
              </button>
            </div>
          </div>
        </div>
      )}
      {saveconfirm === false ? null : (
        <div className="popgridGan"
        >
          <div style={{ textAlign: "center" }}>
            <h4 style={{ fontSize: "24px", fontFamily: "Roobert TRIAL" }}>Save Model?</h4>
            <p style={{ fontSize: "16px", fontWeight: 'lighter' }}>Save model to project.</p>
            <br></br>
            <p style={{ fontSize: "24px", fontWeight: 'lighter', fontFamily: "Roobert TRIAL" }}>Retrained BARBIE STYLEGAN3.</p>

            <div className='d-flex justify-content-between'>
              <button
                type='button'
                className='backstylganbtn'
                onClick={() => setSaveconfirm(!saveconfirm)}>
                BACK
              </button>
              <button
                type='button'
                className='stylganbtn'
                onClick={saveModel}>
                SAVE
              </button>
            </div>

          </div>
        </div>
      )}


      {exportconfirm === false ? null : (
        <div className="popgridGan"
        >
          <div style={{ textAlign: "center" }}> <h4 style={{ fontSize: "24px", fontFamily: "Roobert TRIAL" }}>Export Model?</h4>
            <p style={{ fontSize: "16px", fontWeight: 'lighter' }}>Name and export model file to local directory.</p>
            <br></br>
            <p style={{ fontSize: "24px", fontWeight: 'lighter', fontFamily: "Roobert TRIAL" }}>Retrained BARBIE STYLEGAN3.</p>

            <div className='d-flex justify-content-between'>
              <button
                type='button'
                className='backstylganbtn'
                onClick={() => setExportconfirm(!exportconfirm)}>
                BACK
              </button>
              <button
                type='button'
                className='stylganbtn'
                onClick={exportModel}>
                EXPORT
              </button>
            </div></div>
        </div>
      )}

      {!dataview ? null : (
        <PopupModal>
          <div style={{ marginTop: "10px", position: "relative" }}>
            <p>DATASET</p>
            <i
              className="fas fa-times cross-camera"
              onClick={() => setDataview(false)}
            ></i>
          </div>
          <div className="dataviewGan">
            {datalist?.data?.map((fetched, index) => (
              <section key={index}>

                {path.basename(fetched.video).match(/\.(png|jpg|jpeg)$/) ? (
                  <div className="datasetList" key={index}>

                    <div className="checker">
                      <input
                        type="checkbox"
                        value={fetched.video}
                        onChange={checkhandler}
                      />
                    </div>
                    <div >

                      <img
                        src={fetched.video}
                        alt="dataimg"
                        width={"100px"} height={"100px"}
                      />                    </div>
                    <div className="listingContainer">
                      <p className="listname">{path.basename(fetched.video)}
                      </p>
                    </div>
                  </div>

                ) : null}


              </section>
            ))}{" "}
          </div>
          <button className="camerabtn" onClick={selectData}>
            Select
          </button>
        </PopupModal>
      )}

      {!activatecam ? null : (
        <PopupModal>
          <div style={{ marginTop: "15px", position: "relative" }}>
            <i
              className="fas fa-times cross-camera"
              onClick={() => setActivatecam(false)}
            ></i>
            <Webcam
              audio={false}
              screenshotFormat="image/jpeg"
              ref={webcamRef}
              videoConstraints={videoConstraints}
            />
            <button className="camerabtn" onClick={capture}>
              Capture
            </button>
          </div>
        </PopupModal>
      )}
      <div className='container-fluid'>
        <p style={{ fontSize: "14px" }}>PROJECTNAME/ MODELS/ BARBIE_STYLEGAN3/<span style={{ color: "#E5FF80" }}> RETRAIN</span> </p>
        <p style={{ fontSize: "40px" }}>BARBIE STYLEGAN3</p>

        <div className="stylegandiv">
          <div className="stylegandiv-item1">
            <p style={{ fontSize: "36px", fontStyle: "normal", fontWeight: "300" }}>
              <span className="itemno">1</span>UPLOAD DATA
            </p>
            <p style={{ fontSize: "14px", marginLeft: "60px", fontFamily: 'DM Mono' }}>*file format (png,jpg,jpeg)</p>

            <div className={imagelist?.length > 0 ? "uploadelementRight" : "uploadelement"}>
              <input
                type="file"
                id="fileImgR"
                name="dataUpload"
                onChange={onFileChange}
                style={{ display: "none" }}
                multiple="multiple"

              />
              <i className="fas fa-plus fa-sm " style={{ color: ' #C7CCB2' }} ></i>

              <img alt="" src={folderpic} className="uploadimg" onClick={() => setDataview(!dataview)} />
              <label htmlFor="fileImgR">
                <img alt="" src={uploadpic} className="uploadimg" htmlFor="fileImgR" />
              </label>
              <img alt="" src={camerapic} className="uploadimg" onClick={() => setActivatecam(!activatecam)} />
            </div>
            {imagelist?.length > 0 ? (
              <>
                <div className="styleGanImglist my-5 w-100">
                  {imagelist?.map((d, index) => (

                    <div className="showimgDiv" style={{ backgroundColor: ' #C7CCB2' }} key={index}
                      onMouseLeave={hidedelete}
                      onMouseEnter={() => showdelete(index)}>
                      {d.type === "camera" ? <img alt="" src={d.data} height="180px" width="180px"
                        style={{ borderRadius: "10px" }} className="mainGanimg" /> : null}
                      {d.type === "upload" ? <img src={URL.createObjectURL(d.data)} alt="img1" height="180px" width="180px"
                        style={{ borderRadius: "10px" }} className="mainGanimg" />
                        : null}
                      {d.type === "dataset" ? <img src={d.data} alt="img1" height="180px" width="180px"
                        style={{ borderRadius: "10px" }} className="mainGanimg" />
                        : null}
                      <label>{index}</label>
                      <div style={{ textAlign: "center" }} className={hoveredDel === index ? "delStyleGan" : "notdisplayed"}>
                        <img alt="delete" onClick={() => deleteImage(index)}
                          src={deletepic} height="20px" width="20px" color="#4E4E4E"
                        />
                        <p style={{ color: "#4E4E4E" }}>
                          delete
                        </p></div>
                    </div>

                  ))}
                </div></>
            ) :
              null
            }


          </div>
          <div className="stylegandiv-item2">
            <p style={{ fontSize: "36px", fontStyle: "normal", fontWeight: "300", color: "#C7CCB2" }}>
              <span className="itemno-contrast">2</span>RETRAIN THE MODEL
            </p>
            <p style={{ fontSize: "14px", marginLeft: "60px", fontFamily: 'DM Mono', color: "#C7CCB2" }}>{estimatedtime}</p>
            <div style={{ display: "flex" }}>
              <button
                type="button"
                onClick={trainModel}
                className={
                  imagelist.length >= 2
                    ? "whtactivebtn ms-3"
                    : "whtbtn ms-3"
                }
                disabled={imagelist.length >= 2 && !modeltraining ? false : true}
              >
                TRAIN
              </button>


              <button
                type="button"
                onClick={() => setStopconfirm(!stopconfirm)}
                className="whtbtn ms-3"
                style={{ display: modeltraining ? "block" : "none" }}
              >
                STOP
              </button>
            </div>
          </div>
          <div className="stylegandiv-item3" style={{ display: trained ? "block" : "none" }} >
            <p style={{ fontSize: "36px", fontStyle: "normal", fontWeight: "300" }}>
              <span className="itemno" style={{ backgroundColor: "4E4E4E" }}>3</span>DEMO
            </p>

            <div className='demorow'>
              <div className='colm'>
                <div className='darkbox'>
                  <div className='borderbox'>
                    <p>BARBIE STYLEGAN3</p>
                    <div className='whitebox'>
                      <p>STYLEGAN3</p>
                      <div className='blckbox'>
                        <span><img alt="" src={linkicon} />&nbsp;FFHQ</span>
                        <span><img alt="" src={linkicon} />&nbsp;MetFaces</span>
                        <span><img alt="" src={linkicon} />&nbsp;AFHQv2</span>
                      </div>
                    </div>
                    <center><img alt="" src={downarrow} /></center>
                    <div className='whiteborderbtn'>
                      Retrained with &nbsp;<span><img alt="" src={folderpic} style={{ width: '18px' }}></img></span>&nbsp;database Name
                    </div>
                  </div>
                  <center><img alt="" src={downarrow} /></center>
                  <div className='box'>
                    <div className='whiteborderbtn'>
                      Retrained with&nbsp;<span><img alt="" src={folderpic} style={{ width: '18px' }}></img></span>&nbsp;database Name
                    </div>
                  </div>
                </div>
                <div className='btnbox'>
                  <button className='darbbtn'
                    onClick={runModel} type="button"

                  >RUN MODEL</button>
                </div>
              </div>
              <div className='colm'>
                <div className="styleGanresultDiv  w-100">

                  {resultlist?.length > 3 ? (
                    <>
                      {resultlist?.map((d, index) => (
                        <div className='graybox' key={index}
                          onMouseLeave={hidedelete}
                          onMouseEnter={() => showdelete(index)}>

                          <img className="imgcardgan"
                            src={resultlist[index]}
                            alt="image1"
                            width="100%"
                            height="190px"

                          />
                          <div style={{ textAlign: "center" }} className={hoveredDel === index ? "delStyleGan" : "notdisplayed"}>
                            <img alt="download" onClick={() => downloadimage(resultlist[index])} style={{ borderRadius: "16x" }}
                              src={downloadpic} height="20px" width="20px" color="#4E4E4E"
                            />
                            <p style={{ color: "#4E4E4E" }}>
                              <b>download</b>
                            </p></div>
                        </div>
                      ))}
                    </>
                  ) :
                    <>    <div className='graybox'>
                    </div>
                      <div className='graybox'>
                      </div>        <div className='graybox'>

                      </div>        <div className='graybox'>

                      </div></>
                  }</div>

                <div className='demorow' style={{ paddingTop: 0 }}>
                  <div className='colm' style={{ paddingTop: 0 }}>
                    <div className='btnbox'>
                      <button className="borderbtn" type="button" onClick={() => setSaveconfirm(!saveconfirm)}
                        style={{ display: modelrun ? "block" : "none" }}
                      >SAVE</button>
                    </div>
                  </div>
                  <div className='colm' style={{ paddingTop: 0 }}>
                    <div className='btnbox' type="button" >
                      <button className="borderbtn" type="button" style={{ display: modelrun ? "block" : "none" }}
                        onClick={() => setExportconfirm(!exportconfirm)}>
                        EXPORT</button>
                    </div>

                  </div>
                </div>

              </div>
            </div>


          </div>

        </div></div>
    </>
  );
};

export default StyleGAN;
