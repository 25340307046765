import React, { useState } from 'react'
import { Navigate,useParams } from 'react-router-dom';
// import siteLogo from "../../Assets/Images/siteLogo.png";
import toast from 'react-hot-toast';
import { sharedData } from '../../Storage(Methods)/storage';
import services from '../../API_SERVICES/services';

// Screen of Shared data password Route Flow:-
const SharedDataPassword = () => {
    const [pass, setPass] = useState({
        password: '',
    });
    const { params } = useParams();
    const { password } = pass
    const [code, setCode] = useState("");
    React.useEffect(() => {
        getPassword();
    }, []);

    const id = params;
      const getPassword = () => {
        const apiname ="get-Shared-Password";
        services.getbasic(`${apiname}/${id}`)
          .then((response) => {
            if(response.data.password.length === 0){
                sharedData(true, () => {
                    setPass({ ...pass, password: '' });
                });
            } else {
                setCode(response?.data?.password)
            }
          }).catch((error) => {
            console.log(error);
          });
      }

    const Submit = (e) => {
        e.preventDefault(); 
        if (password !== code) {
            toast.error("Incorrect password")
            setPass({ ...pass, password: '' });
        } else {
            toast.success("correct password")
            sharedData(true, () => {
                setPass({ ...pass, password: '' });
            });
        }
    };
    const handleChange = (e) => {
        setPass({ ...pass, [e.target.name]: e.target.value });
    };

    const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
    return (
        <>
            {JSON.parse(localStorage.getItem('sharedDataKey')) === true ? <Navigate to='/sharedUploadData' state={id} replace={true} /> : null}
            <div style={{height:screenHeight}} className='shared-landing'>
            <div className='sharedCenterize'>
                {/* <img className="mb-5" src={siteLogo} alt="logo" width="200px" /> */}
                <svg width="160" height="111" viewBox="0 0 840 600" style={{marginBottom:"50px"}} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M839.813 300C839.813 465.685 705.498 600 539.813 600C374.127 600 239.813 465.685 239.813 300C239.813 134.315 374.127 0 539.813 0C705.498 0 839.813 134.315 839.813 300Z" fill="#0C090A"/>
    <path d="M178.17 38.7587C182.699 25.9541 194.702 17.4079 208.156 17.4079C225.743 17.4079 240 31.8176 240 49.5929V548.353C240 567.272 224.826 582.608 206.108 582.608H33.9204C10.4789 582.608 -5.88429 559.132 2.00661 536.823L178.17 38.7587Z" fill="#0C090A"/>
  </svg>
 
  <input
                    type='password'
                    className='sharedsiteinput mb-3'
                    placeholder='Enter your code...'
                    autoComplete='off'
                    name='password'
                    value={password}
                    onChange={handleChange}
                />
                <button
                    onClick={Submit}
                    className="sharedsitebtn"
                >
                    ENTER
                </button>
                
            </div>
            </div>
        </>
    )
}

export default SharedDataPassword