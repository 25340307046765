import React, { useState } from "react";
import { toast } from "react-hot-toast";
import profilepic from "../../Assets/Images/Adgar-Allan-Poe.png";
import services from "../../API_SERVICES/services";
import downloadpic from "../../Assets/Images/download.png";
import copypic from "../../Assets/Images/copy.png";

function TextModel() {

  const [resultText, setResultText] = useState("");
  const [words, setWords] = useState("");

  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    setResultText(e.target.value);
  };
  const min = 1;
  const max = 300;

  const handleWord = (e) => {
    const value = Math.max(min, Math.min(max, Number(e.target.value)));
    setWords(value);
  };
  const copy = () => {
    navigator.clipboard.writeText(resultText);
  };

  const refresh = () => {
    if (resultText !== '') {
      setResultText("");
      setWords("")
    }

  };
  const downloadTxtFile = () => {
    if (resultText !== '') {
      const element = document.createElement("a");
      const file = new Blob([document.getElementById('input').value],
        { type: 'text/plain;charset=utf-8' });
      element.href = URL.createObjectURL(file);
      element.download = "Poe_Eto.txt";
      document.body.appendChild(element);
      element.click();
    }

  }
  const spinCenter = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50% -50%)",
  };
  const runModel = async (e) => {
    e.preventDefault();
    setLoading(true);

    //const data = JSON.stringify({ "wordCount": parseInt(words) });
    const data = { "wordCount": parseInt(words) };


    const apiname = "run-textmodel";
 
    services
      .post(apiname, data)
      .then((response) => {
        setResultText(response.data.text);
        setLoading(false);
      }).catch(error => {
        toast.error("Error in running model");
        setLoading(false);

      }
      );

    // await axios
    //   .post("http://184.169.213.202:5000/textgeneration", {
    //     data: data,
    //   })
    //   .then(result => {
    //     console.log("RESPONSE COMMING IS ==>", result);
    //     setResultText(result.data.Text);
    //     setLoading(false);
    //   })
    //   .catch(error => {
    //     console.log("error COMMING IS ==>", error);
    //     toast.error("Error in running model");
    //     setLoading(false);

    //   }
    //   );





  }


  return (
    <div className="row  ps-3 " style={{ width: "100%",marginBottom:"50px"}}>
      
      <p style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between" }}>
        <span style={{ fontSize: "40px", fontWeight: "400" }}>POE-ETO TEXT GENERATION</span>
     
        <span style={{ fontSize: "16px", fontWeight: "400", marginRight: "20px" }}
          className={
            resultText !== ""
              ? "refreshText"
              : "refreshinactive"
          }
          disabled={resultText !== "" ? false : true}
          onClick={refresh}>REFRESH</span>
      </p>


      <div className="col">
        <div className="stylecard">
          <div className="card-body">
            <div className="d-flex justify-content-between " style={{ marginBottom: "20px" }}>
              <h4 className="card-title">
                <span className="no">1</span>INPUT
              </h4>
            </div>

            <p style={{ fontSize: "14px", fontWeight: '300' }}>
              This model was trained on the text corpus of Edgar Allan Poe’s short stories.

            </p>


          </div>

          <center>
            <img alt="Edgar Allan Poe" className="textimg"
              src={profilepic} />
            <p style={{ fontSize: "10px", fontWeight: '500', width: "217px" }}>Edgar Allen Poe. Image from
              <span style={{ fontSize: "10px", fontWeight: '500', fontStyle: "italic", marginBottom: "50px" }}> Encyclopædia Britannica.</span>

            </p>

          </center>
        </div>
      </div>

      <div className="col">
        <div className="stylecard">
          <div className="card-body">
            <div className="d-flex justify-content-between" style={{ marginBottom: "20px" }}>
              <h4 className="card-title">
                <span className="no">2</span>ACTIONS
              </h4>
            </div>

            <div className="divaction">
              <p style={{ fontSize: "14px", fontWeight: '300' }}>Type how many words to generate and then run the model by clicking “GENERATE TEXT”.</p>


              <div style={{ marginBottom: "20px", textAlign: "center" }}>
                <label> <input type="number" value={words} placeholder="#"
                  onChange={handleWord} className="textstyleword" /> Words</label>
                <p className="textcount">max 300</p>

              </div>
              <center>
                <button
                  type="button"
                  onClick={runModel}
                  className={
                    words !== ""
                      ? "blkactiveTextbtn"
                      : "blkTextbtn "
                  }
                  disabled={words !== "" ? false : true}


                >
                  GENERATE TEXT
                </button>
              </center>
            </div>

          </div>

        </div>
      </div>

      <div className="col">
        <div className="stylecard" style={{ position: 'relative' }}>
          <div className="card-body" >
            <div className="d-flex justify-content-between" style={{ marginBottom: "20px" }}>
              <h4 className="card-title">
                <span className="no">3</span>OUTPUT
              </h4>
            </div>
            {loading === false && (

              <div className="resultdiv">
                <textarea value={resultText} onChange={handleChange}
                  className="mt-2 scroll"
                  placeholder="..."
                  id="input"
                  rows={9}
                  disabled='true'
                  style={{
                    fontSize: "20px",
                    paddingLeft: 10,
                    width: "100%",
                    borderRadius: 15,
                    backgroundColor: "#4E4E4E",
                    color: "#FFFFFF",
                    resize: "none"
                  }}
                ></textarea>
                <div style={{
                  textAlign: "end",
                  padding: "8px"
                }}>
                  {/* <span className="iconCopy" onClick={() => copy()} disabled={resultText !== "" ? false : true}
                  ><i className="far fa-regular fa-copy" />
                  </span>
                  <span className="iconCopy" onClick={downloadTxtFile} disabled={resultText !== "" ? false : true}>
                  <i className="far fa-download" />
                  </span> */}
                  
                  <img className="iconCopy" alt="download" src={downloadpic} height="20px" width={"20px"} onClick={() => copy()} disabled={resultText !== "" ? false : true}></img>
                  <img  className="iconCopy" alt="copy" src={copypic} height="20px" width={"20px"} onClick={() => downloadTxtFile()} disabled={resultText !== "" ? false : true}></img>

                  </div>


              </div>

            )}
            {loading === true && (
              <div style={spinCenter}>
                <div
                  className="spinner-border"
                  style={{ width: "3rem", height: "3rem" }}
                  role="status"
                ></div>
              </div>
            )}

          </div>
        </div>
      </div>
    </div>
  )
}

export default TextModel
