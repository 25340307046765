import React, { useEffect, useState } from "react";
import services from "../../API_SERVICES/services";
import { useMenuContext } from '../../Components/Navbar/menuContext';


const Frameexplorer = () => {
    const { setShowAdditionalMenu, setActiveMenuItem } = useMenuContext();

    const [framesdata, setFramesdata] = useState({
        frames: "",
        loading: false,
        expand:false,
        selectedframe:""
    });
    const { frames, loading,expand, selectedframe} = framesdata;

    const [query, setQuery] = useState("");
 
    const search_parameters = ["frame_name", "frame_desc"];
    function search(data) {
        return data.filter(
            (item) =>
                search_parameters.some((parameter) =>
                    item[parameter].toString().toLowerCase().includes(query)
                )
        );
    }
    const spinCenter = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translateX(-50% -50%)",
    };


    const inputStyle = {
        width: "40%",
        borderRadius: "20px",
        textAlign: "center",
        backgroundColor: "#BCBFAA",
        color: "#202020",
        opacity: "70%",
        border: "0px solid black  ",
        marginLeft: "30px",
        height: "40px",
    };


    const Notfound = {
        fontWeight: "bold",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50% , -50%)",
    };
    useEffect(() => {
        setShowAdditionalMenu(true);
        setActiveMenuItem("FRAMES");
        loadFrames();

        return () => {
            setShowAdditionalMenu(false); 
            setActiveMenuItem(null);
          };
    }, []);
    const loadFrames = () => {
        const apiname = "fetch-public-frames";
        services
            .getbasic(apiname)
            .then((response) => {
                setFramesdata({
                    ...framesdata,
                    frames: response.data,
                    loading: true
                });
            })
            .catch((error) => {
                console.log(error.response.data.error);
            });
    };
    return (
        <>


{!expand ? null : (
            <div className="outercontainer">
              <div className="gridframeContributor">
             <div className="framegridContainer">
          <div className="ExpandGridFrame">
              <p style={{fontSize:"42px",color:"#4E4E4E",textTransform:"uppercase"}}>{selectedframe?.frame_name}</p>
          
          
            <i
                    className="far fa-times-circle"
                    onClick={() => setFramesdata({ ...framesdata, expand: !expand })}
                  ></i>
          </div>
          <div className="ExpandGrid">
            <div style={{height:"220px"}}>
              <h5>Description</h5>
              <p  style={{paddingLeft:"10px",width:"80%"}}>{selectedframe?.frame_desc}</p>
            </div>
            <div style={{height:"220px"}}>
              <h5>Inspiration And Attributes</h5>
              <p style={{paddingLeft:"10px",width:"80%"}}>{selectedframe?.frame_attr}</p>
            </div>
            <div style={{height:"220px"}}>
              <h5>Contributions</h5>
            </div>
            <div style={{height:"220px"}}> 
            <img
              src={selectedframe?.frame_image}
              alt="frameimage"
              width="100%"
              height="210x"
              style={{ borderRadius: "10px" }}
            />
            </div>
            
          </div>

          </div>
        </div>        </div>)}



         
                <input
                    type="search"
                    placeholder="SEARCH FRAMES"
                    className="searchinput"
                    style={inputStyle}
                    onChange={(e) => setQuery(e.target.value)}

                ></input>
            

            {loading && frames?.data?.length > 0 ? (
                <div className="projectList my-5">
                    {search(frames?.data)?.map((d, index) => (
                        <div className="cardStyle" 
                      >
                            <div key={index}     onClick={() => {
                        setFramesdata({ ...framesdata, expand: true, selectedframe: d });
                      }}>
                                <h4 style={{ fontSize: "22px", color: "#8EB200", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", }}>
                                    {d.frame_name}
                                </h4>
                                <p className="max-lines"

                                > {d.frame_desc}
                                </p>

                                <img src={d.frame_image} className="imageStyle" alt="frame" />
                                {/* <div className="vote">
                                    <div className="upvote"><i className="fas fa-arrow-up" style={{ fontSize: "12px", color: "#8EB200" }}></i> <span>12</span></div>
                                    <div className="downvote"><i className="fas fa-arrow-down" style={{ fontSize: "12px", color: "#8EB200" }}></i> <span>2</span></div>

                                </div> */}
                            </div>
                        </div>

                    ))}
                </div>
            ) : frames?.data?.length === 0 ? (
                <p className="text-center" style={Notfound}>
                    <i className="fas fa-empty-set"></i> No Frames Found
                </p>
            ) : (
                <div style={spinCenter}>
                    <div
                        className="spinner-border"
                        style={{ width: "3rem", height: "3rem" }}
                        role="status"
                    ></div>
                </div>
            )}
        </>
    );
};

export default Frameexplorer;

