import React from "react";
import { isAuth } from "../Storage(Methods)/storage";
import {Navigate } from "react-router-dom";
export const PrivateRoute = ({ children }) => {
  return JSON.parse(localStorage.getItem("status")) === true && isAuth() ? (
    children
  ) : (
    <Navigate to="/landing" replace={true} />
  );
};
