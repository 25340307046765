import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import linkicon from "../../Assets/Images/linkicon.png";
import { ComingsoonDialog } from "../comingsoon";

const StyletransferModel = (props) => {
  const navigate = useNavigate();
 const {closeModel}=props;
  const [alert, setAlert] = useState(false);
const closeAlert=()=>{
setAlert(false);
}
  return (
    <>
          {alert && <ComingsoonDialog closeAlert={closeAlert}/>}

      <div className="backdrop"  onClick={closeModel}>      </div>

        <div className="backdropinner" style={{ height: "700px",width: "94%" }} >
          <div style={{ borderRadius: "23px" }}>
            <div className="ExpandGridFrame">
              <p
                style={{
                  fontSize: "42px",
                  color: "#4E4E4E",
                  textTransform: "uppercase",
                }}
              >
                STYLE TRANSFER
              </p>
            </div>
            <div className="row">
              <div className="col-sm-6 ">
                <div className="modelstylegrid">
                <div style={{ overflowY: "scroll" }}>
                    {" "}
                    <h5>About</h5>
                    <p style={{ paddingLeft: "10px", width: "80%" }}>
                    TRANSFER STYLE OF ONE IMAGE TO THE CONTENT OF ANOTHER IMAGE
</p>
                    <p style={{ paddingLeft: "10px", width: "80%" }}>

Style Transfer is a technique in computer vision and graphics that involves generating a new image by combining the content of one image with the style of another image. The goal of style transfer is to create an image that preserves the content of the original image while applying the visual style of another image.
                    </p>
                  </div>
                  <div>
                    <h5>ATTRIBUTIONS & contributors</h5>
                    
                    <p style={{ paddingLeft: "10px", width: "80%" }}>
                     
                      <img
                        src={linkicon}
                        alt="LinkIcon"
                        height={"10px"}
                        width={"20px"}
                        style={{ marginTop: "6px" }}
                      /> {" "}
                      <a
                        style={{ color: "rgba(0, 0, 0, 0.7)" }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://arxiv.org/pdf/1508.06576v2.pdf"
                      >
                        A Neural Algorithm of Artistic Style
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="modelmapdiv">
                  <h5>MODEL MAP</h5>

                  <div className="modelmap">
                    <p
                      style={{
                        fontSize: "20px",
                        color: "#4E4E4E",
                        textTransform: "uppercase",
                      }}
                    >
                      VGG-19 CNN
                    </p>

                    <div className="modelmapinner">
                      <img
                        src={linkicon}
                        alt=""
                        height={"10px"}
                        width={"20px"}
                        style={{ marginTop: "6px" }}
                      />
                      &nbsp;   <a
                        style={{ color: "rgba(234, 237, 222, 1)" }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.image-net.org/"
                      >
                        IMAGENET
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <br></br>
              <button
                type="button"
                className="demomodelbutton"
                onClick={() =>
                  navigate("/styletransfer-model", {
                    replace: true,
                    state: { source: "modelexplorer" },
                  })
                }
              >
                DEMO
              </button>
              <button
                type="button"
                className="retrainmodelbutton"
                onClick={() => setAlert(true)}
              >
                RETRAIN
              </button>
            </div>
          </div>
        </div>
    </>
  );
};

export default StyletransferModel;
