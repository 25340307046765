// import React from 'react'

// const ProtoType = (props) => {
//     return (
//         <div className='proptoType-container'>
//             <div className='main-container'>
//                 <div className='button-container'>
//                     <button className='custom_button' onClick={props.modelsView}>MODELS</button>
//                     <button className='custom_button' onClick={props.dataView} >DATA</button>
//                     <button className='custom_button' onClick={props.textEditor}>+ TEXT EDITOR</button>
//                 </div>
//                 <div className="Appearance-container">
//                     <span>PROTOTYPES WILL APPEAR HERE  </span>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default ProtoType


import React from 'react'
 
 
const ProtoType = (props) => {
 
    const cardStyle = {
        borderRadius: "20px",
        backgroundColor: "#EAEDDE",
        padding: "16px",
        height: "285px",
        width: "400px",
        marginTop: "10px",
        marginLeft: "5px",
        marginBottom: "20px"
    };
 
    // function search(data) {
    //     const filteredDATA = data.filter((node) =>
    //         props.selectedType.length > 0
    //             ?
    //             props.selectedType.some((filterTag) =>
    //                 node['type'].includes(filterTag)
    //             )
    //             : data)
    //     return filteredDATA.filter(
    //         (item) =>
    //             props.search_parameters.some((parameter) =>
    //                 item[parameter].toString().toLowerCase().includes(props.query)
    //             )
    //     );
    // }
 
    return (
        <div className='proptoType-container'>
            <div className='main-container'>
                <div className='button-container'>
                    <button className='custom_button' onClick={props.modelsView}>MODELS</button>
                    <button className='custom_button' onClick={props.dataView} >DATA</button>
                    <button className='custom_button' onClick={props.textEditor}>+ TEXT EDITOR</button>
                </div>
                {props.modelData?.length > 0 ? (
                    <div className="blackmodelbox-list">
                        {props.modelData.map((item, index) => (
                            <div style={cardStyle}>
                                <div key={index.toString()}>
                                    {/* <h4 style={{ fontSize: "25px", textTransform: "uppercase" }}>{item.name}</h4> */}
                                    <h4
                                        style={{
                                            width: "100%",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            fontSize: "25px",
                                            textOverflow: "ellipsis",
                                            textTransform: "uppercase"
                                        }}
                                    >
                                        {item.model_type}
                                    </h4>
                                </div>
                                <div style={{ marginTop: "200px" }}>
                                    <p
                                        style={{ textDecoration: "none", color: "rgba(78, 78, 78, 0.7) " }}
                                        onClick={() => props.onClick(item.model_type)}
                                        className='text-hover'
                                    >
                                        DEMO
                                    </p></div>
                            </div>
                        ))}
                    </div>
                ) :
                    <div className="Appearance-container">
                        <span>PROTOTYPES WILL APPEAR HERE  </span>
                    </div>
                }
            </div>
        </div>
    )
}
 
export default ProtoType