import React, { useEffect} from 'react';
import {useNavigate} from 'react-router';
import {toast } from 'react-hot-toast';
import {isAuth} from '../../Storage(Methods)/storage';
import services from '../../API_SERVICES/services';
const UserProfileView = () => {
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    user_bio: '',
    user_location: '',
    user_occupation: '',
    user_own_info: '',
    profileimage:'',
    screen8:false,
  });

  const isStateEmpty = () => {
    for(let x of Object.values(state)){
      if(x.length === 0){
        return true;
      }else {
        return false;
      }
    }
  }

  useEffect(() => {
    loadProfile();
  });

  const onFileChange = (event) => {
    event.preventDefault();
    var formData = new FormData();
    formData.append('profileUpload', event.target.files[0]);
    formData.append('id', isAuth().id)
    const apiname = "user/image-upload"
    services.post(apiname,formData).then((response) => {
      toast.success(response.data.message)
      loadProfile();
    }).catch(error => {
      toast.error(error.response.data.error)
    })
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const { user_bio, user_location, user_occupation, user_own_info, profileimage,screen8 } = state;

  const loadProfile = () => {
    const apiname = "user/get-profile-data"
     services.get(apiname)
      .then((response) => {
        const {
          user_bio,
          user_location,
          user_occupation,
          user_own_info,
          profileimage
        } = response.data;
        setState({
          ...state,
          user_bio,
          user_location,
          user_occupation,
          user_own_info,
          profileimage
        });
      })
      .catch((error) => {
        console.log(error)
        setState({
          ...state,
        screen8:true});
      });
  };
  const uploadProfile = () => {
    const apiname = "user/profile-upload"
    const data = {
      user_id:isAuth().id,
      user_bio:user_bio,
      user_location:user_location,
      user_occupation:user_occupation,
      user_own_info:user_own_info
    }
     services.post(apiname,data)
      .then((response) => {
        toast.success(response.data.message);
        setTimeout(() => {
          navigate('/UserAddNewProject',{replace:true});
        }, 2300);
      })
      .catch((error) => {
        toast.error(error.response.data.error);
      });
  };

  return (
    <>
 {!screen8 ? null : (
  <div className="outercontainer">
              <div className="gridProfile">
          <div style={{textAlign:'center'}}>
          <h4>PRIVACY NOTICE</h4>
          <div className='gridProfilediv'>
          <p style={{fontSize:"16px",fontWeight:'lighter' }}>
            Please only include information as specific as you would like to share. Keep in mind that this information will be public and shared everytime you join a project.
            </p>
          </div>
          
            <button
            style={{fontSize:"16px",backgroundColor:'black',color:'white',width:'100%',height:'60px'}}
                    type='button'
                    className='backbtndelete'
                    onClick={() =>
                      setState({...state, screen8:!screen8})
                    }>
                    CONTINUE
                  </button>
          </div>
        
          </div>
          </div>
        )}

      <div className='profileViewMainContainer'>
        <div className='handingView'>
          <h2 className='handingViewText'>
            START YOUR PROFILE, OR COME BACK LATER (OPTIONAL)
          </h2>
        </div>
      </div>
      <div className='row ms-3 me-3 mt-4'>
        <div className='col-sm-4 col-lg-4 col-md-4'>
          <div className='profileBoxView'>
            <p className='textHandingStyle'>1. UPLOAD A PROFILE PHOTO</p>
            {!profileimage ? (
              <div className='centeringview'>
                <div className='userProfileCircleView'>
                  <label htmlFor='file' id='filelabel'>
                    <i className='fas fa-plus' style={{ color: '#5B5E52' }}></i>{' '}
                  </label>
                  <input
                    type='file'
                    id='file'
                    name='profileUpload'
                    onChange={onFileChange}
                    style={{ display: 'none', visibility: 'none' }}
                  />
                </div>
              </div>
            ) : (
              <>
                <div className='centeringview'>
                  <img
                    src={profileimage}
                    style={{
                      width: '16.75rem',
                      height: '16.75rem',
                      borderRadius: '50%',
                    }}
                    alt='profimage'
                  />
                  <label htmlFor='file' id='filelabel'>
                    <i
                      className='fas fa-camera'
                      style={{ color: 'white', fontSize: '25px' }}></i>
                  </label>
                  <input
                    type='file'
                    id='file'
                    name='profileUpload'
                    onChange={onFileChange}
                    style={{ display: 'none', visibility: 'none' }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className='col-sm-8 col-md-8 col-lg-8'>
          <div className='textInputRow'>
            <div className='numberCountView'>
              <p className='textHandingStyle'>2.</p>
            </div>
            <div className='textInputView'>
              <p className='textHandingStyle'>WRITE A SHORT BIO</p>
              <input
                type='text'
                className='inputBoxView'
                placeholder={!user_bio ? 'Type here...' : null}
                autoComplete='off'
                name='user_bio'
                value={user_bio}
                onChange={handleChange}
                style={{
                  backgroundColor: '#C7CCB2',
                }}
              />
            </div>
          </div>
          <div className='textInputRow'>
            <div className='numberCountView'>
              <p className='textHandingStyle'>3.</p>
            </div>
            <div className='textInputView'>
              <p className='textHandingStyle'>LOCATION (OPTIONAL)</p>
              <input
                type='text'
                className='inputBoxView'
                placeholder={!user_location ? 'Type here...' : null}
                autoComplete='off'
                name='user_location'
                value={user_location}
                onChange={handleChange}
                style={{
                  backgroundColor: '#C7CCB2',
                }}
              />
            </div>
          </div>
          <div className='textInputRow'>
            <div className='numberCountView'>
              <p className='textHandingStyle'>4.</p>
            </div>
            <div className='textInputView'>
              <p className='textHandingStyle'>SKILLS&ensp;&&ensp;INTERESTS</p>
              <input
                type='text'
                className='inputBoxView'
                placeholder={!user_occupation ? 'Type here...' : null}
                autoComplete='off'
                name='user_occupation'
                value={user_occupation}
                onChange={handleChange}
                style={{
                  backgroundColor: '#C7CCB2',
                }}
              />
            </div>
          </div>
          <div className='textInputRow'>
            <div className='numberCountView'>
              <p className='textHandingStyle'>5.</p>
            </div>
            <div className='textInputView'>
              <p className='textHandingStyle'>ADD YOUR OWN...</p>
              <input
                type='text'
                className='inputBoxView'
                placeholder={!user_own_info ? 'Type here...' : null}
                autoComplete='off'
                name='user_own_info'
                value={user_own_info}
                onChange={handleChange}
                style={{
                  backgroundColor: '#C7CCB2',
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {isStateEmpty() ? (
        <div className='buttonRow'>
          <button
            className='skipButtonBoxView'
            onClick={() => navigate('/projectsetup',{replace:true})}>
            <h5 className='buttonHeadingText'>SKIP</h5>
          </button>
          <button
            className='completeProfileButtonBoxView'
            onClick={uploadProfile}>
            <h5 className='buttonHeadingText'>COMPLETE PROFILE</h5>
          </button>
        </div>
      ) : (
        <div className='buttonRow'>
          <button
            className='skipButtonBoxView'
            onClick={() => navigate('/UserAddNewProject',{replace:true})}>
            <h5 className='buttonHeadingText'>IGNORE CHANGES</h5>
          </button>
          <button
            className='completeProfileButtonBoxView'
            onClick={uploadProfile}>
            <h5 className='buttonHeadingText'>SAVE CHANGES</h5>
          </button>
        </div>
      )}
    </>
  );
};

export default UserProfileView;
