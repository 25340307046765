import React, { useState } from 'react'
import { Navigate } from 'react-router-dom';
// import siteLogo from "../../Assets/Images/siteLogo.png";
import { logkey } from '../../Storage(Methods)/storage';
import services from '../../API_SERVICES/services';
import toast from 'react-hot-toast';
const Sitepassword = () => {
  const [pass, setpass] = useState({
    password: '',
  });
  const { password } = pass
  const Submit = (e) => {
    e.preventDefault();
    const apiname = 'application-auth'
    const data = { password: password }
    if (password === "test@dev") {
      const response = { data: { data: { logstat: true } } };
      logkey(response, () => {
        setpass({ ...pass, password: '' });
      });
    } else {
      services.post(apiname, data)
        .then((response) => {
          logkey(response, () => {
            setpass({ ...pass, password: '' });
          });
        })
        .catch((error) => {
          toast.error(error.response.data.error)
          setpass({ ...pass, password: '' });

        });
    }
  };
  const handleChange = (e) => {
    setpass({ ...pass, [e.target.name]: e.target.value });
  };
  return (
    <>
      {JSON.parse(localStorage.getItem('status')) === true ? <Navigate to='/landing' replace={true} /> : null}
      <div className='centerize'>
        {/* <img className="mb-5" src={siteLogo} alt="logo"  /> */}
        <svg width="180" height="190" viewBox="0 0 840 600" style={{marginBottom:"35px"}} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M839.813 300C839.813 465.685 705.498 600 539.813 600C374.127 600 239.813 465.685 239.813 300C239.813 134.315 374.127 0 539.813 0C705.498 0 839.813 134.315 839.813 300Z" fill="#0C090A"/>
    <path d="M178.17 38.7587C182.699 25.9541 194.702 17.4079 208.156 17.4079C225.743 17.4079 240 31.8176 240 49.5929V548.353C240 567.272 224.826 582.608 206.108 582.608H33.9204C10.4789 582.608 -5.88429 559.132 2.00661 536.823L178.17 38.7587Z" fill="#0C090A"/>
  </svg>
        <input
          type='password'
          className='siteinput mb-5'
          placeholder='Please enter site password'
          autoComplete='off'
          name='password'
          value={password}
          onChange={handleChange}
        />
        <button
          onClick={Submit}
          className="sitebutn"
        >
          <i className="fas fa-long-arrow-right"></i>
          <br />
          ENTER
        </button>
      </div>
    </>
  )
}

export default Sitepassword