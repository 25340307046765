import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Menu = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='container-fluid'>
                <div className="menucontainer w-100">
                    <div className="px-0">
                        <p style={{color: '#4E4E4E', fontSize: '74px', marginLeft: '40px',marginBottom:"0px" ,marginTop:"0px"}}>
                            EXPLORE
                        </p>
                        <div className='menucontainerLeft1 text-hover' onClick={() => navigate('/data-contribute', { replace: true })}>
                            <h3 style={{ color: '#4E4E4E',fontSize: '36px', fontWeight:'300'}}>
                                PROJECTS
                            </h3>
                            <h6 style={{fontSize: '14px' ,color: 'rgba(78, 78, 78, 0.7)',}} >
                                VIEW PROJECT DIRECTORY
                            </h6>
                        </div>
                        <div className='grid-container-menu' style={{marginBottom:"40px"}}>
                            <div className='menubox  text-hover' onClick={() => navigate('/public-frames', { replace: true })}>
                            
                                <h1
                                    style={{
                                        paddingTop: '110px',
                                        color: '#4E4E4E',fontSize: '36px', fontWeight:'300'
                                    }}>
                                    FRAMES
                                </h1>
                                <h6 style={{ fontSize: '14px' ,color: 'rgba(78, 78, 78, 0.7)',}}>
                                    VIEW FRAME LIBRARY
                                </h6>
                            </div>
                            <div className='menubox  text-hover' onClick={() => navigate('/public-dataset', { replace: true })}>
                                <h1
                                    style={{
                                        paddingTop: '110px',
                                        color: '#4E4E4E'
                                        ,fontSize: '36px', fontWeight:'300'
                                    }}>
                                    DATA
                                </h1>
                                <h6 style={{ fontSize: '14px' ,color: 'rgba(78, 78, 78, 0.7)'}}>
                                    VIEW PUBLIC DATASETS
                                </h6>
                            </div>
                            <div className='menubox  text-hover' onClick={() => navigate('/model-explorer', { replace: true })}>
                                <h1
                                    style={{
                                        paddingTop: '110px',
                                        color: '#4E4E4E',fontSize: '36px', fontWeight:'300'
                                    }}>
                                    MODELS
                                </h1>
                                <h6 style={{ fontSize: '14px' ,color: 'rgba(78, 78, 78, 0.7)'}}>
                                    VIEW MODEL LIBRARY
                                </h6>
                            </div>
                        </div>

                    </div>
                    <div >
                        <p style={{ color: '#4E4E4E', fontSize: '74px',marginBottom:"0px" ,marginTop:"0px" }}>
                            MAKE
                        </p>
                        <div className="menucontainerRight">
                            <div className='menucontainerRight-item text-hover' id="menuR1" onClick={() => navigate('/projectpage',{replace:true})}>
                                <i className="fa-regular fa-plus fa-xs"></i>
                                <h6 style={{ fontSize: '18px' ,color: 'rgba(0, 0, 0, 0.54)',marginTop:"10px"}}>
                                    NEW ENCODE PROJECT
                                </h6>
                            </div>
                            <div className='menucontainerRight-item text-hover' id="menuR2" >
                            <i className="fa-regular fa-plus fa-xs"></i>
                                <h6 style={{ fontSize: '18px' ,color: 'rgba(0, 0, 0, 0.54)',marginTop:"10px"}}>
                                    NEW DECODE PROJECT
                                </h6>
                            </div>

                            <div className='menucontainerRight-item text-hover' id="menuR3">
                            <i className="fa-regular fa-plus fa-xs"></i>
                                    <h6 style={{ fontSize: '18px',color: 'rgba(0, 0, 0, 0.54)' ,marginTop:"10px"}}>
                                        NEW TRANSFORM PROJECT
                                    </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
