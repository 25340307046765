import React, {  useEffect } from "react";
import { toast } from 'react-hot-toast'
import {  useNavigate } from "react-router-dom";
import services from "../../API_SERVICES/services";
import { useParams } from 'react-router-dom';
import { logkeyinvite, isAuth, signout } from '../../Storage(Methods)/storage';
import { joinProject } from "../../Components/utility";

const jwt = require("jsonwebtoken");

const InviteView = ({ match }) => {
  const { params } = useParams();
  const history = useNavigate();
 
  useEffect(() => {

    const secret = `${process.env.REACT_APP_INVITATION_RESET_PASSWORD}`
    jwt.verify(params, secret, (err, decode) => {
      if (err) {
        toast.error("Expired Link. Try again");     
        setTimeout(() => history("/login"), 5000);
      }
      else {
        var decodedClaims = jwt.verify(params, secret);
        const email_id = decodedClaims.email;
        logkeyinvite();//bypsaa sitepassword

        if (isAuth() && isAuth().email === decodedClaims.email) {

          //already loggedin join and redirect
          joinProject(decodedClaims.email,decodedClaims.projectid,isAuth().id,isAuth().username);
          setTimeout(() => history("/UserAddNewProject", { replace: true }), 5000);

        }
        
        else {
          if (isAuth() && isAuth().email !== decodedClaims.email) {
            signout();
          }

          //check whether user exist or not
          const apiname = "check-collabrator";
          services.getbasic(apiname, email_id)
            .then((response) => {
              const userVal={
                projId: decodedClaims.projectid,
                emailId: decodedClaims.email,
                projName: decodedClaims.projectName,
                useName: decodedClaims.userName,
                tokenexpire: false,
                show: "loginView"

              }
              history("/login", { state: { data: userVal, source: "invite" } }, { replace: true })

            })
            .catch((error) => {
              const userVal={
                projId: decodedClaims.projectid,
                emailId: decodedClaims.email,
                projName: decodedClaims.projectName,
                useName: decodedClaims.userName,
                tokenexpire: false,
                show: "signupView"

              }
              history("/login", { state: { data: userVal, source: "invite" } }, { replace: true })

            });
        }

      }
    });

  }, []);

  const spinCenter = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50% -50%)",
  };
  return (
    <>
 <div style={spinCenter}>
                  <div
                    className="spinner-border"
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  ></div>
                </div>
    </>
  );
};
export default InviteView;
