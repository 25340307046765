import React from "react";
import * as tf from '@tensorflow/tfjs';
import * as speechCommands from "@tensorflow-models/speech-commands";

export const Soundtransfer = () => {   
    let recognizer;
    let examples = [];

    async function createSelect() {
    var NUM_FRAMES = parseInt(document.getElementById("num_sound_class").value);
    await app(NUM_FRAMES);
    var select = document.createElement("SELECT");
    select.setAttribute("id", "class_select");
    document.getElementById("btn_parent").appendChild(select);
    for(var i = 1;i<=NUM_FRAMES;i++) {
        var item = String(i);
        var newOption = document.createElement("option");
        newOption.setAttribute("value", item);
        var textNode = document.createTextNode(item);
        newOption.appendChild(textNode);
        select.appendChild(newOption);
    }
    
    }
    function collect_samples(){
        let current_class;
    current_class = document.getElementById("class_select").value;
    var class_num = parseInt(current_class);
    var NUM_FRAMES = parseInt(document.getElementById("num_sound_class").value);
    collect(class_num, NUM_FRAMES);
    }
    
    // document.getElementById("stop_samples").onClick = function(NUM_FRAMES){
    //     collect(null, NUM_FRAMES);
    // };


    function collect(label, NUM_FRAMES) {
        if (recognizer.isListening()) {
            return recognizer.stopListening();
        }
        if (label == null) {
            return;
        }
        recognizer.listen(async ({spectrogram: {frameSize, data}}) => {
        let vals = normalize(data.subarray(-frameSize * NUM_FRAMES));
        examples.push({vals, label});
        document.querySelector('#console').textContent =
            `${examples.length} examples collected`;
        }, {
        overlapFactor: 0.999,
        includeSpectrogram: true,
        invokeCallbackOnNoiseAndUnknown: true
        });
    }

    function normalize(x) {
    const mean = -100;
    const std = 10;
    return x.map(x => (x - mean) / std);
    }

    // const INPUT_SHAPE = [NUM_FRAMES, 232, 1];
    var model;

    async function train() {
    var NUM_FRAMES = parseInt(document.getElementById("num_sound_class").value);
    const INPUT_SHAPE = [NUM_FRAMES, 232, 1];
    // var model;
    toggleButtons(false);
    const ys = tf.oneHot(examples.map(e => e.label), NUM_FRAMES);
    const xsShape = [examples.length, ...INPUT_SHAPE];
    const xs = tf.tensor(flatten(examples.map(e => e.vals)), xsShape);

    await model.fit(xs, ys, {
    batchSize: 16,
    epochs: 10,
    callbacks: {
        onEpochEnd: (epoch, logs) => {
        document.querySelector('#console').textContent =
            `Accuracy: ${(logs.acc * 100).toFixed(1)}% Epoch: ${epoch + 1}`;
        }
    }
    });
    tf.dispose([xs, ys]);
    toggleButtons(true);
    }

    function buildModel(NUM_FRAMES) {
        const INPUT_SHAPE = [NUM_FRAMES, 232, 1];
        // var model; 
    model = tf.sequential();
    model.add(tf.layers.depthwiseConv2d({
    depthMultiplier: 8,
    kernelSize: [NUM_FRAMES, NUM_FRAMES],
    activation: 'relu',
    inputShape: INPUT_SHAPE
    }));
    model.add(tf.layers.maxPooling2d({poolSize: [1, 2], strides: [2, 2]}));
    model.add(tf.layers.flatten());
    model.add(tf.layers.dense({units: NUM_FRAMES, activation: 'softmax'}));
    const optimizer = tf.train.adam(0.01);
    model.compile({
    optimizer,
    loss: 'categoricalCrossentropy',
    metrics: ['accuracy']
    });
    return model;
    }

    function saveModel(){
        model.save('downloads://my-model');
    }

    function toggleButtons(enable) {
    document.querySelectorAll('button').forEach(b => b.disabled = !enable);
    }

    function flatten(tensors) {
    const size = tensors[0].length;
    const result = new Float32Array(tensors.length * size);
    tensors.forEach((arr, i) => result.set(arr, i * size));
    return result;
    }

    async function moveSlider(labelTensor) {
        const label = (await labelTensor.data())[0];
        document.getElementById('console').textContent = label;
        return;
        // let delta = 0.1;
        // const prevValue = +document.getElementById('output').value;
        // document.getElementById('output').value =
        //     prevValue + (label === 0 ? -delta : delta);
    }
    
    function listen() {
        var NUM_FRAMES = parseInt(document.getElementById("num_sound_class").value);
        const INPUT_SHAPE = [NUM_FRAMES, 232, 1];
        if (recognizer.isListening()) {
        recognizer.stopListening();
        toggleButtons(true);
        document.getElementById('listen').textContent = 'Listen';
        return;
        }
        toggleButtons(false);
        document.getElementById('listen').textContent = 'Stop';
        document.getElementById('listen').disabled = false;
        recognizer.listen(async ({spectrogram: {frameSize, data}}) => {
        const vals = normalize(data.subarray(-frameSize * NUM_FRAMES));
        const input = tf.tensor(vals, [1, ...INPUT_SHAPE]);
        const probs = model.predict(input);
        const predLabel = probs.argMax(1);
        await moveSlider(predLabel);
        tf.dispose([input, probs, predLabel]);
        }, {
        overlapFactor: 0.999,
        includeSpectrogram: true,
        invokeCallbackOnNoiseAndUnknown: true
        });
    }
    

    async function app(NUM_FRAMES) {
        recognizer = speechCommands.create('BROWSER_FFT');
        await recognizer.ensureModelLoaded();
        // predictWord() no longer called.
        buildModel(NUM_FRAMES);
    }

//     function showFunction() {
//     var x = document.getElementById("export_div");
//     if (x.style.display === "none") {
//         x.style.display = "block";
//     } else {
//         x.style.display = "none";
//     }
// }
// app();
    return(
    <>
    <h3 id="sound_header">Sound classification with TF ( Model training )</h3>
    <p id="sound_para">Instructions: Enter the number of classes, click on confirm. Record samples of each class, click train button to train & load the model then you can save or download the model, you can download the model after training</p>
    <div className="create_class_parent d-flex justify-content-center align-items-center">
        <input className="p-2" type="number" id="num_sound_class"/>
        <button className="ms-3" onClick={() => createSelect()} id="sound_confirm">Create classes</button>
    </div>
    <div className="mt-3 mb-3 d-flex justify-content-center align-items-center">
        <div id="console"></div>
    </div>
    <div className="row p-3 mt-3 mb-3 d-flex justify-content-center align-items-center text-center">
        <div className="sound_containers col p-3 me-3 ms-3">
            <p>1: Select class and click on add examples, every click adds an image</p>
            <button id="collect_samples" onClick={() => collect_samples()}><i class="fa-solid fa-play me-2"></i>Start recording</button>
            <button className="ms-3" id="stop_samples" onClick={() => collect(null)}><i class="fa-solid fa-stop me-2"></i>Stop recording</button>
            <div id="btn_parent">Class:  </div>
        </div>
        <div className="sound_containers col p-3 me-3">
            <p>2: After adding examples click on train to train your model, then listen to start classification</p>
            <button id="train" onClick={() => train()}><i className="fa-solid fa-gears me-2"></i>Train</button>
            <button id="listen" className="ms-3" onClick={() => listen()}><i className="fa-solid fa-circle-play me-2"></i>Listen</button>
        </div>
        <div className="sound_containers col p-3 me-3">
            <p>3: Save or download model after completing training</p>
            <button id="sound_downloadb" onClick={() => saveModel()}><i className="fa-solid fa-download me-2"></i>Download</button>
        </div>
    </div>
    </>
);
}

export default Soundtransfer;