import React from 'react'
import { Navigate } from "react-router-dom";

// To manage the DeepfaceRoute Routes
export const DeepfaceRoute = ({ children }) => {
    return (
        JSON.parse(localStorage.getItem('deepfaceStatus')) === true ? (
            children
        ) : (
            <Navigate
                to="/deepfaceMod"
                replace={false}
            />
        ))
}