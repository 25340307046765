import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { PrivateRoute } from "../ProtectedRoutes/privateroute";
import { ProjectPage } from "../Screens/Project-Setup";
// import { Encode } from "../Screens/Projectsetup1/encode";
import { ProjectDecode } from "../Screens/Decodeproject/ProjectDecode";
import Subdescframe from "../Screens/Framedescription/subdescframe";
import Modelspage from "../Screens/scaffoldmodel/modelspage";
import { Howinputtab } from "../Screens/Howdoesscreen/Howinputtab";
import CreateFrames from "../Screens/Howdoesscreen/CreateFrames";
import { Comingsoon } from "../Screens/Alertcomingsoon/comingsoon";
import { ModelComingSoon } from "../Screens/NeuralStyle/comingSoon";
import Login from "../Screens/Login/login";
import { Privacy } from "../Screens/Privacy/privacy";
import Seemoreprivacy from "../Screens/Privacy/seemoreprivacy";
import UserProfileView from "../Screens/UserProfileUpdate/UserProfileView";
import UserAddNewProject from "../Screens/UserAddNewProject/UserAddNewProject";
import ResetPasswordView from "../Screens/Login/ResetPasswordView";
import Datacontribute from "../Screens/Data_Contribution/data_contribute";
// import Expandproject from "../Screens/ExpandProject/expandproject";
import Landing from "../Screens/LandingPage/landingpage";
import { SecuredRoute } from "../ProtectedRoutes/securedroute";
import { PopupModal } from "../Screens/DataPrivacy/PopupModal";
import Projectcontributions from "../Screens/ExpandProject/projectcontributions";
import Editproject from "../Screens/ExpandProject/editproject";
import ExpandEditproject from "../Screens/ExpandProject/expandEditproject";
import { Reflectiondata } from "../Screens/Reflectiondata/reflection";
import Sitepassword from "../Screens/Site-password/sitepassword";
import { TokenVerifier } from "../Storage(Methods)/storage";
import { Editframes } from "../Screens/Howdoesscreen/editframes";
import ModelExecute from "../Screens/scaffoldmodel/ModelExecute";
import InviteView from "../Screens/Invite/InviteView";
import Accept from "../Screens/Invite/Accept";
import Reject from "../Screens/Invite/Reject";
import Modelpassword from "../Screens/Site-password/modelpassword";
import DataSetPassword from "../Screens/Site-password/dataSetPassword";
import { ModelRoute } from "../ProtectedRoutes/modelroute";
import { DataSetRoute } from "../ProtectedRoutes/dataSetRoute";
import Imagemodel from "../Screens/NeuralStyle/imagemodel";
import TextModel from "../Screens/NeuralStyle/textmodel";
import Modellist from "../Screens/NeuralStyle/modellist";
import ModelStyleGAN from "../Screens/scaffoldmodel/ModelStyleGAN"
import StyleGAN from "../Screens/scaffoldmodel/styleGAN"
import { Menu } from "../Screens/Projectsetup1/menu";
import Frameexplorer from "../Screens/Data_Contribution/frame_explorer";
import ProjectPageNew from "../Screens/ExpandProject/ProjectPage";
import Modelexplorer from "../Screens/Data_Contribution/model_explorer";
import DataExplorer from "../Screens/Data_Contribution/data_explorer";
// Model imports
import Mobilenethome from "../Screens/MobileNet/Mobilehome";
import Imagetransfer from "../Screens/MobileNet/MobileNet";
import Imagetrans from "../Screens/MobileNet/MobileDemo";
import Posetrans from "../Screens/PoseNet/PoseNet";
import Soundtransfer from "../Screens/Sound/Sound";
import Stylemodel from "../Screens/scaffoldmodel/stylemodel";
import UserProfileContributor from "../Screens/UserAddNewProject/UserProfleContributor";
import ChatGPTTrain from "../Screens/scaffoldmodel/chatgptTrain";
import ChatGPTDemo from "../Screens/scaffoldmodel/chatgptDemo";
import Dalle from "../Screens/OpenAi/dalle3";
import DataSetList from "../Screens/DataSetScreens/DataSetList";
import DataSetUpload from "../Screens/DataSetScreens/DataSetUploadData";
import DeepfacePassword from "../Screens/Site-password/deepfacePassword";
import { DeepfaceRoute } from "../ProtectedRoutes/deepfaceRoute";
import DeepfaceExecute from "../Screens/scaffoldmodel/deepfaceModel";
import GroupDataView from "../Screens/scaffoldmodel/groupDataView";
import SharedDataPassword from "../Screens/Site-password/sharedDataPassword";
import { SharedDataRoute } from "../ProtectedRoutes/sharedDataRoute";
import SharedUploadData from "../Screens/ExpandProject/sharedUploadData";

const Rout = () => {
  useEffect(() => {
    TokenVerifier();
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Sitepassword />} />
      <Route path="/reset/:token" element={<ResetPasswordView />} />
      <Route path="/invitation/:params" element={<InviteView />} />
      <Route path="/accept/:params" element={<Accept />} />
      <Route path="/reject/:params" element={<Reject />} />
      <Route path="/shared/:params" element={<SharedDataPassword/>} />
      <Route path="/models" element={<Modelpassword />} />
      <Route path="/deepfaceMod" element={<DeepfacePassword />} />
      <Route path="/dataSet" element={<DataSetPassword />} />
      <Route
        path="/deepfaceGlobal"
        element={
          <DeepfaceRoute>
            <DeepfaceExecute />
          </DeepfaceRoute>
        }
      />
      <Route path="/DemoDatabase" element={<GroupDataView/>} />
      <Route
        path="/landing"
        element={
          <SecuredRoute>
            <Landing />
          </SecuredRoute>
        }
      />
      <Route
        path="/login"
        element={
          <SecuredRoute>
            <Login />
          </SecuredRoute>
        }
      />
      <Route
        path="/projectsetup"
        element={
          <PrivateRoute>
            <Menu />
          </PrivateRoute>
        }
      />
      <Route
        path="/profileupdate"
        element={
          <PrivateRoute>
            <UserProfileView />
          </PrivateRoute>
        }
      />
      <Route
        path="/UserAddNewProject"
        element={
          <PrivateRoute>
            <UserAddNewProject />
          </PrivateRoute>
        }
      />
      <Route
        path="/projectpage"
        element={
          <PrivateRoute>
            <ProjectPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/decode"
        element={
          <PrivateRoute>
            <ProjectDecode />
          </PrivateRoute>
        }
      />
      <Route
        path="/CreateFrames"
        element={
          <PrivateRoute>
            <CreateFrames />
          </PrivateRoute>
        }
      />
      <Route
        path="/frames"
        element={
          <PrivateRoute>
            <Subdescframe />
          </PrivateRoute>
        }
      />
      <Route
        path="/modelspage"
        element={
          <PrivateRoute>
            <Modelspage />
          </PrivateRoute>
        }
      />
      <Route
        path="/Howinputtab"
        element={
          <PrivateRoute>
            <Howinputtab />
          </PrivateRoute>
        }
      />
      <Route path="/editframes"
        element={<PrivateRoute><Editframes /></PrivateRoute>} />
      <Route path="/Comingsoon" element={<PrivateRoute><Comingsoon /></PrivateRoute>} />
      <Route path="/Coming_soon" element={<ModelRoute><ModelComingSoon /></ModelRoute>} />
      <Route
        path="/privacy"
        element={
          <PrivateRoute>
            <Privacy />
          </PrivateRoute>
        }
      />
      <Route
        path="/seemoreprivacy"
        element={
          <PrivateRoute>
            <Seemoreprivacy/>
          </PrivateRoute>
        }
      />
      <Route
        path="/data-contribute"
        element={
          <PrivateRoute>
            <Datacontribute />
          </PrivateRoute>
        }
      />
      <Route
        path="/expand-project"
        element={
          <PrivateRoute>
            {/* <Expandproject /> */}
            <ProjectPageNew />
          </PrivateRoute>
        }
      />
      <Route
        path="/expand-edit-project"
        element={
          <PrivateRoute>
            <ProjectPageNew />
          </PrivateRoute>
        }
      />
      <Route
        path="/expand-fork-project"
        element={
          <PrivateRoute>
            <ExpandEditproject />
          </PrivateRoute>
        }
      />
      <Route
        path="/project-dbse"
        element={
          <PrivateRoute>
            <Projectcontributions />
          </PrivateRoute>
        }
      />
      <Route
        path="/popup-modal"
        element={
          <PrivateRoute>
            <PopupModal />
          </PrivateRoute>
        }
      />
      <Route
        path="/edit-project"
        element={
          <PrivateRoute>
            <Editproject />
          </PrivateRoute>
        }
      />
      <Route
        path="/reflection"
        element={
          <PrivateRoute>
            <Reflectiondata />
          </PrivateRoute>
        }
      />
      <Route
        path="/public-frames"
        element={
          <PrivateRoute>
            <Frameexplorer />
          </PrivateRoute>
        }
      />
      <Route
        path="/model-explorer"
        element={
          <PrivateRoute>
            <Modelexplorer />
          </PrivateRoute>
        }
      />
      <Route
        path="/public-dataset"
        element={
          <PrivateRoute>
            <DataExplorer />
          </PrivateRoute>
        }
      />
      <Route path="/model-execute" element={<PrivateRoute><ModelExecute /></PrivateRoute>} />
      <Route path="/model-stylegan3" element={<PrivateRoute><ModelStyleGAN /></PrivateRoute>} />
      <Route path="/stylegan" element={<PrivateRoute><StyleGAN /></PrivateRoute>} />
      <Route path="/styletransfer-model" element={<ModelRoute><Stylemodel /></ModelRoute>} />
      <Route path="/textgeneration-model" element={<ModelRoute><TextModel /></ModelRoute>} />
      <Route path="/chatgpt-train" element={<PrivateRoute><ChatGPTTrain /></PrivateRoute>} />
      <Route path="/chatgpt-demo" element={<PrivateRoute><ChatGPTDemo /></PrivateRoute>} />
      <Route path="/dalle-image" element={<ModelRoute><Dalle /></ModelRoute>} />
      <Route path="/dalle" element={<PrivateRoute><Dalle /></PrivateRoute>} />
      {/* Model routes */}
      <Route
        path="/Mobilenet"
        element={
          <PrivateRoute>
            <Mobilenethome />
          </PrivateRoute>
        }
      />
      <Route
        path="/Mobilenetmodel"
        element={
          <PrivateRoute>
            <Imagetransfer />
          </PrivateRoute>
        }
      />
      <Route
        path="/Mobilenetdemo"
        element={
          <PrivateRoute>
            <Imagetrans />
          </PrivateRoute>
        }
      />
      {/* Posenet routes */}
      <Route
       path="Posenet"
       element={
          <ModelRoute>
            <Posetrans/>
          </ModelRoute>
       }
      />
      <Route
       path="Pose"
       element={
          <PrivateRoute>
            <Posetrans/>
          </PrivateRoute>
       }
      />
      {/* Sound routes */}
      <Route
       path="Soundclassification"
       element={
          <ModelRoute>
            <Soundtransfer/>
          </ModelRoute>
       }
      />
      <Route
       path="Sound"
       element={
          <PrivateRoute>
            <Soundtransfer/>
          </PrivateRoute>
       }
      />
      <Route
        path="/modellist"
        element={
          <ModelRoute>
            <Modellist />
          </ModelRoute>
        }
      />
      <Route
        path="/dataSetList"
        element={
          <DataSetRoute>
            <DataSetList />
          </DataSetRoute>
        }
      />
       <Route
        path="/sharedUploadData"
        element={
          <SharedDataRoute>
            <SharedUploadData />
          </SharedDataRoute>
        }
      />
      <Route
        path="/dataSetProjectDataUpload"
        element={
          <DataSetRoute>
            <DataSetUpload />
          </DataSetRoute>
        }
      />
      <Route
        path="/styletransfer"
        element={
          <PrivateRoute>
            <Imagemodel />
          </PrivateRoute>
        }
      />
      <Route
        path="/textgeneration"
        element={
          <PrivateRoute>
            <TextModel />
          </PrivateRoute>
        }
      />
      <Route
        path="/deepfacemodel"
        element={
          <ModelRoute>
            <ModelExecute />
          </ModelRoute>
        }
      />
      <Route
        path="/deepface"
        element={
          <PrivateRoute>
            <ModelExecute />
          </PrivateRoute>
        }
      />
      <Route
        path="/Userprofile"
        element={
          <PrivateRoute>
            <UserProfileContributor />
          </PrivateRoute>
        }
      />
    </Routes>

  );
};
export default Rout;