import React from "react";
import { useNavigate } from "react-router";

const Landing = () => {
  const navigate = useNavigate();
  return (
    <>
      <div >
        <div className="landingcontent text-hover" onClick={() => navigate("/login", { replace: true })}>
          <h1
            className="text-center mb-2 mt-5 text-hover"
          >
            START MAKING
          </h1>

        </div>
      </div>
    </>
  );
};
export default Landing;
