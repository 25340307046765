import React, { useState } from "react";
import { HowDoesItDo } from "./WhatDoesItDo";
import { WhatDoesItDo } from "./Howdoesitdo";
import { FramesNavBar } from "./HeadarNavbar/FramesNavbar";

export const Howinputtab = () => {
  const [toggleshow, settoggleshow] = useState(1);
  const itemArray = [
    { name: "DISCOVER", value: "/decode" },
    { name: "FRAMES", value: "/CreateFrames" },
    { name: "BLUEPRINT", value: "/Howinputtab" },
    { name: "PROTOTYPE", value: "/modelspage" },
    { name: "REFLECTION", value: "/reflection" },
  ];

  return (
    <>
      <FramesNavBar labels={itemArray} isActive={"BLUEPRINT"} />
      <div className="mt-3">
        <button
          style={{ width: "50%" }}
          className={toggleshow === 1 ? "active1" : "inactive"}
          onClick={() => settoggleshow(1)}
        >
          <h3> WHAT DOES IT DO? </h3>
        </button>
        <button
          style={{ width: "50%" }}
          className={toggleshow === 2 ? "active1" : "inactive"}
          onClick={() => settoggleshow(2)}
        >
          <h3>HOW DOES IT DO?</h3>
        </button>
        {toggleshow === 1 ? <WhatDoesItDo /> : null}
        {toggleshow === 2 ? <HowDoesItDo /> : null}
      </div>
    </>
  );
};
