import React from "react";
export const PopupModal = ({ children}) => {
  return (
    <div className="outercontainer">
      <div className="gridFourth">
        <div className="gridcontent">{children}</div>
      </div>
    </div>
  );
};
