import { saveAs } from 'file-saver';

const FileService = {
    // Function to add a string to a file and download it
    downloadFileWithCustomExtension: (content, fileName, fileExtension) => {
        // Create a Blob containing the file content
        const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });

        // Use the saveAs function from the file-saver library to trigger the download
        saveAs(blob, `${fileName}.${fileExtension}`);
    },
};

export default FileService;