import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StyletransferModel from "../../Components/ModelComponents/styletransfer";
import ImgClassifyModel from "../../Components/ModelComponents/imgclassify";
import { useMenuContext } from '../../Components/Navbar/menuContext';


const Modelexplorer = () => {
  const { setShowAdditionalMenu, setActiveMenuItem } = useMenuContext();
  const navigate = useNavigate();
  const [typedisplay, setTypedisplay] = useState(false);
  const [query, setQuery] = useState("");
  const [selecedType, setSelectedType] = useState([]);
  const [modeltypes, setModeltypes] = useState([
    {
      "name": "Sound",
      "selected": false,
    },
    {
      "name": "Text",
      "selected": false,
    },
    {
      "name": "Image",
      "selected": false,
    },
    {
      "name": "Video",
      "selected": false,
    }]);
  const modelarray = [
    { "id": "1", "name": "art search", "desc": "", "link": "/Comingsoon", "type": "Image" },
    { "id": "2", "name": `BARBIE\nSTYLEGAN3`, "desc": "", "link": "/Comingsoon", "type": "Image" },
    { "id": "3", "name": "POE-ETO", "desc": "Text Generation in the style of Edgar allan poe.", "link": "/textgeneration", "type": "Text" },
    { "id": "4", "name": "style transfer", "desc": "TRANSFER STYLE OF ONE IMAGE TO THE CONTENT OF ANOTHER IMAGE", "link": "/styletransfer", "type": "Image" },
    { "id": "5", "name": "IMAGE\nCLASSIFICATION", "desc": "TRAIN A MODEL TO CLASSIFY POSES", "link": "/Mobilenetmodel", "type": "Image" },
    { "id": "6", "name": "POSENET", "desc": "TRAIN A MODEL TO CLASSIFY POSES", "link": "/Pose", "type": "Video" },
    { "id": "7", "name": "SOUND\nCLASSIFICATION", "desc": "TRAIN A MODEL TO CLASSIFY SOUND", "link": "/Sound", "type": "Sound" },
    { "id": "8", "name": "DEEPFACE", "desc": "FACE DETECTION", "link": "/model-execute", "type": "Video" },
    { "id": "9", "name": "GPT-3.5-TURBO-1106", "desc": "FINETUNE CHATGPT", "link": "/chatgpt-demo", "type": "Text" },
    { "id": "10", "name": "DALL-E-3", "desc": "Generate images", "link": "/dalle", "type": "Image" }
  ];
  const search_parameters = ["name", "desc"];

  function search(data) {
    const filteredDATA = data.filter((node) =>
      selecedType.length > 0
        ?
        selecedType.some((filterTag) =>
          node['type'].includes(filterTag)
        )
        : data)
    return filteredDATA.filter(
      (item) =>
        search_parameters.some((parameter) =>
          item[parameter].toString().toLowerCase().includes(query)
        )
    );
  }

  const handlechange = (index) => {
    setSelectedType([]);
    const newtype = [...modeltypes];
    newtype[index].selected = !modeltypes[index].selected;
    setModeltypes(newtype);
    let arr = [];
    for (let i = 0; i < newtype.length; i++) {
      if (newtype[i].selected) {
        arr.push(newtype[i].name);
      }
    }
    setSelectedType(arr);
  };

  const inputStyle = {
    width: "95%",
    borderRadius: "1000px",
    marginLeft: "10px",
    textAlign: "center",
    backgroundColor: "rgba(78, 78, 78, 0.1)",
    color: "#4E4E4E",
    opacity: "70%",
    border: "none",
    height: "40px",
  };

  useEffect(() => {
    setShowAdditionalMenu(true);
    setActiveMenuItem("MODELS");
    sessionStorage.setItem(
      "identifier",
      -1);
    return () => {
      setShowAdditionalMenu(false);
      setActiveMenuItem(null);
    };
  }, []);

  const [component, setComponent] = useState(0)

  const clickHandler = (component) => {
    switch (component) {
      case "1":
        setComponent(1);
        break;
      case "2":
        setComponent(2)
        break;
      case "3":
        setComponent(3);
        break;
      case "4":
        setComponent(4);
        break;
      case "5":
        setComponent(5);
        break;
      case "6":
        setComponent(6);
        break;
      case "7":
        setComponent(7);
        break;
      case "8":
        setComponent(8);
        break;
      case "9":
        setComponent(9);
        break;
      default:
        setComponent(0);
        break;
    }
  };

  const closeModel = () => {
    setComponent(0);
  }

  return (
    <>
      {component === 0 && <> </>}
      {component === 4 && <StyletransferModel closeModel={closeModel} />}
      {component === 5 && <ImgClassifyModel closeModel={closeModel} />}
      {/* {component === 9 && <ChatGPTModel closeModel={closeModel}/>} */}
      <br></br>
      <div className="d-flex flex-start  w-100 gap-5 mx-3"> </div>
      {/* <div
        className="ms-3 mb-3"
      >
        <div className="prototypeFont mt-3" >
          <h1>Models</h1>
        </div>
      </div> */}
      <div className="row p-3">
        <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
          <input
            type="search"
            placeholder="SEARCH MODELS"
            className="searchinput"
            style={inputStyle}
            onChange={(e) => setQuery(e.target.value)}

          ></input></div>
        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">


          {typedisplay ?
            <>
              <div className='Invisible' onClick={() => setTypedisplay(false)}></div>
              <div className="modeltypelist">
                <span style={{ alignSelf: "center" }}>TYPE</span>
                {modeltypes?.map((mt, index) => {
                  return (
                    <>
                      <div
                        onClick={() => {
                          handlechange(index);
                        }}
                        key={index} className={mt.selected ? 'modeltypesubselected' : 'modeltypesub'}>
                        <span>{mt.name}</span>
                        {mt.selected ? <><i className="fas fa-check" ></i></> : <><i className="fas fa-plus" ></i></>}
                      </div>
                    </>

                  );
                })}
              </div></> : <button class="dropdown-toggle typeinput" data-bs-toggle="dropdown" aria-expanded="false"
                onClick={() => setTypedisplay(true)}
              >
              TYPE
            </button>
          }
        </div>
      </div>
      <br></br>
      {/* <p className="mx-3 my-2">Featured:</p> */}
      <div class="modelexplorerPanel">
        {search(modelarray)?.map((model, index) => (
          <div class="modellboxexp" onClick={() => clickHandler(model.id)}>
            <p
              style={{
                fontSize: "32px",
                color: "#4E4E4E",
                margin: "0 0 10px 10px",
                lineHeight: "42px",
                fontWeight: "400",
                textTransform: "uppercase",
                whiteSpace: 'pre-line'
              }}
            >
              {model.name}
            </p>
            <p style={{
              fontSize: "16px", color: "rgba(78, 78, 78, 0.7)", margin: "10px 0 0 10px", lineHeight: "21px",
              fontWeight: "400",
            }}>
              {model.desc}
            </p>
            <p
              style={{
                fontSize: "25px",
                color: "rgba(78, 78, 78, 0.7)",
                left: "12px",
                bottom: "0",
                position: "absolute",
                lineHeight: "12px"
              }}
            >
              {/* <div className="modelImageLogoHoverStyle" style={{ height: 70, width: "38vh", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#C7CCB2", border: "0px solid black", borderRadius: "15px" }}
                onClick={() => navigate(model.link, { replace: false, state: { source: "modelexplorer" } })}
              >
                <img src={model.type === "Image" ? dottedLogo : model.type === "Text" ? lineLogo : model.type === "Video" ? humanLogo : model.type === "Sound" ? musicNote : "https://poietodata.s3.us-east-2.amazonaws.com/Data-contributions/Project-image/Radio_01-1684889256745.png"} style={{ width: 50, height: 50 }} alt="!WaitStillFetching!" />
              </div> */}
              <a
              href=""
                style={{  textDecoration: "none", color: "rgba(78, 78, 78, 0.7) " }}
                onClick={() => navigate(model.link, { replace: false, state: { source: "modelexplorer" } })}
              >
                DEMO
              </a>
            </p>
          </div>
        ))}
      </div>
    </>
  );
};

export default Modelexplorer;

