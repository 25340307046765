import React, { useState,useEffect } from "react";
import { useLocation,} from "react-router-dom";
import MakeAccount from "../MakeAccount/makeaccount";
import LogInFormView from "./LogInFormView";
import ForgetPasswordView from "./forgetPasswordView";


const Login = () => {
  let location = useLocation();

  const [show, setShow] = useState("loginView");
  useEffect(() => {
    if (location?.state?.source === "invite") {
      setShow(location?.state?.data?.show);
    }
  },[]);
  return (
    <>
      <div
        className="d-flex justify-content-between ms-3 me-3"
        style={{ width: "97%" }}
      >
        <button
          style={{
            width: "50%",

            fontSize: "25px",
          }}
          className={
            show === "loginView" || show === "passwordView"
              ? "active1"
              : "active2"
          }
          onClick={() => setShow("loginView")}
        >
          LOGIN
        </button>
        <button
          style={{
            width: "50%",
            fontSize: "25px",
          }}
          className={show === "signupView" ? "active1" : "active2"}
          onClick={() => setShow("signupView")}
        >
          MAKE AN ACCOUNT
        </button>
      </div>
      {show === "loginView" ? <LogInFormView setShow={setShow} /> : null}
      {show === "signupView" ? <MakeAccount setShow={setShow} /> : null}
      {show === "passwordView" ? (
        <ForgetPasswordView setShow={setShow} />
      ) : null}
    </>
  );
};
export default Login;
