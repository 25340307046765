import React from "react";
import { Stage, Layer, Line} from "react-konva";
import html2canvas from "html2canvas";
import "./Canvas.css";

const DrawingArea = ({ onSvgFileChange }) => {
  const [lines, setLines] = React.useState([]);
  const isDrawing = React.useRef(false);

  const handleMouseDown = (e) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { points: [pos.x, pos.y] }]);
  };

  const handleMouseMove = (e) => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();

    // To draw line
    let lastLine = lines[lines.length - 1];
    if (lastLine) {
      // add point
      lastLine.points = lastLine.points.concat([point.x, point.y]);

      // replace last
      lines.splice(lines.length - 1, 1, lastLine);
      setLines(lines.concat());
    }
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
    convertLinesToPngFile()
  };

  // convert drawn lines to png file
  const convertLinesToPngFile = () => {
    const stage = document.querySelector(".canvas-stage");
    html2canvas(stage).then((canvas) => {
      canvas.toBlob((blob) => {
        const file = new File([blob], 'drawing-prompt.png', { type: 'image/png' });
        onSvgFileChange(file);
      }, 'image/png');
    });
  };
 
  const isMobile = window.innerWidth < 768;

  return (
    <div>
      <Stage
        width={isMobile ? window.innerWidth / 1.6: window.innerWidth / 2.4}
        height={isMobile ? 120:170}
        onMouseDown={handleMouseDown}
        onMousemove={handleMouseMove}
        onMouseup={handleMouseUp}
        onTouchStart={handleMouseDown} // Added for mobile
        onTouchMove={handleMouseMove} // Added for mobile
        onTouchEnd={handleMouseUp} // Added for mobile
        className="canvas-stage"
      >
        <Layer>
          {lines.map((line, i) => (
            <Line
              key={i.toString()}
              points={line?.points}
              stroke="#D7402B"
              strokeWidth={3}
              lineCap="round"
              globalCompositeOperation={
                line.tool === "eraser"? "destination-out" : "source-over"
              }
            />
          ))}
        </Layer>
      </Stage>
    </div>
  );
};

export default DrawingArea;

