import React, { useCallback,useEffect, useState } from "react";
import { toast} from "react-hot-toast";
import debounce from "lodash.debounce";
import { FramesNavBar } from "../Howdoesscreen/HeadarNavbar/FramesNavbar";
import {isId ,isAuth} from "../../Storage(Methods)/storage";
import services from '../../API_SERVICES/services';
export const Reflectiondata = () => {
  const [answer, setAnswer] = useState("");
  const itemArray = [
    { name: "DISCOVER", value: "/decode" },
    { name: "FRAMES", value: "/CreateFrames" },
    { name: "BLUEPRINT", value: "/Howinputtab" },
    { name: "PROTOTYPE", value: "/modelspage" },
    { name: "REFLECTION", value: "/reflection" },
  ];
  useEffect(() => {
    LoadQuestions();
  },[])
  const handleChange = (e) => {
      setAnswer(e.target.value);
      const apiname = "poieto/reflection"
      const data = {
        pId:isId(),
        answer:e.target.value,
        user_id: isAuth().id

      }
      DelayApiCall(apiname, data);

  }
  // const handleReflection = (e) => {
  //   e.preventDefault();
  //   const apiname = "poieto/reflection"
  //   const data = {
  //     pId:isId(),
  //     answer:answer
  //   }
  //   services.post(apiname,data)
  //     .then((response) => {
  //       toast.success(response.data.message);
  //       LoadQuestions();
    
  //     })
  //     .catch((error) => {
  //       toast.error(error.response.data.error);
  //     });
  // }
  const DelayApiCall = useCallback(
    debounce(
      (apiname, data) =>
        services
          .post(apiname, data)
          .then((response) => {
            toast.success(response.data.message);
            LoadQuestions();
          })
          .catch((error) => {
            toast.error(error.response.data.error);
          }),
      1500
    ),
    []
  );
  const LoadQuestions = () => {
    const apiname = "get-question"
    const project = true
     services.get(apiname, project)
      .then((response) => {
        const {reflection,own_reflection} = response.data
        setAnswer(reflection)
        var array =[];
        if(own_reflection!=null&&own_reflection!=='')
        {
          array = JSON.parse(own_reflection)
        }
        setFormValues(array);
      })
      .catch((error) => {
          console.log(error);
      });
  };


  const [formValues, setFormValues] = useState([])
  let handleChangeform = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);


    var own_reaction=JSON.stringify(newFormValues);
    const apiname = "poieto/ownreflection"
      const data = {
        pId:isId(),
        own_r:own_reaction,
        user_id: isAuth().id
      }
      DelayApiCall(apiname, data);
  }

let addFormFields = () => {
    setFormValues([...formValues, { q: "", ans: "" }])
  }
//   let removeFormFields = (i) => {
//     let newFormValues = [...formValues];
//     newFormValues.splice(i, 1);
//     setFormValues(newFormValues)
// }  
// let handleSubmit = (event) => {
//   alert(JSON.stringify(formValues));
// }
  return (
    <>
      <FramesNavBar labels={itemArray} isActive={"REFLECTION"}  />
       <div className="reflectionContainer">
         <div className="reflectionBody">
        <h5>
          A SPACE FOR REFLECTING AND THINKING CRITICALLY:
        </h5>
        </div>
        <h5 className="mt-3 ms-3">WHAT COULD GO WRONG AND WHO COULD BE AFFECTED?</h5>
      <textarea
        className="mt-2 "
        rows="4"
        name="answer"
        value={answer}
        placeholder='Type Here...'
        onChange={handleChange}
        
        style={{
          fontSize: "25px",
          paddingLeft: 10,
          width: "97%",
          borderRadius: 15,
          backgroundColor:'#C7CCB2',
          marginLeft:'0.7rem'
        }}
      ></textarea>
     
  

      {formValues.map((element, index) => (
            <div  key={index}>
              <input type="text"  placeholder="Type Your Question Here"
              className=' reflectioninput'

name="q" value={element.q || ""} onChange={e => handleChangeform(index, e)} style={{display: "block", fontSize: "26px",backgroundColor:'rgb(199, 204, 178)',width:'96%',margin:'10px',border:'rgb(199, 204, 178)',outline:'none'}} />              
              <textarea
        className="mt-2 "
        rows="4"
        name="ans"
        value={element.ans || ""}
        placeholder='Type Here...'
        onChange={e => handleChangeform(index, e)}
        
        style={{
          fontSize: "25px",
          paddingLeft: 10,
          width: "97%",
          borderRadius: 15,
          backgroundColor:'#C7CCB2',
          marginLeft:'0.7rem'
        }}
      ></textarea>
               {/* {
                index ? 
                <i className="fa fa-times me-4 mt-2 " style={{ color: "red",position:"absolute",marginRight:"40px" }}  onClick={() => removeFormFields(index)}></i> 
                : null
              }  */}
            </div>
          ))}
          <h6 className="ms-3" style={{fontSize:'25px'}}>ADD YOUR OWN QUESTION</h6>
      <div className="d-flex justify-content-between">
        <div className="round me-2 ms-3" style={{ textAlign: "center" }} onClick={() => addFormFields()}>
          +
        </div>
      </div>
      
          </div>

    </>
  );
};
