import React, { useState } from "react";
import { PopupModal } from "../DataPrivacy/PopupModal";
import { toast } from "react-hot-toast";
import { isAuth } from "../../Storage(Methods)/storage";
import services from "../../API_SERVICES/services";
import { useNavigate } from "react-router-dom";

export const ProjectPage = () => {
  const navigate = useNavigate();
  const [projectVal, setProjectVal] = useState({
    project_title: "",
    project_desc: "",
    project_privacy: "",
    screen1: false,
    screen2: false,
    invitee: "",
    toggleAdmin: false,
    isAdmin: false,
    userImageFile: "",
    projectId: "",
    projname: "",
  });
  const handleChange = (e) => {
    setProjectVal({ ...projectVal, [e.target.name]: e.target.value });
  };
  const handlePrivacy = (e) => {
    setProjectVal({ ...projectVal, project_privacy: e.target.value });
  };
  const { project_title, project_desc, screen1, screen2, project_privacy, userImageFile, invitee, isAdmin, projectId, projname } = projectVal;
  const onFileChange = (event) => {
    if (event.target.files[0]?.size > 5242880) {
      toast.error('File size greater than 5 MB, Please upload a file with size less than 5 MB');
      setProjectVal({ ...projectVal, userImageFile: '' });

    }
    else {
      setProjectVal({ ...projectVal, userImageFile: event.target.files[0] });
      toast.success('File uploaded successfully');

    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const apiname = "create-project";
    var formData = new FormData();
    formData.append('project_title', project_title)
    formData.append('project_desc', project_desc)
    formData.append('project_privacy', project_privacy)
    formData.append('user_id', isAuth().id)
    formData.append('projectUpload', userImageFile)
    services
      .post(apiname, formData)
      .then((response) => {
        toast.success(response.data.message);
        sessionStorage.setItem("identifier", JSON.stringify(response.data.data.pId));
        setProjectVal({ ...projectVal, project_title: "", project_desc: "", project_privacy: "", screen2: !screen2, projectId: response.data.data.pId, projname: response.data.data.project_title });

      })
      .catch((error) => {
        // setProjectVal({ ...projectVal, project_title: "", project_desc: "" });
        toast.error(error.response.data.error);
      });
  };
  const inviteCollabrator = () => {

    if (isAuth().email.toLowerCase() !== invitee.toLowerCase()) {
      const apiname = "invite_collabrator";
      const data = {
        pid: projectId,
        project_name: projname,
        email_id: invitee,
        admin: isAdmin ? 'Y' : 'N',
        user_name: isAuth().username,
      }
      services.post(apiname, data)
        .then((response) => {
          toast.success("invited successfully");
          setProjectVal({ ...projectVal, screen2: false })
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data.error);
        });
    }
    else {
      toast.success("You are owner of this project");
      setProjectVal({ ...projectVal, screen2: false })
    }
    setTimeout(() => {
      navigate("/decode", { replace: true });
    }, 2300);
  };
  const handleAdminChange = (e) => {
    setProjectVal({
      ...projectVal,
      isAdmin: !isAdmin,
      toggleAdmin: e.target.checked,
    });
  };
  const handleinviteChange = (e) => {
    setProjectVal({ ...projectVal, [e.target.name]: e.target.value });
  };
  const onskip = (e) => {
    setProjectVal({ ...projectVal, screen2: false })
    navigate("/decode", { replace: true });

  }
  return (
    <>
      <div className="d-flex w-100  justify-content-between">
        <input
          className="text-uppercase  ms-3 plachetitle"
          name="project_title"
          value={project_title}
          onChange={handleChange}
          placeholder="ENTER PROJECT TITLE"
        ></input>
        <button
          type="button"
          className="publicbtn me-3"
          onClick={() => setProjectVal({ ...projectVal, screen1: true })}
        >
          {project_privacy.length === 0 ? 'PRIVACY SETTINGS' : project_privacy}
        </button>
      </div>
      {!screen1 ? null : (
        <PopupModal>
          <h5 className="text-center">PROJECT PRIVACY SETTINGS</h5>
          <div className="textInputContent mt-4">
            <div className="numberCountView  align-items-center">
              <input
                type="radio"
                name="privacy_button"
                className="checkbox-round"
                value="PUBLIC AND OPEN"
                id={1}
                onChange={handlePrivacy}
              />
            </div>
            <div className="textInputView">
              <p className="m-0">PUBLIC AND OPEN</p>
              <p>
                Project is listed publicly in our project database and anyone
                can join and start editing.
              </p>
            </div>
          </div>
          <div className="textInputContent mt-3">
            <div className="numberCountView   align-items-center">
              <input
                type="radio"
                name="privacy_button"
                class="checkbox-round"
                onChange={handlePrivacy}
                id={1}
                value="PUBLIC AND CLOSED"
              />
            </div>
            <div className="textInputView">
              <p className="m-0">PUBLIC AND CLOSED</p>
              <p>
                Project is listed publicly in our project database and others
                can request to join
              </p>
            </div>
          </div>
          <div className="textInputContent mt-3">
            <div className="numberCountView  align-items-center">
              <input
                type="radio"
                name="privacy_button"
                class="checkbox-round"
                onChange={handlePrivacy}
                id={1}
                value="PRIVATE"
              />
            </div>
            <div className="textInputView">
              <p className="m-0">PRIVATE</p>
              <p>
                Project is not listed and collaborators must be invited directly
              </p>
            </div>
          </div>
          <button
            type="button"
            className="savebtn"
            onClick={() => setProjectVal({ ...projectVal, screen1: !screen1 })}
          >
            SAVE
          </button>
        </PopupModal>
      )}
      {!screen2 ? null : (
        <div className="outercontainer">
          <div className="gridJoin">
            <div className="grid-joinContributor">
              <span className="inviteText mt-4 mb-4"> Invite Collaborators? </span>
              <p>Send an invite link to someone you want to contribute to the project.</p>
              <input
                type="text"
                className="inviteBoxView mt-2 mb-2"
                placeholder="Enter an Email"
                autoComplete="off"
                value={invitee}
                name="invitee"
                onChange={handleinviteChange}
              />

              <div className="uploadinput " style={{ textAlign: 'left' }}>
                <div className="d-flex flex-column">
                  <div className="mt-3">
                    <input
                      type="checkbox"
                      checked={isAdmin}
                      onChange={handleAdminChange}
                    />    Make admin?

                  </div>

                  <span className="mt-4">Admin users are granted the same permissions you have. (including deleting the project or inviting others) Admin access can be granted later but not taken away.</span>
                </div>
              </div>
              <div className="d-flex justify-content-around mt-4">
                <button
                  type="button" className="joinbtn mr-2"
                  onClick={() => onskip()}
                >SKIP</button>
                <button
                  type="button"
                  className={invitee === '' ? "invitebtndisabled" : "invitebtn"}
                  disabled={invitee === ''}

                  onClick={() => inviteCollabrator()}

                >
                  INVITE
                </button></div>
            </div></div></div>
      )}
      <div className="gridContainer">
        <div className="grid-item-1 text-center">
          <textarea
            className="plachetitle2"
            name="project_desc"
            value={project_desc}
            onChange={handleChange}
            placeholder={'WRITE A SHORT DESCRIPTION \nOPTIONAL'}

            rows="7"
          >

          </textarea>
        </div>
        <div
          className="grid-item-2 text-center"
          style={{ border: "1px solid black", borderRadius: "10px" }}
        >
          <label htmlFor="file" id="filelabel">
            <i className="fas fa-plus"></i>
            <p>UPLOAD PROJECT IMAGE</p>
            <p>OPTIONAL</p>

          </label>

          <input
            type="file"
            id="file"
            name="projectUpload"
            onChange={onFileChange}
            style={{ display: "none", visibility: "none" }}
          />
        </div>
      </div>
      <div className="gridfifth">


        <div style={{ backgroundColor: project_privacy.length > 0 && project_title.length > 0 ? "#EAEDDE" : "#c7ccb2" }}
          onClick={handleSubmit}>
          {" "}
          <p>CREATE AND ENTER</p>
        </div>
      </div>
    </>
  );
};
