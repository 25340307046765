import React, {useState} from "react";
import { toast } from 'react-hot-toast'
import { Link, useNavigate } from "react-router-dom";
import services from "../../API_SERVICES/services";
import { useParams } from 'react-router-dom';

const ResetPasswordView = ({ match }) => {
  const history = useNavigate();
  const [userVal, setUserVal] = useState({
    newPassword: '',
    cpassword: '',
  });
  const { token } = useParams();                          
  const handleChange = (e) => {
    setUserVal({
      ...userVal, [e.target.name]: e.target.value,
    });
  };
  const {newPassword, cpassword } = userVal;
  const handleSubmit = (e) => {
    e.preventDefault();
    const apiname = '/authenticate/reset-password'
    const data = {
      newPassword: newPassword,
      cpassword: cpassword,
      resetLink: token
    }
      services.post(apiname,data).then((response) => {
        setUserVal('');
        toast.success(response.data.message);
        setTimeout(() => history("/login"), 2000);
      })
      .catch((error) => {
        toast.error(error.response.data.error);
      });
  };

  return (
    <>
      <div className="content1">
        <form className="formView" style={{ marginTop: "40px" }}>
          <div className="textContainer">
            <p className="forgotViewHeadingText">CHANGE PASSWORD</p>
            <p className="forgotViewText">PASSWORD REQUIREMENTS:</p>
          </div>
          <label className="inputLabelText">NEW PASSWORD</label>
          <input
            type="password"
            className="inputmake"
            placeholder="Enter your new password"
            name="newPassword"
            value={newPassword}
            onChange={handleChange}
            autoComplete="off"
          />
          <label className="inputLabelText">CONFIRM NEW PASSWORD</label>
          <input
            type="password"
            className="inputmake"
            placeholder="Confirm your new password"
            name="cpassword"
            value={cpassword}
            onChange={handleChange}
            autoComplete="off"
          />
        </form>
        <Link
          className="resetPasswordButtonView"
          onClick={handleSubmit}
          style={{ fontSize: "20px" }}
          to="/login"
        >
          <i className="fas fa-long-arrow-right"></i>
          SUBMIT
        </Link>
      </div>
    </>
  );
};
export default ResetPasswordView;
