import React from "react";

import Editor from "@monaco-editor/react";

const CodeEditorWindow = ({ onChange, language, code, theme }) => {

  const handleEditorChange = (value) => {
    onChange("code", value);
  };

  return (
    <Editor
      // height="auto"
      // width="80%"
      className="editorContainer"
      language={language || "javascript"}
      value={code}
      theme={theme}
      defaultValue="# Welcome to Poieto Text Editor"
      onChange={handleEditorChange}
    />
  );
};
export default CodeEditorWindow;
