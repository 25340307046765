import React from "react";
import Sketch from "react-p5";
import * as ml5 from "ml5";


var video, labelDiv, dataLabel, trainDiv, trainButton, saveDiv, downloadButton, addExButMain, addExBut;
var num = 3;
var label = 'loading model';
var height, header, instructions, inp;
var download = true;
var width;
var conf;
var poseNet;
var poses = [];
var addEx;
var brain;
var p5Object;
var poseNett, expDiv, expQuitButton;

const Posetransfer =  (props) => {
  const createExport = (p5) => {
    expDiv = p5.createDiv();
    expDiv.style('border', '1px solid gray').size(1200,700).position(150,50);
    expDiv.style('border-radius', '10px');
    expDiv.style('background-color', 'white');
    expDiv.style('color','rgb(60, 60, 60)');
    expDiv.style('padding-top', '5px');
    expDiv.style('font-size', '15px');
    expDiv.style('text-align','center');
    const savePose1 = (p5) =>{
      p5.save('poseDemo.js');
    };

    const savePose2 = (p5) =>{
      p5.save('poseDemo2.js');
    };

    // const savePose3 = (p5) =>{
    //   p5.save('demo_model.py');
    // };
    // const savePose4 = (p5) =>{
    //   p5.save('poseDemo2.js');
    // };

    let expCode13 = p5.createButton("Download P5 sketch template 'Real time'");
    expCode13.position(200,500).size(130,40).style('background-color', 'rgb(255, 140, 192)');
    expCode13.style('border','none');
    expCode13.style('border-radius', '5px');
    expCode13.mousePressed(function() {
      savePose1(p5);
    });

    let expCode14 = p5.createButton("Download P5 sketch template 'Upload pose'");
    expCode14.position(350,500).size(130,40).style('background-color', 'rgb(255, 140, 192)');
    expCode14.style('border','none');
    expCode14.style('border-radius', '5px');
    expCode14.mousePressed(function() {
      savePose2(p5);
    });

    // let expCode15 = p5.createButton("Download '.html' upload pose template");
    // expCode15.position(500,500).size(130,40).style('background-color', 'rgb(255, 140, 192)');
    // expCode15.style('border','none');
    // expCode15.style('border-radius', '5px');
    // expCode15.mousePressed(function() {
    //   savePose3(p5);
    // });

    // let expCode16 = p5.createButton("Download '.py' template");
    // expCode16.position(650,500).size(130,40).style('background-color', 'rgb(255, 140, 192)');
    // expCode16.style('border','none');
    // expCode16.style('border-radius', '5px');
    // expCode16.mousePressed(function() {
    //   savePose4(p5);
    // });
    let expHeader = p5.createElement("h1", "Export model").position(650,70);
    let expCode = p5.createElement("h2", "After training and downloading you model you can use the below code to use your model externally:").position(150,120);
    expCode.style("padding-left", "2%");
    expCode.style("padding-right", "10%");
    let expCode2 = p5.createElement("h2", "For javaScript:").position(150,170);
    let expCode3 = p5.createP("Include: https://cdn.jsdelivr.net/npm/@tensorflow/tfjs in script tag").position(150,200);
    let expCode4 = p5.createP("Include: https://cdn.jsdelivr.net/npm/@tensorflow-models/posenet in script tag").position(150,220);
    let expCode5 = p5.createP("NPM: npm install @tensorflow-models/posenet").position(150,250);
    let expCode2h = p5.createElement("h2", "For Python:").position(150,280);
    let expCode6 = p5.createP("Install: pip install posenet").position(150,310);
    let expCode2d = p5.createElement("h2", "Or use the following snippet files to use your models:").position(150,400);
    expCode2d.style("padding-left", "2%");
    expCode2.style("padding-left", "2%");
    expCode2h.style("padding-left", "2%");
    expCode2.style("padding-right", "10%");
    expCode3.style("padding-left", "4%");
    expCode4.style("padding-left", "4%");
    expCode5.style("padding-left", "4%");
    expCode6.style("padding-left", "4%");
    expQuitButton = p5.createButton('X');
    expQuitButton.position(1300,60).size(40,40).style('background-color', 'rgb(255, 140, 192)');
    expQuitButton.style('border','none');
    expQuitButton.style('border-radius', '5px');
    expQuitButton.mousePressed(function() {
      expQuitButton.hide();
      expDiv.hide();
      expCode13.hide();
      expCode14.hide();
      // expCode15.hide();
      // expCode16.hide();
      expHeader.hide();
      expCode.hide();
      expCode2.hide();
      expCode3.hide();
      expCode4.hide();
      expCode5.hide();
      expCode2h.hide();
      expCode6.hide();
      expCode2.hide();
      expCode2d.hide();
    })
  };

  //  Add a training example
  const getInputss = (iamgeArray) => {
    let keypoints = iamgeArray[0].pose.keypoints;
    let inputs = [];
    for (let i = 0; i < keypoints.length; i++) {
      inputs.push(keypoints[i].position.x);
      inputs.push(keypoints[i].position.y);
    }
    return inputs;
  }
  const addExamplee = (iamgeArray) =>{
    if (iamgeArray.length > 0) {
      let inputs = getInputss(iamgeArray);
      let target = dataLabel.value();
      brain.addData(inputs, [target]);
    }
  }

  const handleFile = async (file) => {
    let image = p5Object.createImg(file.data);  
    image.size(240,240);
    image.hide();
    let iamgeArray = await poseNett.singlePose(image);
    addExamplee(iamgeArray);
  }

  //  Add a training example
  const getInputs = () => {
    let keypoints = poses[0].pose.keypoints;
    let inputs = [];
    for (let i = 0; i < keypoints.length; i++) 
    {
      inputs.push(keypoints[i].position.x);
      inputs.push(keypoints[i].position.y);
    }
    return inputs;
  }

  const addExample = () =>{
    if (poses.length > 0) {
      let inputs = getInputs();
      let target = dataLabel.value();
      brain.addData(inputs, [target]);
    }
  }

  function trainModel() {
    brain.normalizeData();
    let options = {
      epochs: 25
    }
    brain.train(options, finishedTraining);
  }
  // Classify
  function classify() {
    if (poses.length > 0) {
      let inputs = getInputs();
      brain.classify(inputs, gotResults);
    }
  }
  // Begin prediction
  function finishedTraining() {
    classify();
  }
  
	const modelReady = () => {
    label = "Model loaded successfully";
    // classifier.load('model.json', customModelReady);
  }

  // const videoReady = () => {
  //   label = "Video loaded successfully";
  // }

  const gotResults = (error, results) => {
    classify();
    label = results[0].label;
  }

  const createClass = (p5) => {
    num = inp.value();
    // var pos = 0;
    dataLabel = p5.createSelect();
    dataLabel.position(95,700).size(95,40).style('background-color', 'rgb(163, 255, 169)');
    dataLabel.style('border-radius','20px');
    dataLabel.style('border','none');
    dataLabel.style('font-size', '12px');
    dataLabel.style('padding','0.5%');
    for (let i = 1; i<=num; i++){
      dataLabel.option('Pose '+i);
    };
  };
  const setup = (p5, canvasParentRef) => {
    p5Object = p5;
    header = p5.createElement('h3','Pose classification with ML5 ( Model training )').center();
    header.style('color','rgb(57, 57, 57)');
    instructions = p5.createElement('p','Instructions: Enter the number of classes, click on confirm. Record samples of each class, click train button to train & load the model then you can save or download the model, you can download the model after training');
    instructions.style('padding-left','10%');
    instructions.style('padding-right','10%');
    instructions.style('text-align','center');
    instructions.position(0,120)
    
    inp = p5.createInput('','Number');
    inp.style('border','none').position(630,185).size(60,30);
    conf = p5.createButton('Confirm');
    conf.position(700,185).size(90,30).style('background-color','rgb(229,	255,	128)');
    conf.style('border','none');
    conf.style('border-radius','20px');
    conf.mousePressed(function() {
      createClass(p5);
    });

    p5.createCanvas(430, 380).position(500,230).style('border-radius','5px');
    let constraints = {video:{optional: [{ maxFrameRate: "auto" }]},audio: false}
    video = p5.createCapture(constraints);
    video.hide();
    p5.background(0);
    poseNett = ml5.poseNet(modelReady);
    poseNett.on('pose', function (resultss) {
      // posess = resultss; 
    });
    
    poseNet = ml5.poseNet(video, modelReady);
    poseNet.on('pose', function (results) {
      poses.push(results);
      poses = results; 
    });
    
    let options = {
      inputs: 34,
      outputs: 2,
      task: 'classification',
      minConfidence: 0.001,
      debug: true
    }
    brain = ml5.neuralNetwork(options);

    labelDiv = p5.createDiv('1) Select class and click on add examples, every click adds an image');
    labelDiv.style('border', '1px dotted gray').size(280,180).position(80,620);
    labelDiv.style('border-radius', '10px');
    labelDiv.style('color','rgb(60, 60, 60)');
    labelDiv.style('padding-top', '5px');
    labelDiv.style('font-size', '15px');
    labelDiv.style('text-align','center');

    addEx = p5.createButton('Add examples');
    addEx.position(200,700).size(150,40).style('background-color','rgb(229,	255,	128)');
    addEx.style('border','none');
    addEx.style('border-radius','20px');
    addEx.mousePressed(addExample);

    addExButMain = p5.createElement("label", 'Upload files');
    addExBut = p5.createFileInput(handleFile, "label", [true]).attribute("label", "Upload");;
    addExButMain.position(160,745).size(80,40).style('background-color', 'rgb(255, 140, 192)');
    addExButMain.style('border','none');
    addExButMain.style('border-radius', '20px');
    addExButMain.style('text-align', 'center');
    addExButMain.style('line-height', '40px');
    addExButMain.style('vertical-align', 'middle');
    addExButMain.child(addExBut);
    addExBut.hide();

    trainDiv = p5.createDiv('2) After adding examples click on train to train your model');
    trainDiv.style('border', '1px dotted gray').size(280,130).position(580,620);
    trainDiv.style('border-radius', '10px');
    trainDiv.style('color','rgb(60, 60, 60)');
    trainDiv.style('padding-top', '5px');
    trainDiv.style('font-size', '15px');
    trainDiv.style('text-align','center');

    trainButton = p5.createButton('Train');
    trainButton.position(680,695).size(80,40).style('background-color', 'rgb(255, 140, 192)');
    trainButton.style('border','none');
    trainButton.style('border-radius', '20px');
    trainButton.mousePressed(trainModel);

    saveDiv = p5.createDiv('3) Save or download model after completing training');
    saveDiv.style('border', '1px dotted gray').size(280,180).position(1000,620);
    saveDiv.style('color','rgb(60, 60, 60)');
    saveDiv.style('border-radius', '10px');
    saveDiv.style('padding-top', '5px');
    saveDiv.style('font-size', '15px');
    saveDiv.style('text-align','center');

    downloadButton = p5.createButton('Download');
    downloadButton.position(1150,700).size(100,40).style('background-color', 'rgb(229,	255,	128)');
    downloadButton.style('border','none');
    downloadButton.style('border-radius', '20px');
    downloadButton.mousePressed(function() {
      brain.save(download = true);
    });

    var saveButtonDb = p5.createButton('Save');
    saveButtonDb.position(1050,700).size(90,40).style('background-color', 'rgb(163, 255, 169)');
    saveButtonDb.style('border','none');
    saveButtonDb.style('border-radius', '20px');
    saveButtonDb.mousePressed(async function() {
        let dataModel = await brain.save()
    });

    // exportBut = p5.createButton('Export');
    // exportBut.position(1100,745).size(80,40).style('background-color', 'rgb(255, 140, 192)');
    // exportBut.style('border','none');
    // exportBut.style('border-radius', '20px');
    // exportBut.mousePressed(function() {
    //   createExport(p5Object);
    // });
};
	const draw = (p5) => {
    p5.background(0);
    p5.image(video, 0, 0, width, height);
    p5.strokeWeight(2);
    p5.fill(255);
    p5.textSize(20);
    p5.text(label, 10, 370); 
    if (poses.length > 0) {
      let pose = poses[0].pose;
      for (let i = 0; i < pose.keypoints.length; i++) {
        p5.fill(213, 0, 143);
        p5.noStroke();
        p5.ellipse(pose.keypoints[i].position.x, pose.keypoints[i].position.y, 8);
      }
    }
	};

	return (
    <>
      <Sketch setup={setup} draw={draw} />
    </>
  );
};

export default Posetransfer;