import React from "react";
import Navbar from "../../Components/Navbar/navbar";

export const Privacy = ({ history }) => {
  return (
    <>
      <Navbar />

      <div className="container-fluid">
        <div className="d-flex justify-content-between w-100">
          <h1 className="infostyle">PRIVACY</h1>
          <h1 className="infostyle">INFORMATION</h1>
        </div>

        <div className="mac-class1">
          <p
            className="constyle mt-2"
            rows="6"
            cols="6"
            placeholder="COPY/CONTENT"
            style={{
              fontSize: "15px",
              paddingLeft: 25,
              width: "100%",
              border: "none",
              borderColor: "#C7CCB2",
              backgroundColor: "transparent",
              outline: "none",
              resize: "none",
              fontFamily:'IBM Plex Mono',
            }}
          >
            Privacy Policy Effective date: Nov 10, 2021 Poieto, LLC. ("us",
            "we", or "our") operates the poieto web application (the "Service").
            This page informs you of our policies regarding the collection, use,
            and disclosure of personal data when you use our Service and the
            choices you have associated with that data. Our Privacy Policy for
            Mutable Studio, Inc. is created with the help of the Free Privacy
            Policy website. We use your data to provide and improve the Service.
            By using the Service, you agree to the collection and use of
            information in accordance with this policy. 
            <p style={{color:'#000',fontFamily:'IBM Plex Mono'}}onClick={() => history.push("/seemoreprivacy")}>see more</p>
          </p>
        </div>

        <button
          type="submit"
          className="acceptbutton"
          onClick={() => history.push("/Editprofilenew")}
          // onClick={() => history.push("/projectsetup")}
          style={{fontFamily:'IBM Plex Mono'}}
        >
          I ACCEPT
        </button>
      </div>
    </>
  );
};
