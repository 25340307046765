import React, { createContext, useContext, useState } from 'react';

const MenuContext = createContext();

export const useMenuContext = () => {
  return useContext(MenuContext);
};

export const MenuProvider = ({ children }) => {
  const [showAdditionalMenu, setShowAdditionalMenu] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(null);

  return (
    <MenuContext.Provider value={{ showAdditionalMenu, setShowAdditionalMenu, activeMenuItem, setActiveMenuItem }}>
      {children}
    </MenuContext.Provider>
  );
};
