import React from 'react'
import "./Share.css"
import { CustomInputField } from './CustomInputField';
import toast from "react-hot-toast";

const Share = ({projectName,pId,uploadShareData,closeShare,code}) => {
 const [initialLoading, setInitialLoading] = React.useState(true);
 const [emailError, setEmailError] = React.useState('');
 const [isPasswordEye, setIsPasswordEye] = React.useState(true);
 const [copyButton,setCopyButton] = React.useState("Loading link...");
 const [shareState,setShareState] = React.useState({
    pId:pId,
    projectName:projectName,
    generatedLink:`${process.env.REACT_APP_API_SHARE_URL}/shared/${pId}`,
    email:"",
    password:"",
    message:"",
    isChecked:false
 });

 const saveShareData = () =>{
  if(shareState.isChecked && shareState.password.length === 0) {
    toast.error("Password is required");
    return;
  }
  if (validateEmail(shareState.email)) {
    uploadShareData(shareState);
    setShareState({
      pId: pId,
      projectName: projectName,
      generatedLink: `${process.env.REACT_APP_API_SHARE_URL}/shared/${pId}`,
      email: "",
      password: "",
      message: "",
      isChecked: false
    });
    if(code !== shareState.password){
      toast.success("Password Updated");
    }
  } else {
    toast.error('Invalid email address');
  }
}

 React.useEffect(() => {
  if(code?.length > 0) {
    setShareState({...shareState,isChecked:true})
    setShareState((prev) => ({ ...prev, password: code }));
  }else{
    setShareState({...shareState,isChecked:false})
    setShareState((prev) => ({ ...prev, password: '' }));
  }

    // Set a timeout to remove the loader after 2 seconds
    const timer = setTimeout(() => {
        setCopyButton("Copy link");
      setInitialLoading(false);
    }, 3000);
    // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const toggleChecked = () => {
    setShareState(prevState => ({
      ...prevState,
      isChecked: !prevState.isChecked,
       password: prevState.isChecked ? prevState.password : ''
    }));
  };

  const setShareData = (event) => {
    const { name, value } = event.target;
    setShareState({ ...shareState, [name]: value });
    if (name === "email") {
      if (!validateEmail(value)) {
        setEmailError('Invalid email address');
      } else {
        setEmailError('');
      }
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  const emailInputClass = emailError ? 'input-text-invalid' : (shareState.email && !emailError) ? 'input-text-valid' : '';

 const copyLinkToClipboard = () => {
    setCopyButton("Copied!");
    navigator.clipboard.writeText(shareState.generatedLink).then(() => {
        setTimeout(() => {
            setCopyButton("Copy link");
          }, 1000);
    }, (err) => {
      console.error('Failed to copy link: ', err);
    });
  };
  const copyPasswordToClipboard = () => {
    navigator.clipboard.writeText(shareState.password);
  };



  return (
    <div className="share-dialog">
    <div className="share-header">
      <h3>Share "{projectName}"</h3>
      <button className="close-btn">
      <i
            className="far fa-times-circle fa-lg close-icon"
            onClick={() => {
              closeShare(false);
            }}
            style={{ color: "grey" }}
          ></i>
      </button>
    </div>
    <div className="share-body">
      {/* <div className="input-group"> */}
        <CustomInputField
        type="text"
        placeholder="Add email"
        name="email"
        style={{ fontFamily: "DM Sans"}}
        value={shareState?.email}
        onChange={(event) => setShareData(event)}
        className={emailInputClass}
        />
         {/* <CustomInputField
        type="text"
        placeholder="Generated link"
        name="link"
        style={{ fontFamily: "DM Sans" }}
        value={shareState?.generatedLink}
        disabled
        /> */}
        <div className='share-code'>
        <div className="toggleContainer">
        <label>Add Password</label>
            <div
              className={`custom-toggle-slider ${shareState?.isChecked ? "checked" : ""}`}
              onClick={toggleChecked}
            >
              <div className="slider"></div>
            </div>
          </div>
          {shareState?.isChecked &&
          <>
          <CustomInputField
           type={isPasswordEye ? "password" : "text"}
           placeholder="Password"
           name="password"
           style={{ fontFamily: "DM Sans"}}
           onChange={(event) => setShareData(event)}
           value={shareState?.password}
           showCopyIcon={shareState.password ? true : false}
           onCopy={copyPasswordToClipboard}
           />
           {shareState.password &&
            <i className={isPasswordEye ? "fas fa-eye" : "fas fa-eye-slash"} 
            style={{ color: "#5B5E52",margin:"0 5px" }}
            onClick={() => setIsPasswordEye(!isPasswordEye)}
            >
              </i>}
          </>
           
           }
         
        </div>
        
         
      {/* </div> */}
      <textarea placeholder="Add a message (Optional)" name='message'
      onChange={(event) => setShareData(event)}
      value={shareState.message}/>
      <div className="footer">
        <div className="actions">

  <button className="copy-link-btn" onClick={copyLinkToClipboard} disabled={initialLoading}>
   {copyButton} 
  </button>


          <button className="send-btn" onClick={saveShareData}>Share</button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Share