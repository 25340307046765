import React from "react";

const LoadingComponent = (props) => {
    const { isLoading,style } = props;
    return (
        isLoading ?
            <div className="save-model-loader" style={style}></div>
            : null
    );
}

export { LoadingComponent }