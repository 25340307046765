import React from 'react'
import services from '../../API_SERVICES/services';
import toast from 'react-hot-toast';
import Pagination from '../../Components/Pagination';
import iconMp3 from "../../Assets/Images/mp3_icon.png"
import path from 'path';
import moment from 'moment';
import { debounce } from 'lodash.debounce';
import { isAuth,isId } from '../../Storage(Methods)/storage';
import bulletpic from "../../Assets/Images/Ellipse.png";
import ReactPlayer from "react-player";
import { PopupModal } from "../DataPrivacy/PopupModal";
import downArrow from "../../Assets/Images/darrow.png"

const GroupDataView = (props) => {
    const [q, setQ] = React.useState("");
    const video = JSON.parse(sessionStorage.getItem("data"));
    const [isHovered, setIsHovered] = React.useState(false);
    const pId = sessionStorage.getItem("identifier");
    const [isUploadDataLoading, setIsUploadDataLoading] = React.useState(false);
    const [state, setState] = React.useState({
        dataContributions: '',
        extensions: [],
        MasterChecked: false,
        loading: false,
        screen7: false,
        editMode: false,
        fetched: '',
        screen5: false,
        image: '',
        screen6: false,
    });

    const [currentPage, setCurrentPage] = React.useState(1);
    // const [recordsPerPage, setRecordsPerPage] = useState(10);
    const recordsPerPage = 5;
    const [selectedList, setSelectedList] = React.useState([]);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const nPages = props.Data?.length > 0 ? Math.ceil(props.Data?.length / recordsPerPage) : 1;
    const pertData =props.perticularProject;
   
    const [filterParam, setFilterParam] = React.useState(['']);
    // const [searchParam] = useState(["video", "author"]);
    const [details, setDetails] = React.useState({
        author: "",
        q1: "",
        q2: "",
        permissions1: "",
        permissions2: "",
        permissions3: "",
    });

    // OnChange the FILE TYPE
    const handleChange = (e) => {
        setFilterParam(e.target.value);
    };

    const handleAction = (e) => {
        if (e.target.value === "Download") {
            downloadItems1();
        } else if (e.target.value === "Delete") {
            deleteItems();
        }
    }

    // ACTIONS dropdown option function
    const downloadItems1 = () => {
        for (var i = 0; i < selectedList?.length; i++) {
            if (selectedList[i].video.match(/\.(png|jpg|jpeg)$/)) {
                fetch(selectedList[i].video, {
                    method: "GET",
                    cache: "no-cache"
                }).then(response => {
                    response.arrayBuffer().then(function (buffer) {
                        const url = window.URL.createObjectURL(new Blob([buffer]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "image.png"); //or any other extension
                        link.click();
                    });
                }).catch(err => {
                    console.log(err);
                });
            }
            if (selectedList[i].video.match(/\.(mp3|aac)$/)) {
                fetch(selectedList[i].video, {
                    method: "GET",
                    cache: "no-cache"
                }).then(response => {
                    response.arrayBuffer().then(function (buffer) {
                        const url = window.URL.createObjectURL(new Blob([buffer]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "audio.mp3"); //or any other extension
                        link.click();
                    });
                }).catch(err => {
                    console.log(err);
                });
            }
            if (selectedList[i].video.match(/\.(mp4)$/)) {
                fetch(selectedList[i].video, {
                    method: "GET",
                    cache: "no-cache"
                }).then(response => {
                    response.arrayBuffer().then(function (buffer) {
                        const url = window.URL.createObjectURL(new Blob([buffer]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "video.mp4"); //or any other extension
                        link.click();
                    });
                }).catch(err => {
                    console.log(err);
                });
            }
        }
    }
    const deleteItems = () => {
        let deleteTemp = [];
        for (var i = 0; i < selectedList?.length; i++) {
            deleteTemp.push(selectedList[i].dcId);
        }
        const data = {
            dcId: deleteTemp
        };
        services
            .post("delete-data", data)
            .then((response) => {
                toast.success("Deleted successfully");
                props.fetchData(true)
            }).catch((err) => {
                console.log(err);
            });
    }

    const onMasterCheck = (e) => {
        // SelectedList: this.state.List.filter((e) => e.selected),
        let tempList = state.dataContributions;
        // Check/ UnCheck All Items
        tempList?.data.map((data) => (data.selected = e.target.checked));
        setState({
            ...state,
            dataContributions: tempList,

        });
        setSelectedList(state.dataContributions.data.filter((e) => e.selected))
    }

    // Set the file in states
    // const onFileChange = (e) => {
    //     setState({ ...state, image: e.target.files[0] });
    // };

    // Search function
    // function search(items) {
    //     return items.filter((item) => {
    //         if ((path.basename(item?.video)).includes(filterParam)) {
    //             return searchParam.some((newItem) => {
    //                 if (item[newItem] !== null) {
    //                     return (
    //                         item[newItem]
    //                             .toString()
    //                             .toLowerCase()
    //                             .indexOf(q.toLowerCase()) > -1
    //                     );
    //                 }
    //             });
    //         } else if (filterParam == "") {
    //             return searchParam.some((newItem) => {
    //                 return (
    //                     item[newItem]
    //                         .toString()
    //                         .toLowerCase()
    //                         .indexOf(q.toLowerCase()) > -1
    //                 );
    //             });
    //         }
    //     });
    // }

    // To select the row from the table
    const onItemCheck = (e, item) => {
        let tempList = props?.Data;
        tempList.map((data) => {
            if (data.dcId === item.dcId) {
                data.selected = e.target.checked;
            }
            return data;
        });
        setSelectedList(tempList.filter((item) => item.selected));
        if (props.deepFace) {
            props.selectImage(item.video);
        }
    }

    // const DelayApiCall = React.useCallback(
    //     debounce(
    //         (apiname, data) =>
    //             services
    //                 .post(apiname, data)
    //                 .then((response) => {
    //                     toast.success(response.data.message);
    //                 })
    //                 .catch((error) => {
    //                     toast.error(error.response.data.error);
    //                 }),
    //         1500
    //     ),
    //     []);

    // let handleChangeauthor = (e) => {
    //     let newfetched = state.fetched;
    //     newfetched.author = e.target.value;
    //     setState({ ...state, fetched: newfetched });
    //     const data = { id: state.fetched.dcId, q1: state.fetched.q1, q2: e.target.value, author: e.target.value }
    //     DelayApiCall("edit_dataset", data);
    // }

    // let handleChangeform = (e) => {
    //     let newfetched = state.fetched;
    //     newfetched.q1 = e.target.value;
    //     setState({ ...state, fetched: newfetched });
    //     const data = { id: state.fetched.dcId, q1: e.target.value, q2: state.fetched.q2, author: state.fetched.author }
    //     DelayApiCall("edit_dataset", data);
    // }

    // let handleChangeform2 = (e) => {
    //     let newfetched = state.fetched;
    //     newfetched.q2 = e.target.value;
    //     setState({ ...state, fetched: newfetched });
    //     const data = { id: state.fetched.dcId, q1: state.fetched.q1, q2: e.target.value, author: state.fetched.author }
    //     DelayApiCall("edit_dataset", data);
    // }

    // const dataUpload = (childStates) => {
    //     setIsUploadDataLoading(true);
    //     const apiname = "data_upload";
    //     var formData = new FormData();
    //     formData.append("dataUpload", childStates.image);
    //     formData.append("pId", isId());
    //     formData.append("user_id", isAuth().id);
    //     formData.append("contributor", isAuth().username);
    //     services
    //         .post(apiname, formData)
    //         .then((response) => {
    //             toast.success(response.data.message);
    //             sessionStorage.setItem(
    //                 "data",
    //                 JSON.stringify(response?.data?.data?.video)
    //             );
    //             sessionStorage.setItem(
    //                 "id",
    //                 JSON.stringify(response?.data?.data?.dcId)
    //             );
    //             setState({
    //                 ...state,
    //                 screen5: true,
    //             });
    //             completeUpload(childStates, response?.data?.data?.dcId)
    //         })
    //         .catch((err) => {
    //             setIsUploadDataLoading(false);
    //             toast.error(err.response.data.error);
    //             setState({
    //                 ...state,
    //                 image: ''
    //             });
    //         });
    // };

    // const checkhandler = (e) => {
    //     setDetails({ ...details, [e.target.name]: e.target.value });
    // };

    const completeUpload = (childStates, id) => {
        const apiname = "data-details";
        var per = "";
        if (childStates.permissionOne !== '') {
            per = per + childStates.permissionOne + ";";
        }
        if (childStates.permissionTwo !== '') {
            per = per + childStates.permissionTwo + ";";
        }
        if (childStates.permissionThree !== '') {
            per = per + childStates.permissionThree;
        }
        const data = {
            // author: details.author,
            // q1: details.q1,
            // q2: details.q2,
            // permissions: per,
            // id: dataId,
            author: childStates.author,
            q1: childStates.whereDidThisComeFrom ? childStates.whereDidThisComeFrom : "NA",
            q2: childStates.howWasThisSourced,
            permissions: per,
            id: id,
            call_for_data_fields: JSON.stringify(childStates.dataFields),
            optional_question_data: `[]`,
            consent_optional_email: childStates.email,
            consent_donation_paid: childStates.donationOrPaid,
        };
        services
            .post(apiname, data)
            .then((response) => {
                toast.success(response.data.message);
                setDetails({ ...details, author: "", q1: "", q2: "", permissions1: "", permissions2: "", permissions3: "" });
                setState({ ...state, image: "", screen6: false });
                setIsUploadDataLoading(false);
            })
            .catch((err) => {
                setIsUploadDataLoading(false);
                toast.error(err.response.data.error);
            });
    };

    // Handle mouse enter and leave events to track hover state
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const changeFileExtension = (fileName) => {
        if (fileName.endsWith('.png') && fileName.startsWith('drawing-prompt')) {
          return fileName.replace('.png', '.svg');
        }
        return fileName;
      }

      //temporary api call for grouping , remove it after completion
      const [perticularProject, setPerticularProject] = React.useState([]);
      const [authorGroups, setAuthorGroups] = React.useState({}); // to store author groups
      
  const [selectedAuthor, setSelectedAuthor] = React.useState(null); // to store selected author
 
      const projId = "33"
      const fetchPerticularProj = React.useCallback(() => {
        const apiname1 = "fetch-project-data";
        services
          .getbasic(apiname1, projId)
          .then((response) => {
            const data = response.data;
            const authorGroups = {};
        data.forEach((item) => {
          const author = item.author;
          if (!authorGroups[author]) {
            authorGroups[author] = [];
          }
          authorGroups[author].push(item);
          console.log("authorGroups",authorGroups)
        });
        setPerticularProject(data);
        setAuthorGroups(authorGroups);
          }).catch((error) => {
            console.log(error);
          });
      },[]);
      
      const handleAuthorClick = (author) => {
        setSelectedAuthor((prevAuthor) => (prevAuthor === author ? null : author));
      };
      React.useEffect(() => {
    fetchPerticularProj();
      },[fetchPerticularProj])

    const buttonStyles = {
        padding: "20px",
        borderRadius: "50px",
        width: "45%",
        display: "block",
        margin: "50px auto 0 auto",
        color: "rgba(255, 255, 255, 0.7)",
        border: "none",
        backgroundColor: isHovered ? "black" : "rgba(0, 0, 0, 0.54)",
        textAlign: "center",
    };

    const inputStyles = {
        width: "100%",
        borderRadius: "20px",
        padding: "7px",
        border: "1px solid black",
    };
    
    const dropInputStyles = {
        width: "100%",
        padding: "5px",
        fontSize: "16px",
        lineHeight: "1",
        border: "1px solid black",
        borderRadius: "20px",
        // background: 'url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right ',
        background: 'url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+)no-repeat right',
        backgroundColor: "none",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: "right 20px top 0.4em",
        height: "37px",
        paddingLeft: "20px"
    };
    
    const imageStyle = {
        width: "30px",
        height: "30px",
    };

    const imagedStyle = {
        width: "15px",
        height: "10px",
        // margin:"0 5px"
    };

    const authorEntries = Object.entries(authorGroups);
  
  // Slice the entries array to display only the relevant records
//   const displayedAuthors = authorEntries.slice(indexOfFirstRecord, indexOfLastRecord);
    

  return (
    <div>
      <div className="projectdb mt-3">
                <div className="row p-3">
                    <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <input
                            type="search"
                            className="artsearchinput"
                            placeholder="SEARCH"
                            style={inputStyles}
                            value={q}
                            onChange={(e) => setQ(e.target.value)}
                        ></input>
                    </div>
                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 customdrop">
                        <select onChange={handleChange} name="FILETYPE " className="artsearchinput"
                            style={dropInputStyles}>
                            <option key={0} value=''> FILETYPE</option>
                            {/* {props.ExtensionList.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))} */}
                        </select>
                    </div>
                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 customdrop">
                        <select onChange={handleAction} name="ACTION " className="artsearchinput"
                            style={dropInputStyles}>
                            <option key={0} value=''> ACTION</option>
                            <option key={1} value="Download" > Download</option>
                            <option key={2} value="Delete"> Delete</option>
                        </select>
                    </div>
                    <div className="col-md-2 col-lg-2 col-xl-2">
                        <p className="txtpagination">items per page: 10</p>
                    </div>
                    <div className="  col-md-2 col-lg-2 col-xl-2">
                        <Pagination
                            nPages={nPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            lastrecord={indexOfLastRecord}
                            total={props.Data?.length}
                        />
                    </div>
                </div>
                
        <table className="table">
        <thead>
          <tr style={{ fontSize: "13px", color: " rgba(78, 78, 78, 0.7)", textTransform: "lowercase" }}>
          <th>#</th>
                            <th>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={state.MasterChecked}
                                    id="mastercheck"
                                    onChange={(e) => onMasterCheck(e)}
                                /></th>
                            <th >icon</th>
                            <th className="text-start">file name</th>
                            {/* <th>author(s)</th> */}
                            <th>file size</th>
                            <th>date added</th>
                            <th>contributor</th>
          </tr>
        </thead>
        <tbody>
        <label style={{display:"flex",width:"100px",justifyContent:"center"}}>Authors</label>
          {authorEntries.slice(indexOfFirstRecord, indexOfLastRecord).map(([author, items], index) => (
            <React.Fragment key={index}>
                
              <div
               onClick={() => handleAuthorClick(author)}
               style={{display:"flex",width:"50%",justifyContent:"space-between"}}>
                
                <div>
                  
                    <label style={{fontSize:"14px"}}>
                        {/* <label style={{textTransform:"none"}}>Author:</label>  */}
                        {author}</label>
                    </div>
                    <div>
                    <img src={downArrow} style={imagedStyle}/>
                    </div>
              </div>
              {selectedAuthor === author &&
                items.map((item, index) => (
                    
                  <tr key={index}>
                    
                    <td style={{ fontSize: "13px" }}>{index + 1}</td>
                    <td>
                                    <input
                                        type="checkbox"
                                        checked={item?.selected || false}
                                        className="form-check-input"
                                        onChange={(e) => onItemCheck(e, item)}
                                    />
                                    </td>
                                    <td
                                    onClick={() => {
                                        setState({ ...state, screen7: !state.screen7, fetched: item });
                                    }}
                                >
                                    {" "}
                                    <img
                                        src={item?.video.includes("mp3") ? iconMp3 : item?.video}
                                        style={imageStyle}
                                        alt="item.video"
                                    />
                                </td>
                    <td className="text-start" style={{ fontSize: "13px" }}>{item.video.split("/").at(-1)}</td>
                    {/* <td style={{ fontSize: "13px" }}>{item.author}</td> */}
                    <td style={{ fontSize: "13px" }}>{item.fileSize} KB</td>
                    <td style={{ fontSize: "13px" }}>{moment(item.createdAt).format('YYYY-MM-DD')}</td>
                    <td style={{ fontSize: "13px" }}>{item.contributor}</td>
                  </tr>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      
                {/* {state.dataContributions?.data?.length === 0 ? (
                <p className="text-center pb-3" style={{ fontWeight: "bold" }}>
                    <i className="fas fa-empty-set"></i> No Data Found
                </p>
            ) : !loading ? (
                <div style={spinCenter}>
                    <div
                        className="spinner-border"
                        style={{ width: "3rem", height: "3rem" }}
                        role="status"
                    ></div>
                </div>
            ) : null} */}
            </div>
    </div>
  )
}

export default GroupDataView
