import React from "react";
const Toggle = ({ onChange}) => {
  return (
    <label className="check_switch">
      <input type="checkbox" className="checkinput" onChange={onChange} />
      <span className="slider"></span>
    </label>
  );
};
export default Toggle;
