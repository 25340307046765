import { useNavigate} from "react-router-dom";

const FramesNavBar = (props) => {
  const navigate = useNavigate();
  const Data = props?.labels;
  const Item = Data?.map((item) =>
    item.name === props?.isActive ? (
      <div
        className="framesRowActiveComponents text-hover"
        onClick={() => navigate(item.value)}
      >
        {item.name}
      </div>
    ) : (
      <div className="framesRowComponents text-hover" onClick={() => navigate(item.value)}>
        {item.name}
      </div>
    )
  );

  return (
    <>
      <div className="framesRow">{Item}</div>
    </>
  );
};

export { FramesNavBar };
