import React from "react";
import "./CallForData.css";
import CustomDropdown from "./CustomDropdown";
import { CustomLabel } from "./CustomInputField";
import Arrow from "../../Assets/Images/darrow.png";
import Darrow from "../../Assets/Images/uarrow.png";
import Ellipse from "../../Assets/Images/Ellipse.png";
import MultiSelectTextInput from "./multiSelectDropDown";
import toast from "react-hot-toast";
import { validateInput } from "../../utils/general";
import option from "../../Assets/Images/options.png";
import Share from "./Share";
import Trace from "./Trace";

const options = [
  { name: "IMAGE (.PNG)", selected: false, value: "PNG" },
  { name: "IMAGE (.JPG)", selected: false, value: "JPG" },
  { name: "DRAW", selected: false, value: "SVG" },
  { name: "RECORD", selected: false, value: "MP3" },
  { name: "TEXT (.TXT)", selected: false, value: "TXT" },
  { name: "AUDIO (.MP3)", selected: false, value: "MP3" },
  { name: "3D (.obj)", selected: false, value: "obj" },
];

const dataTypes = [
  { name: "IMAGE (PNG,JPG,SVG)", value: "PNG,JPG,SVG" },
  { name: "TEXT (TXT)", value: "TXT" },
  { name: "AUDIO (MP3)", value: "MP3" },
  { name: "3D (obj)", value: "3D (obj)" },
];
const color = {
  color: "#4E4E4EB2",
};

const CallForData = ({
  parentStates,
  callenabled,
  uploadData,
  closeModal,
  projDesc,
  unPublish,
  deleteCall,
  loadShareData
}) => {
  const [state, setState] = React.useState({
    DataCallTitle: "",
    title: "",
    titleMessage: "",
    isProjectDescriptionChecked: false,
    fieldsData: [],
    dataTypes: [],
    optionalData: [],
    author: "",
    whereDidThisComeFrom: "",
    howWasThisSourced: "",
    permissionOne: `I consent to this data being used according to the data settings
    of this project, for as long as the project chooses to store the
    data (read about our data policy and future plans here)`,
    permissionTwo: "Can we follow-up with you about your upload?",
    permissionThree: "",
    consentsAndPermissions: [],
    fieldCount: 1,
    optionalFieldCount: 1,
    crudOperation: false,
    enableShare:false,
    trace: false
  });

  const [showOptions, setShowOptions] = React.useState(false);

  const handleOptionClick = () => {
    setShowOptions(!showOptions);
  };

  React.useEffect(() => {
    if (
      parentStates?.callfordata_enabled === "Y" ||
      parentStates?.DataCallTitle
    ) {
      setState({ ...state, crudOperation: true });
    }
  }, []);

  const [fieldTempData, setFieldTempData] = React.useState({
    question: "",
    dropDownValue: "",
  });

  const [optionalQuestions, setOptionalQuestions] = React.useState({
    question: "",
    value: "",
  });

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [screenCount, setScreenCount] = React.useState(
    parentStates.callfordata_enabled === "Y" || parentStates.dataTypes ? 4 : 1
  );
  // const [isChecked, setIsChecked] = React.useState(false);
  const [isAdvance, setisAdvance] = React.useState(false);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const projectName = sessionStorage.getItem("projectName");

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const changeScreen = () => {
    const isValid = () =>
      validateInput(state.DataCallTitle, "DataCallTitle") &&
      validateInput(state.title, "title") &&
      validateInput(state.titleMessage, "titleMessage") &&
      validateInput(state.dataTypes.join(""), "dataTypes");

    if (!isAdvance) {
      if (!isValid()) return;
      setScreenCount(4);
    } else {
      if (screenCount === 1 && isValid()) {
        if (fieldTempData.question && fieldTempData.dropDownValue) {
          setState({
            ...state,
            fieldsData: [
              ...state.fieldsData,
              {
                question: fieldTempData.question,
                selectedValue: fieldTempData.dropDownValue,
              },
            ],
            fieldCount: state.fieldCount + 1,
          });
          setFieldTempData({ question: "", dropDownValue: "" });
        }
      } else if (screenCount === 2 && optionalQuestions.question) {
        setState({
          ...state,
          optionalData: [
            ...state.optionalData,
            {
              question: optionalQuestions.question,
              value: optionalQuestions.value,
            },
          ],
          optionalFieldCount: state.optionalFieldCount + 1,
        });
        setOptionalQuestions({ question: "", value: "" });
      }
      setScreenCount(screenCount + 1);
    }
  };

  const handleBack = () => {
    if (screenCount === 1) {
      closeModal(false);
    } else if (!isAdvance && screenCount === 4) {
      setScreenCount(1);
      setState({ ...state, crudOperation: false });
    } else {
      setScreenCount(screenCount - 1);
    }
  };

  // Send the states of Call For Data Settings on server
  const saveCallForData = () => {
    uploadData(state);
    // setState({...state,crudOperation:true})
  };
  const data = {
    pId: parentStates?.pId,
  };

  //send project ID to parent
  const unPublishCallForData = () => {
    unPublish(data);
    setShowOptions(false);
  };

  //send project ID to parent
  const deleteCallForData = () => {
    deleteCall(parentStates?.pId);
    setShowOptions(false);
  };

  // const toggleChecked = () => {
  //   setIsChecked(!isChecked);
  //   setState((prevState) => ({
  //     ...prevState,
  //     consentsAndPermissions: isChecked ? [] : ["checked"],
  //   }));
  // };

  // const setPermissions = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.value });
  // };

  const setStateData = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleQuestionsData = (e, index) => {
    const { value } = e.target;
    setFieldTempData({ ...fieldTempData, question: value }); //Set the Question in Temp State
  };

  const handleDropdownChange = (selectedOption, index) => {
    setFieldTempData({ ...fieldTempData, dropDownValue: selectedOption });
  };

  const handleDataTypeChange = (selectedValues) => {
    setState({ ...state, dataTypes: selectedValues });
  };

  //   const handleDataTypeChange = (selectedValues) => {
  //     const imageOptions = ["IMAGE (PNG)", "IMAGE (JPG)", "IMAGE (SVG)"];
  //     let lastImageOption = null;

  //     // Filter selected values to allow only one image type
  //     const filteredValues = selectedValues.filter(option => {
  //         if (imageOptions.includes(option.name)) {
  //             lastImageOption = option;
  //             return false;
  //         }
  //         return true;
  //     });

  //     // If there is a selected image option, add it to the filtered values
  //     if (lastImageOption) {
  //         filteredValues.push(lastImageOption);
  //     }

  //     // Update state with the filtered values
  //     setState({ ...state, dataTypes: filteredValues });
  // };

  const addDataFields = () => {
    let jsonObj = {
      question: fieldTempData.question,
      selectedValue: fieldTempData.dropDownValue,
    };
    // Add jsonObj in existing array data of fieldData
    setState({
      ...state,
      fieldsData: [...state.fieldsData, jsonObj],
      fieldCount: state.fieldCount + 1,
    });
    setFieldTempData({ question: "", dropDownValue: "" });
  };

  const addOptionalFields = () => {
    let jsonObj1 = {
      question: optionalQuestions.question,
      value: "",
    };
    // Add jsonObj in existing array data of optionalData
    setState({
      ...state,
      optionalData: [...state.optionalData, jsonObj1],
      optionalFieldCount: state.optionalFieldCount + 1,
    });
    setOptionalQuestions({ question: "" });
  };

  const handleOptionalQuestionsData = (e, index) => {
    const { value } = e.target;
    setOptionalQuestions({ ...optionalQuestions, question: value }); //Set the Question in Temp State
  };

  const screenOne = () => {
    return (
      <React.Fragment>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p style={{ fontSize: "14px", marginTop: "15px" }}>
            Here you can further customize your call for data and add additional
            inputs within a single form contribution.
          </p>
        </div>
        {state.fieldsData.length > 0
          ? state.fieldsData.map((item, index) => (
              <div className="fieldRowView">
                <label>Field {index + 1}</label>
                <input
                  name="question"
                  type="text"
                  value={item.question}
                  placeholder="Type your question"
                  disabled={true}
                />
                <CustomDropdown
                  options={options}
                  selectedValue={item.selectedValue}
                  placeholder="Select an option"
                  dropdownStyle={{ width: "200px" }}
                  inputStyle={{
                    padding: "10px",
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    border: "1px solid #ccc",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                  onChange={(selectedOption) =>
                    handleDropdownChange(selectedOption, 1)
                  }
                />
              </div>
            ))
          : null}
        <div className="fieldRowView">
          <label>Field {state.fieldCount}</label>
          <input
            name="question"
            type="text"
            placeholder="Type your question"
            value={fieldTempData.question}
            onChange={(e) => handleQuestionsData(e)}
          />
          <CustomDropdown
            options={options} // List of Array
            selectedValue={fieldTempData.dropDownValue}
            placeholder="Select an option"
            dropdownStyle={{ width: "200px" }}
            inputStyle={{
              padding: "10px",
              fontFamily: "DM Sans",
              fontSize: "16px",
              border: "1px solid #ccc",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
            onChange={(selectedOption) =>
              handleDropdownChange(selectedOption, 0)
            }
          />
        </div>
        <div className="basicRow">
          <div className="plusButton" onClick={addDataFields}>
            &#43;
          </div>
        </div>
      </React.Fragment>
    );
  };

  const screenTwo = () => {
    return (
      <React.Fragment>
        <div className="headingScreenTwo">
          <p style={{ fontSize: "14px" }}>
            Provenance is the chronology of an object’s ownership.
          </p>
          <p style={{ fontSize: "14px" }}>
            These required questions will appear to contributors after uploading
            files to your <br />
            data call:
          </p>
        </div>
        <div
          style={{
            border: "2px dotted #4E4E4EB2",
            width: "93%",
            padding: "20px 21px",
          }}
        >
          <div className="basicRow" style={{ width: "100%" }}>
            <CustomLabel label="AUTHOR" />
          </div>
          <div className="basicRow" style={{ width: "100%" }}>
            <CustomLabel label="HOW WAS THIS SOURCED?" />
          </div>
        </div>
        <div className="headingScreenTwo" style={{ marginTop: "10px" }}>
          <p>Optional</p>
        </div>
        {state.optionalData.length > 0
          ? state.optionalData.map((item, index) => (
              <div className="fieldRowView" style={{ height: "90px" }}>
                <label>Question {index + 1}</label>
                <input
                  name="question"
                  type="text"
                  value={item.question}
                  placeholder="Type your question"
                  disabled={true}
                />
              </div>
            ))
          : null}
        <div className="fieldRowView" style={{ height: "90px" }}>
          <label>Question {state.optionalFieldCount}</label>
          <input
            name="question"
            type="text"
            placeholder="Type your question"
            value={optionalQuestions.question}
            onChange={(e) => handleOptionalQuestionsData(e)}
          />
        </div>
        <div className="basicRow">
          <div className="plusButton" onClick={addOptionalFields}>
            &#43;
          </div>
        </div>
      </React.Fragment>
    );
  };

  const screenThree = () => {
    return (
      <React.Fragment>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {/* <p style={{ fontSize: "24px" }}>Consent & Permissions</p> */}
        </div>
        <div className="headingScreenTwo">
          <p style={{ fontSize: "14px" }}>What will appear to contributors:</p>
        </div>
        <div className="basicRow">
          {/* <h6 className="headingScreenThree">CONSENT AND PERMISSIONS:</h6>
          <p className="headingScreenThree">
            PLEASE SELECT ALL THAT APPLY.PLEASE SEND ANY QUESTIONS TO
            DATA@POIETO.COM
          </p> */}
          {/* <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name="permissionOne"
              onChange={setPermissions}
              value="I CERTIFY I HAVE THE PERMISSIONS TO SHARE THIS FILE"
              style={{ marginTop: "0.8rem" }}
            />
            <label
              className="form-check-label"
              htmlFor="gridCheck1"
              style={{
                fontSize: "12px",
                color: "#4E4E4EB2",
                marginTop: "0.8rem",
              }}
            >
              I CERTIFY I HAVE THE PERMISSIONS TO SHARE THIS FILE
            </label>
          </div> */}
          <div
            style={{
              border: "2px dotted #4E4E4EB2",
              width: "100%",
              padding: "46px 21px",
            }}
          >
            <div className="form-check mb-2 mt-2">
              {/* <input
                className="form-check-input"
                type="checkbox"
                name="permissionTwo"
                value=" I consent to this data being used according to the data
                        settings of this project, for as long as the project
                        chooses to store the data"
                onChange={setPermissions}
              /> */}
              <label
                className="form-check-label"
                htmlFor="gridCheck1"
                style={{
                  textTransform: "uppercase",
                  fontSize: "12px",
                  color: "#4E4E4EB2",
                }}
              >
                I consent to this data being used according to the data settings
                of this project, for as long as the project chooses to store the
                data (read about our data policy and future plans here)
              </label>
            </div>
            <div className="form-check">
              {/* <input
                className="form-check-input"
                type="checkbox"
                name="permissionThree"
                onChange={setPermissions}
                value=" Can we follow-up with you about your upload?"
                style={{ marginTop: "0.8rem" }}
              /> */}
              <label
                className="form-check-label"
                htmlFor="gridCheck1"
                style={{
                  textTransform: "uppercase",
                  fontSize: "12px",
                  color: "#4E4E4EB2",
                  marginTop: "0.8rem",
                }}
              >
                Can we follow-up with you about your upload?
              </label>
            </div>
          </div>
          <div style={{ marginTop: "25px" }}>
            <p style={color}>
              Email data@poieto.com with any questions or requests.
            </p>
          </div>
          {/* <div className="toggleContainer">
            <div
              className={`custom-toggle-slider ${isChecked ? "checked" : ""}`}
              onClick={toggleChecked}
            >
              <div className="slider"></div>
            </div>
          </div> */}
        </div>
      </React.Fragment>
    );
  };

  const handleShare = () =>{
    setState(
      {...state,enableShare:true,})
      setShowOptions(!showOptions);
  }

  const onShareData =(states) => {
    loadShareData(states)
    setState({...state,enableShare:false})
  }

  const showTrace = () =>{
    setState(
      {...state,trace:true,})
      setShowOptions(!showOptions);
  }

  const screenFour = () => {
    return (
      <React.Fragment>
        {state.enableShare && 
         <Share
         projectName = {projectName}
         pId={parentStates?.pId}
         uploadShareData={(states) => onShareData(states)}
         code={parentStates?.call_for_data_code}
         closeShare={(value) => {
          setState({...state,enableShare:value})
         }} />
       }
       {state.trace &&
       <Trace 
       closeTrace={(value) => {
        setState({...state,trace:value})
       }}/>
       }
       
        <div className="FourthScreencontainer">
          <i
            className="far fa-times-circle fa-lg close-icon"
            onClick={() => {
              closeModal(false);
            }}
            style={{ color: "grey" }}
          ></i>
          <div className="FSC-options">
            <p
              style={{
                fontSize: "20px",
                color: "rgba(0, 0, 0, 0.54)",
                fontWeight: "400",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Call for Data
            </p>
            {state?.crudOperation && (
              <div style={{ position: "relative" }}>
                <img
                  alt="menu"
                  src={option}
                  style={{ height: "16px", cursor: "pointer" }}
                  onClick={handleOptionClick}
                />
                {showOptions && (
                  <div className="options-popup">
                    <ul>
                    <li onClick={handleShare}>
                      <i class="fa fa-share-square" aria-hidden="true"
                       style={{ marginRight: "7px", marginLeft: "4px" }}></i>
                        Share
                      </li>
                      <li onClick={unPublishCallForData}>
                        <i
                          className="fa fa-eye-slash"
                          aria-hidden="true"
                          style={{ marginRight: "10px" }}
                        ></i>
                        Unpublish
                      </li>
                      <li onClick={deleteCallForData}>
                        <i
                          className="fa fa-trash"
                          aria-hidden="true"
                          style={{ marginRight: "13px", marginLeft: "4px" }}
                        ></i>
                        Delete
                      </li>
                      {/* <li onClick={showTrace}>
                        <i
                          className="fa fa-code-fork"
                          aria-hidden="true"
                          style={{ marginRight: "13px", marginLeft: "4px" }}
                        ></i>
                        Trace
                      </li> */}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>

          <p style={{ fontSize: "14px", lineHeight: "1" }}>
            A Call for Data will make a public posting on POIETO Data.
          </p>
          <div className="innerFourth">
            {!state.crudOperation ? (
              <div className="headingScreenFour">
                <p style={{ display: "flex", flexDirection: "row" }}>
                  {state.dataTypes?.map((item, index) => {
                    return `${item.value}${
                      index < state.dataTypes.length - 1 &&
                      item.value.length > 1
                        ? ","
                        : ""
                    } `;
                  })}
                </p>
                <p
                  style={{
                    color: "#4E4E4EB2",
                    fontWeight: "300",
                    fontSize: "32px",
                    margin: "25px 0px",
                  }}
                >
                  {state.DataCallTitle
                    ? state.DataCallTitle.length > 20
                      ? state.DataCallTitle.slice(0, 20) + "..."
                      : state.DataCallTitle
                    : "DATA CALL TITLE"}
                </p>
              </div>
            ) : (
              <div className="headingScreenFour">
                <p style={{ display: "flex", flexDirection: "row" }}>
                  {parentStates?.dataTypes ? parentStates?.dataTypes : ""}
                </p>
                <p
                  style={{
                    color: "#4E4E4EB2",
                    fontWeight: "300",
                    fontSize: "32px",
                    margin: "25px 0px",
                  }}
                >
                  {parentStates?.DataCallTitle
                    ? parentStates?.DataCallTitle
                    : "DATA CALL TITLE"}
                </p>
              </div>
            )}

            <div className="yellowbox" style={{ marginBottom: "10px" }}>
              <div className="d-flex align-items-center">
                <div style={{ marginRight: "10px" }}>
                  <img src={Ellipse} alt="user" />
                </div>
                {parentStates ? parentStates?.project_title : "PROJECT"}
              </div>
            </div>
            <p style={{ color: "#000000b3", marginTop: "5px" }}>
              {state.title
                ? state.title.length > 50
                  ? state.title.slice(0, 50) + "..."
                  : state.title
                : state.crudOperation && parentStates?.title}
            </p>
            <p style={{ color: "#000000b3", lineHeight: "1.2" }}>
              {state.titleMessage
                ? state.titleMessage.length > 85
                  ? state.titleMessage.slice(0, 85) + " ..."
                  : state.titleMessage
                : state.crudOperation && parentStates.message.length > 85
                ? parentStates.message.slice(0, 85) + " ..."
                : parentStates.message}
            </p>
          </div>
          <div
            className="footer"
            style={{
              width: "100%",
              justifyContent: state.crudOperation ? "center" : "space-between",
            }}
          >
            <button
              onClick={handleBack}
              className="FooterBackbutton"
              style={{
                width: state.crudOperation ? "75%" : "48%",
                height: "50px",
              }}
            >
              Back to Edit
            </button>
            {!state.crudOperation && (
              <button
                onClick={saveCallForData}
                className="footerButton"
                style={{ width: "48%", height: "50px" }}
              >
                Publish
              </button>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  // Function thats change the screens components according to the Screen Count
  const switchScreens = () => {
    if (!isAdvance) {
      return;
    }
    switch (screenCount) {
      case 1:
        return screenOne();
      case 2:
        return screenTwo();
      case 3:
        return screenThree();
      case 4:
        return screenFour();
      default:
        // Default Screen One will target
        return screenOne();
    }
  };

  return (
    <div
      className="outerMainContainer"
      style={{ width: screenWidth, zIndex: "1" }}
    >
      {screenCount === 4 ? (
        <>{screenFour()}</>
      ) : (
        <div
          className="innerCenterContainer"
          style={{ width: screenWidth / 1.7 }}
        >
          <div className="text-right b-none" style={{ margin: " 5px 15px" }}>
            <i
              className="far fa-times-circle fa-lg"
              onClick={() => {
                closeModal(false);
              }}
              style={{ color: "grey" }}
            ></i>
          </div>
          <div className="header">
            <p style={{ fontSize: "24px", color: "#4E4E4EB2" }}>
              Call For Data Settings
            </p>
            <p style={{ fontSize: "14px", color: "#4E4E4EB2" }}>
              A Call for Data will make a public posting on POIETO Data.
            </p>
            <div className="headerInputs">
              <input
                id="DataCall"
                name="DataCallTitle"
                type="text"
                value={state.DataCallTitle}
                placeholder="ENTER DATA CALL TITLE*"
                style={{ fontStyle: "italic", border: "none" }}
                onChange={(event) => setStateData(event)}
              />
              <label
                style={{
                  display: "flex",
                  paddingLeft: "25px",
                  width: "100%",
                  color: "#4E4E4EB2",
                }}
              >
                Data Call Tagline - Describe the data you are looking for.
              </label>
              <input
                name="title"
                type="text"
                value={state.title}
                placeholder="Enter info..."
                onChange={(event) => setStateData(event)}
              />
              <label
                style={{
                  display: "flex",
                  paddingLeft: "25px",
                  width: "100%",
                  color: "#4E4E4EB2",
                }}
              >
                Project Description - Describe how you are planning to use the
                data.
              </label>
              <div className="cfd-ProjDescSection">
                <div
                  className="yellowbox"
                  style={{ margin: "5px 0px 5px 25px" }}
                >
                  <div className="d-flex align-items-center">
                    <div style={{ marginRight: "10px" }}>
                      <img src={Ellipse} alt="user" />
                    </div>
                    {projectName ? projectName.toUpperCase() : "PROJECT"}
                  </div>
                </div>
                <div className="cfd-checkbox">
                  <input
                    type="checkbox"
                    className="projectdescriptioncheckbox"
                    checked={state.isProjectDescriptionChecked}
                    onChange={(event) => {
                      const isChecked = event.target.checked;
                      setState((prevState) => ({
                        ...prevState,
                        isProjectDescriptionChecked: isChecked,
                        titleMessage: isChecked ? projDesc : "",
                      }));
                      if (isChecked && !projDesc) {
                        setState((prevState) => ({
                          ...prevState,
                          isProjectDescriptionChecked: false,
                          titleMessage: isChecked ? projDesc : "",
                        }));
                        toast.error(
                          "Project description cannot be empty. Please write a project description."
                        );
                      }
                    }}
                    style={{
                      width: "20px",
                      height: "20px",
                      fontFamily: "DM Mono",
                    }}
                  />
                  <label style={color}>USE PROJECT DESCRIPTION</label>
                </div>
              </div>
              <input
                name="titleMessage"
                type="text"
                value={
                  state.isProjectDescriptionChecked ? null : state.titleMessage
                }
                style={
                  state.isProjectDescriptionChecked ? { opacity: 0.5 } : {}
                }
                placeholder="Enter info..."
                disabled={state.isProjectDescriptionChecked}
                onChange={(event) => setStateData(event)}
              />
            </div>
          </div>

          <>
            <div>
              <label
                style={{
                  display: "flex",
                  paddingLeft: "25px",
                  width: "100%",
                  color: "#7D7E77",
                }}
              >
                Data Type(s) - Select type of data to appear on the data call
                posting. This can be more than one type.
              </label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "25px",
                marginTop: "20px",
              }}
            >
              <MultiSelectTextInput
                options={dataTypes}
                placeholder="TYPE"
                onChange={handleDataTypeChange}
                dropdownStyle={{ width: "200px", maxHeight: "35px" }}
                inputStyle={{
                  padding: "10px",
                  backgroundColor: "transparent",
                  width: "40px",
                  height: "35px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              />

              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {state.dataTypes?.map((value, index) => (
                  <div key={index} className="cfd-selectedvalues">
                    <p>{value.name}</p>
                  </div>
                ))}
              </div>
            </div>
          </>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0 25px",
            }}
          >
            {/* <hr style={{ border: "1px solid black", flex: "1" ,zIndex:"auto"}} /> */}
            <div
              className="cfd-line"
              style={{ width: "40%", backgroundColor: "#0000008c" }}
            ></div>
            <p
              onClick={() => {
                setisAdvance(!isAdvance);
              }}
              style={{
                padding: "0 10px",
                color: "#4E4E4EB2",
                marginTop: "15px",
                cursor: "pointer",
              }}
            >
              Advanced
              {!isAdvance ? (
                <img
                  src={Arrow}
                  alt="arrow"
                  style={{ width: "15px", marginLeft: "5px" }}
                />
              ) : (
                <img
                  src={Darrow}
                  alt="arrow"
                  style={{ width: "15px", marginLeft: "5px" }}
                />
              )}
            </p>
            {/* <hr style={{ border: "1px solid black", flex: "1",zIndex:"2" }} /> */}
            <div
              className="cfd-line"
              style={{ width: "40%", backgroundColor: "#0000008c" }}
            ></div>
          </div>
          {isAdvance ? (
            <div className="centerMainContainer">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="step">
                  <div
                    className={
                      screenCount === 1
                        ? "activeStep"
                        : screenCount > 1
                        ? "inactiveStep"
                        : "transparentStep"
                    }
                  ></div>
                  <label style={color}>Data</label>
                </div>
                <div className="cfd-line"></div>
                <div className="step">
                  <div
                    className={
                      screenCount === 2
                        ? "activeStep"
                        : screenCount > 2
                        ? "inactiveStep"
                        : "transparentStep"
                    }
                  ></div>
                  <label style={color}>Provenance</label>
                </div>
                <div className="cfd-line"></div>
                <div className="step">
                  <div
                    className={
                      screenCount === 3 ? "activeStep" : "transparentStep"
                    }
                  ></div>
                  <label style={color}>Consent & Permissions</label>
                </div>
              </div>
              {/* Using switch function to change the screens according to ScreenCount */}
              {switchScreens()}
            </div>
          ) : null}

          <div className="footer"
          style={{justifyContent : !isAdvance ? "center" : "space-evenly"}}>
            {!isAdvance ? null : (
              <button onClick={handleBack} className="FooterBackbutton">
                Back
              </button>
            )}

            <button
              onClick={changeScreen}
              style={!isAdvance ? { width: "93%" } : {}}
              className="footerButton"
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CallForData;
