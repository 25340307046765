import React, {useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FramesNavBar } from "./HeadarNavbar/FramesNavbar";
import Toggle from "../ToggleSwitch/toggleswitch";
import services from "../../API_SERVICES/services";
import toast from "react-hot-toast";
export const Editframes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [frames, setframesVal] = useState({
    id: location?.state?.data?.fId,
    frame_name: location?.state?.data?.frame_name,
    frame_desc: location?.state?.data?.frame_desc,
    frame_attr: location?.state?.data?.frame_attr,
    toggle: location?.state.data?.frame_privacy === "PUBLIC" ? false : true,
    userImageFile: location?.state?.data?.frame_image,
  });
  const { frame_name, frame_desc, frame_attr, toggle, userImageFile, id } =
    frames;
  const itemArray = [
    { name: "DISCOVER", value: "/decode" },
    { name: "FRAMES", value: "/CreateFrames" },
    { name: "BLUEPRINT", value: "/Howinputtab" },
    { name: "PROTOTYPE", value: "/modelspage" },
    { name: "REFLECTION", value: "/reflection" },
  ];
  const onFileChange = (event) => {
    if( event.target.files[0]?.size > 5242880)
    {
      toast.error('File size greater than 5 MB, Please upload a file with size less than 5 MB');
    }
    else{
      var formData = new FormData();
      formData.append("frameUpload", event.target.files[0]);
      formData.append("fId", id);
      const apiname = "edit-frame-image";
      services
        .put(apiname, formData)
        .then((response) => {
          toast.success(response.data.message);
        })
        .catch((err) => {
          toast.error(err.resposne.data.error);
        });

    }
  
  };
  const handleChange = (e) => {
    setframesVal({ ...frames, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const apiname = "edit-frames";
    const data = {
      fId: id,
      frame_name: frame_name,
      frame_desc: frame_desc,
      frame_privacy: toggle === true ? "PRIVATE" : "PUBLIC",
      frame_attr: frame_attr,
    };
    services
      .put(apiname, data)
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <FramesNavBar labels={itemArray} isActive={"FRAMES"} />
      <div className="d-flex justify-content-between">
        <input
          className="plachetitle"
          name="frame_name"
          value={frame_name}
          onChange={handleChange}
          placeholder="Enter frame name"
          style={{ paddingLeft: "20px", paddingTop: "20px" }}
        ></input>
        <div className="d-flex mt-3">
          <p className="me-3 mt-1">{toggle ? "PRIVATE" : "PUBLIC"}</p>
          <Toggle
            onChange={(e) =>
              setframesVal({ ...frames, toggle: e.target.checked })
            }
            toggle={toggle}
          />
        </div>
      </div>

      <div className="gridmeshdesc">
        <div className="item1">
          <div className="d-flex justify-content-between">
            <p
              className="ms-2"
              style={{
                paddingLeft: "20px",
                fontSize: "30px",
                color: "#4E4E4E",
                fontWeight: "100",
              }}
            >
              FRAME DESCRIPTION
            </p>
          </div>
          <textarea
            rows="2"
            placeholder="TYPE HERE..."
            name="frame_desc"
            value={frame_desc}
            onChange={handleChange}
            style={{
              fontSize: "25px",
              paddingLeft: 25,
              width: "100%",
              border: "none",
              borderColor: "#C7CCB2",
              backgroundColor: "transparent",
              outline: "none",
            }}
          ></textarea>
        </div>
        <div className="item2">
          <div className="d-flex justify-content-between">
            <p
              className="ms-2"
              style={{
                paddingLeft: "20px",
                fontSize: "30px",
                color: "#4E4E4E",
                fontWeight: "100",
              }}
            >
              INSPIRATION AND ATTRIBUTES
            </p>
          </div>
          <textarea
            className="mt-2"
            rows="2"
            name="frame_attr"
            value={frame_attr}
            onChange={handleChange}
            placeholder="TYPE HERE..."
            style={{
              fontSize: "25px",
              paddingLeft: 25,
              width: "100%",
              border: "none",
              borderColor: "#C7CCB2",
              backgroundColor: "transparent",
              outline: "none",
              resize: "none",
            }}
          ></textarea>
        </div>
        <div className="item4">
          <div className="d-flex">
            <p
              className="ms-2"
              style={{
                paddingLeft: "20px",
                fontSize: "30px",
                color: "#4E4E4E",
                fontWeight: "100",
              }}
            >
              CONTRIBUTORS
            </p>
          </div>
          <div
            className="roundcont me-2"
            // onClick={() => setframesVal({ ...frames, screen4: !screen4 })}
          >
            +
          </div>
        </div>
        <div
          className="item5"
          style={{ position: "relative", padding: 0, border: "none" }}
        >
          <img
            src={userImageFile}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "20px",
            }}
            alt="profimage"
          />
          <label htmlFor="file" id="filelabel">
            <i
              className="fas fa-camera"
              style={{ color: "black", fontSize: "25px" }}
            ></i>
          </label>
          <input
            type="file"
            id="file"
            name="frameUpload"
            onChange={onFileChange}
            style={{ display: "none", visibility: "none" }}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between ms-3">
        <button type="submit" className="butndesc" onClick={() => navigate(-1)}>
          <i
            className="fal fa-long-arrow-left mb-3"
            style={{ backgroundColor: "transparent", fontWeight: "500" }}
          />
          <br />
          BACK
        </button>
        <button
          type="submit"
          className="butndesc ms-3"
          style={{ backgroundColor: "#E5FF80" }}
          onClick={handleSubmit}
        >
          SAVE Changes
        </button>
      </div>
    </>
  );
};
