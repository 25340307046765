import React, { useState } from "react";
import { Navigate, useLocation ,useNavigate} from "react-router-dom";
import { toast } from 'react-hot-toast'
import { isAuth, logger } from '../../Storage(Methods)/storage';
import services from "../../API_SERVICES/services";
import { joinProject } from "../../Components/utility";

const MakeAccount = (props) => {
  let location = useLocation();
  const history = useNavigate();

  const [userVal, setUserVal] = useState({
    fullname: "",
    email: "",
    username: "",
    password: "",
    cpassword: "",
  });
  const { fullname, email, username, password, cpassword } = userVal;
  const handleChange = (e) => {
    setUserVal({ ...userVal, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const apiname = 'authentication/signup'
    const data = {
      fullname: fullname,
      email: email,
      username: username,
      password: password,
      cpassword: cpassword
    }
    services.post(apiname, data).then((response) => {
      if (response.data.message === "Signup Success Please Signin") {
        const eid=email;
        setUserVal({
          ...userVal,
          fullname: "",
          email: "",
          username: "",
          password: "",
          cpassword: "",
        });
        toast.success(response.data.message);
        if (location?.state?.source === "invite"&&eid.toLowerCase()===location?.state?.data.emailId.toLowerCase()) {
          handleLogin(response.data.data);
          setTimeout(() => history("/UserAddNewProject"), 2000);
        }
        else
        {
          setTimeout(() => history("/login", { state: null}), 2000);
          setTimeout(() => props.setShow("loginView"), 2000);
        }

      }
    })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  };

  const handleLogin = (e) => {
    const apiname = 'authentication/signin'
    const data = {
      email: location?.state?.data.emailId,
      password: password
    }
    services.post(apiname, data).then((response) => {
      if (response.data.message === 'Logged in Successfully') {
        joinProject(location?.state?.data.emailId,location?.state?.data?.projId,e.id,e.username);
        logger(response, () => {
        });
      }
    })
      .catch((error) => {
      });
  };
  return (
    <>
      {isAuth() ? location?.state?.source === "invite"?<Navigate to='/UserAddNewProject' replace={true} />: <Navigate to={-1} /> : null}
      <div className="content1">
        <form style={{ marginTop: "40px" }}>
          <label className="fulllabel">FULL NAME</label>
          <input
            type="text"
            className="makeaccinput"
            placeholder="Enter your full name"
            autoComplete="off"
            name="fullname"
            value={fullname}
            onChange={handleChange}
          />
          <div className="d-flex m-auto" style={{ width: "70%" }}>
            <div className="w-50">
              <label className="emaillabel">EMAIL</label>
              <input
                type="email"
                className="makeaccinput1"
                placeholder="Enter your email"
                autoComplete="off"
                name="email"
                value={email}
                onChange={handleChange}

              />
            </div>
            <div className="w-50">
              <label className="userlabel">USERNAME</label>
              <input
                type="text"
                className="makeaccinput1"
                placeholder="Enter your username"
                autoComplete="off"
                name="username"
                value={username}
                onChange={handleChange}

              />
            </div>
          </div>
          <div className="d-flex m-auto" style={{ width: "70%" }}>
            <div className="w-50">
              <label className="passlabel">PASSWORD</label>
              <input
                type="password"
                className="makeaccinput1"
                placeholder="Enter your new password"
                autoComplete="off"
                name="password"
                value={password}
                onChange={handleChange}

              />
            </div>
            <div className="w-50">
              <label className="confirmlabel">CONFIRM PASSWORD</label>
              <input
                type="password"
                className="mb-5 makeaccinput1"
                placeholder="Confirm your new password"
                autoComplete="off"
                style={{
                  backgroundColor: "#C7CCB2",
                }}
                name="cpassword"
                value={cpassword}
                onChange={handleChange}
              />
            </div>
          </div>
        </form>
        <button
          className="butn"
          onClick={handleSubmit}
          style={{ fontSize: "20px" }}
        >
          <i className="fas fa-long-arrow-right"></i>
          <br />
          Make Account
        </button>
      </div>
    </>
  );
};
export default MakeAccount;
