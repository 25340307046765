import React from 'react';
import "./CustomInputField.css"

const CustomInputField = ({ accept, label, style, type, value, disabled, placeholder, name, onChange, className, labelStyles, onCopy, showCopyIcon }) => {

    return (
        <div>
            <label className="labelText" style={labelStyles}>{label}</label>
            <input
                type={type}
                className={`inputFieldView ${className}`}
                placeholder={placeholder}
                autoComplete="off"
                name={name}
                accept={accept}
                value={value}
                onChange={onChange}
                style={style}
                disabled={disabled}
            />
            {showCopyIcon && (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="rgba(0, 0, 0, 0.4)" class="bi bi-copy cif-copy-icon" viewBox="0 0 16 16"
                    onClick={onCopy}>
                    <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                </svg>
            )}
        </div>
    );
}

const CustomLabel = ({ label }) => {
    return (
        <div>
            <label className='labelText'>{label}</label>
        </div>
    )
}


export { CustomInputField, CustomLabel };