import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import debounce from "lodash.debounce";
import path from "path";
import { PopupModal } from "../DataPrivacy/PopupModal";
import services from "../../API_SERVICES/services";
import toast from "react-hot-toast";
import { isAuth} from "../../Storage(Methods)/storage";
import Pagination from "../../Components/Pagination";
import iconMp3 from '../../Assets/Images/mp3_icon.png'
import editpic from "../../Assets/Images/edit.png";
import bulletpic from "../../Assets/Images/Ellipse.png";
import UploadData from "../../Components/ProjectDBSEComponents/UploadData";


const DataSetUpload = () => {
    let navigate = useNavigate();
    const video = JSON.parse(sessionStorage.getItem("data"));
    const [isHovered, setIsHovered] = useState(false);
    const [global, setglobal] = useState({
        screen5: false,
        screen6: false,
        screen7: false,
        datacontributions: "",
        loading: false,
        fetched: "",
        image: "",
        extensions: [],
        editmode: false,
        screencall: false,
    });
  
    const [MasterChecked, setMasterChecked] = useState(false)
    const [selectedlist, setselectedlist] = useState([]);
    const [isUploadDataLoading, setIsUploadDataLoading] = React.useState(false);
    const [details, setDetails] = useState({
        author: "",
        q1: "",
        q2: "",
        permissions1: "",
        permissions2: "",
        permissions3: "",
    });
    
    const location = useLocation();
    const projName= location.state.data.project_title;
    const projDesc = location.state.data.project_desc;
  const pId = location.state.data.pId;
    const {
        screen5,
        screen6,
        screen7,
        datacontributions,
        loading,
        fetched,
        image,
        extensions,
        editmode,
        screencall,
    } = global;
    // const { author, q1, q2, permissions1, permissions2, permissions3 } = details;

    useEffect(() => {
        loaddata();
    }, []);

    const [parent, setParent] = useState({
        DataCallTitle: location?.state?.data?.call_for_data_call_title,
        title: location?.state?.data?.callfordata_title,
        message:  location?.state?.data?.callfordata_message,
        dataTypes:location?.state?.data?.callfordata_type,
        call_for_data_fields: JSON.parse(location?.state?.data?.call_for_data_fields),
        consent_and_permissions: location?.state?.data?.consent_and_permissions,
        core_question_author_name: location?.state?.data?.core_question_author_name,
        core_question_how_this_sourced:  location?.state?.data?.core_question_how_this_sourced,
        core_question_where_did_this_come:  location?.state?.data?.core_question_where_did_this_come,
        optional_question_data:  JSON.parse(location?.state?.data?.optional_question_data),
        consent_optional_email: '',
        consent_donation_paid: '',
      });


    const loaddata = () => {
        let apiname = "fetch-project-data";
        services
            .getProjectData(apiname, location?.state?.data?.pId)
            .then((response) => {
                setglobal({
                    ...global,
                    datacontributions: response,
                    loading: true,
                    extensions: getExtensionList(response),
                    screen6: false,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // const setcallfordata = () => {
    //     let arr = [];
    //     if (location?.state?.data?.callfordata_enabled === 'Y') {
    //         const newtype = [...modeltypes];
    //         setcallenabled(true);
    //         for (let i = 0; i < newtype.length; i++) {
    //             if (location?.state?.data?.callfordata_type.includes(newtype[i].name)) {
    //                 newtype[i].selected = true;
    //                 arr.push(newtype[i]);
    //             }
    //         }
    //         setValues({
    //             ...values,
    //             title: location?.state?.data?.callfordata_title,
    //             message: location?.state?.data?.callfordata_message,
    //             type: arr
    //         });
    //     }

    // }

    const [currentPage, setCurrentPage] = useState(1);
    // No of Records to be displayed on each page   
    // const [recordsPerPage, setRecordsPerPage] = useState(10);
    const recordsPerPage = 10;
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const nPages = datacontributions?.data?.length > 0 ? Math.ceil(datacontributions?.data.length / recordsPerPage) : 1;
    const [filterParam, setFilterParam] = useState(['']);

    // Handle mouse enter and leave events to track hover state
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };


    // const currentRecords = datacontributions?.data?.length > 0?datacontributions?.data.slice(indexOfFirstRecord,  indexOfLastRecord):[];

    // const onFileChange = (e) => {
    //     setglobal({ ...global, image: e.target.files[0] });
    // };
    const checkhandler = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value });
    };
    const inputStyles = {
        width: "100%",
        borderRadius: "20px",
        padding: "7px",
        border: "1px solid black",
    };
    const spinCenter = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translateX(-50% -50%)",
    };
    const dropinputStyles = {
        width: "100%",
        padding: "5px",
        fontSize: "16px",
        lineHeight: "1",
        border: "1px solid black",
        borderRadius: "20px",
        // background: 'url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right ',
        background: 'url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+)no-repeat right',
        backgroundColor: "none",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: "right 20px top 0.4em",
        height: "37px",
        paddingLeft: "20px"

    };
    const buttonStyles = {
        padding: "20px",
        borderRadius: "50px",
        width: "45%",
        display: "block",
        margin: "100px auto 0 auto",
        color: "rgba(255, 255, 255, 0.7)",
        border: "none",
        backgroundColor: isHovered ? "black" : "rgba(0, 0, 0, 0.54)",
        textAlign: "center",
        marginBottom: "50px"
    };
    const imageStyle = {
        width: "30px",
        height: "30px",
    };

    const [searchParam] = useState(["video", "author"]);
    const [q, setQ] = useState("");
    function search(items) {
        return items.filter((item) => {
            if ((path.basename(item?.video)).includes(filterParam)) {
                return searchParam.some((newItem) => {
                    if (item[newItem] !== null) {
                        return (
                            item[newItem]
                                .toString()
                                .toLowerCase()
                                .indexOf(q.toLowerCase()) > -1
                        );
                    }
                });
            } else if (filterParam === "") {
                return searchParam.some((newItem) => {
                    return (
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(q.toLowerCase()) > -1
                    );
                });
            }
        });
    }
    const handleChange = (e) => {
        setFilterParam(e.target.value);
        //filter
    };
    const getExtensionList = (response) => {
        var ext = [];
        for (var i = 0; i < response?.data?.length; i++) {
            var filename = path.basename(response?.data[i]?.video);
            var extensions = (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename).toString() : undefined
            if (!ext.includes(extensions) && extensions !== undefined) {
                ext.push(extensions);
            }
        }
        return ext;
    }
    const completeUpload = (childStates, id) => {
        // e.preventDefault();
        const apiname = "data-details";
        var per = "";
        if (childStates.permissionOne !== '') {
            per = per + childStates.permissionOne + ";";
        }
        if (childStates.permissionTwo !== '') {
            per = per + childStates.permissionTwo + ";";
        }
        if (childStates.permissionThree !== '') {
            per = per + childStates.permissionThree;
        }
        const data = {
            // author: author,
            // q1: q1,
            // q2: q2,
            // permissions: per,
            // id: dataId,
            author: childStates.author ? childStates.author : "",
      q1: childStates.whereDidThisComeFrom ? childStates.whereDidThisComeFrom : "NA",
      q2: childStates.howWasThisSourced,
      permissions: per,
      id:childStates.ids.length > 0 ? childStates.ids : [] ,
      call_for_data_fields: JSON.stringify(childStates.dataFields) ,
      optional_question_data: `[]`,
      consent_optional_email: childStates.email,
      consent_donation_paid: childStates.donationOrPaid,
        };
        services
            .post(apiname, data)
            .then((response) => {
                toast.success(response.data.message);
                setDetails({ ...details, author: "", q1: "", q2: "", permissions1: "", permissions2: "", permissions3: "" });
                setglobal({ ...global, image: "" })
                loaddata();
                setIsUploadDataLoading(false);
            })
            .catch((err) => {
                setIsUploadDataLoading(false);
                toast.error(err.response.data.error);
            });
    };
    const DelayApiCall = useCallback(
        debounce(
            (apiname, data) =>
                services
                    .post(apiname, data)
                    .then((response) => {
                        toast.success(response.data.message);
                    })
                    .catch((error) => {
                        toast.error(error.response.data.error);
                    }),
            1500
        ),
        []
    );
    let handleChangeform = (e) => {
        let newfetched = fetched;
        newfetched.q1 = e.target.value;
        setglobal({ ...global, fetched: newfetched });
        const data = { id: fetched.dcId, q1: e.target.value, q2: fetched.q2, author: fetched.author }
        DelayApiCall("edit_dataset", data);
    }
    let handleChangeform2 = (e) => {
        let newfetched = fetched;
        newfetched.q2 = e.target.value;
        setglobal({ ...global, fetched: newfetched });
        const data = { id: fetched.dcId, q1: fetched.q1, q2: e.target.value, author: fetched.author }
        DelayApiCall("edit_dataset", data);

    }
    let handleChangeauthor = (e) => {
        let newfetched = fetched;
        newfetched.author = e.target.value;
        setglobal({ ...global, fetched: newfetched });
        const data = { id: fetched.dcId, q1: fetched.q1, q2: e.target.value, author: e.target.value }
        DelayApiCall("edit_dataset", data);

    }
    const dataUpload = (childStates) => {
        setIsUploadDataLoading(false);
        // e.preventDefault();
        const apiname = "data_upload";
        var formData = new FormData();
        formData.append("dataUpload", childStates.image);
        formData.append("pId", location?.state?.data?.pId);
        formData.append("user_id", "-1");
        formData.append("contributor", "anonymous");
        services
            .post(apiname, formData)
            .then((response) => {
                toast.success(response.data.message);
                sessionStorage.setItem(
                    "data",
                    JSON.stringify(response?.data?.data?.video)
                );
                sessionStorage.setItem(
                    "id",
                    JSON.stringify(response?.data?.data?.dcId)
                );
                setglobal({
                    ...global,
                    // screen5: false,
                    // screen6: true,
                });
                completeUpload(childStates, response?.data?.data?.dcId)
            })
            .catch((err) => {
                setIsUploadDataLoading(false);
                toast.error(err.response.data.error);
                setglobal({ ...global, image: "" })
            });
    };

    const onMasterCheck = (e) => {
        setMasterChecked(e.target.checked);
        // SelectedList: this.state.List.filter((e) => e.selected),
        let tempList = datacontributions;
        // Check/ UnCheck All Items
        tempList?.data.map((data) => (data.selected = e.target.checked));
        setglobal({
            ...global,
            datacontributions: tempList,

        });
        setselectedlist(datacontributions.data.filter((e) => e.selected))
    }

    const onItemCheck = (e, item) => {
        item.selected = e.target.checked;
        let tempList = datacontributions;

        tempList?.data.map((data) => {
            if (data.dcId === item.dcId) {
                data.selected = e.target.checked;
            }
            return data;
        });

        setglobal({
            ...global,
            datacontributions: tempList,

        });
        setselectedlist(datacontributions.data.filter((e) => e.selected))


    }

    const handleAction = (e) => {
        if (e.target.value === "Download") {
            downloadItems1();
        } else if (e.target.value === "Delete") {
            deleteItems();
        }
    }

    const downloadItems1 = () => {
        for (var i = 0; i < selectedlist?.length; i++) {

            if (selectedlist[i].video.match(/\.(png|jpg|jpeg)$/)) {

                fetch(selectedlist[i].video, {
                    method: "GET",
                    cache: "no-cache"

                }).then(response => {
                    response.arrayBuffer().then(function (buffer) {
                        const url = window.URL.createObjectURL(new Blob([buffer]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "image.png"); //or any other extension
                        link.click();
                    });
                }).catch(err => {
                    console.log(err);
                });
            }
            if (selectedlist[i].video.match(/\.(mp3|aac)$/)) {
                fetch(selectedlist[i].video, {
                    method: "GET",
                    cache: "no-cache"
                }).then(response => {
                    response.arrayBuffer().then(function (buffer) {
                        const url = window.URL.createObjectURL(new Blob([buffer]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "audio.mp3"); //or any other extension
                        link.click();
                    });
                }).catch(err => {
                    console.log(err);
                });
            }
            if (selectedlist[i].video.match(/\.(mp4)$/)) {
                fetch(selectedlist[i].video, {
                    method: "GET",
                    cache: "no-cache"
                }).then(response => {
                    response.arrayBuffer().then(function (buffer) {
                        const url = window.URL.createObjectURL(new Blob([buffer]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "video.mp4"); //or any other extension
                        link.click();
                    });
                }).catch(err => {
                    console.log(err);
                });
            }
        }
    }

    // const downloadItems = () => {
    //   for (var i = 0; i < selectedlist?.length; i++) {
    //     if (selectedlist[i].video.match(/\.(png|jpg|jpeg)$/)) {
    //       console.log(selectedlist[i]);
    //       const data = {
    //         key: selectedlist[i].video
    //       };
    //       services
    //         .post("getimage-buffer", data)
    //         .then((response) => {
    //           console.log(response);
    //           var a = document.createElement("a");
    //           a.href = "data:image/png;base64," + response.data;
    //           a.download = "Image.png";
    //           a.click();
    //         })
    //         .catch((err) => {
    //         });
    //     }
    //   }

    // }
    const deleteItems = () => {
        let dids = [];
        for (var i = 0; i < selectedlist?.length; i++) {
            dids.push(selectedlist[i].dcId);
        }
        const data = {
            dcId: dids
        };
        services
            .post("delete-data", data)
            .then((response) => {
                toast.success("Deleted successfully");
                loaddata();

            }).catch((err) => {
                console.log(err);
            });
    }
    const handlechangetype = (index) => {
        setValues({ ...values, type: [] });

        const newtype = [...modeltypes];
        newtype[index].selected = !modeltypes[index].selected;
        setModeltypes(newtype);
        let arr = [];
        for (let i = 0; i < newtype.length; i++) {
            if (newtype[i].selected) {
                arr.push(newtype[i]);
            }
        }
        setValues({ ...values, type: arr });

    };

    // const oncalldataOpen = () => {
    //     if (!callenabled) {
    //         setValues({
    //             ...values, title: '',
    //             message: '',
    //             type: []
    //         });
    //         setTogglecall(false);

    //     }
    //     else {
    //         setTogglecall(true);

    //     }
    //     setglobal({ ...global, screencall: !screencall });
    // }
    const onsubmitdata = () => {

        setglobal({ ...global, screencall: !screencall });
        setTogglecall(false);

        const joinedStr = values?.type.map((list) => list.name).join(",")

        const data = {
            pId: location?.state?.data?.pId,
            msg: values.message,
            type: joinedStr,
            title: values?.title,
        };
        const apiname = '/activate-callfordata'
        services
            .post(apiname, data)
            .then((response) => {
                setcallenabled(true);
                toast.success(response.data.message);

            })
            .catch((err) => {
                toast.error(err.response.data.error);
            });
    }


    const [typedisplay, setTypedisplay] = useState(false);
    const [modeltypes, setModeltypes] = useState([
        {
            "name": "Sound",
            "selected": false,
        },
        {
            "name": "Text",
            "selected": false,

        },
        {
            "name": "Image",
            "selected": false,

        },
        {
            "name": "Video",
            "selected": false,
        }])
    const handleDatacall = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };

    const [values, setValues] = useState({
        title: '',
        message: '',
        type: []
    });
    const [callenabled, setcallenabled] = useState(false);
    const [togglecall, setTogglecall] = useState(false);

    const changeFileExtension = (fileName) => {
        if (fileName.endsWith('.png') && fileName.startsWith('drawing-prompt')) {
          return fileName.replace('.png', '.svg');
        }
        return fileName;
      }

    return (
        <>
            {location.state === undefined ? (
                <h4
                    style={{
                        fontSize: "35px",
                        fontWeight: "lighter",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50% , -50%)",
                    }}
                >
                    {" "}
                    404 PAGE NOT FOUND
                </h4>
            ) : (
                <>
                    <div className="d-flex mt-5 justify-content-between maindiv">
                        <h1 className="text-uppercase mt-2 ms-3 plachetitle">
                            {location?.state?.data?.project_title}
                        </h1>
                    </div>


                    {!screencall ? null : (
                        <div className="outercontainer">
                            <div className="gridJoin">
                                <div className="grid-joinContributor">

                                    <div className="calldataContainer">
                                        <div className="calldatalabel">
                                            <p style={{ fontSize: "24px" }}>DATABASE SETTING</p>
                                            <p style={{ fontSize: "14px" }}>
                                                A call for Data will allow POIETO members to upload data to the
                                                project
                                            </p>
                                            <p style={{ fontSize: "14px" }}> Select Type of Data.This can be more than one type</p>
                                        </div>
                                        <div>

                                            {typedisplay ?
                                                <>
                                                    <div className='Invisible' onClick={() => setTypedisplay(false)}></div>
                                                    <div className="dttypelist">
                                                        <span style={{ alignSelf: "center" }}>TYPE</span>
                                                        {modeltypes?.map((mt, index) => {
                                                            return (

                                                                <div key={index}
                                                                    onClick={() => {
                                                                        handlechangetype(index);
                                                                    }}
                                                                    className={mt.selected ? 'modeltypesubselected' : 'modeltypesub'}>
                                                                    <span>{mt.name}</span>
                                                                    {mt.selected ? <><i className="fas fa-check" ></i></> : <><i className="fas fa-plus" ></i></>}
                                                                </div>
                                                            );
                                                        })}


                                                    </div></> : <button className="dropdown-toggle dttypeinput" data-bs-toggle="dropdown" aria-expanded="false"
                                                        onClick={() => setTypedisplay(true)}

                                                    >
                                                    TYPE
                                                </button>
                                            }
                                        </div>
                                        <input
                                            name="title"
                                            type="text"
                                            value={values.title}
                                            placeholder="Title" style={{ fontStyle: "italic" }}
                                            onChange={handleDatacall}
                                        />
                                        <input
                                            name="message"
                                            type="text"
                                            value={values.message}
                                            placeholder="Include a message" style={{ fontStyle: "italic" }}
                                            onChange={handleDatacall}
                                        />


                                        <div className="  toggledt  text-center">
                                            <div className="d-flex flex-column">
                                                <div className="mt-3">
                                                    <p className="row justify-content-md-center"></p>
                                                    <label className="check_switch">
                                                        <input
                                                            type="checkbox"
                                                            style={{ display: "none" }}
                                                            className="checkinput"
                                                            onChange={(e) => {
                                                                setTogglecall(!togglecall);
                                                            }}
                                                            checked={togglecall}
                                                        />
                                                        <span className="slider-join"></span>
                                                    </label>
                                                </div>

                                                <span className="mt-4">Activate Data Call</span>
                                            </div>
                                        </div>
                                        <button onClick={onsubmitdata}
                                            className={togglecall ? "buttoncalldata" : "buttoncalldatadisabled"}

                                            disabled={!togglecall}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div></div></div>)}
                    {!screen7 ? (
                        <p className="ms-3"

                            onClick={() => location?.state?.source === 'expand' ? navigate("/expand-project", { state: { data: location?.state?.data, source: "add_Project" } }, { replace: true }) :
                                (location?.state?.source === 'expanddt' ? navigate("/public-dataset", { replace: true }) :
                                    navigate("/expand-edit-project", { state: { data: location?.state?.data, source: "add_Project" } }, { replace: true }))}
                        >
                            {location?.state?.data?.project_title} / DATABASE

                        </p>
                    ) : (
                        <p className="ms-3">
                            {location?.state?.data?.project_title} / DATABASE /{" "}
                            {path.basename(fetched?.video)}
                        </p>
                    )}


                    {!screen5 ? null : (
                        // <PopupModal>
                        //     <div className="text-center b-none mb-3">
                        //         <p style={{ fontSize: "24px", color: "rgba(0, 0, 0, 0.54)" }}>Upload image</p>
                        //         <span style={{ fontSize: "16px" }}>Required</span>
                        //     </div>
                        //     {image === "" ? (<div className="uploadinput text-center">
                        //         <div className="d-flex flex-column">
                        //             <label htmlFor="file" id="filelabel">
                        //                 <h1 className="mb-5">+</h1>
                        //             </label>
                        //             <input
                        //                 type="file"
                        //                 id="file"
                        //                 name="dataUpload"
                        //                 onChange={onFileChange}
                        //                 style={{ display: "none" }}
                        //             />

                        //             <span className="mt-4" style={{ fontWeight: "400", fontSize: "16px" }}>
                        //                 Choose a file (image, video, or audio)
                        //             </span>
                        //         </div>
                        //     </div>) : (<div className="uploadinput text-center">   <img alt="Upload" htmlFor="fileImg"
                        //         src={URL.createObjectURL(image)} height="200px" width={"200px"} /></div>)}

                        //     <button className="Uplodbtn" onClick={dataUpload}>
                        //         Upload
                        //     </button>
                        // </PopupModal>
                        <UploadData
                        completeUploadData={(childStates) => dataUpload(childStates)}
                        parentStates={parent}
                        projDesc={projDesc}
                        projName={projName}
                        isLoading={isUploadDataLoading}
                        pId={pId}
                        closeModal={(value) => {
                            setglobal({ ...global, screen5: value });
                          }}
                        />
                    )}
                    {!screen6 ? null : (
                        <PopupModal>
                            {" "}
                            <div className="row">
                                <h4
                                    className="text-center"
                                    style={{ fontSize: "22px", color: "#0000008A" }}
                                >
                                    CALL FOR DATA
                                </h4>
                                <h4
                                    className="text-center"
                                    style={{ fontSize: "18px", color: "#0000008A" }}
                                >
                                    File upload successful
                                </h4>
                                {path.basename(video).match(/\.(png|jpg|jpeg)$/) ? (
                                    <img
                                        src={video}
                                        alt="dataimg"
                                        className="d-block m-auto"
                                        style={{ width: "120px", height: "120px", borderRadius: "20px" }}
                                    />
                                ) : (
                                    <ReactPlayer
                                        url={video}
                                        className="d-block m-auto"
                                        controls
                                        width="30%"
                                        height="55%"
                                        playing
                                    />
                                )}
                                <p className="text-center">
                                    {image.name}-{"Anonymous"}
                                </p>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <label style={{ fontSize: "12px" }}>AUTHOR</label>
                                    <input
                                        type="text"
                                        className="dataContributeFormInput"
                                        placeholder="Enter info..."
                                        autoComplete="off"
                                        name="author"
                                        onChange={checkhandler}
                                    />
                                    <label style={{ fontSize: "12px", marginTop: "1rem" }}>
                                        WHERE DID THIS COME FROM ?
                                    </label>
                                    <input
                                        type="text"
                                        className="dataContributeFormInput mb-3"
                                        placeholder="Enter info..."
                                        autoComplete="off"
                                        name="q1"
                                        onChange={checkhandler}
                                    />
                                    <label style={{ fontSize: "12px" }}>
                                        HOW WAS THIS SOURCED ?
                                    </label>
                                    <input
                                        type="text"
                                        className="dataContributeFormInput"
                                        placeholder="Enter info..."
                                        autoComplete="off"
                                        name="q2"
                                        onChange={checkhandler}
                                    />
                                    <h6 className="mt-3 mb-1" style={{ fontSize: "12px", color: "#4E4E4EB2" }}>CONSENT AND PERMISSIONS</h6>
                                    <p style={{ fontSize: "12px", color: "#4E4E4EB2" }}>
                                        PLEASE SELECT ALL THAT APPLY.PLEASE SEND ANY QUESTIONS TO
                                        DATA@POIETO.COM
                                    </p>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="permissions1"
                                            onChange={checkhandler}
                                            value="I CERTIFY I HAVE THE PERMISSIONS TO SHARE THIS FILE"
                                            style={{ marginTop: "0.8rem" }}

                                        />
                                        <label className="form-check-label" for="gridCheck1" style={{ fontSize: "12px", color: "#4E4E4EB2", marginTop: "0.8rem" }}>
                                            I CERTIFY I HAVE THE PERMISSIONS TO SHARE THIS FILE
                                        </label>
                                    </div>
                                    <div className="form-check mb-2 mt-2">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="permissions2"
                                            value=" I consent to this data being used according to the data
                        settings of this project, for as long as the project
                        chooses to store the data"
                                            onChange={checkhandler}
                                            style={{ marginTop: "0.8rem" }}

                                        />
                                        <label
                                            className="form-check-label"
                                            for="gridCheck1"
                                            style={{ textTransform: "uppercase", fontSize: "12px", color: "#4E4E4EB2", marginTop: "0.8rem" }}
                                        >
                                            I consent to this data being used according to the data
                                            settings of this project, for as long as the project
                                            chooses to store the data (read about our data policy and
                                            future plans here)
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="permissions3"
                                            onChange={checkhandler}
                                            value=" Can we follow-up with you about your upload?"
                                            style={{ marginTop: "0.8rem" }}

                                        />
                                        <label
                                            className="form-check-label"
                                            for="gridCheck1"
                                            style={{ textTransform: "uppercase", fontSize: "12px", color: "#4E4E4EB2", marginTop: "0.8rem" }}
                                        >
                                            Can we follow-up with you about your upload?
                                        </label>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button type="button" className="backbtn">
                                            BACK
                                        </button>
                                        <button
                                            type="button"
                                            className="savebtn"
                                            onClick={completeUpload}
                                        >
                                            COMPLETE UPLOAD
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </PopupModal>
                    )}
                    {!screen7 ? null : (
                        <div className="outercontainer">
                            <div className="griddataContributor">
                                <div >
                                    <div className="d-flex  justify-content-between ">

                                        <i
                                            className="far fa-times-circle"
                                            onClick={() => setglobal({ ...global, screen7: !screen7, editmode: false })}
                                        ></i>
                                        {fetched.contributor === isAuth().username ? <img alt="edit"
                                            src={editpic} height="20px" width="20px"
                                            onClick={() => setglobal({ ...global, editmode: true })} /> : null}

                                    </div>
                                    <div className="row">
                                        <div className="col-lg-7 col-sm-7 col-xl-7">
                                            {path.basename(fetched.video).match(/\.(png|jpg|jpeg)$/) ? (
                                                <img
                                                    src={fetched.video}
                                                    alt="dataimg"
                                                    width="540px"
                                                    height="333px"
                                                    style={{ padding: "30px", paddingRight: "0px" }}
                                                />
                                            ) : (
                                                <ReactPlayer
                                                    url={fetched.video}
                                                    controls
                                                    width="571px"
                                                    height="353px"
                                                    style={{ padding: "30px", paddingRight: "0px" }}
                                                    playing
                                                />
                                            )}
                                            <p style={{ paddingLeft: "30px", fontSize: "13px", fontFamily: "Roboto", fontWeight: "400" }}
                                            >                   {changeFileExtension(path.basename(fetched.video))}</p>
                                        </div>
                                        <div className="col-lg-5 col-sm-5 col-xl-5">

                                            <div className="container" style={{ padding: "30px", paddingLeft: "0px", fontSize: "12px", fontFamily: "DM Mono", fontWeight: "400", textAlign: "start" }}>
                                                <div className="row">
                                                    <div className="col" style={{ padding: "10px", textTransform: "uppercase", textAlign: "start" }}>Contributed by</div>
                                                    <div className="col" style={{ padding: "10px", fontSize: "13px", fontFamily: "Roboto", textAlign: "start" }}>
                                                        <img alt="download"
                                                            src={bulletpic} height="20px" width="20px"
                                                        />&nbsp;{fetched.contributor}</div>
                                                    <div className="w-100"></div>
                                                    <div className="col" style={{ padding: "10px", textAlign: "start" }}>AUTHOR</div>
                                                    <div className="col" style={{ padding: "10px", fontSize: "13px", textAlign: "start" }}>
                                                        <input type="text" disabled={!editmode}
                                                            className='griddataText'
                                                            value={fetched.author}
                                                            onChange={e => handleChangeauthor(e)}
                                                            style={{ backgroundColor: "transparent", outline: 'none', border: "none", marginBottom: "20px" }} />

                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{ padding: "30px", paddingLeft: "0px", fontSize: "12px", fontFamily: "DM Mono", fontWeight: "400", textAlign: "start" }}
                                            >

                                                <p className="griddataText">WHERE DID THIS COME FROM</p>
                                                <div className="lineseperator2"></div>

                                                <input type="text" disabled={!editmode}
                                                    className='griddataText'
                                                    value={fetched.q1}
                                                    onChange={e => handleChangeform(e)}
                                                    style={{ backgroundColor: "transparent", outline: 'none', border: "none", marginBottom: "20px" }} />

                                                <br></br>
                                                <br></br>
                                                <p className="mb-1 griddataText" >HOW WAS THIS SOURCED</p>
                                                <div className="lineseperator2"></div>

                                                <input type="text" disabled={!editmode}
                                                    className='griddataText'
                                                    value={fetched.q2}
                                                    onChange={e => handleChangeform2(e)}
                                                    style={{ backgroundColor: "transparent", outline: 'none', border: "none", marginBottom: "20px" }} />
                                            </div></div>
                                        <br></br><br></br>
                                    </div>
                                </div></div></div>
                    )}
                    {/* Table View */}
                    <div className="projectdb mt-3">
                        <div className="row p-3">
                            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <input
                                    type="search"
                                    className="artsearchinput"
                                    placeholder="SEARCH"
                                    style={inputStyles}
                                    value={q}
                                    onChange={(e) => setQ(e.target.value)}

                                ></input>
                            </div>
                            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 customdrop">
                                <select onChange={handleChange} name="FILETYPE " className="artsearchinput"
                                    style={dropinputStyles}>
                                    <option key={0} value=''> FILETYPE</option>
                                    {extensions.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 customdrop">
                                <select onChange={handleAction} name="ACTION " className="artsearchinput"
                                    style={dropinputStyles}>
                                    <option key={0} value=''> ACTION</option>
                                    <option key={1} value="Download" > Download</option>
                                    <option key={2} value="Delete"> Delete</option>
                                </select>
                            </div>

                            <div className="col-md-2 col-lg-2 col-xl-2">
                                <p className="txtpagination">items per page: 10</p>
                            </div>
                            <div className="  col-md-2 col-lg-2 col-xl-2">
                                <Pagination
                                    nPages={nPages}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    lastrecord={indexOfLastRecord}
                                    total={datacontributions?.data?.length}
                                />
                            </div>
                        </div>
                        <table className="table">
                            <thead>
                                <tr style={{ fontSize: "13px", color: " rgba(78, 78, 78, 0.7)", textTransform: "lowercase" }}>
                                    <th>#</th>
                                    <th>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={MasterChecked}
                                            id="mastercheck"
                                            onChange={(e) => onMasterCheck(e)}
                                        /></th>
                                    <th >icon</th>
                                    <th>file name</th>
                                    <th>author(s)</th>
                                    <th>contributor</th>
                                </tr>
                            </thead>
                            {loading && datacontributions?.data?.length > 0 && (
                                <tbody>
                                    {search(datacontributions?.data).slice(indexOfFirstRecord, indexOfLastRecord)?.map((info, key = 0) => (
                                        <tr
                                            key={key + 1}
                                        // onClick={() => {
                                        //     setglobal({ ...global, screen7: true, fetched: info });
                                        // }}
                                        >
                                            <td style={{ fontSize: "13px" }}>{key + 1}</td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={info?.selected || false}
                                                    className="form-check-input"
                                                    onChange={(e) => onItemCheck(e, info)}
                                                /></td>
                                            <td>
                                                {" "}
                                                <img
                                                    src={info?.video.includes(".mp3") ? iconMp3 : info?.video}
                                                    style={imageStyle}
                                                    alt="projimg"
                                                />
                                            </td>
                                            <td style={{ fontSize: "13px" }}>
                                                {changeFileExtension(path.basename(info?.video))}
                                            </td>
                                            <td style={{ fontSize: "13px" }}>{info.author}</td>
                                            <td style={{ fontSize: "13px" }}>{info.contributor}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                        </table>
                        {datacontributions?.data?.length === 0 ? (
                            <p className="text-center pb-3" style={{ fontWeight: "bold" }}>
                                <i className="fas fa-empty-set"></i> No Data Found
                            </p>
                        ) : !loading ? (
                            <div style={spinCenter}>
                                <div
                                    className="spinner-border"
                                    style={{ width: "3rem", height: "3rem" }}
                                    role="status"
                                ></div>
                            </div>
                        ) : null}
                    </div>
                    <button
                        style={buttonStyles}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => setglobal({ ...global, screen5: true, image: "" })}
                    >
                        <p className="m-0" style={{ color: "rgba(255, 255, 255, 0.7)" }}>UPLOAD</p>
                    </button>
                </>
            )}
        </>
    );
};

export default DataSetUpload;