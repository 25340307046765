import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import services from "../../API_SERVICES/services";
import { isAuth, isId } from "../../Storage(Methods)/storage";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
export const WhatDoesItDo = () => {
  const navigate = useNavigate();
  const [blueprint, setBlueprint] = useState({
    input: "",
    actions: "",
    output: "",
  });
  const { input, actions, output } = blueprint;
  useEffect(() => {
    loadblueprint();
  }, []);

  const DelayApiCall = useCallback(
    debounce(
      (apiname, data) =>
        services
          .post(apiname, data)
          .then((response) => {
            toast.success(response.data.message);
            loadblueprint();
          })
          .catch((error) => {
            toast.error(error.response.data.error);
          }),
      1500
    ),
    []
  );

  const handleInput = (e) => {
    const input = e.target.value;
    setBlueprint({ input: input });
    const apiname = "save-input";
    const data = {
      input: input,
      pId: isId(),
      user_id: isAuth().id,
    };
    DelayApiCall(apiname, data);
  };
  const handleActions = (e) => {
    const actions = e.target.value;
    setBlueprint({ actions: e.target.value });
    const apiname = "save-actions";
    const data = {
      actions: actions,
      pId: isId(),
      user_id: isAuth().id,
    };
    DelayApiCall(apiname, data);
  };
  const handleOutput = (e) => {
    const output = e.target.value;
    setBlueprint({ output: output });
    const apiname = "save-output";
    const data = {
      output: output,
      pId: isId(),
      user_id: isAuth().id,
    };
    DelayApiCall(apiname, data);
  };
  const loadblueprint = () => {
    const apiname = "fetch-blueprint";
    const project = true;
    services
      .get(apiname, project)
      .then((response) => {
        const { input, actions, output } = response.data.data;
        setBlueprint({ ...blueprint, input, actions, output });
      })
      .catch((error) => {
      });
  };
  return (
    <>
      <div className="row justify-content-around ps-3 pt-4 mb-3">
        <h4 className="text-uppercase mb-3" style={{ fontSize: "14px",color:"#4E4E4EB2" }}>
          sketch out the project from a conceptual standpoint.
        </h4>
        <div className="col">
          <div className="stylecardframe">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h4 className="card-title" style={{ fontSize: "36px",color:"#4E4E4E" }}>INPUT</h4>
              </div>
              <p style={{ fontSize: "14px",color:"#4E4E4E8A" }}>What goes into the system while it is operating?</p>
              <textarea
                className="cardtxthow mt-1 mb-3 pt-2"
                rows="10"
                value={input}
                name="input"
                onChange={handleInput}
                placeholder="TYPE HERE...."
               
              ></textarea>
            </div>
            <button className="addbtn  ms-3" onClick={() => navigate('/Comingsoon',{replace:true})}>
              <i
                className="fas fa-plus"
              />
              Add a Frame
            </button>
          </div>
        </div>
        <div className="col">
          <div className="stylecardframe">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h4 className="card-title" style={{ fontSize: "36px",color:"#4E4E4E" }}>ACTIONS</h4>
              </div>
              <p style={{ fontSize: "14px",color:"#4E4E4E8A" }}>What happens to the input in the system?</p>
              <textarea
                className="cardtxthow mt-1 mb-3 pt-2"
                rows="10"
                value={actions}
                name="actions"
                onChange={handleActions}
                placeholder="TYPE HERE...."
              ></textarea>
            </div>
            <button className="addbtn ms-3" onClick={() => navigate('/Comingsoon',{replace:true})}>
              <i
                className="fas fa-plus"
              />
              Add a Frame
            </button>
          </div>
        </div>
        <div className="col">
          <div className="stylecardframe">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h4 className="card-title" style={{ fontSize: "36px",color:"#4E4E4E" }}>OUTPUT</h4>
              </div>
              <p style={{ fontSize: "14px",color:"#4E4E4E8A" }}>What does the system produce from the input?</p>
              <textarea
                className="cardtxthow mt-1 mb-3 pt-2"
                rows="10"
                value={output}
                name="output"
                onChange={handleOutput}
                placeholder="TYPE HERE...."
              ></textarea>
            </div>
            <button className="addbtn  ms-3" onClick={() => navigate('/Comingsoon',{replace:true})}>
              <i
                className="fas fa-plus"
              />
              Add a Frame
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
