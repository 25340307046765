import React from "react";
import { useLocation,useNavigate } from "react-router-dom";

export const Comingsoon = () => {
  let location = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <div className="outercontainer">
        <div className="gridFourth">
          <div className="gridcontent">
            <h5>COMING SOON...</h5>
           {location?.state?.source === "prototype"?<button
              type="button"
              className="savebtn"
              onClick={() => navigate('/modelspage',{replace:true})}
            >
              BACK
            </button>:
            <button
              type="button"
              className="savebtn"
              onClick={() => navigate('/model-explorer',{replace:true})}
            >
              BACK
            </button>} 
            
          </div>
        </div>
      </div>
    </>
  );
};
