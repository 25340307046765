import React from "react";

export const ComingsoonDialog = (props) => {
  const { closeAlert } = props;

  return (
    <>
      <div className="comingsoondiv"  onClick={closeAlert}>      </div>

        <div className="comingsoonModal">
          <div className="gridcontent">
            <h5>COMING SOON...</h5>
          <button
              type="button"
              className="savebtn"
              onClick={closeAlert}
            >
              BACK
            </button>
           
            
          </div>
        </div>
    </>
  );
};
