import React from "react";
import { useNavigate } from "react-router-dom";
import { FramesNavBar } from "../Howdoesscreen/HeadarNavbar/FramesNavbar";
import modelpic from "../../Assets/Images/Radio_01.png";

const ModelStyleGAN = () => {
    const navigate = useNavigate();
    const itemArray = [
        { name: "DISCOVER", value: "/decode" },
        { name: "FRAMES", value: "/CreateFrames" },
        { name: "BLUEPRINT", value: "/Howinputtab" },
        { name: "PROTOTYPE", value: "/modelspage" },
        { name: "REFLECTION", value: "/reflection" },
    ];

    return (
        <>
            <FramesNavBar labels={itemArray} isActive={"PROTOTYPE"} />
            <div className="row justify-content-around ps-3 pt-4">
                <p style={{ fontSize: "40px" }}>BARBIE STYLEGAN3</p>
                <p style={{ fontSize: "14px" }}>MODELS/ BARBIE_STYLGAN3</p>
                <div className="stylegan">
                    <div>
                        <img alt="" src={modelpic} className="styleganimg" />
                        <img alt="" src={modelpic} className="styleganimg" />
                        <img alt="" src={modelpic} className="styleganimg" />
                        <p style={{ fontSize: "16px" }}>About</p>
                        <p style={{ fontSize: "20px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit ess.</p>
                        <p style={{ fontSize: "16px" }}>Reference Links:</p>
                        <p style={{ fontSize: "20px" }}>hajbdabofibn.pdf</p>
                        <p style={{ fontSize: "20px" }}>hajbdabofibn.pdf</p>

                    </div>
                    <div>
                        <div className="styleGanRight">
                            <div className='stylegan-item' >
                                <h6 style={{ fontSize: '36px' }}>
                                    DEMO
                                </h6>
                            </div>
                            <div className='stylegan-item' onClick={() => navigate('/stylegan', { replace: true })} >
                                <h6 style={{ fontSize: '36px' }}>
                                    RETRAIN
                                </h6>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default ModelStyleGAN;
