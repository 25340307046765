import React, { useEffect, useState } from "react";  
import { useNavigate } from "react-router-dom";
import StyletransferModel from "../../Components/ModelComponents/styletransfer";
import ImgClassifyModel from "../../Components/ModelComponents/imgclassify";
import { useMenuContext } from '../../Components/Navbar/menuContext';
import dottedLogo from '../../Assets/Images/dottedLogo.png';
import humanLogo from '../../Assets/Images/humanLogo.png';
import musicNote from '../../Assets/Images/musicNote.png';
import lineLogo from "../../Assets/Images/lineLogo.png"
const Modellist = () => {
  const { setShowAdditionalMenu, setActiveMenuItem } = useMenuContext();
  const navigate = useNavigate();
  const [typedisplay, setTypedisplay] = useState(false);
  const [query, setQuery] = useState("");
  const [selecedType, setSelectedType] = useState([]);
  const [modeltypes, setModeltypes] = useState([
    {
      "name": "Sound",
      "selected": false,
    },
    {
      "name": "Text",
      "selected": false,
    },
    {
      "name": "Image",
      "selected": false,
    },
    {
      "name": "Video",
      "selected": false,
    }]);
  const modelarray = [
    { "id": "1", "name": "POE-ETO", "desc": "Text Generation in the style of Edgar allan poe.", "link": "/textgeneration-model", "type": "Text" },
    { "id": "2", "name": "style transfer", "desc": "TRANSFER STYLE OF ONE IMAGE TO THE CONTENT OF ANOTHER IMAGE", "link": "/styletransfer-model", "type": "Image" },
    { "id": "3", "name": "POSENET", "desc": "TRAIN A MODEL TO CLASSIFY POSES", "link": "/Posenet", "type": "Video" },
    { "id": "4", "name": "SOUND CLASSIFICATION", "desc": "TRAIN A MODEL TO CLASSIFY SOUND", "link": "/Soundclassification", "type": "Sound", "subtype": "TF" },
    { "id": "5", "name": "DALL-E-3", "desc": "Generate images", "link": "/coming_soon", "type": "Image" },
  ];
  const search_parameters = ["name", "desc"];

  function search(data) {
    const filteredDATA = data.filter((node) =>
      selecedType.length > 0
        ?
        selecedType.some((filterTag) =>
          node['type'].includes(filterTag)
        )
        : data)
    return filteredDATA.filter(
      (item) =>
        search_parameters.some((parameter) =>
          item[parameter].toString().toLowerCase().includes(query)
        )
    );
  }

  const handlechange = (index) => {
    setSelectedType([]);
    const newtype = [...modeltypes];
    newtype[index].selected = !modeltypes[index].selected;
    setModeltypes(newtype);
    let arr = [];
    for (let i = 0; i < newtype.length; i++) {
      if (newtype[i].selected) {
        arr.push(newtype[i].name);
      }
    }
    setSelectedType(arr);
  };

  const inputStyle = {
    width: "95%",
    borderRadius: "1000px",
    marginLeft: "10px",
    textAlign: "center",
    backgroundColor: "rgba(78, 78, 78, 0.1)",
    color: "#4E4E4E",
    opacity: "70%",
    border: "none",
    height: "40px",
  };

  useEffect(() => {
    setActiveMenuItem("MODELS");
    sessionStorage.setItem(
      "identifier",
      -1);
    return () => {
      setShowAdditionalMenu(false);
      setActiveMenuItem(null);
    };
  }, []);

  const [component, setComponent] = useState(0)

  const clickHandler = (component) => {
    switch (component) {
      case "1":
        setComponent(1);
        break;
      case "2":
        setComponent(2)
        break;
      case "3":
        setComponent(3);
        break;
      case "4":
        setComponent(4);
        break;
      case "5":
        setComponent(5);
        break;
      case "6":
        setComponent(6);
        break;
      case "7":
        setComponent(7);
        break;
      case "8":
        setComponent(8);
        break;
      case "9":
        setComponent(9);
        break;
      default:
        setComponent(0);
        break;
    }
  };

  const closeModel = () => {
    setComponent(0);
  }

  // This function is used to change the uppercase sentence to lowercase
  function toNormalCase(sentence) {
    // Convert the first letter of the sentence to uppercase and the rest to lowercase
    const normalCaseFirstWord = sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();

    return normalCaseFirstWord;
  }


  return (
    <>
      {component === 0 && <> </>}
      {component === 4 && <StyletransferModel closeModel={closeModel} />}
      {component === 5 && <ImgClassifyModel closeModel={closeModel} />}
      {/* {component === 9 && <ChatGPTModel closeModel={closeModel}/>} */}
      <div className="d-flex flex-start  w-100 gap-5 mx-3"> </div>
      <div
        className="ms-3"
      >
        <div className="prototypeFont mt-3" >
          <h1>Models</h1>
        </div>
      </div>
      <div className="row p-3">
        <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
          <input
            type="search"
            placeholder="SEARCH MODELS"
            className="searchinput"
            style={inputStyle}
            onChange={(e) => setQuery(e.target.value)}

          ></input></div>
        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">


          {typedisplay ?
            <>
              <div className='Invisible' onClick={() => setTypedisplay(false)}></div>
              <div className="modeltypelist">
                <span style={{ alignSelf: "center" }}>TYPE</span>
                {modeltypes?.map((mt, index) => {
                  return (
                    <>
                      <div
                        onClick={() => {
                          handlechange(index);
                        }}
                        key={index} className={mt.selected ? 'modeltypesubselected' : 'modeltypesub'}>
                        <span>{mt.name}</span>
                        {mt.selected ? <><i className="fas fa-check" ></i></> : <><i className="fas fa-plus" ></i></>}
                      </div>
                    </>

                  );
                })}
              </div></> : <button class="dropdown-toggle typeinput" data-bs-toggle="dropdown" aria-expanded="false"
                onClick={() => setTypedisplay(true)}
              >
              TYPE
            </button>
          }
        </div>
      </div>
      <br></br>
      {/* <p className="mx-3 my-2">Featured:</p> */}
      <div class="modelexplorerPanel">
        {search(modelarray)?.map((model, index) => (
          <div class="modellboxexp" onClick={() => clickHandler(model.id)}>
            <div className="ModelsContainer" style={{ display: "flex", height: "225px", flexDirection: "column" }}>
              <div style={{ flexGrow: 0.1, }}> 
                <p
                  style={{
                    fontSize: "32px",
                    color: "#4E4E4E",
                    margin: "0 0 10px 10px",
                    lineHeight: "42px",
                    fontWeight: "400",
                    textTransform: "uppercase",
                    whiteSpace: 'pre-line',
                    // minHeight: "85px"
                  }}
                >
                  {model.name}
                </p>
                <p style={{
                  fontSize: "16px", color: "rgba(78, 78, 78, 0.7)", margin: "10px 0 0 10px", lineHeight: "21px",
                  fontWeight: "400",
                }}>
                  {toNormalCase(model.desc)}
                </p>
              </div>
              <div style={{ flexGrow: 1, alignContent: "flex-end",padding:"0px 10px" }}>
                <div
                  className="modelImageLogoHoverStyle"
                  style={{
                    height: 90,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#C7CCB2",
                    // border: "1px solid black",
                    borderRadius: "15px",
                  }}
                  onClick={() => navigate(model.link, { replace: false, state: { source: "modelexplorer" } })}
                >
                  <img src={model.type === "Image" ? dottedLogo : model.type === "Text" ? lineLogo : model.type === "Video" ? humanLogo : model.type === "Sound" ? musicNote : "https://poietodata.s3.us-east-2.amazonaws.com/Data-contributions/Project-image/Radio_01-1684889256745.png"} style={{ width: 50, height: 50 }} alt="!WaitStillFetching!" />
                </div>
              </div>
            </div>
            {/* 
             */}
            {/*  */}
          </div>
        ))}
      </div>
    </>
  );
};

export default Modellist;