import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import eclippse from "../../Assets/Images/Ellipse.png";
import sendimg from "../../Assets/Images/send.png";
import services from "../../API_SERVICES/services";
import { toast } from "react-hot-toast";
import { isId } from "../../Storage(Methods)/storage";

function ChatGPTDemo() {
  const [prompt, setPrompt] = useState("");
  const [chats, setChats] = useState([])
  const [loading, setLoading] = useState(true);
  const [tune, setTune] = useState(false);
  const [demo, setDemo] = useState(false);
  const [projectName, setProjectName] = useState("")
  const navigate = useNavigate();


  useEffect(() => {
    loadstatus();
    var projid = isId();
    if (projid !== -1) {
      const apiname1 = "fetch-project-id";
      services
        .getbasic(apiname1, projid)
        .then((response) => {
          if (response === null || response.data == null) {

          }
          else {
            setProjectName(response.data.project_title);
          }

        }).catch((error) => {
        });
    }
   
  }, []);
  // const spinCenter = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translateX(-50% -50%)",
  // };
  const loadstatus = () => {
    var projid = isId();

    const apiname = "getchatstatus";
    const data = {
      'projectid': projid
    }
    services
      .post(apiname, data)
      .then((response) => {
        setLoading(false);
        if (response?.data?.data?.model === 'completed') {
          setDemo(true);
        }
        else {
          setDemo(false);

        }

      })
      .catch((error) => {
        toast.error("Error in Loading model");

      });
  }


  const loadmodel = () => {
    var projid = isId();
    setLoading(true);
    // const apiname = "load-chatmodel";
    const data = {
      'projectid': projid
    }
    services
      .post('load-chatmodel', data)
      .then((response) => {
        setTune(true);
        setLoading(false);

      })
      .catch((error) => {
        toast.error("Error in loading model");

      });
  }


  const handleChange = (e) => {
    setPrompt(e.target.value);
  }
  const handlepromptaction = () => {

    const apiname = "getPrompt";
    const data = {
      'input': prompt,
      'projectid': isId(),
    }
    services
      .post(apiname, data)
      .then((response) => {
        let newFormValues = [...chats];
        let i = chats.length;
        let obj = {
          "q": prompt,
          "ans": response?.data?.data?.model
        }
        newFormValues[i] = obj;
        setChats(newFormValues);
        setPrompt("");
      })
      .catch((error) => {
        toast.error("Error in getting response");
      });


  }


  return (
    <>
      <p className="chatgptcontainer-header">{projectName}/MODELS/ GPT-3.5-Turbo-1106/ Fine-Tune</p>
      {tune ?
        <div>
          <p style={{ fontSize: "24px", color: " #0000008A", marginTop: "20px", textAlign: "center" }}>GPT-3.5-Turbo-1106</p>
          <div className="chatgptdemo">
            {chats.map((element, index) => (
              <div key={index} className="chatgptdemoinner">
                <div className="chatqlabel">
                  <img alt="Upload" src={eclippse} height="20px" width={"20px"} style={{ marginLeft: "20px", marginRight: "20px" }} />
                  <p className="chatgptinput" name="q">{element.q || ""}</p>
                </div>
                <div className="chatanslabel" >
                  <p name="ans" className="chatgptinput">{element.ans || ""}</p>

                </div>
              </div>
            ))}

          </div>
          <div className="chatgptwindow">
            <img alt="Upload" src={eclippse} height="20px" width={"20px"} />
            <input type="text" className="chatgptinput" placeholder="TYPE A PROMPT" value={prompt} onChange={e => handleChange(e)}></input>
            <img alt="Upload" src={sendimg} height="20px" width={"20px"} onClick={handlepromptaction} />
          </div>
          <div className="chatsavecnt">
            <button className="chatsave" onClick={() => isId()===-1?navigate('/model-explorer', { replace: true }):navigate('/modelspage', { replace: true })}>Fine-Tune</button>
          </div>
        </div>
        : <>
          <div className="chatgptcontainer">

            <p style={{ fontSize: "24px", color: " #0000008A", marginTop: "20px" }}>GPT-3.5-Turbo-1106</p>
            <p style={{ fontSize: "24px", color: " #0000008A" }}>Fine-Tune</p>
            {loading ? <>
              <div
                className="spinner-border"
                style={{ width: "3rem", height: "3rem" }}
                role="status"
              ></div>
            </> :
              <>  {demo ? <>
                <p style={{ marginTop: "100px", fontSize: "16px", color: "#0000008A" }}>Model Ready ! </p>
                <button className="chatupload" onClick={loadmodel} >Demo</button>
              </> : <>
                <p style={{ marginTop: "100px", fontSize: "16px", color: "#0000008A" }}>Model Not Ready </p>
                <p style={{ fontSize: "16px", color: "#0000008A" }}>Check back later!</p>

                <button className="chatupload" onClick={() => isId()===-1?navigate('/model-explorer', { replace: true }):navigate('/modelspage', { replace: true })}>Close</button>
              </>}</>}
          </div>
        </>}
    </>
  )
}

export default ChatGPTDemo
