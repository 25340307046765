

import React from "react"

const Pagination = ({ nPages, currentPage, setCurrentPage ,lastrecord,total}) => {
    const nextPage = () => {
            if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    const prevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }
    return (
    <div className="txtpagination">
    <span> <i className='fas fa-less-than' onClick={prevPage} >
                        
                        {/* {'<'} */}
                    </i></span>
                    <span className="mx-3">
              {currentPage} To {lastrecord} OF {total}
                    </span>
              <span> <i className='fas fa-greater-than'
                        onClick={nextPage}
                        >
                        
                    </i></span>
                   
                    </div>
    )
}

export default Pagination