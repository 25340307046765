import React, { useRef, useState, useEffect } from 'react';
import "./AudioRecorder.css";
import lamejs from 'lamejs';
const AudioRecorder = ({ onSaveAudio, index, onDeleteAudio }) => {
  const [recordedUrl, setRecordedUrl] = useState('');
  const [recordedFile, setRecordedFile] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [audioDevices, setAudioDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const audioRef = useRef(null);
  const mediaStream = useRef(null);
  const mediaRecorder = useRef(null);
  const chunks = useRef([]);
  const allChunks = useRef([]);  // To store all chunks
  const timerInterval = useRef(null);
  const dropdownRef = useRef(null);
  const MAX_RECORDING_TIME = 300; // 5 minutes in seconds
  const MAX_FILE_SIZE = 10485760;

  const startRecording = async () => {
    if(isSaved){
      return;
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: selectedDeviceId },
      });
      mediaStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream);

      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
           // Combine chunks to check size
      const currentSize = new Blob(allChunks.current.concat(chunks.current), { type: 'audio/webm' }).size;
      if (currentSize > MAX_FILE_SIZE) {
        stopRecording(); // Stop the recording if the size limit is exceeded
      }
        }
      };

     
      mediaRecorder.current.onstop = () => {
        const recordedBlob = new Blob(chunks.current, { type: 'audio/webm' });
        allChunks.current.push(recordedBlob);  // Store each blob
        const combinedBlob = new Blob(allChunks.current, { type: 'audio/webm' }); // Combine all blobs

        const url = URL.createObjectURL(combinedBlob);
        setRecordedUrl(url);
        chunks.current = []; // Reset the chunks for the next recording session
      };

      mediaRecorder.current.start();
      setIsRecording(true);
      startTimer();
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.stop();
      setIsRecording(false);
      stopTimer();
    }

    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => track.stop());
    }
  };

  const encodeAudioBufferLame = async (audioBuffer) => {
      const sampleRate = audioBuffer.sampleRate;
      const numChannels = audioBuffer.numberOfChannels;
  
      // Initialize MP3 encoder with the appropriate sample rate and channels
      const mp3Encoder = new lamejs.Mp3Encoder(numChannels, sampleRate, 192); // 192kbps for better quality
  
      const mp3Data = [];
      const channelData = [];
      
      // Collect channel data
      for (let i = 0; i < numChannels; i++) {
          channelData.push(audioBuffer.getChannelData(i));
      }
  
      const sampleBlockSize = 1152; // Use a multiple of 576
      const float32ToInt16 = (float32Array) => {
          const int16Array = new Int16Array(float32Array.length);
          for (let i = 0; i < float32Array.length; i++) {
              int16Array[i] = Math.min(32767, Math.max(-32768, Math.round(float32Array[i] * 32767.5)));
          }
          return int16Array;
      };
  
      for (let i = 0; i < channelData[0].length; i += sampleBlockSize) {
          const leftChunk = float32ToInt16(channelData[0].subarray(i, i + sampleBlockSize));
          const rightChunk = numChannels > 1 ? float32ToInt16(channelData[1].subarray(i, i + sampleBlockSize)) : undefined;
  
          const mp3buf = mp3Encoder.encodeBuffer(leftChunk, rightChunk);
  
          if (mp3buf.length > 0) {
              mp3Data.push(mp3buf);
          }
      }
  
      const mp3buf = mp3Encoder.flush(); // Finish encoding
  
      if (mp3buf.length > 0) {
          mp3Data.push(mp3buf);
      }
  
      return new Blob(mp3Data, { type: 'audio/mp3' });
  };
  
  const saveRecording = async () => {
      if (recordedUrl) {
          const response = await fetch(recordedUrl);
          const arrayBuffer = await response.arrayBuffer();
          const audioContext = new AudioContext();
          const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  
          // Encode audio buffer to MP3
          const mp3Blob = await encodeAudioBufferLame(audioBuffer);
          const fileName = `recording_${index}.mp3`;
          const file = new File([mp3Blob], fileName, { type: 'audio/mpeg' });
  
          // Send the File object to parent component
          onSaveAudio({ file, name: fileName, size: file.size, type: file.type }, index);
  
          setIsSaved(true);
      }
  };
  
 

  const startTimer = () => {
    timerInterval.current = setInterval(() => {
      setRecordingTime((prevTime) => {
        const newTime = prevTime + 1;
        if (newTime >= MAX_RECORDING_TIME) {
          setIsRecording(false);
          stopRecording();
        }
        return newTime;
      });
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(timerInterval.current);
  };

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const deleteRecording = () => {
    if (recordedUrl) {
      URL.revokeObjectURL(recordedUrl);
      setRecordedUrl('');
      setRecordingTime(0);
      chunks.current = [];
      allChunks.current = []; // Clear all stored chunks
      onDeleteAudio(index);
      setIsSaved(false);
    }
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const audioDevices = devices.filter((device) => device.kind === 'audioinput');
      setAudioDevices(audioDevices);
      if (audioDevices.length > 0) {
        setSelectedDeviceId(audioDevices[0].deviceId);
      }
    });

    return () => {
      clearInterval(timerInterval.current);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="audio-recorder-container">
      <div className='audio-recorder-controls'>
        {!isRecording ? (
          <div className="audio-record-button" onClick={startRecording} title="Start Recording">
            <div className="audio-red-circle"></div>
          </div>
        ) : (
          <div className="audio-record-button" onClick={stopRecording} title="Stop Recording">
            <div className="audio-blue-square"></div>
          </div>
        )}

        <div className="audio-device-selector-container">
          <button className="audio-device-icon" onClick={toggleDropdown} title="Select Audio Device">
            <i className="fas fa-microphone" style={{ color: "rgba(0, 0, 0, 0.54)" }}></i>
          </button>
          {isDropdownVisible && (
            <select
              className="audio-device-selector"
              ref={dropdownRef}
              onChange={(e) => setSelectedDeviceId(e.target.value)}
              value={selectedDeviceId}
            >
              {audioDevices.map((device) => (
                <option key={device.deviceId} value={device.deviceId} style={{width:"50%"}}>
                  {device.label || `Device ${device.deviceId}`}
                </option>
              ))}
            </select>
          )}
        </div>

        <div className="audio-time-display">{formatTime(recordingTime)} / 05:00</div>
        {recordedUrl && (
        <div className="audio-audio-controls">
          <div className="audio-play-button" onClick={togglePlay} title={isPlaying ? "Pause" : "Play"}>
            <i className={`fas ${isPlaying ? 'fa-pause' : 'fa-play'}` } ></i>
          </div>
          <audio ref={audioRef} src={recordedUrl} onEnded={() => setIsPlaying(false)} />
        </div>
      )}
        {!isSaved && recordedUrl && (
         <i className="fas fa-save" style={{fontSize:"25px"}} onClick={saveRecording} title="Save Recording"></i>
      )}
        <i className="fas fa-times" style={{fontSize:"25px"}} onClick={deleteRecording} disabled={!recordedUrl} title="Delete"></i>
      </div>
    </div>
  );
};

export default AudioRecorder;



