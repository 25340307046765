import services from "../../src/API_SERVICES/services";

export const joinProject = (eid,pojid,id,username) => {
    const apiname = "join-project";
    const data = {
      pid: pojid,
      email_id: eid,
      user_id: id,
      username: username,
    };
    services.post(apiname, data)
      .then((response) => {
      })
      .catch((error) => {
        console.log(error);
      });
  };