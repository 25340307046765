import React, { useEffect, useState } from "react";
import path from "path";
import services from "../../API_SERVICES/services";
import iconMp3 from "../../Assets/Images/mp3_icon.png";

const ModelDataset = (props) => {
  const {data,closeVeiw,selectVeiw}=props;
  const [global, setglobal] = useState({
    datacontributions: "",
    loading: false,
    fetched: "",
    image: "",
    extensions: [],
    editmode: false,
  });
  const {
    datacontributions,
    loading,
    extensions,
  } = global;
  const loaddata = React.useCallback(() => {
    let apiname = "fetch-project-data";
    const project = true;
    services
      .get(apiname, project)
      .then((response) => {
        setglobal({
          ...global,
          datacontributions: response,
          loading: true,
          extensions: getExtensionList(response),
        });
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  }, [global, setglobal]);
  useEffect(() => {
    loaddata();
  }, [loaddata]);
  const [filterParam, setFilterParam] = useState(['']);
  const [selectedItem, setSelectedItem] = useState('');
  const inputStyles = {
    width: "100%",
    borderRadius: "20px",
    padding: "7px",
    border: "1px solid black",
  };
  const spinCenter = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50% -50%)",
  };
  const dropinputStyles = { 
    width: "100%",
    padding: "5px",
    fontSize: "16px",
    lineHeight: "1",
    border: "1px solid black",
    borderRadius: "20px",
    // background: 'url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right ',
    background: 'url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+)no-repeat right',
    backgroundColor:"none",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: "right 20px top 0.4em",
    height:"37px",
    paddingLeft:"20px"

  };
  const imageStyle = {
    width: "30px",
    height: "30px",
  };
  const [searchParam] = useState(["video", "author"]);
  const [q, setQ] = useState("");
  function search(items) {
    return items.filter((item) => {

      if ((path.basename(item?.video)).includes(filterParam)) {
        return searchParam.some((newItem) => {
          if (item[newItem] !== null) {
            return (
              item[newItem]
                .toString()
                .toLowerCase()
                .indexOf(q.toLowerCase()) > -1
            );
          }
        });
      } else if (filterParam === "") {
        return searchParam.some((newItem) => {
          return (
            item[newItem]
              .toString()
              .toLowerCase()
              .indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }
  const handleChange = (e) => {
    setFilterParam(e.target.value);

    //filter
  };
  const getExtensionList = (response) => {

    var ext = [];
    for (var i = 0; i < response?.data?.length; i++) {
      var filename = path.basename(response?.data[i]?.video);
      var extensions = (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename).toString() : undefined

      if (!ext.includes(extensions) && extensions !== undefined) {
        ext.push(extensions);
      }
    }
    return ext;
  }
  const onItemCheck = (e, item) => {
    setSelectedItem(e.target.value);
  }
  return (
    <>
     <div className="backdropinner" style={{ maxaHeight: "700px", width: "94%",borderRadius:"40px" }} >
     <div style={{borderRadius:"40px"}}>

     <center><p style={{fontSize:"24px"}}> Project Dataset</p></center>
            <p className="ms-3" style={{fontSize:"14px"}}>
              {data?.project_title} / DATABASE
            </p>
          <div className="projectdb mt-3" style={{fontSize:"13px",color:" #4E4E4E"}}>
            <div className="row p-3">
              <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                <input
                  type="search"
                  className="artsearchinput"
                  placeholder="SEARCH"
                  style={inputStyles}
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                ></input>
              </div>
              <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 customdrop">

                <select onChange={handleChange} name="FILETYPE " className="artsearchinput"
                  style={dropinputStyles}>
                  <option key={0} value=''> FILETYPE</option>
                  {extensions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2 col-lg-2 col-xl-2 customdp"></div>
            </div>
            <table className="table tabledt"  style={{overflowY:"scroll",height:"400px",width:"100%",display:"block"}}>
              <thead style={{width:"100%",display:"table"}}>
                <tr style={{ fontSize: "13px", color: " rgba(78, 78, 78, 0.7)", textTransform: "lowercase" ,width:"100%"}}>
                  <th>#</th>
                  <th></th>
                  <th >icon</th>
                  <th>file name</th>
                  <th>author(s)</th>
                  <th>contributor</th>
                </tr>
              </thead>
              {loading && datacontributions?.data?.length > 0 && (
                <tbody style={{width:"100%" ,display:"table"}}>
                  {search(datacontributions?.data)?.map((info, key = 0) => (
                    <tr
                      key={key + 1}>
                      <td style={{ fontSize: "13px" }}>{key + 1}</td>
                      <td>
                        <input
                          type="radio"  value={info?.video}
                          className="form-check-input"
                          onChange={(e) => onItemCheck(e, info)}
                        /></td>
                      <td>
                        {" "}
                        <img
                          // src={data?.projectimage}
                          src={info?.video.includes(".mp3")?iconMp3:info?.video}
                          style={imageStyle}
                          alt="projimg"
                        />
                      </td>
                      <td style={{ fontSize: "13px" }}>
                        {path.basename(info?.video)}
                      </td>
                      <td style={{ fontSize: "13px" }}>{info.author}</td>
                      <td style={{ fontSize: "13px" }}>{info.contributor}</td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            <div className='d-flex justify-content-between'>
            <button   className='datasetbtn' onClick={closeVeiw}>Cancel</button>
            <button   className='backdatasetbtn' onClick={() => selectVeiw(selectedItem)} >Select</button>
            </div>
          

            {datacontributions?.data?.length === 0 ? (
              <p className="text-center pb-3" style={{ fontWeight: "bold" }}>
                <i className="fas fa-empty-set"></i> No Data Found
              </p>
            ) : !loading ? (
              <div style={spinCenter}>
                <div
                  className="spinner-border"
                  style={{ width: "3rem", height: "3rem" }}
                  role="status"
                ></div>
              </div>
            ) : null}
          </div>
     </div>

     
        

        </div>
     
    </>
  );
};

export default ModelDataset;
