import React from "react";
import {  useNavigate } from "react-router-dom";

export const ModelComingSoon = () => {
    // let location = useLocation();
    const navigate = useNavigate();
    return (
        <>
            <div className="outercontainer">
                <div className="gridFourth">
                    <div className="gridcontent">
                        <h5>COMING SOON...</h5>
                            <button
                                type="button"
                                className="savebtn"
                                onClick={() => navigate('/modellist', { replace: true })}
                            >
                                BACK
                            </button>
                    </div>
                </div>
            </div>
        </>
    );
};
