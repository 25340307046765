import React,{useCallback,useEffect, useState} from "react";
import {toast} from 'react-hot-toast'
import {isAuth, isId } from "../../Storage(Methods)/storage";
import services from "../../API_SERVICES/services";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
export const HowDoesItDo = () => {
  const navigate = useNavigate();
  const[blueprint,setBlueprint] = useState({
    datas:'',
    rules:'',
    formData:''
  })
  const {datas,rules,formData} = blueprint
  useEffect(() => {
    loadblueprint();
  },[])
  const DelayApiCall = useCallback(
    debounce(
      (apiname, data) =>
        services
          .post(apiname, data)
          .then((response) => {
            toast.success(response.data.message);
            loadblueprint();
          })
          .catch((error) => {
            toast.error(error.response.data.error);
          }),
      1500
    ),
    []
  );
  const loadblueprint = () => {
    const apiname = "fetch-blueprint"
    const project = true;
    services.get(apiname, project)
      .then((response) => {
        const { data, rules, formdata } = response.data.data;
        setBlueprint({ ...blueprint, datas:data, rules, formData:formdata });
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };
  const handleData = (e) => {
    const datas = e.target.value;
    setBlueprint({datas:datas})
    const apiname = "save-data"
    const data = {
      data:datas,
      pId: isId(),
      user_id: isAuth().id,
    };
    DelayApiCall(apiname,data)
  };
  const handleRules = (e) => {
    const rules = e.target.value
    setBlueprint({rules:rules})
    const apiname = "save-rules"
    const data = {
      rules:rules,
      pId: isId(),
      user_id: isAuth().id,
    };
    DelayApiCall(apiname,data)
  };
  const handleFormMaterial = (e) => {
  const formData = e.target.value
  setBlueprint({formData:formData})
  const apiname = "save-form-material"
  const data = {
    formdata:formData,
    pId: isId(),
    user_id: isAuth().id,
  };
  DelayApiCall(apiname,data)
  };
  return (
    <>
    <div className="row justify-content-around ps-3 pt-4 mb-3">
    <h4 className="text-uppercase mb-3" style={{fontSize: "14px",color:"#4E4E4EB2"}}>Now, sketch out the project from a TECHNICAL STANDPOINT.</h4>
      <div className="col">
        <div className="stylecardframe">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h4 className="card-title" style={{ fontSize: "36px",color:"#4E4E4E" }}>DATA</h4>
            </div>
            <p style={{ fontSize: "14px",color:"#4E4E4E8A" }}>What DATA ARE YOU INPUTTING?</p>
            <textarea
              className="cardtxthow mt-1 mb-3 pt-2"
              rows="7"
              value={datas}
              name="data"
              onChange={handleData}
              placeholder="TYPE HERE...."
            
            ></textarea>
          </div>
            <button className="addbtn  ms-3" onClick={() => navigate('/Comingsoon',{replace:true})}>
                <i
                  className="fas fa-plus"
                  style={{ backgroundColor: "transparent" }}
                />
                Add a Frame
            </button>
        </div>
      </div>
      <div className="col">
        <div className="stylecardframe ">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h4 className="card-title" style={{ fontSize: "36px",color:"#4E4E4E" }}>RULES</h4>
            </div>
            <p style={{ fontSize: "14px",color:"#4E4E4E8A" }}>What algorithm or models are used to perform actions on that data?</p>
            <textarea
              className="cardtxthow mt-1 mb-3 pt-2"
              rows="7"
              value={rules}
              name="rules"
              onChange={handleRules}
              placeholder="TYPE HERE...."
             
            ></textarea>
          </div>
            <button className="addbtn ms-3" onClick={() => navigate('/Comingsoon',{replace:true})}>
                <i
                  className="fas fa-plus"
                  style={{ backgroundColor: "transparent" }}
                />
                Add a Frame
            </button>
         
        </div>
      </div>
      <div className="col">
        <div className="stylecardframe">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h4 className="card-title" style={{ fontSize: "36px",color:"#4E4E4E" }}>FORM/MATERIAL</h4>
            </div>
            <p style={{ fontSize: "14px",color:"#4E4E4E8A" }}>What is the resulting form of the project? What materials are you using?</p>
            <textarea
              className="cardtxthow mt-1 mb-3 pt-2"
              rows="4"
              value={formData}
              name="formdata"
              onChange={handleFormMaterial}
              placeholder="TYPE HERE...."
            ></textarea>
          </div>
         
            
              <button className="addbtn  ms-3" onClick={() => navigate('/Comingsoon',{replace:true})}>
                  <i
                    className="fas fa-plus"
                    style={{ backgroundColor: "transparent" }}
                  />
                  Add a Frame
              
              </button>
        </div>
      </div>
    </div>
    </>
  );
};
