import { useNavigate} from "react-router-dom";

const ExplorerNavBar = (props) => {
  const navigate = useNavigate();
  const Data = props?.labels;
  const Item = Data?.map((item,index) =>
    item.name === props?.isActive ? (
      <div key={index}
        className="explorerComponentsActive text-hover"
        onClick={() => navigate(item.value)}
      >
        {item.name}
      </div>
    ) : (
      <div  key={index} className="explorerComponents text-hover" onClick={() => navigate(item.value)}>
        {item.name}
      </div>
    )
  );

  return (
    <>
      <div className="framesRowTop">{Item}</div>
    </>
  );
};

export { ExplorerNavBar };
