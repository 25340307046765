import React, {useEffect, useState} from "react";
import Sketch from "react-p5";
import * as ml5 from "ml5";
import { FramesNavBar } from "../Howdoesscreen/HeadarNavbar/FramesNavbar";
import { isId } from "../../Storage(Methods)/storage";
import services from "../../API_SERVICES/services";

var classifier, p5Object, video, classifyInp, inpImage;
var videoHeight = 390;
var videoWidth = 220;
var modelReadyText;
var flag = 0;


function convertImageToBase64(imgUrl) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.height = image.naturalHeight;
            canvas.width = image.naturalWidth;
            ctx.drawImage(image, 0, 0);
            const dataUrl = canvas.toDataURL();
            resolve(dataUrl);
        };
        image.onerror = error => {
            reject(error);
        };
        image.src = imgUrl + "?not-from-cache-please";
    });
}


const createS3ImageClassify = async (link) => {
    try {
        p5Object.select("#classified-image").remove();
    }
    catch { }
    try {
        p5Object.select("#result-header").remove();
    }
    catch { }
    try {
        p5Object.select("#result-confidence").remove();
    }
    catch { }
    let imageUrl = link;
    convertImageToBase64(imageUrl)
        .then(base64Image => {
            let loadedImg = new Image()
            loadedImg.src = base64Image;
            var result = classifier.classify(loadedImg);
            result.then((value) => {
                let classifyInp = value[0].label;
                let conf = value[0].confidence * 100;
                // inpImage.show();
                inpImage = p5Object.createImg(base64Image).addClass("output-images");
                inpImage.parent("c-image").id("classified-image");
                p5Object.createElement("h5", classifyInp).parent("c-class").id("result-header").addClass("gray-dark");
                p5Object.createElement("h5", conf.toFixed(2) + "%").parent("c-conf").id("result-confidence").addClass("gray-dark");
            });
        })
}

// Uploading input images
function handleInputFile(file) {
    if (file.type === 'image') {
        inpImage = p5Object.createImg(file.data).addClass("output-images").parent("c-image");
        try {
            p5Object.select("#classified-image").remove();
        }
        catch { }
        try {
            p5Object.select("#result-header").remove();
            p5Object.select("#result-confidence").remove();
        }
        catch { }
        inpImage.id("classified-image");
        inpImage.hide();
    } else {
        file = null;
    }
}

function classifyInput() {
    var result = classifier.classify(inpImage);
    result.then((value) => {
        classifyInp = value[0].label;
        let conf = value[0].confidence * 100;
        inpImage.show();
        p5Object.createElement("h4", classifyInp).parent("c-class").id("result-header").addClass("gray-dark");
        p5Object.createElement("h5", conf.toFixed(2) + "%").parent("c-conf").id("result-confidence").addClass("gray-dark text-right");
    });
}


const Imagetrans = (props) => {
    var modelPath = "mobilenet";
    const loaddata = async () => {
        let apiname = "fetch-project-data";
        const project = true;
        await services
            .get(apiname, project)
            .then((response) => {
                setProjectData(response);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error.response.data.error);
            });
    };
    const [projectData, setProjectData] = useState({ "data": [] });
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            loaddata();
        }, 3000);
    }, []);
    if (isLoading){
        return(
          <>
            <div className="mt-5 save-model-loader-ml5"></div>
            <h4 className="mt-5 text-center">Loading model & project data...</h4>
          </>
        );
    }
    const itemArray = [
        { name: "DISCOVER", value: "/decode" },
        { name: "FRAMES", value: "/CreateFrames" },
        { name: "BLUEPRINT", value: "/Howinputtab" },
        { name: "PROTOTYPE", value: "/modelspage" },
        { name: "REFLECTION", value: "/reflection" },
    ];
    var setup = (p5, canvasParentRef) => {
        loadModel().then((response) => {
            modelPath = response;
            classifier = ml5.imageClassifier(modelPath, modelReady);
        });
        p5Object = p5;
        var constraints = { video: { optional: [{ maxFrameRate: 30 }] }, audio: false }
        video = p5.createCapture(constraints);
        video.hide();
        // classifier = ml5.imageClassifier(modelPath, modelReady);


        // S3 data container classify
        var s3ModalClassify = p5.createDiv().addClass("s3-data-container s3-img-cont").id("s3-image-container-classify");
        p5.createDiv().parent("s3-image-container-classify").addClass("row ps-3").id("s3-image-input-row-classify");
        p5.createInput().parent("s3-image-input-row-classify").addClass("col-5 s3-fields search-container-s3").attribute('placeholder', 'SEARCH');
        p5.createSelect().parent("s3-image-input-row-classify").addClass("col-1 s3-fields filetype-container-s3").option('FILETYPE');
        p5.createDiv().parent("s3-image-input-row-classify").addClass("col").id("close-modal-classify");
        p5.createButton("X").class("close-modal-btn").parent("close-modal-classify").mousePressed(() => {
            s3ModalClassify.hide();
        });

        // S3 table classify
        p5.createDiv().parent("s3-image-container-classify").id("data-table-container-classify").addClass("pt-2 pb-2 mt-2");
        p5.createDiv().parent("data-table-container-classify").addClass("row mt-2 ms-2 me-2 pb-3").id("s3-imageheader-row-classify");
        p5.createDiv("#").parent("s3-imageheader-row-classify").addClass("col-1 text-center").id("image-id");
        p5.createDiv("icon").parent("s3-imageheader-row-classify").addClass("col-1").id("image-icon");
        p5.createDiv("filename").parent("s3-imageheader-row-classify").addClass("col-4").id("image-filename");
        p5.createDiv("author(s)").parent("s3-imageheader-row-classify").addClass("col-2").id("image-author");
        p5.createDiv("contributor").parent("s3-imageheader-row-classify").addClass("col-2").id("image-contributor");
        p5.createDiv("select").parent("s3-imageheader-row-classify").addClass("col-2").id("image-select");
        p5.createDiv().parent("data-table-container-classify").id("data-table-content-classify");
        for (let img = 0; img < projectData.data.length; img++) {
            p5.createDiv().parent("data-table-content-classify").addClass("row model-data-content mt-2 ps-2 pe-2 pt-2 pb-2 mb-2").id("s3-imageheader-row-classify" + img);
            p5.createDiv(img + 1).parent("s3-imageheader-row-classify" + img).addClass("col-1 text-center").id("image-id");
            p5.createDiv("").parent("s3-imageheader-row-classify" + img).addClass("col-1").id("image-icon-classify" + img);
            p5.createImg(projectData.data[img].video).parent("image-icon-classify" + img).addClass("icon-images").size(60, 40);
            p5.createDiv(projectData.data[img].video.split("/").at(-1)).parent("s3-imageheader-row-classify" + img).addClass("col-4").id("image-filename");
            p5.createDiv(projectData.data[img].author).parent("s3-imageheader-row-classify" + img).addClass("col-2").id("image-author");
            p5.createDiv(projectData.data[img].contributor).parent("s3-imageheader-row-classify" + img).addClass("col-2").id("image-contributor");
            p5.createCheckbox().parent("s3-imageheader-row-classify" + img).addClass("col-2 classify-checkboxes").id("image-select-classify" + img);
            let d = document.getElementById("image-select-classify" + img);
            d.value = projectData.data[img].video;
        }
        p5.createButton("UPLOAD").addClass("upload-s3-button save-model mt-4").parent("data-table-container-classify").mousePressed(() => {
            let checkss = document.querySelectorAll('input[type="checkbox"]:checked');
            for (let c of checkss) {
                if (c.parentNode.classList.contains = "classify-checkboxes") {
                    createS3ImageClassify(c.parentNode.value);
                    s3ModalClassify.hide();
                    c.checked = false;
                }
                break;
            }
        });
        s3ModalClassify.hide();
        var cameraCont = p5Object.createDiv().addClass("cam-container").id("cam-cont");
        var canvas = p5.createCanvas(videoHeight, videoWidth).style('border-radius', '30px').parent("cam-cont");
        p5.createDiv().addClass("blackmodelbox-n mb-2 p-4").id("main-gray");
        cameraCont.hide();
        canvas.hide();
        const videoSetup = () => {
            captureBtn.show();
            canvas.show();
            cameraCont.show();
            confirmBtn.show();
        }
        // var containerNew = p5.createDiv().addClass("d-flex text-center justify-content-center align-items-center").parent("cam-cont").id("camera-btn-container")
        var captureBtn = p5.createButton("Capture").addClass("capture-btn me-2 demo-mobilenet-buttons").parent("camera-btn-container").mousePressed(function () {
            try {
                p5Object.select("#classified-image").remove();
            }
            catch { }
            try {
                p5Object.select("#result-header").remove();
                p5Object.select("#result-confidence").remove();
            }
            catch { }
            var result = classifier.classify(video);
            result.then((value) => {
                classifyInp = value[0].label;
                let conf = value[0].confidence * 100;
                p5Object.createElement("h5", classifyInp).parent("c-class").id("result-header").addClass("gray-dark text-left");
                p5Object.createElement("h5", conf.toFixed(2) + "%").parent("c-conf").id("result-confidence").addClass("gray-dark text-right");
            });
            var capturedImage = p5.createImage(video.width, video.height);
            capturedImage.copy(video, 0, 0, video.width, video.height, 0, 0, video.width, video.height);
            var i = p5.createImg(capturedImage.canvas.toDataURL()).addClass("").id("classified-image");
            i.parent("c-image");
            video = p5.createCapture(constraints);
            video.hide();
        })
        var confirmBtn = p5.createButton("Done").addClass("capture-btn me-2 demo-mobilenet-buttons").parent("camera-btn-container").mousePressed(function () {
            captureBtn.hide();
            canvas.hide();
            cameraCont.hide();
            confirmBtn.hide();
        })
        p5.createDiv().addClass("demo-inner-box row ps-3 mb-2 ms-2 me-4").parent("main-gray").id("demo-box");
        // Input section
        p5.createElement("h4", "INPUT").addClass("col mt-3 ms-2 gray-dark").parent("demo-box").id("demo-box-1");
        p5.createDiv().addClass("model-inp-container-1 mt-2 content-radius d-flex justify-content-center align-items-center").parent("demo-box-1").id("model-input-container");
        p5.createElement("i", "").addClass("fa-solid fa-folder upload-logo-btn").parent("model-input-container").mousePressed(() => {
            s3ModalClassify.show();

        })
        p5.createButton("").addClass("ms-2 camera fa-solid fa-camera upload-logo-btn").id("cameraBtnO").parent("model-input-container").mousePressed(function () {
            // cameraCont.parent("model-input-container");
            cameraCont.parent("model-input-container");
            // canvas.position(90,410)
            videoHeight = document.getElementById("model-input-container").offsetWidth;
            videoWidth = document.getElementById("model-input-container").offsetHeight;
            p5.resizeCanvas(videoHeight, videoWidth);
            videoSetup();
            modelReadyText.hide();
        });
        var imgBtn = p5.createElement("label", '').addClass("fa-solid fa-upload upload-logo-btn ms-2").parent("model-input-container");
        var imgBtnLg = p5.createFileInput(handleInputFile, "label", [true]).attribute("label", "Upload");
        imgBtn.child(imgBtnLg);
        imgBtnLg.hide();

        // Action section
        p5.createDiv().addClass("col").parent("demo-box").id("demo-actions");
        p5.createElement("h4", "ACTIONS").addClass("col mt-3 ms-2 gray-dark").parent("demo-actions");
        p5.createDiv().addClass("model-inp-container content-radius text-center  pb-3").parent("demo-actions").id("model-action-container");
        modelReadyText = p5.createElement("p", "Model ready").parent("model-action-container").addClass("text-center text-light pt-5").id("model-ready-text").hide();
        p5.createButton("RUN MODEL").addClass("run-model-btn-n btn-bot").parent("model-action-container").mousePressed(function () {
            classifyInput();
        })

        // Result section
        p5.createDiv().addClass("col").parent("demo-box").id("demo-results");
        p5.createElement("h4", "OUTPUT").addClass("col mt-3 ms-2 gray-dark").parent("demo-results");
        p5.createDiv().addClass("model-out content-radius d-flex flex-column justify-content-center align-items-center text-center").id("result-container").parent("demo-results");
        p5.createDiv().addClass("classified-image-container text-center").parent("result-container").id("c-image");
        p5.createDiv().addClass("classified-image-container-text text-center row").parent("result-container").id("c-text");
        p5.createDiv().addClass("col").parent("c-text").id("c-class");
        p5.createDiv().addClass("col").parent("c-text").id("c-conf");
    }
    const loadModel = async () => {
        try {
            let project;
            let response = await services.get("mobilenetLoad", project = isId());
            return response.data.path;
        }
        catch (err) {
            console.log('Error:', err)
        }
    }
    function modelReady() {
        flag = +1;
        modelReadyText.show();
    }
    const draw = (p5) => {
        p5.background(0);
        p5.image(video, 0, 0, videoHeight, videoWidth);
    };
    return (
        <>
            <FramesNavBar labels={itemArray} isActive={"PROTOTYPE"} />
            <div className="ms-3 mb-3" style={{ borderBottom: "2px solid black", width: "98%" }}>
                <h1 className="mb-0 mt-3">MODELS</h1>
            </div>
            <div className="ms-3">
                <p className="text-uppercase">
                    <h6>
                        MODELS/ML5_IMAGE_CLASSIFICATION/DEMO
                    </h6>
                </p>
            </div>
            <div className="ms-3 mb-1">
                <h2 className="mb-0 mt-3 ms-3">IMAGE CLASSIFICATION</h2>
            </div>
            <Sketch setup={setup} draw={draw} />
        </>
    );
};

export default Imagetrans
