import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import services from '../../API_SERVICES/services';
import { isAuth } from "../../Storage(Methods)/storage";


const Editproject = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [projectVal, setProjectVal] = useState({
    project_title: location?.state?.data?.project_title,
    project_desc: location?.state?.data?.project_desc,
  });
  const onFileChange = (event) => {
    event.preventDefault();
    var formData = new FormData();
    formData.append('projectUpload', event.target.files[0]);
    formData.append('pId', location?.state?.data?.pId)
    const apiname = "edit-image"
    services.put(apiname, formData)
      .then((response) => {
        toast.success(response.data.message)
      })
      .catch((error) => {
        toast.error(error.response.data.error);
      })
  }

  const handleChange = (e) => {
    setProjectVal({ ...projectVal, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      pId: location?.state.data?.pId,
      project_title: projectVal.project_title,
      project_desc: projectVal.project_desc
    }
    const apiname = "edit-projects"
    services.put(apiname, data)
      .then((response) => {
        toast.success(response.data.message);
        setTimeout(() => {
          navigate('/UserAddNewProject', { replace: true })
        }, 2300);
      })
      .catch((error) => {
        toast.error(error.response.data.error);
      })
  }
  const [contributeVal, setContributeVal] = useState({
    contributor: "",
    loading: false,
  });
  const { contributor, loading } = contributeVal;

  useEffect(() => {
    loadContributor();
  }, []);
  const loadContributor = () => {

    const apiname = "list-collabrator";
    const proj_user_id = location?.state?.data?.pId;
    services
    .getbasic(apiname, proj_user_id)
    .then((response) => {
      setContributeVal({ ...contributeVal, contributor: response, loading: true });
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  };
  return (
    <>
      {location.state === undefined ? (<h4 style={{ fontSize: '35px', fontWeight: 'lighter', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50% , -50%)' }}> 404 PAGE NOT FOUND</h4>) : (<>
        <div className='d-flex w-100  justify-content-between'>
          <input
            className='text-uppercase  ms-3 plachetitle'
            name='project_title'
            value={projectVal.project_title}
            onChange={handleChange}
          ></input>
          <button type='button' className='publicbtn me-2'>
            {location?.state?.data?.project_privacy}
          </button>
        </div>
        <div className='gridContainer'>
          <div className='grid-item-1'>
            <textarea
              className='plachetitle2'
              name='project_desc'
              value={projectVal.project_desc}
              onChange={handleChange}
              placeholder='DESCRIPTION'
              rows='4'></textarea>
          </div>
          {location?.state?.data?.projectimage === null ? (<div
            className='grid-item-2'
            style={{ border: '1px solid black', borderRadius: '10px' }}>
            <label htmlFor='file' id='filelabel'>
              <i
                className='fas fa-camera'
                style={{ color: 'black', fontSize: '25px' }}></i>
            </label>
            <input
              type='file'
              id='file'
              name='projectUpload'
              onChange={onFileChange}
              style={{ display: 'none', visibility: 'none' }}
            />
          </div>) : (
            <div
              className='grid-item-2'
              style={{ border: '1px solid black', borderRadius: '10px' }}>
              <img
                src={location?.state?.data?.projectimage}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '10px',
                }}
                alt='profimage'
              />
              <label htmlFor='file' id='filelabel'>
                <i
                  className='fas fa-camera'
                  style={{ color: 'black', fontSize: '25px' }}></i>
              </label>
              <input
                type='file'
                id='file'
                name='projectUpload'
                onChange={onFileChange}
                style={{ display: 'none', visibility: 'none' }}
              />
            </div>)}
        </div>
        <div className='gridExpand'>
          <div className="contributor-div">
            <div className="row">
              <div className="col-xl-12">
                <h6>CONTRIBUTORS</h6>
              </div>
              <div className="col-xl-12 my-2">
              </div>
              <div className="col-xl-12">   <ul className="listcontributor">
                {loading && contributor?.data?.map((d) => (
                  <li key={d.username}> {d.collaborator_mailid===isAuth().email?'You':d.username}({d.is_admin==='Y'?"Admin":"Contributor"}) 
                  </li>
                )

                )}


              </ul></div>
            </div>
          </div>


          <div className='grid-item'>
            {' '}
            <h6>DATA SETTINGS</h6>{' '}
          </div>

          <button className='grid-item-3' onClick={handleSubmit} >
            UPDATE DATA
          </button>
        </div></>)}
    </>
  )
}

export default Editproject