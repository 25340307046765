import React, { useEffect,useState } from "react";
import services from "../../API_SERVICES/services";
import { toast } from "react-hot-toast";
// import { useNavigate } from "react-router-dom";
import uploadpic from "../../Assets/Images/upload.png";
import downloadpic from "../../Assets/Images/download.png";
import deletepic from "../../Assets/Images/delete.png";
import deletepicy from "../../Assets/Images/delete_y.png";
import downloadpicy from "../../Assets/Images/download_y.png";
import folderpic from "../../Assets/Images/folder.png";
// import { PopupModal } from "../DataPrivacy/PopupModal";
// import path from "path";
import {isId } from "../../Storage(Methods)/storage";
import ModelDataset from "../../Components/Dataset/modelDataset";


function Stylemodel() {
  // const spinCenter = {
  //   position: "absolute",

  //   transform: "translateX(-50% -50%)",
  // };
  // const navigate = useNavigate();

  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const [temp1, setTemp1] = useState("");
  const [temp2, setTemp2] = useState("");
  const [dataview, setDataview] = useState(false);
  const [dsCount,setDsCount]=useState(0);

  // const [base64File1, setBase64File1] = useState("");
  // const [base64File2, setBase64File2] = useState("");
  const [setBase64File1] = useState("");
  const [setBase64File2] = useState("");
  const [base64File3, setBase64File3] = useState("");

  const [loading, setLoading] = useState(false);
  const [display1, setDisplay1] = useState("notdisplayed");
  const [display2, setDisplay2] = useState("notdisplayed");
  const [display3, setDisplay3] = useState("notdisplayed");
  // const [datalist, setDatalist] = useState("");
  // const [setDatalist] = useState("");
  const [details, setDeatils] = useState("");

  const [hover, setHover] = useState(false);
  // useEffect(() => {
  //   loaddata();
  // }, []);

  useEffect(() => {
    var projid = isId();
    if (projid !== -1) {
      const apiname1 = "fetch-project-id";
      services
        .getbasic(apiname1, projid)
        .then((response) => {
          if (response === null || response.data == null) {

          }
          else {
           // loaddata();
           setDeatils(response.data);
          }

        }).catch((error) => {
        });
    }
   
  }, []);
  // const loaddata = () => {
  //   let apiname = "get-allddata";
  //   services.getbasic(apiname)
  //     .then((response) => {
  //       setDatalist(response);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  // const checkhandler = (e) => {
  //   setDsitem(e.target.value);
  // };
  const selectData = (dsitem) => {
    if(dsitem!=null&& dsitem!=='')
    {

      if(dsCount===1)
      {
        fetch(dsitem, {
          method: "GET", 
          cache: "no-cache"
        }).then(response => {
          response.arrayBuffer().then(function (buffer) {
            const blob = new Blob([buffer]);
            const file = new File([blob], 'image1.jpg', {type: blob.type});
            setFile1(file);
            setTemp1(dsitem);
            setDataview(!dataview);
          });
        }).catch(err => {
          console.log(err);
        });
      }
      if(dsCount===2)
      {
        fetch(dsitem, {
          method: "GET", 
          cache: "no-cache"
        }).then(response => {
          response.arrayBuffer().then(function (buffer) {
            const blob = new Blob([buffer]);
            const file = new File([blob], 'image2.jpg', {type: blob.type});
            setFile2(file);
            setTemp2(dsitem);
            setDataview(!dataview);
          });
        }).catch(err => {
          console.log(err);
        });
      
      }
         
            setDsCount(0);
    }
    setDataview(false);


  };
  const showImg = e => {
    e.preventDefault();
    setHover(true);
  };

  const hideImg = e => {
    e.preventDefault();
    setHover(false);

  };
  const closeDataview = () => {
    setDataview(false);
  }
  const showButton1 = e => {
    e.preventDefault();
    setDisplay1("displayed");
  };

  const hideButton1 = e => {
    e.preventDefault();
    setDisplay1("notdisplayed");
  };
  const showButton2 = e => {
    e.preventDefault();
    setDisplay2("displayed");
  };

  const hideButton2 = e => {
    e.preventDefault();
    setDisplay2("notdisplayed");
  };
  const showButton3 = e => {
    e.preventDefault();
    setDisplay3("displayed");
  };

  const hideButton3 = e => {
    e.preventDefault();
    setDisplay3("notdisplayed");
  };
  const onFileChange1 = (e) => {
    e.preventDefault();
    setFile1(e.target.files[0]);
    setTemp1(URL.createObjectURL(e.target.files[0]));
  };
  const onFileChange2 = (e) => {
    e.preventDefault();
    setFile2(e.target.files[0]);
    setTemp2(URL.createObjectURL(e.target.files[0]));
  };

  const downloadTxtFile = () => {
    if (base64File3 !== "") {
      var a = document.createElement("a");
      a.href = "data:image/png;base64," + base64File3;
      // a.href = base64File3;
      a.download = "Image.png";
      a.click();
    }
  };
  const dataUpload = async (e) => {
    e.preventDefault();
    setLoading(true);

    const result1 = await getBase64(file2);
    file1["base64"] = result1;
    setBase64File1(result1);


    const result2 = await getBase64(file1);
    file2["base64"] = result2;
    setBase64File2(result2);


    const data = {
      data: [
        {
          filename: file1.name,
          base64string: result1,
        },
        {
          filename: file2.name,
          base64string: result2,
        },
      ],
    };

    const apiname = "run-imgmodel";

    services
      .post(apiname, data)
      .then((response) => {


        setBase64File3(response.data.Image);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Error in running model");
        setLoading(false);
      });
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const refresh = () => {
    setFile1("");
    setFile2("");
    setTemp1("");
    setTemp2("");
    setBase64File1("");
    setBase64File2("");
    setBase64File3("");
  };
  const delete1 = () => {
    setFile1("");
    setTemp1("");
    setBase64File1("");
  };
  const delete2 = () => {
    setFile2("");
    setTemp2("");
    setBase64File2("");
  };


  return (
    < >


      <div className="row  ps-3 " style={{
        width: '100%' ,marginBottom:"50px"
      }}>

<p><span style={{ fontSize: "40px", fontWeight: "400" }}>
            STYLE TRANSFER
          </span></p>
        <p
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <span style={{ fontSize: "14px", fontWeight: "400" }}>
          A generative model to transfer the style from one image to the content of another.
          </span>
          <span
            style={{ fontSize: "16px", fontWeight: "400", marginRight: "20px" }}
            className={
              temp1 !== "" && temp2 !== "" ? "refreshText" : "refreshinactive"
            }
            disabled={temp1 !== "" && temp2 !== "" ? false : true}
            onClick={refresh}
          >
            REFRESH
          </span>
        </p>

        {!dataview ? null : (
      <ModelDataset data={details} closeVeiw={closeDataview} selectVeiw={selectData}></ModelDataset>
      )}

        <div className="col-sm-8">
          <div className="styleTransfercard">
            <div className="row justify-content-around ">
              <div className="col-sm-6">
                <div className="card-body">
                  <div
                    className="d-flex justify-content-between "
                    style={{ marginBottom: "20px" }}
                  >
                    <h4 className="card-title">
                      <span className="no">1</span>Style
                    </h4>
                  </div>

                  <div className="styleTransferdiv">
                    {temp1 === "" ? (
                      <div style={{ textAlign: "center" }} className="styleoption">
                      <div className="styleoptioninner">
                        <label htmlFor="fileImg">
                          <img alt="Upload" htmlFor="fileImg"
                            src={uploadpic} height="20px" width={"20px"} />
                        </label>&nbsp;&nbsp;
                        <img alt="Dataset" 
                            src={folderpic} height="20px" width={"20px"} onClick={() => {setDataview(!dataview);setDsCount(1)}}/>
                        <input
                          type="file"
                          id="fileImg"
                          name="dataUpload"
                          onChange={onFileChange1}
                          style={{ display: "none" }}
                        /></div>
                        <p className="mt-1" style={{ color: "#C7CCB2" }}>
                          upload image
                        </p>
                      </div>
                    ) : (
                      <div className="main_content" onMouseEnter={e => showButton1(e)}
                        onMouseLeave={e => hideButton1(e)}>
                        <img className="imgcard1" 
                          src={temp1}
                          alt="image1"
                          width="100%"
                          height="280px"

                        />

                        <div style={{ textAlign: "center" }} className={display1} onMouseEnter={e => showImg(e)}
                          onMouseLeave={e => hideImg(e)}>
                          <img alt="delete" onClick={delete1}
                            src={hover ? deletepicy : deletepic} height="20px" width={"20px"} />
                          <p className="mt-1" style={{ color: hover ? "#E5FF80" : "#C7CCB2" }}>
                            delete
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card-body">
                  <div
                    className="d-flex justify-content-between "
                    style={{ marginBottom: "20px" }}
                  >
                    <h4 className="card-title">
                      <span className="no">2</span>Content
                    </h4>
                  </div>
                  <div className="styleTransferdiv">
                    {temp2 === "" ? (

                      <div style={{ textAlign: "center" }} className="styleoption">
                      <div className="styleoptioninner">

                        <label htmlFor="fileImg1">
                          <img alt="Upload"
                            src={uploadpic} height="20px" width={"20px"} />
                        </label>
                        &nbsp;&nbsp;
                        <img alt="Dataset" 
                            src={folderpic} height="20px" width={"20px"} onClick={() => {setDataview(!dataview);setDsCount(2)}}/>
                
                        <input
                          type="file"
                          id="fileImg1"
                          name="dataUpload"
                          onChange={onFileChange2}
                          style={{ display: "none" }}
                        /></div>
                        <p className="mt-1" style={{ color: "#C7CCB2" }}>
                          upload image
                        </p>
                      </div>
                    ) : (
                      <div className="main_content" onMouseEnter={e => showButton2(e)}
                        onMouseLeave={e => hideButton2(e)}>
                        <img className="imgcard2" 
                          src={temp2}
                          alt="image1"
                          width="100%"
                          height="280px"

                        />

                        <div style={{ textAlign: "center" }} className={display2} onMouseEnter={e => showImg(e)}
                          onMouseLeave={e => hideImg(e)}>
                          <img alt="delete" onClick={delete2}
                            src={hover ? deletepicy : deletepic} height="20px" width={"20px"} />
                          <p className="mt-1" style={{ color: hover ? "#E5FF80" : "#C7CCB2" }}>
                            delete
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <center>
              {" "}
              <button
                type="button"
                onClick={dataUpload}
                className={
                  temp1 !== "" && temp2 !== ""
                    ? "blkactiveStylebtn"
                    : "blkStylebtn "
                }
                disabled={temp1 !== "" && temp2 !== "" ? false : true}
              >
                TRANSFER STYLE {">>"}
              </button>
            </center>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="styleTransfercard">
            <div className="card-body">
              <div
                className="d-flex justify-content-between "
                style={{ marginBottom: "20px" }}
              >
                <h4 className="card-title">
                  <span className="no">3</span>Output
                </h4>
              </div>
              <div className="styleTransferdiv">
                {loading === true && (
                  <div className="styleoption" style={{ textAlign: "center" }}>
                    <div
                      className="spinner-border"
                      style={{ width: "3rem", height: "3rem" }}
                      role="status"
                    ></div>
                  </div>
                )}
                {loading === false && (
                  <>
                      {base64File3 !== "" ? (
                        <div className="main_content" onMouseEnter={e => showButton3(e)}
                        onMouseLeave={e => hideButton3(e)}>
                        <img
                          src={`data:image/jpeg;base64,${base64File3}`}
                          alt="..."
                          width="100%"
                          height="280px"
                          className="imgcard3" 
                        />


                             <div style={{ textAlign: "center" }} className={display3} onMouseEnter={e => showImg(e)}
                          onMouseLeave={e => hideImg(e)}>

                          <img alt="download"
                            src={hover ? downloadpicy : downloadpic} height="20px" width={"20px"} onClick={downloadTxtFile} disabled={base64File3 !== "" ? false : true} />
                          <p className="mt-1" style={{ color: hover ? "#E5FF80" : "#C7CCB2" }}>
                            download
                          </p>
                        </div>
</div>
                      ) : (
                        <div style={{ textAlign: "center" }} className="styleoption">
                        <label >
                          <img alt="download" 
                            src={downloadpic} height="20px" width={"20px"} />
                        </label>
                       
                        <p className="mt-1" style={{ color: "#C7CCB2" }}>
                          download Image
                        </p>
                      </div>



                        

                      )}
                  

                  </>
                )}

                {/* <img src={`data:image/jpeg;base64,${base64File3}`} alt="" width="90%"
 height="250px"/> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Stylemodel;
