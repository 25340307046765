import React from "react";

const OutputDetails = ({ outputDetails }) => {
  return (
    <div className="row text-center">
      <p className="col text-sm">
        Status:{" "}
        <span className="font-semibold">
          {outputDetails?.status?.description}
        </span>
      </p>
      <p className="col text-sm">
        Memory:{" "}
        <span className="font-semibold">
          {outputDetails?.memory} kb
        </span>
      </p>
      <p className="col text-sm">
        Time:{" "}
        <span className="font-semibold">
          {outputDetails?.time}
        </span>
      </p>
    </div>
  );
};

export default OutputDetails;
