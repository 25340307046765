import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Navigate, useLocation } from 'react-router-dom';
import { isAuth, logger } from '../../Storage(Methods)/storage';
import services from '../../API_SERVICES/services';
import { joinProject } from "../../Components/utility";
import { LoadingComponent } from '../../Components/Loaders';

const LoginFormView = (props) => {
  const [userVal, setUserVal] = useState({
    email: '',
    password: '',
  });
  const [isLoading, setIsLoading] = React.useState(false);
  let location = useLocation();

  const { email, password } = userVal;
  const handleChange = (e) => {
    setUserVal({ ...userVal, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    setIsLoading(true)
    e.preventDefault();
    const apiname = 'authentication/signin'
    const data = {
      email: email,
      password: password
    }
    services.post(apiname, data).then((response) => {
      if (response.data.message === 'Logged in Successfully') {
        const eid = email;
        if (location?.state?.source === "invite" && eid.toLowerCase() === location?.state?.data.emailId.toLowerCase()) {
          joinProject(location?.state?.data.emailId, location?.state?.data?.projId, response.data.user.id, response.data.user.username);
          setTimeout(function () {
            logger(response, () => {
              setUserVal({ ...userVal, email: '', password: '' });
              setIsLoading(false);
            });
          }, 4000);
        }
        else {
          logger(response, () => {
            setUserVal({ ...userVal, email: '', password: '' });
            setIsLoading(false);
          });
        }
      }
    }).catch((error) => {
      setUserVal({ ...userVal, email: '', password: '' });
      setIsLoading(false)
      toast.error(error.response.data.error);
    });
  };

  const handleForgotPassword = (val) => () => {
    props.setShow(val);
  };

  return (
    <>
      {isAuth() ? location?.state?.source === "invite" ? <Navigate to='/UserAddNewProject' replace={true} /> : <Navigate to='/projectsetup' replace={true} /> : null}
      <div className='content1'>
        <form className='formView' style={{ marginTop: '40px' }}>
          <label
            className='w-25 align-self-center'
            style={{ fontSize: '13px' }}>
            EMAIL
          </label>
          <input
            type='email'
            className='inputmake'
            placeholder='Enter your email'
            autoComplete='off'
            name='email'
            value={email}
            onChange={handleChange}

          />
          <label
            className='w-25 align-self-center'
            style={{ fontSize: '13px' }}>
            PASSWORD
          </label>
          <input
            type='password'
            className='inputmake'
            placeholder='Enter your password'
            name='password'
            value={password}
            onChange={handleChange}
            autoComplete='off'
          />
           <div className="forgotButtonStyle">
            <p className="forgotText">
              <span
                onClick={handleForgotPassword("passwordView")}
                style={{ cursor: "pointer" }}
              >
                FORGOT PASSWORD?
              </span>
            </p>
          </div>
        </form>
        <button
          className='makebutn'
          onClick={handleSubmit}
          style={{ fontSize: '20px' }}>
          {isLoading ?
            <LoadingComponent isLoading={isLoading} />
            :
            <>
              <i className='fas fa-long-arrow-right'></i>
              <br />
              Log In
            </>
          }
        </button>
      </div>
    </>
  );
};

export default LoginFormView;
