import React,{useState} from "react";
import { toast } from 'react-hot-toast';
import services from "../../API_SERVICES/services";
const ForgetPasswordView = () => {
  const [email, setEmail] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  const apiname = '/authenticate/forgot-password'
  const data = {email:email}
    services.post(apiname, data)
      .then((response) => {
        setEmail('');
        toast.success(response.data.message);
      })
      .catch((error) => {
        toast.error(error.response.data.error);
      });
  };

  return (
    <>
      <div className="content1">
        <form className="formView" style={{ marginTop: "40px" }}>
          <div className="textContainer">
            <p className="forgotViewHeadingText">FORGET YOUR PASSWORD?</p>
            <p className="forgotViewText">
              PLEASE TYPE THE EMAIL YOU USED TO SET-UP AN ACCOUNT, AND A RESET
              LINK WILL BE SENT TO YOUR EMAIL.
            </p>
          </div>
          <label className="inputLabelText">EMAIL</label>
          <input
            type="email"
            className="inputmake"
            placeholder="Enter an email"
            name="email"
            value={email}
            onChange={handleChange}
            autoComplete="off"
          />
        </form>
        <button
          className="forgetPasswordButtonView"
          onClick={handleSubmit}
          style={{ fontSize: "20px" }}
        >
          <i className="fas fa-long-arrow-right"></i>
          <br />
          SUBMIT
        </button>
      </div>
    </>
  );
};
export default ForgetPasswordView;
