import React from 'react'

export const TablePopupModal = ({ children }) => {
  return (
    <div className="Tableoutercontainer">
    <div className="TablegridFourth">
      <div className="gridcontent">{children}</div>
    </div>
  </div>
  );
};

