import React from "react";
import { classnames } from "../../utils/general";
import coPilot from "../../Assets/Images/coPilot.png";
import { languageOptions } from "../../constants/languageOptions";

const CustomInput = ({ customInput, setCustomInput, selectedLanguage, writeCode, addComments, isWriting, isCommenting }) => {

  // Select language 
  const handleChange = (e) => {
    let parseData = JSON.parse(e.target.value)
    // setFilterParam(e.target.value);
    selectedLanguage(parseData);
  };

  return (
    <>
      <h1 className="font-bold text-xl bg-clip-text text-transparent bg-gradient-to-r from-slate-900 to-slate-700 mb-2">
        Input
      </h1>
      <div className="codeCompilerInputSection">
        <div className="coPilotDiv">
          <div style={{ display: "flex", alignItems: "center", flexDirection: "row", background: "#D9DBCB", padding: "10px" }}>
            <img alt="edit" src={coPilot} height="30px" />
            <h3 className="text-xl bg-clip-text text-transparent bg-gradient-to-r from-slate-900 to-slate-700 mb-0 ms-3">
              CO-PILOT
            </h3>
          </div>
          <div style={{ padding: "5px", background: "#D9DBCB" }}>
            <p>Select an action</p>
            <div className="row">
              <div className="col-4">
                <button
                  onClick={writeCode}
                  // disabled={!code}
                  className={classnames("coPilotButtons")}
                >
                  {isWriting ? "Writing Code..." : "Write Code"}
                </button>
              </div>
              <div className="col-8">
                <button
                  onClick={addComments}
                  disabled={false}
                  className={classnames("coPilotButtons")}
                >
                  {isCommenting ? "Adding Comments..." : "Add Comments to the code"}
                </button>
              </div>
            </div>
          </div>
          <div style={{ padding: "5px", background: "#D9DBCB"}}>
            <p>Select a language (optional)</p>
            {/* <LanguagesDropdown onSelectChange={onSelectChange} /> */}
            <div className="custom-select">
              <select onChange={handleChange} name="Select Language">
                {/* <option key={0} value='' >Select Language</option> */}
                {languageOptions.map((option, index) => (
                  <option key={index} value={JSON.stringify(option)}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="customInputDiv">
          <textarea
            value={customInput}
            onChange={(e) => setCustomInput(e.target.value)}
            placeholder={`Custom prompt`}
            className={classnames("textEditorTextArea")}
          ></textarea>
        </div>
      </div>
    </>
  );
};

export default CustomInput;