import React from 'react'
import "./Trace.css"
import ellipse from "../../Assets/Images/Ellipse.png"
import Tree from 'react-d3-tree'
const Trace = ({closeTrace}) => {

    const [traceModalState, setTraceModalState] = React.useState({
        data: [],
        isDataNotFound: false,
        isApiCall: false
    });

    const orgChart = {
        "name": "UI-Testing",
        "children": [
          {
            "name": "Version 1: Data call title",
            "attributes": {
              "department": "Version-1"
            },
          },
          {
            "name": "Version 2: Demo two",
            "attributes": {
              "department": "Marketing"
            },
          
          }
        ]
      }
    // Create the Dynamic Tree Nodes
    const renderRectSvgNode = ({ nodeDatum, toggleNode }) => {
        const text = nodeDatum?.name?.length > 30 ? nodeDatum?.name.substring(0, 20) + "..." : nodeDatum?.name;
        const textWidth = text.length * 8;
        const padding = 5;
        const fixedX = -((textWidth + 3 * padding + 30) / 2);
        const yOffset = -20;
        const rectWidth = textWidth + padding + 40;
      
        return (
            <g onClick={toggleNode}>
                <rect
                    width={rectWidth}
                    height="30"
                    x={fixedX}
                    y={yOffset}
                    rx="15"
                    ry="15"
                    fill="rgb(229,255,128)"
                    stroke='none'
                />
                <image
                    href={ellipse}
                    x={fixedX + 5}
                    y={yOffset + 5}
                    width={padding + 20}
                    height="20"
                />
                <text fill="black" x={fixedX + 32} y={yOffset + 14} dy=".35em" stroke="none" fontWeight="normal">
                    {text}
                </text>
                {nodeDatum.__rd3t.depth > 0 && (
                <foreignObject x={fixedX - 25} y={yOffset + 4} width={20} height={20} style={{ cursor: 'pointer' }}>
                    <i className="fa fa-eye" title='Publish' aria-hidden="true"></i>
                </foreignObject>
           )}
            </g>
        );
    };
    return (
        <div className="modal-outer-container">
            <div className="center-div">
                <div className="traceModal-grid-joinContributor">
                    <div style={{ display: "flex" }}>
                        <div style={{ display: "flex", flexGrow: 1, justifyContent: "end" }}>
                            <span className="header-text" style={{color:"rgba(0, 0, 0, 0.54)"}}> TRACE </span>
                        </div>
                        <div style={{ flexGrow: 1, display: "flex", justifyContent: "end" }}>
                            <button type="button" className="close-button mr-2"
                           onClick={() => {
                            closeTrace(false);
                          }}
                            >
                                x
                            </button>
                        </div>
                    </div>
                    {/* <br></br> */}
                    <div className="center-container-box">
                        {traceModalState?.isDataNotFound ?
            <p className="m-4"> No collaborators found in this project. </p>
            :
            <div id="treeWrapper" className="treeWrapperClass" style={{ width: '100%', height: '100%'}}>
              <Tree
                data={orgChart}
                renderCustomNodeElement={renderRectSvgNode}
                orientation="vertical"
                translate={{ x: 400, y: 40 }}  // values for centering the tree
                scaleExtent={{ min: 0.8, max: 1.5 }}   // values for zooming
                zoomable={true}
                draggable={true}
                separation={{ siblings: 2, nonSiblings: 2 }}
                collapsible={true}
              />
            </div>
          }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Trace