import React, { useState, useEffect } from "react";
import {  useNavigate } from "react-router-dom";
// import eclippse from "../../Assets/Images/Ellipse.png";
// import sendimg from "../../Assets/Images/send.png";
import services from "../../API_SERVICES/services";
import { toast } from "react-hot-toast";
// import { PopupModal } from "../DataPrivacy/PopupModal";
import { isAuth, isId } from "../../Storage(Methods)/storage";

function ChatGPTTrain() {
  const navigate = useNavigate();
  useEffect(() => {
    var projid = isId();
    if (projid !== -1) {
      const apiname1 = "fetch-project-id";
      services
        .getbasic(apiname1, projid)
        .then((response) => {
          if (response === null || response.data == null) {
          }
          else {
            setProjectName(response.data.project_title);
          }
        }).catch((error) => {
        });
    }
  }, []);
  const [file, setFile] = useState("");
  const [tune, setTune] = useState(false);
  // const [demo, setDemo] = useState(false);
  // const [prompt, setPrompt] = useState("");
  // const [chats, setChats] = useState([])
  const [screensave, setScreensave] = useState(false)
  // const location = useLocation();
  const [projectName, setProjectName] = useState("")
  const [loading, setLoading] = useState(false);

  const onFileChange = (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
  };


  const getBase64 = (file) => {
    return new Promise(async (resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      //reader.readAsDataURL(file);
      reader.readAsText(file, "UTF-8");
      reader.onload = () => {
        baseURL = reader.result;
        const encoded = btoa(encodeURIComponent(baseURL));
        resolve(encoded);
      };
    });
  };
  const finetune = async () => {
    const result1 = await getBase64(file);
    const apiname = "train-chatmodel";
    var projid = isId();

    const data = {
      'file': result1,
      'filename': file.name,
      'projectid': projid,
    }
    setLoading(true);
    services
      .post(apiname, data)
      .then((response) => {
        setTune(true);
        setLoading(false);

        const savedata = {
          'pId': projid,
          'name': projectName,
          'user_id': isAuth().id,
          'modeltype': 'chatgpt',
          'filepath': ''
        }

        services
          .post('save-chatmodel', savedata)
          .then((response1) => {
          })
          .catch((error1) => {
            console.log(error1);
            toast.error("Error in saving model");
            setTune(false);

          });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error in training model");
        setTune(false);

      });
  }

  // const handledemo = () => {
  //   //setTune(false);
  //   // setDemo(true);

  // }
  const handlesave = () => {
    setScreensave(false);
  }


  const handleDrop = (event) => {
    event.preventDefault();

    const { files } = event.dataTransfer;
    if (files.length > 0) {
      setFile(event.dataTransfer.files[0]);

    }
  };

  const handleDragOver = (event) => {
    event.preventDefault()

  }
  const handleDragEnter = e => {
    e.preventDefault();
  };
  const handleDragLeave = e => {
    e.preventDefault();
  };

  // const handleChange = (e) => {
  //   setPrompt(e.target.value);
  // }
  // const handlepromptaction = () => {

  //   const apiname = "getPrompt";
  //   var projid = isId();

  //   const data = {
  //     'input': prompt,
  //     'projectid': projid
  //   }
  //   console.log(data);
  //   services
  //     .post(apiname, data)
  //     .then((response) => {
  //       setTune(true);
  //       console.log(response);
  //     })
  //     .catch((error) => {
  //       toast.error("Error in training model");
  //       setTune(false);

  //     });

  //   let newFormValues = [...chats];
  //   let i = chats.length;
  //   let obj = {
  //     "q": prompt,
  //     "ans": "dssssssssssssssssssssssssssssssssssssssssssssssssssssssss"
  //   }
  //   newFormValues[i] = obj;


  //   setChats(newFormValues);
  //   setPrompt("");
  // }


  return (
    <>
      {screensave === false ? null : (
        <div className="outercontainer" style={{ height: "100vh", marginTop: "500px" }}>
          <div className="gridFourth" style={{ height: "100vh", marginTop: "50px" }}>
            <div className="gridcontent">
              <div style={{ textAlign: "center" }}>
                <h4>Save Model?</h4>
                <p style={{ fontSize: "16px", fontWeight: 'lighter', color: "#0000008A" }}>Save model to project.</p>
                <br></br>
                <p style={{ fontSize: "24px", fontWeight: 'lighter', color: "#0000008A" }}>{projectName}</p>
                <br></br>
              </div>
              <div className='d-flex justify-content-between'>
                <button
                  type='button'
                  className='backbtndelete'
                  onClick={() =>
                    setScreensave(!screensave)
                  }>
                  BACK
                </button>
                <button
                  type='button'
                  className='deletebtn'
                  onClick={handlesave}>
                  SAVE
                </button>
              </div>
            </div>  </div>  </div>
      )}
      <div>
        <p className="chatgptcontainer-header">{projectName}/MODELS/ GPT-3.5-TURBO/ Fine-Tune</p>
        {/* {!demo ? */}
        <div className="chatgptcontainer">

          <p style={{ fontSize: "24px", color: " #0000008A", marginTop: "20px" }}>GPT-3.5-TURBO ChatGPT</p>
          <p style={{ fontSize: "24px", color: " #0000008A" }}>Fine-Tune</p>
          {tune ? <>
            <p style={{ marginTop: "100px", fontSize: "16px", color: "#0000008A" }}>You’ve joined the queue! </p>
            <p style={{ fontSize: "16px", color: "#0000008A" }}>Estimated wait time can be around 3 hours. Please return later and check your project’s . </p>
            <p style={{ fontSize: "16px", color: "#0000008A" }}> sandbox for model progress. </p>

            <button className="chatupload" onClick={() => navigate('/modelspage', { replace: true })}>Close</button>
          </> :
            <>
              {file === '' ?
                <>
                  <div className="chatgptinner" onDrop={handleDrop} onDragOver={handleDragOver} onDragEnter={e => handleDragEnter(e)}
                    onDragLeave={e => handleDragLeave(e)}>
                    <div className="draggable-container">
                      <label htmlFor="filec">
                        <i className="fa-regular fa-plus fa-xl" style={{ color: "black", marginBottom: "10px" }}></i></label>

                      <input
                        type="file"
                        id="filec"
                        name="dataUpload"
                        onChange={onFileChange}
                        style={{ display: "none" }}
                      />


                    </div>
                    <p style={{ fontSize: "16px", color: "#0000008A" }}>Drop or choose a .JSONL File</p>

                    <div>
                      <p style={{ fontSize: "16px", color: "#00000040", marginBottom: "0.2rem", marginTop: "0.2rem" }}> {`{"prompt": "<prompt text>", "completion": "<ideal generated text>"}`}</p>
                      <p style={{ fontSize: "16px", color: "#00000040", marginBottom: "0.2rem", marginTop: "0.2rem" }}> {`{"prompt": "<prompt text>", "completion": "<ideal generated text>"}`}</p>
                      <p style={{ fontSize: "16px", color: "#00000040", marginBottom: "0.2rem", marginTop: "0.2rem" }}> {`{"prompt": "<prompt text>", "completion": "<ideal generated text>"}`}</p>
                      <p style={{ fontSize: "16px", color: "#00000040", marginBottom: "0.2rem", marginTop: "0.2rem", textAlign: "center" }}> {`...`}</p>

                    </div>
                  </div>
                  <button className="chatupload">Upload</button>
                </>
                :
                <>
                  <div className="chatgptfile">
                    {loading ? <><div>
                      <div
                        className="spinner-border"
                        style={{ width: "3rem", height: "3rem" }}
                        role="status"
                      ></div>
                    </div></> : <>{file.name}</>}


                  </div>
                  <button className="chatupload" onClick={finetune}>Fine Tune</button>
                </>
              }
            </>
          }
        </div>
        {/* :
          <>
            <p style={{ fontSize: "24px", color: " #0000008A", marginTop: "20px", textAlign: "center" }}>GPT-3.5-TURBO-1106 ChatGPT</p>
            <p style={{ fontSize: "24px", color: " #0000008A", textAlign: "center" }}>Fine-Tune</p>
            <div className="chatgptdemo">



              {chats.map((element, index) => (
                <div key={index} className="chatgptdemoinner">
                  <div className="chatqlabel">
                    <img alt="Upload" src={eclippse} height="20px" width={"20px"} style={{ marginLeft: "20px", marginRight: "20px" }} />
                    <p className="chatgptinput" name="q">{element.q || ""}</p>
                  </div>
                  <div className="chatanslabel" >
                    <p name="ans" className="chatgptinput">{element.ans || ""}</p>

                  </div>
                </div>
              ))}

            </div>
            <div className="chatgptwindow">
              <img alt="Upload" src={eclippse} height="20px" width={"20px"} />
              <input type="text" className="chatgptinput" placeholder="TYPE A PROMPT" value={prompt} onChange={e => handleChange(e)}></input>
              <img alt="Upload" src={sendimg} height="20px" width={"20px"} onClick={handlepromptaction} />
            </div>
            <div className="chatsavecnt">
              <button className="chatsave" onClick={() =>
                setScreensave(!screensave)
              } >Save</button>

            </div>
          </>
        } 
        */}
      </div>

    </>
  )
}

export default ChatGPTTrain
