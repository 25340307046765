import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import services from "../../API_SERVICES/services";
import { useMenuContext } from '../../Components/Navbar/menuContext';


const Datacontribute = () => {
  const { setShowAdditionalMenu, setActiveMenuItem } = useMenuContext();

  const [datacontribute, setDataContribute] = useState({
    projects: "",
    loading: false
  });

  const [query, setQuery] = useState("");
  const search_parameters = ["project_title", "project_desc"];
  function search(data) {
    return data.filter(
      (item) =>
        search_parameters.some((parameter) =>
          item[parameter].toString().toLowerCase().includes(query)
        )
    );
  }
  const spinCenter = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50% -50%)",
  };
  const imageStyle = {
    width: "100%",
    height: "145px",
    borderRadius: "10px",
  };
  const inputStyle = {
    width: "40%",
    borderRadius: "20px",
    textAlign: "center",
    backgroundColor: "#BCBFAA",
    color: "#202020",
    opacity: "70%",
    border: "0px solid black  ",
    marginLeft: "30px",
    height: "40px",
  };

 
  const Notfound = {
    fontWeight: "bold",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50% , -50%)",
  };
  useEffect(() => {
    setShowAdditionalMenu(true);
    setActiveMenuItem("PROJECTS");

    loadProjects();
    return () => {
      setShowAdditionalMenu(false);
      setActiveMenuItem(null);
    };
  }, []);
  const { projects, loading } = datacontribute;
  const loadProjects = () => {
    const apiname = "fetch-project-public";
    services
      .get(apiname)
      .then((response) => {
        setDataContribute({
          ...datacontribute,
          projects: response,
          loading: true
        });
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };
  return (
    <>

      {/* <div className="d-flex flex-start  w-100 gap-5 mx-3">
        <h1 style={{paddingTop:"10px",fontSize:"55px"}}>EXPLORE</h1>

      </div> */}
      <input
        type="search"
        placeholder="SEARCH PROJECTS"
        className="searchinput"
        style={inputStyle}
        onChange={(e) => setQuery(e.target.value)}
      ></input>

      {loading && projects?.data?.length > 0 ? (
        <div className="projectList my-5">
          {search(projects?.data)?.map((d) => (
            <Link key={d.pId + 1}
              className="projectLink"
              to={"/expand-project"}
              state={{ data: d, source: "data_contribution" }}
            >
              <div className="modelcardStyle" style={{height:"225px"}}>
                <div key={d.pId + 1}>
                  <h4 style={{ fontSize: "14px" }}>{d.project_title.length>20?d.project_title.substring(0,20)+"...":d.project_title}</h4>
                  <h4
                    style={{
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      fontSize: "14px",
                      textOverflow: "ellipsis",
                      height: '3vh'
                    }}
                  >
                    {d.project_desc}
                  </h4>

                  <img src={d.projectimage ? d.projectimage : "https://poietodata.s3.us-east-2.amazonaws.com/Data-contributions/Project-image/Radio_01-1684889256745.png"} style={imageStyle} alt="projimg" />
                </div>
              </div>
            </Link>
          ))}
        </div>
      ) : projects?.data?.length === 0 ? (
        <p className="text-center" style={Notfound}>
          <i className="fas fa-empty-set"></i> No Projects Found
        </p>
      ) : (
        <div style={spinCenter}>
          <div
            className="spinner-border"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          ></div>
        </div>
      )}
    </>
  );
};

export default Datacontribute;
