import React, {useState } from "react";
import Audience from "./Audience";
import CriticalQuestions from "./CriticalQuestions";
import Explore from "./Explore";
import { FramesNavBar } from "../Howdoesscreen/HeadarNavbar/FramesNavbar";

export const ProjectDecode = () => {
  const itemArray = [
    { name: "DISCOVER", value: "/decode" },

    { name: "FRAMES", value: "/CreateFrames" },

    { name: "BLUEPRINT", value: "/Howinputtab" },

    { name: "PROTOTYPE", value: "/modelspage" },

    { name: "REFLECTION", value: "/reflection" },
  ];
  const [show, setShow] = useState(1);
  return (
    <>
      <FramesNavBar labels={itemArray} isActive={"DISCOVER"} />
      <div className="justify-content-around mt-3">
        <button
          style={{ width: "33.3%", fontFamily: "IBM Plex Mono" }}
          className={show === 1 ? "active1" : "inactive" || "hover-text"}
          onClick={() => setShow(1)}
        >
          EXPLORE
        </button>
        <button
          style={{ width: "33.3%", fontFamily: "IBM Plex Mono" }}
          className={show === 2 ? "active1" : "inactive" || "hover-text"}
          onClick={() => setShow(2)}
        >
          AUDIENCE
        </button>
        <button
          style={{ width: "33.3%", fontFamily: "IBM Plex Mono" }}
          className={show === 3 ? "active1" : "inactive" || "hover-text"}
          onClick={() => setShow(3)}
        >
          CRITICAL QUESTIONS
        </button>
        {show === 1 ? <Explore setShow={setShow} /> : null}
        {show === 2 ? <Audience setShow={setShow} /> : null}
        {show === 3 ? <CriticalQuestions setShow={setShow} /> : null}
      </div>
    </>
  );
};
