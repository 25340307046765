import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { isAuth, isId } from "../../Storage(Methods)/storage";
import { PopupModal } from "../DataPrivacy/PopupModal";
import { FramesNavBar } from "../Howdoesscreen/HeadarNavbar/FramesNavbar";
import services from "../../API_SERVICES/services";
import Toggle from "../ToggleSwitch/toggleswitch";
import { useNavigate } from "react-router-dom";
const Subdescframe = () => {
  const navigate = useNavigate();
  const [frames, setframesVal] = useState({
    frame_name: "",
    frame_desc: "",
    frame_attr: "",
    screen3: false,
    screen4: false,
    toggle: false,
    userImageFile: "",
  });
  const {
    frame_name,
    frame_desc,
    frame_attr,
    screen3,
    screen4,
    toggle,
    userImageFile,
  } = frames;
  const itemArray = [
    { name: "DISCOVER", value: "/decode" },
    { name: "FRAMES", value: "/CreateFrames" },
    { name: "BLUEPRINT", value: "/Howinputtab" },
    { name: "PROTOTYPE", value: "/modelspage" },
    { name: "REFLECTION", value: "/reflection" },
  ];
  const [temp,setTemp]=useState("");
  const onFileChange = (event) => {

    if( event.target.files[0]?.size > 5242880)
    {
      toast.error('File size greater than 5 MB, Please upload a file with size less than 5 MB');
      setframesVal({ ...frames, userImageFile: '' });

    }
    else{
      setframesVal({ ...frames, userImageFile: event.target.files[0] });
      setTemp(URL.createObjectURL(event.target.files[0]));
      toast.success('File uploaded successfully');

    }

  };
  const handleChange = (e) => {
    setframesVal({ ...frames, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("frameUpload", userImageFile);
    formData.append("pId", isId());
    formData.append("user_id", isAuth().id);
    formData.append("frame_name", frame_name);
    formData.append("frame_desc", frame_desc);
    formData.append("frame_privacy", toggle ? "PUBLIC" : "PRIVATE");
    formData.append("frame_attr", frame_attr);
    const apiname = "poieto/create-frames";
    services
      .post(apiname, formData)
      .then((response) => {
        toast.success(response.data.message);
        setframesVal({
          ...frames,
          frame_name: "",
          frame_desc: "",
          frame_attr: "",
          toggle: false,
          userImageFile: "",
        });
        setTemp("");

        setTimeout(() => {
          navigate("/createFrames", { replace: true });
        }, 1000);
      })
      .catch((error) => {
        setframesVal({
          ...frames,
          frame_name: "",
          frame_desc: "",
          frame_attr: "",
          toggle: false,
          userImageFile: "",
        });
        setTemp("");
        toast.error(error.response.data.error);
      });
  };
  return (
    <>
      <FramesNavBar labels={itemArray} isActive={"FRAMES"} />
      {screen3 ? (
        <PopupModal>
          {" "}
          <h5 className="mt-4 mb-4">Upload Image</h5>
          <p>req:</p>
          <button
            type="button"
            className="savebtn"
            style={{ color: "#e5ff80" }}
            onClick={() => setframesVal({ ...frames, screen3: !screen3 })}
          >
            UPLOAD FROM COMPUTER
          </button>
        </PopupModal>
      ) : null}
      {screen4 ? (
        <PopupModal>
          {" "}
          <h5 className="mt-4 mb-4">ADD CONTRIBUTORS</h5>
          <p>from project collaborators:</p>
          <button
            type="button"
            className="savebtn"
            style={{ color: "#e5ff80" }}
            onClick={() => setframesVal({ ...frames, screen4: !screen4 })}
          >
            ADD
          </button>
        </PopupModal>
      ) : null}

      <div className="d-flex justify-content-between">
        <input
          className="plachetitle"
          name="frame_name"
          value={frame_name}
          onChange={handleChange}
          placeholder="Enter frame name"
          style={{ paddingLeft: "20px", paddingTop: "20px" }}
        ></input>
        <div className="d-flex mt-3">
          <p className="me-3 mt-1">{toggle ? "PUBLIC" : "PRIVATE"}</p>
          <Toggle
            onChange={(e) =>
              setframesVal({ ...frames, toggle: e.target.checked })
            }
            toggle={toggle}
          />
        </div>
      </div>

      <div className="gridmeshdesc">
        <div className="item1">
          <div className="d-flex justify-content-between">
            <p
              className="ms-2"
              style={{
                paddingLeft: "20px",
                fontSize: "30px",
                color: "#4E4E4E",
                fontWeight: "100",
              }}
            >
              FRAME DESCRIPTION
            </p>
          </div>
          <textarea
            rows="2"
            placeholder="TYPE HERE..."
            name="frame_desc"
            value={frame_desc}
            onChange={handleChange}
            style={{
              fontSize: "25px",
              paddingLeft: 25,
              width: "100%",
              border: "none",
              borderColor: "#C7CCB2",
              backgroundColor: "transparent",
              outline: "none",
            }}
          ></textarea>
        </div>
        <div className="item2">
          <div className="d-flex justify-content-between">
            <p
              className="ms-2"
              style={{
                paddingLeft: "20px",
                fontSize: "30px",
                color: "#4E4E4E",
                fontWeight: "100",
              }}
            >
              INSPIRATION AND ATTRIBUTES
            </p>
          </div>
          <textarea
            className="mt-2"
            rows="2"
            name="frame_attr"
            value={frame_attr}
            onChange={handleChange}
            placeholder="TYPE HERE..."
            style={{
              fontSize: "25px",
              paddingLeft: 25,
              width: "100%",
              border: "none",
              borderColor: "#C7CCB2",
              backgroundColor: "transparent",
              outline: "none",
              resize: "none",
            }}
          ></textarea>
        </div>
        <div className="item4">
          <div className="d-flex">
            <p
              className="ms-2"
              style={{
                paddingLeft: "20px",
                fontSize: "30px",
                color: "#4E4E4E",
                fontWeight: "100",
              }}
            >
              CONTRIBUTORS
            </p>
          </div>
          <div
            className="roundcont me-2"
            onClick={() => setframesVal({ ...frames, screen4: !screen4 })}
          >
            +
          </div>
        </div>
        <div className="item5" style={{ position: "relative" ,padding:"0px"}}>

        {temp === "" ? (<><label htmlFor="file" id="filelabel">
            <i
              className="fas fa-plus"
              style={{ backgroundColor: "transparent", fontSize: "15px" }}
            />
            <p>UPLOAD FRAME IMAGE</p>
          </label>

          <input
            type="file"
            id="file"
            name="frameUpload"
            onChange={onFileChange}
            style={{ display: "none", visibility: "none" }}
          /></>):
          (<>
            <div className="main_content" >
                        <img className="frameimg" 
                          src={temp}
                          alt="image1"
                          width="100%"
                          height="200px"

                        /></div>
          </>)}
          
        </div>
      </div>
      <div className="d-flex justify-content-between ms-3">
        <button type="submit" className="butndesc">
          <i
            className="fal fa-long-arrow-left mb-3"
            style={{ backgroundColor: "transparent", fontWeight: "500" }}
          />
          <br />
          BACK
        </button>
        <button
          type="submit"
          className="butndesc ms-3"
          style={{ backgroundColor: "#E5FF80" }}
          onClick={handleSubmit}
        >
          SAVE
        </button>
      </div>
    </>
  );
};
export default Subdescframe;
