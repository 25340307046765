import React, { useEffect, useState } from "react";
import CodeEditorWindow from "./CodeEditorWindow";
import axios from "axios";
import { classnames } from "../../utils/general";
import { toast } from 'react-hot-toast';
// import { defineTheme } from "../../lib/defineTheme";
import OutputWindow from "./OutputWindow";
import CustomInput from "./CustomInput";
import OutputDetails from "./OutputDetails";
import services from "../../API_SERVICES/services";
import { isId } from "../../Storage(Methods)/storage";
// import ThemeDropdown from "./ThemeDropdown";
import FileService from "../../Service/DownloadFile";

// const javascriptDefault = `
// def pypart(n):
//     myList = []
//     for i in range(1,n+1):
//         myList.append("*"*i)
//     print("\n".join(myList))

// # Driver Code
// n = 5
// pypart(n)`;


const Compiler = () => {
  const [code, setCode] = useState("# Welcome to Poieto Text Editor.");
  const [customInput, setCustomInput] = useState("");
  // const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
  const screenHeight = window.innerHeight
  const [outputDetails, setOutputDetails] = useState(null);
  const [processing, setProcessing] = useState({
    compileProcessing: false,
    generateCodeProcessing: false,
    generateCommentsProcessing: false,
    codeProcessing: false,
    fileDownloading: false
  });
  const [language, setLanguage] = useState({
    id: 71,
    name: "Python (3.8.1)",
    label: "Python (3.8.1)",
    value: "python",
    ext: "py"
  });

  useEffect(() => {
    fetchCode();
  }, []);

  const fetchCode = () => {
    const apiName = "get-code";
    const project = true;
    services.get(apiName, project)
      .then((response) => {
        setCode(response?.data?.code);
      })
      .catch((error) => {
      });
  };


  const onChange = (action, data) => {
    switch (action) {
      case "code": {
        setCode(data);
        break;
      }
      default: {
        console.warn("case not handled!", action, data);
      }
    }
  };

  const handleCompile = () => {
    setProcessing({ ...processing, compileProcessing: true });
    const formData = {
      language_id: language.id,
      // encode source code in base64
      source_code: btoa(code),
      stdin: btoa(customInput),
    };
    const options = {
      method: "POST",
      url: process.env.REACT_APP_RAPID_API_URL,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "content-type": "application/json",
        "Content-Type": "application/json",
        "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
        "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
      },
      data: formData,
    };

    axios
      .request(options)
      .then(function (response) {
        const token = response.data.token;
        checkStatus(token);
      })
      .catch((err) => {
        // let error = err.response ? err.response.data : err;
        // get error status
        let status = err.response.status;
        if (status === 429) {
          toast.error(
            `Quota of 100 requests exceeded for the Day! Please read the blog on freeCodeCamp to learn how to setup your own RAPID API Judge0!`,
            10000
          );
        }
        setProcessing(false);
      });
  };

  const checkStatus = async (token) => {
    const options = {
      method: "GET",
      url: process.env.REACT_APP_RAPID_API_URL + "/" + token,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
        "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
      },
    };
    try {
      let response = await axios.request(options);
      let statusId = response.data.status?.id;

      // Processed - we have a result
      if (statusId === 1 || statusId === 2) {
        // still processing
        setTimeout(() => {
          checkStatus(token);
        }, 2000);
        return;
      } else {
        setProcessing({ ...processing, compileProcessing: false });
        setOutputDetails(response.data);
        showSuccessToast(`Compiled Successfully!`);
        return;
      }
    } catch (err) {
      setProcessing({ ...processing, compileProcessing: false });
      showErrorToast();
    }
  };

  const showSuccessToast = (msg) => {
    toast.success(msg || `Compiled Successfully!`);
  };
  const showErrorToast = (msg, timer) => {
    toast.error(msg || `Compiled Successfully!`);
  };

  const handleCode = () => {
    if (!customInput) {
      toast.error(`Custom input can't be blank.`);
      return false;
    }
    setProcessing({ ...processing, generateCodeProcessing: true });
    const apiName = "generate-code";
    const data = {
      data:
      {
        prompt: customInput + `in ${language.name} and return only code.`// Use input from custom input box
      }
    }
      ;
    services.post(apiName, data)
      .then((response) => {
        setCode(response?.data?.output);
        setProcessing({ ...processing, generateCodeProcessing: false });
      })
      .catch((error) => {
        setProcessing({ ...processing, generateCodeProcessing: false });
      });
  }

  const handleSaveCode = () => {
    setProcessing({ ...processing, codeProcessing: true });
    const apiName = "save-code";
    const data = {
      data:
      {
        pId: isId(),
        code: code
      }
    };
    services.post(apiName, data)
      .then((response) => {
        setProcessing({ ...processing, codeProcessing: false });
        toast.success("Code have been saved successfully.")
      })
      .catch((error) => {
        setProcessing({ ...processing, codeProcessing: false });
      });
  }
  const handleComments = () => {
    setProcessing({ ...processing, generateCommentsProcessing: true });
    const apiName = "generate-comments";
    const data = {
      data:
      {
        prompt: code // Use input from Text Editor.
      }
    }
      ;
    services.post(apiName, data)
      .then((response) => {
        setProcessing({ ...processing, generateCommentsProcessing: false });
        setCode(response?.data?.output);
      })
      .catch((error) => {
        setProcessing({ ...processing, generateCommentsProcessing: false });
      });
  }

  // Download the file
  const handleDownloadFile = () => {
    setProcessing({ ...processing, fileDownloading: true });  
    FileService.downloadFileWithCustomExtension(code, "codeFile", language?.ext);
    setProcessing({ ...processing, fileDownloading: false });
  }

  return (
    <>
      <div style={{ height: screenHeight/0.7, display: "flex" }}>
        <div style={{ width: "65%", padding: "10px" ,zIndex:"-1"}}>
          <CodeEditorWindow
            code={code ? code : "# Welcome to Poieto Text Editor."}
            onChange={onChange}
            language={language?.value}
            theme={"vs-dark"}
          />
          <button
            onClick={handleCompile}
            disabled={!code}
            className={classnames("editorButtons")}
          >
            {processing.compileProcessing ? "Processing..." : "Compile"}
          </button>
        </div>
        <div style={{ flexGrow: 4, flexDirection: "column" }}>
          <div style={{ height: "54.3%", padding: "10px" }}>
            <CustomInput
              customInput={customInput}
              setCustomInput={setCustomInput}
              selectedLanguage={(val) => { setLanguage(val) }}
              writeCode={handleCode}
              addComments={handleComments}
              isWriting={processing.generateCodeProcessing}
              isCommenting={processing.generateCommentsProcessing}
            />
          </div>
          <div style={{ height: "44.3%", padding: "10px"}}>
            <OutputWindow outputDetails={outputDetails} />
            {outputDetails && <OutputDetails outputDetails={outputDetails} />}
          </div>
          <div className="row mx-1">
            <div className="col text-center">
              <button
                onClick={handleSaveCode}
                disabled={!code}
                className={classnames("editorButtons")}
              >
                {processing.codeProcessing ? "Saving..." : "Save"}
              </button>
            </div>
            <div className="col text-center">
              <button
                onClick={handleDownloadFile}
                disabled={!code}
                className={classnames("editorButtons")}
              >
                {processing.fileDownloading ? "Downloading..." : "Download"}
              </button>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};
export default Compiler;