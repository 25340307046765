import React, { useEffect, useState } from "react";
import { isId, isAuth } from "../../Storage(Methods)/storage";
import { toast } from "react-hot-toast";
import services from "../../API_SERVICES/services";

export default function Audience(props) {
  const [userqs, setuserqs] = useState({
    audq1: "",
    audq2: "",
  });
  const handleChange = (e) => {
    setuserqs({ ...userqs, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    LoadQuestions();
  }, []);
  const { audq1, audq2 } = userqs;
  const LoadQuestions = () => {
    const apiname = "get-question"
    const project = true
    services.get(apiname, project)
      .then((response) => {
        const { audq1, audq2 } = response.data
        setuserqs({ ...userqs, audq1, audq2 })
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if ((audq1 != null && audq1 !== '')||(audq2 != null  && audq2 !== '') ) {
      const apiname = "poieto/aud-query"
      const data = {
        audq1: audq1,
        audq2: audq2,
        pId: isId(),
        id: isAuth().id

      }
      services.post(apiname, data)
        .then((response) => {
          toast.success(response.data.message);
          LoadQuestions();
          props.setShow(3);
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });
    }
    else {
      props.setShow(3);

    }

  };
  return (
    <>
      <div className="container-fluid mt-3">
        <h3 style={{ color: "#4E4E4E", fontWeight: "normal" }}>
          WHO (OR WHAT) IS GOING TO USE THIS PROJECT?
        </h3>

        <p
          style={{
            margin: "0px",
            color: "grey",
            fontSize: "12px",
            fontWeight: "600",
          }}
        >
          EXAMPLE, THIS PROJECTS IS TO HELP DOCTORS WILL USE THIS TO PREDICT
          MORTALITY RATES
        </p>
        <p
          style={{
            margin: "0px",
            color: "grey",
            fontSize: "12px",
            fontWeight: "600",
          }}
        >
          IN A MATERNITY WARD. +OR+ THIS ARTWORK IS TO BE VIEWED BY ANYONE
          INTERESTED IN
        </p>
        <p
          style={{
            color: "grey",
            fontSize: "12px",
            fontWeight: "600",
          }}
        >
          COMPUTATIONAL ART CATS
        </p>

        <textarea
          className="mt-2"
          rows="3"
          placeholder={!audq1 ? "Type Here..." : null}
          name="audq1"
          value={audq1}
          onChange={handleChange}
          style={{
            fontSize: "25px",
            paddingLeft: 10,
            width: "100%",
            borderRadius: 15,
            backgroundColor: "#C7CCB2",
          }}
        ></textarea>
        <h3
          className="mt-5"
          style={{ margin: "0px", color: "#4E4E4E", fontWeight: "normal" }}
        >
          HOW WILL THE PEOPLE (OR SYSTEM) YOU ARE MAKING THIS FOR
        </h3>
        <h3 style={{ color: "#4E4E4E", fontWeight: "normal" }}>
          GOING TO USE THIS TECHNOLOGY?
        </h3>
        <p
          style={{
            margin: "0px",
            color: "grey",
            fontSize: "12px",
            fontWeight: "600",
          }}
        >
          TRY TO CRITICALLY THINK ON THE ROLE AND BEHAVIOR OF THE END USER; HOW
          ARE THEY
        </p>
        <p style={{ color: "grey", fontSize: "12px", fontWeight: "600" }}>
          GOING TO USE THIS AI.
        </p>
        <textarea
          className="mt-2"
          rows="3"
          name="audq2"
          value={audq2}
          onChange={handleChange}
          placeholder={!audq2 ? "Type Here..." : null}
          style={{
            fontSize: "25px",
            paddingLeft: 10,
            width: "100%",
            borderRadius: 15,
            backgroundColor: "#C7CCB2",
          }}
        ></textarea>

        <button
          style={{
            marginBottom: 10,
            width: "50%",
            height: 125,
            backgroundColor: "#FFF",
            borderRadius: 10,
            border: "none",
            fontSize: "23px",
          }}
          onClick={() => props.setShow(1)}
          type="button"
          className="btn-lg btn-block mt-4"
        >
          <i className="fas fa-arrow-left" />
          <br />
          BACK
        </button>

        <button
          style={{
            marginBottom: 10,
            width: "48.5%",
            height: 125,
            backgroundColor: "#E5FF80",
            borderRadius: 10,
            border: "none",
            fontSize: "23px",
          }}
          onClick={handleSubmit}
          type="button"
          className="btn-lg btn-block mt-4 ms-3"
        >
          <i className="fas fa-arrow-right" />
          <br />
          NEXT
        </button>
      </div>
    </>
  );
}
