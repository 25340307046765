import React from 'react'
import {Navigate} from "react-router-dom";
export const SecuredRoute = ({children}) => {
  return (

      JSON.parse(localStorage.getItem('status')) === true ? (
       children
      ) : (
        <Navigate
          to="/" 
          replace={true}
        />
      ))}