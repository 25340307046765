import React from "react";
import { useNavigate } from "react-router-dom";
import Mobile from "../../Assets/Images/mobile.png"
import { FramesNavBar } from "../Howdoesscreen/HeadarNavbar/FramesNavbar";
export const Mobilenethome = () => {
  const navigate = useNavigate();
  const itemArray = [
    { name: "DISCOVER", value: "/decode" },
    { name: "FRAMES", value: "/CreateFrames" },
    { name: "BLUEPRINT", value: "/Howinputtab" },
    { name: "PROTOTYPE", value: "/modelspage" },
    { name: "REFLECTION", value: "/reflection" },
  ];
  return (
    <>
      <FramesNavBar labels={itemArray} isActive={"PROTOTYPE"} />
      <div className="row">
        <div className="col-8">
            <div className="ms-3 mt-4">
                <h1>IMAGE CLASSIFICATION DEMO</h1>
            </div>
            <div className="ms-3">
                <p className="">
                <h8>
                    MODELS / ML5_IMAGE_CLASSIFICATION
                </h8>
                <br></br><br></br>
                <img
                    src={Mobile}
                    width="100"
                    height="100"
                    alt="logo"
                />
                <img
                    className="ms-4"
                    src={Mobile}
                    width="100"
                    height="100"
                    alt="logo"
                />
                <img
                    className="ms-4"
                    src={Mobile}
                    width="100"
                    height="100"
                    alt="logo"
                />
                <br></br><br></br>
                <h8>About:</h8>
                <br></br>
                </p>
            </div>
            <div className="ms-3 me-5 pe-5">
                <p className="">
                <h6 className="me-5 pe-5">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                </h6>
                </p>
            </div>
        </div>
        <div className="col-4 pt-5">
            <div className="">
                <div className="container-btn-mobile modellboxx text-center">
                    <h3 style={{lineHeight: "15vh", verticalAlign:"middle"}}>DEMO</h3>
                </div>
            </div>
            <div className="mt-5">
                <div className="container-btn-mobile modellboxx text-center" onClick={() => navigate('/Mobilenetmodel',{replace:true})}>
                    <h3 style={{lineHeight: "15vh", verticalAlign:"middle"}}>RETRAIN</h3>
                </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default Mobilenethome;
