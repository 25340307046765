import React, { useEffect, useState } from "react";
import { NavLink,useLocation} from 'react-router-dom';
import moment from "moment";
import services from "../../API_SERVICES/services";
import adminpic from "../../Assets/Images/admin.png";



const UserProfileContributor = () => {
  let location = useLocation();
  const [values, setValues] = useState({
    profileimage: "",
    user_bio: "",
    user_location: "",
    user_occupation: "",
  });

  const [projects, setProjects] = useState("");


  const loadProjects = () => {
    const apiname = "fetch-project-public-id";
    services
      .getbasic(apiname, location?.state?.profileid)
      .then((response) => {
        setProjects(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };




  const loadProfile = () => {
    const apiname = "user/get-profile-data";
    services
    .getbasic(apiname, location?.state?.profileid)
      .then((response) => {
        const { profileimage, user_bio, user_location, user_occupation } =
          response.data;
          setValues({
            ...values,
            profileimage,
            user_bio,
            user_location,
            user_occupation,
          });
      

      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    loadProfile();
    loadProjects();
  }, []);
  const {
    profileimage,
    user_occupation,
    user_location,
    user_bio,
  } = values;
  return (
    <>
      <div className="mainContainer">
        <div className="handingRowView">
          <div className="leftHeadingView">
            <h2 className="handingViewText">PROFILE</h2>
          </div>
          <div className="rightHeadingView">
            <h2 className="handingViewText">PROJECTS</h2>
          </div>
        </div>
     
   
        <div className="contentContainer" >
          <div className="leftContentContainer">
            <div className="userDetailsView">
              {!profileimage ? (
                <div className="imageBlankView"></div>
              ) : (
                <img
                  className="imageView"
                  src={profileimage}
                  alt="profileimage"
                />
              )}
              <div className="userInfoContentRow">
                <h4
                  style={{
                    fontWeight: 100,
                    color: "gray",
                    marginBottom: 0,
                  }}
                >
                  {location?.state?.profileName}
                </h4>
                {user_occupation === "" ? (
                  <p></p>
                ) : (
                  <p
                    style={{
                      color: "gray",
                      fontSize: 12,
                      marginBottom: 0,
                    }}
                  >
                    {user_occupation} / {user_location}
                  </p>
                )}
                {user_bio === "" ? (
                  <p></p>
                ) : (
                  <p
                    style={{
                      color: "gray",
                      fontSize: 12,
                      marginTop: 10,
                    }}
                  >
                    {user_bio}
                  </p>
                )}
              </div>
            </div>
       
          </div>
          <div className="rightContentContainer">
        
            {projects?.data?.length === 0 ? (
              <div className="projectDetailsView">
                <h4 className="text-center">
                  {" "}
                  <i
                    className="fas fa-empty-set"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  No Projects{" "}
                </h4>
              </div>
            ) : (
              <>
                {projects?.data?.map(
                  (info) => (
                    (
                      <div className="projectDetailsView" key={info.pId}>
                        <div className="projectDetailRow">
                          <div className="d-flex justify-content-between">
                            <h4
                              style={{
                                fontWeight: 100,
                                color: "gray",
                              }}
                            >
                              {info.project_title}
                            </h4>
                            <div className="d-flex">
                              {values?.projMap?.get(info?.pId) === 'Y' ? <img alt="download" src={adminpic} height="16px" /> : ''}

                            </div>
                          </div>
                          <p
                            style={{
                              color: "gray",
                              fontSize: 12,
                              marginBottom: 5,
                            }}
                          >
                            LAST UPDATED:{" "}
                            {moment(`${info.updatedAt}`).format(" DD-MMMM h:mm a")}
                          </p>
                          <p
                            style={{
                              color: "gray",
                              fontSize: 12,
                              marginBottom: 5,
                            }}
                          >
                            CREATED AT:
                            {moment(`${info.createdAt}`).format(" DD-MMMM h:mm a")}
                          </p>
                          <NavLink
                            className="projectLink"
                            to={"/expand-edit-project"}
                            state={{ data: info, source: "add_Project" }}
                          >
                            <i
                              className="fas fa-arrow-right"
                              style={{ color: "#5B5E52" }}
                            ></i>
                          </NavLink>
                        </div>
                      </div>
                    )
                  )
                )}{" "}


              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfileContributor;
