import React, { useEffect } from "react";
import { toast } from 'react-hot-toast'
import { useNavigate } from "react-router-dom";
import services from "../../API_SERVICES/services";
import { useParams } from 'react-router-dom';


const jwt = require("jsonwebtoken");

const Reject = ({ match }) => {
  const { params } = useParams();
  const history = useNavigate();
 
  useEffect(() => {

    const secret = `${process.env.REACT_APP_INVITATION_RESET_PASSWORD}`
    jwt.verify(params, secret, (err, decode) => {
      if (err) {
        toast.error("Expired Link. Try again");
       
        setTimeout(() => history("/login"), 5000);

      }
      else {
        var decodedClaims = jwt.verify(params, secret);
        // const email_id = decodedClaims.email;


        DeclinetProject(decodedClaims.email,decodedClaims.projectid,decodedClaims.user_id,decodedClaims.username);
      
        setTimeout(() => history("/UserAddNewProject", { replace: true }), 5000);


      }
    });

  }, []);

  const DeclinetProject = (eid,pojid,id,username) => {
    const apiname = "decline-project";
    const data = {
      pid: pojid,
      email_id: eid,
      user_id: id,
      username: username,
    };
    services.post(apiname, data)
      .then((response) => {
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const spinCenter = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50% -50%)",
  };
  return (
    <>
 <div style={spinCenter}>
                  <div
                    className="spinner-border"
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  ></div>
                </div>
    </>
  );
};
export default Reject;
