import React, { useState } from 'react';
import './CustomDropdown.css'; // Import CSS file for styling
import Darrow from "../../Assets/Images/darrow.png"
import Uarrow from "../../Assets/Images/uarrow.png"
const CustomDropdown = ({ options, placeholder, dropdownStyle = {}, inputStyle = {}, onChange, isMultiple = false, selectedValue }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const arrowStyle ={
        width:"15px",
        height:"10px"
      }

    // const handleOptionClick = (option) => {
    //     const index = selectedOptions.findIndex((selectedOption) => selectedOption.name === option.name);
    //     if (index !== -1) {
    //         setSelectedOptions(selectedOptions.filter((selectedOption) => selectedOption.name !== option.name));
    //     } else {
    //         setSelectedOptions([...selectedOptions, option]);
    //     }
    //     onChange(selectedOptions) not updating the state immediately
    // };

    const handleOptionClick = (option) => {
        if(isMultiple){
            const index = selectedOptions.findIndex((selectedOption) => selectedOption.name === option.name);
            let updatedOptions; // to get the new state of selected option after selection
            if (index !== -1) {
                updatedOptions = selectedOptions.filter((selectedOption) => selectedOption.name !== option.name);
            } else {
                updatedOptions = [...selectedOptions, option];
            }
            setSelectedOptions(updatedOptions);
            // pass the state prop to parent
            onChange(updatedOptions);
            // To close the dropdown automatically
            setIsOpen(isOpen ? false : true);
        }else{
            setSelectedOptions([option]);
            // pass the state prop to parent
            onChange(option.name);
            // To close the dropdown automatically
            setIsOpen(isOpen ? false : true);
        }
    };


    return (
        <div className="custom-dropdown-container" style={dropdownStyle}>
            <div className="input-container" onClick={toggleDropdown}>
                <input
                    type="text"
                    placeholder={placeholder || 'Select an option'}
                    value={selectedValue}
                    readOnly
                    style={inputStyle}
                    onChange={onChange}
                />
                {/* <div className="placeholder-icon">v</div> */}
                <div className="placeholder-icon">{isOpen ? <img src={Uarrow} alt='' style={arrowStyle}/> :  <img src={Darrow} alt='' style={arrowStyle}/>}</div>
            </div>
            {isOpen && (
                <div className="options-list">
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={`option ${selectedOptions.some(selectedOption => selectedOption.name === option.name) ? 'selected' : ''}`}
                            onClick={() => handleOptionClick(option)}
                        >
                            {option.name} {selectedOptions.some(selectedOption => selectedOption.name === option.name) ? <><span className="option-icon"><i className="fas fa-check" ></i></span></> : <><span className="option-icon">&#10010;</span></>}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomDropdown;