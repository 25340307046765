export const adminList = [
    {
        name: "ayu11",
        type: "admin"
    },
    {
        name: "CareyL",
        type: "admin"
    },
    {
        name: "its_bhuvi",
        type: "admin"
    },
    {
        name: "classes",
        type: "admin"
    },
    {
        name: "dev11",
        type: "admin"
    },
];
