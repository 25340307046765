import Sketch from "react-p5";
import * as ml5 from "ml5";
import React, { useEffect, useState } from "react";
import { isId } from "../../Storage(Methods)/storage";
import services from "../../API_SERVICES/services";
import { useNavigate } from "react-router-dom";


var currentClass;
var p5Object, classifier, input1, input2, classifyInp, video, labeltoadd, displayLabel, inpImage;
var cameraStart = 1;
var classifyOrAdd = false;
var videoHeight = 170;
var videoWidth = 310;
var canvas;
var conf;
var imgCount = 0;
var circle1, circle2;


function convertImageToBase64(imgUrl) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      ctx.drawImage(image, 0, 0);
      const dataUrl = canvas.toDataURL();
      resolve(dataUrl);
    };
    image.onerror = error => {
      reject(error);
    };
    image.src = imgUrl + "?not-from-cache-please";
  });
}


const createS3Image = async (link) => {
  convertImageToBase64(link)
    .then(base64Image => {
      // Use the base64Image as needed
      let loadedImg = new Image()
      loadedImg.src = base64Image;
      classifier.addImage(loadedImg, labeltoadd);
      p5Object.createDiv().addClass("mobilenet-images-container col").parent("input-images-"+currentClass).id("image-container-"+imgCount);
      let image = p5Object.createImg(base64Image).addClass("uploaded-images s3-uploaded-images loading-image");
      image.parent("image-container-"+imgCount);
      p5Object.createButton("").id("imgCount-"+imgCount).addClass("fa-solid fa-x remove-img-btn").parent("image-container-"+imgCount).mousePressed(function(){
        document.getElementById("image-container-"+this.elt.id.split("-").at(-1)).remove();
      });
      imgCount += 1;
    })
    .catch(error => {});
};


const createS3ImageClassify = async (link) => {
  try{
    p5Object.select("#classified-image").remove();
  }
  catch{}
  try{
    p5Object.select("#result-header").remove();
  }
  catch{}
  try{
    p5Object.select("#result-confidence").remove();
  }
  catch{}
  let imageUrl = link;
  convertImageToBase64(imageUrl)
  .then(base64Image => {
    let loadedImg = new Image()
    loadedImg.src = base64Image;
    var result = classifier.classify(loadedImg);
    result.then((value) => {
      classifyInp = value[0].label;
      conf = value[0].confidence*100;
      // inpImage.show();
      inpImage = p5Object.createImg(base64Image).addClass("output-images");
      inpImage.parent("c-image").id("classified-image");
      p5Object.createElement("h5", classifyInp).parent("c-text").id("result-header").addClass("gray-dark");
      p5Object.createElement("h5", conf.toFixed(2)+"%").parent("c-confidence").id("result-confidence").addClass("gray-dark");
    });
  })
}


function handleFile(file) {
  if (file.type === 'image') {
    currentClass = 1;
    p5Object.createDiv().addClass("mobilenet-images-container col").parent("input-images-1").id("image-container-"+imgCount);
    let image = p5Object.createImg(file.data).addClass("uploaded-images").id("input-image-"+imgCount);
    // image.parent("input-images-1");
    image.parent("image-container-"+imgCount);
    p5Object.createButton("").id("imgCount-"+imgCount).addClass("fa-solid fa-x remove-img-btn").parent("image-container-"+imgCount).mousePressed(function(){
     document.getElementById("image-container-"+this.elt.id.split("-").at(-1)).remove();
    });
    classifier.addImage(image, input1.value());
    imgCount += 1;
  } else {
    file = null;
  }
}


function handleFile2(file) {
  currentClass = 2;
  if (file.type === 'image') {
    p5Object.createDiv().addClass("mobilenet-images-container col").parent("input-images-2").id("image-container-"+imgCount);
    let image = p5Object.createImg(file.data).addClass("uploaded-images");
    image.parent("image-container-"+imgCount);
    p5Object.createButton("").id("imgCount-"+imgCount).addClass("fa-solid fa-x remove-img-btn").parent("image-container-"+imgCount).mousePressed(function(){
      document.getElementById("image-container-"+this.elt.id.split("-").at(-1)).remove();
     });
    classifier.addImage(image, input2.value());
    imgCount += 1;
  } else {
    file = null;
  }
}


function handleFile3(file) {
  if (file.type === 'image') {
    p5Object.createDiv().addClass("mobilenet-images-container col").parent("input-images-"+displayLabel).id("image-container-"+imgCount);
    let image = p5Object.createImg(file.data).addClass("uploaded-images");
    image.parent("image-container-"+imgCount);
    p5Object.createButton("").id("imgCount-"+imgCount).addClass("fa-solid fa-x remove-img-btn").parent("image-container-"+imgCount).mousePressed(function(){
      document.getElementById("image-container-"+this.elt.id.split("-").at(-1)).remove();
     });
    classifier.addImage(image, labeltoadd);
    imgCount += 1;
  } else {
    file = null;
  }
}


function handleInputFile(file) {
  if (file.type === 'image') {
    inpImage = p5Object.createImg(file.data).addClass("output-images");
    inpImage.hide();
    try{
      p5Object.select("#classified-image").remove();
    }
    catch{}
    try{
      p5Object.select("#result-header").remove();
    }
    catch{}
    try{
      p5Object.select("#result-confidence").remove();
    }
    catch{}
  } else {
    file = null;
  }
}


function classifyInput(){
  var result = classifier.classify(inpImage);
  try{
    p5Object.select("#classified-image").remove();
  }
  catch{}
  try{
    p5Object.select("#result-confidence").remove();
  }
  catch{}
  try{
    p5Object.select("#result-header").remove();
  }
  catch{}
  result.then((value) => {
    classifyInp = value[0].label;
    conf = value[0].confidence*100;
    inpImage.show();
    inpImage.parent("c-image").id("classified-image");
    p5Object.createElement("h5", classifyInp).parent("c-text").id("result-header").addClass("gray-dark");
    p5Object.createElement("h5", conf.toFixed(2)+"%").parent("c-confidence").id("result-confidence").addClass("gray-dark");
  });
}


const Imagetransfer = (props) => {
  var navigate = useNavigate();
  const loaddata = async () => {
    let apiname = "fetch-project-data";
    const project = true;
    await services
      .get(apiname, project)
      .then((response) => {
        setProjectData(response);
        // projectData = response;
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const [projectData, setProjectData] = useState({"data":[]});
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      loaddata();
    }, 3000);
  }, []);
  if (isLoading){
    return(
      <>
        <div className="mt-5 save-model-loader-ml5"></div>
        <h4 className="mt-5 text-center">Loading project data...</h4>
      </>
    );
  }
  let projectName = sessionStorage.getItem("projectName");
  var projid = isId();
  // p5 setup function to setup all contents of p5
  const setup = (p5, canvasParentRef) => {
    // Video setup
    var download = true;
    p5Object = p5;
    var constraints = {video: {optional: [{ maxFrameRate: 30 }]}, audio: false}
    var cameraCont = p5Object.createDiv().addClass("camera-container").id("camera-cont");
    canvas = p5.createCanvas(videoWidth, videoHeight).style('border-radius','30px').parent("camera-cont");
    var cameraContainer = p5.createDiv().addClass("d-flex text-center justify-content-center align-items-center").parent("camera-cont").id("camera-btn-container").position(25,120);
    var captureLogo = p5.createElement("i","").addClass("c-logo fa-solid fa-camera me-2 mt-2").parent("camera-cont").position(280,10);
    var captureBtn = p5.createButton("Capture").addClass("capture-btn me-2 mt-2").parent("camera-btn-container").mousePressed(function(){
      if(classifyOrAdd){
        try{
          p5Object.select("#classified-image").remove();
        }
        catch{}
        try{
          p5Object.select("#result-confidence").remove();
        }
        catch{}
        try{
          p5Object.select("#result-header").remove();
        }
        catch{}
        var result = classifier.classify(video);
        result.then((value) => {
          classifyInp = value[0].label;
          conf = value[0].confidence*100;
          p5Object.createElement("h5", classifyInp).parent("c-text").id("result-header").addClass("gray-dark");
          p5Object.createElement("h5", conf.toFixed(2)+"%").parent("c-confidence").id("result-confidence").addClass("gray-dark");
        });
        var capturedImage = p5.createImage(video.width, video.height);
        capturedImage.copy(video, 0, 0, video.width, video.height, 0, 0, video.width, video.height);
        var i = p5.createImg(capturedImage.canvas.toDataURL()).addClass("").id("classified-image");
        i.parent("c-image");
        video = p5.createCapture(constraints);
        video.hide();
      }
      else{
        classifier.addImage(video, labeltoadd);
        var capturedImage = p5.createImage(video.width, video.height);
        capturedImage.copy(video, 0, 0, video.width, video.height, 0, 0, video.width, video.height);
        p5Object.createDiv().addClass("mobilenet-images-container col").parent("input-images-"+currentClass).id("image-container-"+imgCount);
        var i = p5.createImg(capturedImage.canvas.toDataURL()).addClass("uploaded-images");
        i.parent("image-container-"+imgCount);
        p5Object.createButton("").id("imgCount-"+imgCount).addClass("fa-solid fa-x remove-img-btn").parent("image-container-"+imgCount).mousePressed(function(){
          document.getElementById("image-container-"+this.elt.id.split("-").at(-1)).remove();
        });
        imgCount += 1;
        video = p5.createCapture(constraints);
        video.hide();
      }
    })
    var confirmBtn = p5.createButton("Done").addClass("capture-btn me-2 mt-2").parent("camera-btn-container").mousePressed(function(){
      video.remove();
      captureBtn.hide(); 
      canvas.hide();
      cameraCont.hide();
      confirmBtn.hide();
      classifyOrAdd = false;
    })
    captureBtn.hide();
    canvas.hide();
    cameraCont.hide();
    confirmBtn.hide();
    // Model setup
    var mobilenet = ml5.featureExtractor('MobileNet');
    classifier = mobilenet.classification(video);
    
    // Function video setup
    const videoSetup = (parent) => {
      let h = document.getElementById(parent).offsetHeight;
      let w = document.getElementById(parent).offsetWidth;
      p5.resizeCanvas(w,h);
      videoHeight = h;
      videoWidth = w;
      video = p5.createCapture(constraints);
      video.position(window.innerHeight/2, window.innerWidth/2);
      // canvas.parent("imageUploadContainer1").style("position","absolute");
      video.hide();
      cameraCont.parent(parent);
      cameraStart = 0;
      captureBtn.show();
      canvas.show();
      cameraCont.show();
      confirmBtn.show();
    }
    
    // While training the model
    const whileTraining = (loss) => {
      try{
        p5Object.select("#trained-text").remove();
      }
      catch{}
      circle1.show();
      circle2.show();
      if (loss == null) 
      {
        p5.createElement("p", "model ready!").addClass("mt-1 ms-5 pt-2 pb-2 color-2").parent("train-container").id("trained-text");
        circle1.hide();
        circle2.hide();
      }
    }
    
    // Variable for class count
    var classCount = 3;
    var maxClasses = 11;
    
    // Create class function
    const createClass = () => {
      // Allowing maximum 10 classes to be added
      if(classCount<maxClasses){
        // var labelName = "label"+classCount;
        p5.createDiv().addClass("label-container mb-3 p-3").parent("more-class").id("label"+classCount);
        p5.createDiv().addClass("row").parent("label"+classCount).id("main-row-class"+classCount);
        p5.createDiv().addClass("col-9").parent("main-row-class"+classCount).id("class-name"+classCount);
        p5.createInput().parent("class-name"+classCount).addClass("class-name-input").value("Class "+classCount).id("input"+classCount);
        p5.createDiv().addClass("col").parent("main-row-class"+classCount).id("input-column"+classCount);
        p5.createDiv().addClass("image-upload-container d-flex justify-content-center align-items-center").parent("input-column"+classCount).id("imageUploadContainer"+classCount);
        var d = p5.createDiv().addClass("images-display-container").parent("class-name"+classCount).id("input-images-"+classCount);
        p5.createElement("i","").id("s3Btn"+classCount).addClass("upload-logo-btn fa-solid fa-folder me-2").parent("imageUploadContainer"+classCount).mousePressed(function(){
          currentClass = this.elt.id.split("s3Btn").at(-1);
          labeltoadd = p5.select("#input"+currentClass).value();
          s3Modal.show();
        })
        p5.createButton("").addClass("upload-logo-btn").id("cameraBtn"+classCount).parent("imageUploadContainer"+classCount).mousePressed(function(){
          currentClass = this.elt.id.split("cameraBtn").at(-1);
          // let temp = classCount-1;
          labeltoadd = p5.select("#input"+currentClass).value();
          videoSetup("imageUploadContainer"+currentClass);
        });
        p5.createElement("i", "").addClass("fa-solid fa-camera").parent("cameraBtn"+classCount);
        var addExButMain = p5.createElement("label", '').id("file-upload-"+classCount).addClass("fa-solid fa-upload upload-logo-btn ms-2").parent("imageUploadContainer"+classCount).mousePressed(function(){
          currentClass = this.elt.id.split("file-upload-").at(-1);
          // let temp = classCount-1;
          labeltoadd = p5.select("#input"+currentClass).value();
          displayLabel = currentClass;
        });
        var addExBut = p5.createFileInput(handleFile3, "label", [true]).attribute("label", "Upload");
        addExButMain.child(addExBut);
        addExBut.hide();
        classCount += 1;
      }
      else{
        addClass.hide();
      } 
    }
    
    // basic UI setup
    p5.createP(projectName+" / MODELS / ML5_IMAGE_CLASSIFICATION / DEMO").addClass("ms-3").id("beginning");
    p5.createElement("h1", "IMAGE CLASSIFICATION MODEL").addClass("gray-dark ms-3 mt-2 img-class-header");

    // S3 data container 
    var s3Modal = p5.createDiv().addClass("s3-data-container").id("s3-image-container");
    p5.createDiv().parent("s3-image-container").addClass("row ps-3").id("s3-image-input-row");
    p5.createInput().parent("s3-image-input-row").addClass("col-5 s3-fields search-container-s3").attribute('placeholder', 'SEARCH');
    p5.createSelect().parent("s3-image-input-row").addClass("col-1 s3-fields filetype-container-s3").option('FILETYPE');
    p5.createDiv().parent("s3-image-input-row").addClass("col").id("close-modal");
    p5.createButton("X").class("close-modal-btn").parent("close-modal").mousePressed(()=>{
      s3Modal.hide();
    });
    
    // S3 table 
    p5.createDiv().parent("s3-image-container").id("data-table-container").addClass("pt-2 pb-2 mt-2");
    p5.createDiv().parent("data-table-container").addClass("row mt-2 ms-2 me-2 pb-3").id("s3-imageheader-row");
    p5.createDiv("#").parent("s3-imageheader-row").addClass("col-1 text-center").id("image-id");
    p5.createDiv("icon").parent("s3-imageheader-row").addClass("col-1").id("image-icon");
    p5.createDiv("filename").parent("s3-imageheader-row").addClass("col-4").id("image-filename");
    p5.createDiv("author(s)").parent("s3-imageheader-row").addClass("col-2").id("image-author");
    p5.createDiv("contributor").parent("s3-imageheader-row").addClass("col-2").id("image-contributor");
    p5.createDiv("select").parent("s3-imageheader-row").addClass("col-2").id("image-select");
    p5.createDiv().parent("data-table-container").id("data-table-content");
    for(let img=0; img<projectData.data.length; img++){
      p5.createDiv().parent("data-table-content").addClass("row model-data-content mt-2 ps-2 pe-2 pt-2 pb-2 mb-2").id("s3-imageheader-row"+img);
      p5.createDiv(img+1).parent("s3-imageheader-row"+img).addClass("col-1 text-center").id("image-id");
      p5.createDiv("").parent("s3-imageheader-row"+img).addClass("col-1").id("image-icon"+img);
      p5.createImg(projectData.data[img].video).parent("image-icon"+img).addClass("icon-images").size(60, 40);
      p5.createDiv(projectData.data[img].video.split("/").at(-1)).parent("s3-imageheader-row"+img).addClass("col-4").id("image-filename");
      p5.createDiv(projectData.data[img].author).parent("s3-imageheader-row"+img).addClass("col-2").id("image-author");
      p5.createDiv(projectData.data[img].contributor).parent("s3-imageheader-row"+img).addClass("col-2").id("image-contributor");
      p5.createCheckbox().parent("s3-imageheader-row"+img).addClass("col-2").id("image-select"+img);
      let d = document.getElementById("image-select"+img);
      d.value = projectData.data[img].video;
    }
    p5.createButton("UPLOAD").addClass("upload-s3-button save-model mt-4").parent("data-table-container").mousePressed(()=>{
      let checkboxes = document.querySelectorAll('input[type="checkbox"]:checked');
      for (const checkbox of checkboxes) {
        labeltoadd = p5.select("#input"+currentClass).value();
        createS3Image(checkbox.parentNode.value);
        checkbox.checked = false;
      }
      s3Modal.hide();
    });
    s3Modal.hide();

    //Upload data section
    p5.createDiv().addClass("model-container-main content-radius ms-3 me-3").id("main-container");
    p5.createDiv().addClass("row ps-3 mb-3").parent("main-container").id("container-label-row");
    p5.createDiv("1").addClass("col-1 circle ms-3 mt-3").parent("container-label-row").id("num-col");
    p5.createElement("h4", "UPLOAD DATA").addClass("gray-dark col mt-3 ms-2").parent("container-label-row");
    p5.createElement("p", "NAME EACH CLASS AND UPLOAD IMAGES").addClass("mt-2 ms-2 gray-light").parent("container-label-row");
    p5.createDiv().addClass("ps-4 pe-4").parent("main-container").id("class-container");
    
    // Two labels added by default
    // Label one
    p5.createDiv().addClass("label-container mb-3 p-3").parent("class-container").id("label1");
    p5.createDiv().addClass("row").parent("label1").id("main-row-class1");
    p5.createDiv().addClass("col-9").parent("main-row-class1").id("class-name1");
    input1 = p5.createInput().id("input1").parent("class-name1").addClass("class-name-input gray-light").value("Class 1");
    p5.createDiv().addClass("images-display-container").parent("class-name1").id("input-images-1");
    p5.createDiv().addClass("col").parent("main-row-class1").id("input-column1");
    p5.createDiv().addClass("image-upload-container d-flex justify-content-center align-items-center").parent("input-column1").id("imageUploadContainer1");
    p5.createButton("").id("s3Btn1").addClass("upload-logo-btn fa-solid fa-folder me-2").parent("imageUploadContainer1").mousePressed(function(){
      currentClass = this.elt.id.split("s3Btn").at(-1);
      labeltoadd = p5.select("#input1").value();
      s3Modal.show();
    })
    p5.createButton("").addClass("upload-logo-btn").id("cameraBtn1").parent("imageUploadContainer1").mousePressed(function(){
      displayLabel = 1;
      labeltoadd = input1.value();
      currentClass = 1;
      videoSetup("imageUploadContainer1");
    });
    p5.createElement("i", "").addClass("fa-solid fa-camera").parent("cameraBtn1");
    var addExButMain = p5.createElement("label", '').addClass("fa-solid fa-upload upload-logo-btn ms-2").parent("imageUploadContainer1");
    var addExBut = p5.createFileInput(handleFile, "label", [true]).attribute("label", "Upload");
    addExButMain.child(addExBut);
    addExBut.hide();
    
    // Label two
    p5.createDiv().addClass("label-container mb-3 p-3").parent("class-container").id("label2");
    p5.createDiv().addClass("row").parent("label2").id("main-row-class2");
    p5.createDiv().addClass("col-9").parent("main-row-class2").id("class-name2");
    input2 = p5.createInput().id("input2").parent("class-name2").addClass("class-name-input gray-light").value("Class 2");
    p5.createDiv().addClass("images-display-container").parent("class-name2").id("input-images-2");
    p5.createDiv().addClass("col").parent("main-row-class2").id("input-column2");
    p5.createDiv().addClass("image-upload-container d-flex justify-content-center align-items-center").parent("input-column2").id("imageUploadContainer2");
    p5.createElement("i","").id("s3Btn2").addClass("upload-logo-btn fa-solid fa-folder me-2").parent("imageUploadContainer2").mousePressed(function(){
      currentClass = this.elt.id.split("s3Btn").at(-1);
      labeltoadd = p5.select("#input2").value();
      s3Modal.show();
    })
    p5.createButton("").addClass("upload-logo-btn").id("cameraBtn2").parent("imageUploadContainer2").mousePressed(function(){
      displayLabel = 2;
      currentClass = 2;
      labeltoadd = input2.value();
      videoSetup("imageUploadContainer2");
    });
    p5.createElement("i", "").addClass("fa-solid fa-camera").parent("cameraBtn2");
    var addExButMain = p5.createElement("label", '').addClass("fa-solid fa-upload upload-logo-btn ms-2").parent("imageUploadContainer2");
    var addExBut = p5.createFileInput(handleFile2, "label", [true]).attribute("label", "Upload");
    addExButMain.child(addExBut);
    addExBut.hide();
    
    // More classed added in this container
    p5.createDiv().parent("class-container").id("more-class");
    // Add class button
    var addClass = p5.createButton("+ class").addClass("add-class-btn mb-3 ps-4 gray-light").parent("class-container");
    // Add class functionality
    addClass.mousePressed(function() {
        createClass();
      }
    );

    // Train model section
    p5.createDiv().addClass("model-container-train content-radius ms-3 me-3 mt-5").id("train-container");
    p5.createDiv().addClass("row ps-3 mb-3").parent("train-container").id("container-label-row-train");
    p5.createDiv().addClass("train-model-loader").parent("train-container").id("loading-container");
    circle1 = p5.createDiv().parent("loading-container").addClass("loading-circle");
    circle2 = p5.createDiv().parent("loading-container").addClass("loading-circle");
    circle1.hide();
    circle2.hide();
    p5.createDiv("2").addClass("col-1 circle-2 ms-3 mt-3").parent("container-label-row-train").id("num-col");
    p5.createElement("h4", "TRAIN THE MODEL").addClass("col mt-3 ms-2 color-2").parent("container-label-row-train");
    p5.createButton("TRAIN").addClass("train-model-btn ms-5 pt-2 pb-2 mb-3").parent("train-container").mousePressed(function(){
      classifier.train(whileTraining);
    });

    // Output model section
    p5.createDiv().addClass("model-container-output content-radius ms-3 me-3 mt-5 mb-5").id("output-container");
    p5.createDiv().addClass("row ps-3 mb-3").parent("output-container").id("container-label-row-output");
    p5.createDiv("3").addClass("col-1 circle ms-3 mt-3").parent("container-label-row-output").id("num-col");
    p5.createElement("h4", "DEMO").addClass("col mt-3 ms-2 gray-dark").parent("container-label-row-output");
    p5.createElement("p", "DEMO THE RETRAINED MODEL...").addClass("mt-1 ms-2 gray-light").parent("container-label-row-output");
    p5.createDiv().addClass("row ps-3 mb-2 ms-2 me-4").parent("output-container").id("row2-output");
    p5.createDiv().addClass("col").parent("row2-output").id("demo-inputs");
    p5.createButton("").addClass("upload-logo-btn").id("cameraBtn1").parent("imageUploadContainer1").mousePressed(function(){
      displayLabel = 1;
      labeltoadd = input1.value();
    });
    p5.createDiv().addClass("row ps-2 mb-2 ms-4 me-4 pe-2 ml-auto ").parent("output-container").id("row3-output");
    p5.createButton("Download").addClass("col me-2 mb-3 model-save-btn text-center gray-dark").parent("row3-output").mousePressed(function(){
      classifier.save(download);
    })

    //Save modal
    var saveModelContainer = p5.createDiv().addClass("save-model-outer-container").id("save-outer");
    var saveModelLoader = p5.createDiv().addClass("save-data-container").id("save-loader-container").parent("save-outer");
    p5.createDiv().class("save-model-loader mt-3").parent("save-loader-container");
    p5.createElement("h5", "Saving model...").parent("save-loader-container").addClass("text-center mt-5");
    p5.createDiv().addClass("save-data-container").id("save-image-container").parent("save-outer");
    p5.createElement("h3", "Save Model ?").parent("save-image-container").addClass("text-center");
    p5.createElement("p", "Save model to project").parent("save-image-container").addClass("text-center");
    p5.createElement("h5", "Retrained IMAGE CLASSIFICATION MODEL").parent("save-image-container").addClass("mt-4 text-center font-italic retrain-text");
    p5.createButton("BACK").parent("save-image-container").addClass("save-modal-btn back-model").mousePressed(()=>{
      saveModelContainer.hide();
    });
    p5.createButton("SAVE").parent("save-image-container").addClass("save-modal-btn save-model").mousePressed(()=>{
      saveModelLoader.show();
      let ver = classifier.saveDb();
      ver.then((result)=>{
        // hitting API
        const formfiles = new FormData();
        formfiles.append("file", new File([result.Weights], "model.weights.bin"));
        formfiles.append("file", new File([result.Manifest], "manifest.json"));
        formfiles.append("id", projid);
        let apiName = "mobilenetSave" // https://wekinator.poieto.com/api/mobilenetSave
        services
          .post(apiName, formfiles)
          .then((response)=>{
            navigate("/modelspage");
          })
      });
    });
    saveModelLoader.hide();
    saveModelContainer.hide();
    p5.createButton("SAVE").addClass("col mb-3 model-save-btn text-center gray-dark").parent("row3-output").mousePressed(function(){ 
      saveModelContainer.show();
    })


    // Input section
    // S3 data container classify
    var s3ModalClassify = p5.createDiv().addClass("s3-data-container").id("s3-image-container-classify");
    p5.createDiv().parent("s3-image-container-classify").addClass("row ps-3").id("s3-image-input-row-classify");
    p5.createInput().parent("s3-image-input-row-classify").addClass("col-5 s3-fields search-container-s3").attribute('placeholder', 'SEARCH');
    p5.createSelect().parent("s3-image-input-row-classify").addClass("col-1 s3-fields filetype-container-s3").option('FILETYPE');
    p5.createDiv().parent("s3-image-input-row-classify").addClass("col").id("close-modal-classify");
    p5.createButton("X").class("close-modal-btn").parent("close-modal-classify").mousePressed(()=>{
      s3ModalClassify.hide();
    });
    
    // S3 table classify
    p5.createDiv().parent("s3-image-container-classify").id("data-table-container-classify").addClass("pt-2 pb-2 mt-2");
    p5.createDiv().parent("data-table-container-classify").addClass("row mt-2 ms-2 me-2 pb-3").id("s3-imageheader-row-classify");
    p5.createDiv("#").parent("s3-imageheader-row-classify").addClass("col-1 text-center").id("image-id");
    p5.createDiv("icon").parent("s3-imageheader-row-classify").addClass("col-1").id("image-icon");
    p5.createDiv("filename").parent("s3-imageheader-row-classify").addClass("col-4").id("image-filename");
    p5.createDiv("author(s)").parent("s3-imageheader-row-classify").addClass("col-2").id("image-author");
    p5.createDiv("contributor").parent("s3-imageheader-row-classify").addClass("col-2").id("image-contributor");
    p5.createDiv("select").parent("s3-imageheader-row-classify").addClass("col-2").id("image-select");
    p5.createDiv().parent("data-table-container-classify").id("data-table-content-classify");
    for(let img=0; img<projectData.data.length; img++){
      p5.createDiv().parent("data-table-content-classify").addClass("row model-data-content mt-2 ps-2 pe-2 pt-2 pb-2 mb-2").id("s3-imageheader-row-classify"+img);
      p5.createDiv(img+1).parent("s3-imageheader-row-classify"+img).addClass("col-1 text-center").id("image-id");
      p5.createDiv("").parent("s3-imageheader-row-classify"+img).addClass("col-1").id("image-icon-classify"+img);
      p5.createImg(projectData.data[img].video).parent("image-icon-classify"+img).addClass("icon-images").size(60, 40);
      p5.createDiv(projectData.data[img].video.split("/").at(-1)).parent("s3-imageheader-row-classify"+img).addClass("col-4").id("image-filename");
      p5.createDiv(projectData.data[img].author).parent("s3-imageheader-row-classify"+img).addClass("col-2").id("image-author");
      p5.createDiv(projectData.data[img].contributor).parent("s3-imageheader-row-classify"+img).addClass("col-2").id("image-contributor");
      p5.createCheckbox().parent("s3-imageheader-row-classify"+img).addClass("col-2 classify-checkboxes").id("image-select-classify"+img);
      let d = document.getElementById("image-select-classify"+img);
      d.value = projectData.data[img].video;
    }
    p5.createButton("UPLOAD").addClass("upload-s3-button save-model mt-4").parent("data-table-container-classify").mousePressed(()=>{
      let checkss = document.querySelectorAll('input[type="checkbox"]:checked');
      for (let c of checkss) {
        if(c.parentNode.classList.contains = "classify-checkboxes"){
          createS3ImageClassify(c.parentNode.value);
          s3ModalClassify.hide();
          c.checked = false;
        }
        break;
      }
    });
    s3ModalClassify.hide();
    p5.createElement("h4", "INPUT").addClass("col mt-3 ms-2 gray-dark").parent("demo-inputs");
    p5.createDiv().addClass("model-input-container content-radius d-flex justify-content-center align-items-center").parent("demo-inputs").id("model-input-container");
    // p5.createElement("i", "").addClass("camera fa-solid fa-camera").parent("model-input-container");
    p5.createElement("i","").addClass("upload-logo-btn fa-solid fa-folder me-2").parent("model-input-container").mousePressed(function(){
      s3ModalClassify.show();
    })
    p5.createButton("").addClass("camera fa-solid fa-camera upload-logo-btn").id("cameraBtnO").parent("model-input-container").mousePressed(function(){
      classifyOrAdd = true;
      captureLogo.position(390, 10);
      cameraContainer.position(80, 250);
      videoSetup("model-input-container");
    });
    var imgBtn = p5.createElement("label", '').addClass("fa-solid fa-upload upload-logo-btn ms-2").parent("model-input-container");
    var imgBtnLg = p5.createFileInput(handleInputFile, "label", [true]).attribute("label", "Upload");
    imgBtn.child(imgBtnLg);
    imgBtnLg.hide();

    // Action section
    p5.createDiv().addClass("col").parent("row2-output").id("demo-actions");
    p5.createElement("h4", "ACTIONS").addClass("col mt-3 ms-2 gray-dark").parent("demo-actions");
    p5.createDiv().addClass("model-input-container content-radius text-center").parent("demo-actions").id("model-action-container");
    p5.createButton("RUN MODEL").addClass("run-model-btn btn-bot").parent("model-action-container").mousePressed(function(){
      classifyInput();
    })
    
    // Result section
    p5.createDiv().addClass("col").parent("row2-output").id("demo-results");
    p5.createElement("h4", "OUTPUT").addClass("col mt-3 ms-2 gray-dark").parent("demo-results");
    p5.createDiv().addClass("model-outputs content-radius d-flex flex-column justify-content-center align-items-center text-center").id("result-container").parent("demo-results");
    p5.createDiv().addClass("classified-image-container text-center").parent("result-container").id("c-image");
    p5.createDiv().id("label-row").parent("result-container").addClass("row w-100 ms-3 me-3 text-center");
    p5.createDiv().addClass("col classified-image-container-text text-center text-center").parent("label-row").id("c-text");
    p5.createDiv().addClass("col classified-image-container-text text-center text-center").parent("label-row").id("c-confidence");
  };
	const draw = (p5) => {
    p5.background(0);
    if (cameraStart === 0){
      p5.translate(videoWidth, 0);
      p5.scale(-1, 1);
      p5.image(video, 0, 0, videoWidth, videoHeight);
    }
	};
  
	return (
    <>
      <Sketch setup={setup} draw={draw} />
    </>
  );
};


export default Imagetransfer;