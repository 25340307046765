import React, { useEffect, useState } from "react";
import { isId,isAuth } from "../../Storage(Methods)/storage";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import services from "../../API_SERVICES/services";
export default function CriticalQuestions({ setShow }) {
  const navigate = useNavigate();
  const [userqs, setuserqs] = useState({
    crtq1: "",
  });
  useEffect(() => {
    LoadQuestions();
  }, []);
  const handleChange = (e) => {
    setuserqs({ ...userqs, [e.target.name]: e.target.value });
  };
  const { crtq1 } = userqs;

  const LoadQuestions = () => {
    const apiname = "get-question";
    const project = true;
    services
      .get(apiname, project)
      .then((response) => {
        const { crtq} = response.data;
        setuserqs({ ...userqs, crtq1: crtq });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if ((crtq1 != null && crtq1 !== '')) {

    const apiname = "poieto/crtq-query";
    const data = { crtq1: crtq1, pId: isId(), id: isAuth().id};
    services
      .post(apiname, data)
      .then((response) => {
        toast.success(response.data.message);
        LoadQuestions();
        setTimeout(() => {
          navigate("/createFrames", { replace: true });
        }, 1200);
      })
      .catch((error) => {
        toast.error(error.response.data.error);
      });
    }
    else
    {
      setTimeout(() => {
        navigate("/createFrames", { replace: true });
      }, 1200);
    }
  };
  return (
    <>
      <div className="container-fluid mt-3">
        <h3 style={{ margin: "0px", color: "#4E4E4E", fontWeight: "normal" }}>
          BIAS
        </h3>
        <p style={{ color: "#4E4E4E", fontWeight: "normal" }}>
          WHAT PERSPECTIVE OR EXPERIENCES ARE YOU BRINGING TO THIS PROJECT
        </p>

        <textarea
          className="mt-2"
          rows="3"
          name="crtq1"
          value={crtq1}
          onChange={handleChange}
          placeholder={!crtq1 ? "Type Here" : null}
          style={{
            fontSize: "25px",
            paddingLeft: 10,
            width: "100%",
            borderRadius: 15,
            backgroundColor: "#C7CCB2",
          }}
        ></textarea>

        <h3
          className="mt-3"
          style={{
            borderBottom: "1.5px solid grey",
            color: "#4E4E4E",
            fontWeight: "normal",
          }}
        >
          COMMUNITY SOURCED QUESTIONS
        </h3>

        <h3
          className="mt-5"
          style={{ margin: "0px", color: "#4E4E4E", fontWeight: "normal" }}
        >
          ADD YOUR OWN QUESTION
        </h3>
        <div>
          <button
            onClick={() => navigate("/Comingsoon", { replace: true })}
            style={{
              border: "1px solid #4e4e4e",
              height: "45px",
              width: "45px",
              borderRadius: "25px",
              paddingBottom: 5,
              backgroundColor: "#C7CCB2",
            }}
            type="button"
          >
            +
          </button>
        </div>
        <button
          className="mt-5 btn-lg btn-block mt-4"
          style={{
            marginBottom: 10,
            width: "50%",
            height: 125,
            backgroundColor: "#FFF",
            borderRadius: 10,
            border: "none",
            fontSize: "23px",
          }}
          onClick={() => setShow(2)}
          type="button"
        >
          <i className="fas fa-arrow-left" />
          <br />
          BACK
        </button>

        <button
          style={{
            marginBottom: 10,
            width: "48.5%",
            height: 125,
            backgroundColor: "#E5FF80",
            borderRadius: 10,
            border: "none",
            fontSize: "23px",
          }}
          onClick={handleSubmit}
          type="button"
          className="btn-lg btn-block mt-4 ms-3"
        >
          <i className="fas fa-arrow-right" />
          <br />
          NEXT
        </button>
      </div>
    </>
  );
}
