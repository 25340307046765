import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { isAuth } from "../../Storage(Methods)/storage";
import { toast } from "react-hot-toast";
import { PopupModal } from "../DataPrivacy/PopupModal";
import moment from "moment";
import services from "../../API_SERVICES/services";
import options from "../../Assets/Images/options.png";
import deletepic from "../../Assets/Images/del.png";
import editpic from "../../Assets/Images/editicon.png";
import adminpic from "../../Assets/Images/admin.png";
import exitpic from "../../Assets/Images/leave.png";
import { adminList } from "../../constants/adminList";
import {DeleteProjectPopup} from "../DataPrivacy/DeleteProjectPopup";


const UserAddNewProject = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    profileimage: "",
    user_bio: "",
    user_location: "",
    user_occupation: "",
    screen8: false,
    screen9: false,
    fetched: "",
    projMap: new Map(),
  });
  const [menupop, setMenupop] = useState(false);
  const [selectedProject, setSelectedProject] = useState("s");
  const [projects, setProjects] = useState("");
  const [leftcord, setLeftcord] = useState(0);
  const [topcord, setTopcord] = useState(0);
  const [isPasswordEye, setIsPasswordEye] = useState(true);
  const [sitePassword, setSitePassword] = useState('');
  const [isPasswordLoading, SetIsPasswordLoading] = useState(false);

  useEffect(() => {
    loadProfile();
    loadProjects();
    if (userCheck() === true) {
      getSitePassword();
    }
  }, []);

  // Check the user is Admin or not
  const userCheck = () => {
    var value = adminList.some(item => item.name === isAuth().username);
    if (value) {
      return true;
    } else {
      return false;
    }
  }

  const loadProjects = () => {
    const apiname = "fetch-project";
    const project = false;
    const frames = false;
    services
      .get(apiname, project, frames)
      .then((response) => {
        setProjects(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleLeave = (pId) => {
    const apiname = "leave-projects";
    const data = { pId: pId, user_id: isAuth().id };
    services
      .delete(apiname, data)
      .then((response) => {
        toast.success(response.data.message);
        setValues({ ...values, screen9: false });
        setTimeout(() => {
          loadProjects();
          loadProfile();
        }, 900);
      })
      .catch((error) => {
        toast.error(error.response.data.error);
      });
  };

  const closemenu = () => {
    if (menupop === true) {
      setMenupop(false);
    }
  }
  const onmenuclick = (e, info) => {
    setSelectedProject(info);
    setMenupop(true);
    setLeftcord(e.pageX - 200);
    setTopcord(e.pageY);
  }

  const handleDelete = (pId) => {
    const apiname = "delete-projects";
    const data = { pId: pId };
    services
      .delete(apiname, data)
      .then((response) => {
        toast.success(response.data.message);
        setValues({ ...values, screen8: !screen8 });
        setTimeout(() => {
          loadProjects();
          loadProfile();

        }, 900);
      })
      .catch((error) => {
        toast.error(error.response.data.error);
      });
  };
  const loadProfile = () => {
    const apiname = "user/get-profile-data";
    services
      .get(apiname)
      .then((response) => {
        const { profileimage, user_bio, user_location, user_occupation } =
          response.data;
        var projectmap = new Map();

        services.getbasic("get_permission", isAuth().id)
          .then((response2) => {
            response2.data.map(item => {
              projectmap.set(parseInt(item.pId), item.is_admin);
              return "";
            });
            setValues({
              ...values,
              profileimage,
              user_bio,
              user_location,
              user_occupation,
              projMap: projectmap,
              screen8: false,
              screen9: false
            });
          })
          .catch((error) => {
            console.log(error.response.data.error);

          });

      })
      .catch((error) => {
        console.log(error);
        var projectmap = new Map();
        services.getbasic("get_permission", isAuth().id)
          .then((response2) => {
            response2.data.map(item => {
              projectmap.set(parseInt(item.pId), item.is_admin);
              return "";
            });
            setValues({
              ...values,
              projMap: projectmap,
              screen8: false,
              screen9: false
            });
          })
          .catch((error) => {
            console.log(error.response.data.error);
          });
      });
  };

  // get the Site Password
  const getSitePassword = async () => {
    const apiname = `get-site-password/${isAuth().username}`;
    services
      .getbasic(apiname)
      .then((response) => {
        setSitePassword(response.data.password);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // update the Site Password
  const updateSitePassword = async () => {
    SetIsPasswordLoading(true);
    const apiname = `update-site-password`;
    const data = {
      "username": isAuth().username,
      "password": sitePassword
    };
    services
      .post(apiname, data)
      .then((response) => {
        SetIsPasswordLoading(false)
        toast.success(response.data.message);
      })
      .catch((error) => {
        toast.error("Something went wrong!");
        SetIsPasswordLoading(false)
        console.log(error);
      });
  }



  const {
    profileimage,
    user_occupation,
    user_location,
    user_bio,
    screen8,
    screen9,
    fetched,
  } = values;
  return (
    <>
      <div className="mainContainer">
        <div className="handingRowView">
          <div className="leftHeadingView">
            <h2 className="handingViewText">PROFILE</h2>
          </div>
          <div className="rightHeadingView">
            <h2 className="handingViewText">PROJECTS</h2>
          </div>
        </div>
        {screen8 === false ? null : (
          <DeleteProjectPopup
          >
            <h4>ARE YOU SURE YOU WANT TO DELETE THIS PROJECT?</h4>
            <p style={{ fontSize: "20px", fontWeight: 'lighter' }}>{fetched.project_title}</p>
            <div className='d-flex justify-content-between'>
              <button
                type='button'
                className='backbtndelete'
                onClick={() =>
                  setValues({ ...values, screen8: !screen8 })
                }>
                BACK
              </button>
              <button
                type='button'
                className='deletebtn'
                onClick={() => { handleDelete(fetched.pId) }}>
                DELETE
              </button>
            </div>
          </DeleteProjectPopup>
        )}
        {!screen9 ? null : (
          <PopupModal
          >
            <h4>ARE YOU SURE YOU WANT TO LEAVE THIS PROJECT?</h4>
            <p style={{ fontSize: "20px", fontWeight: 'lighter' }}>{fetched.project_title}</p>
            <div className='d-flex justify-content-between'>
              <button
                type='button'
                className='backbtndelete'
                onClick={() =>
                  setValues({ ...values, screen9: false })
                }>
                BACK
              </button>
              <button
                type='button'
                className='deletebtn'
                onClick={() => { handleLeave(fetched.pId) }}>
                EXIT
              </button>
            </div>
          </PopupModal>
        )}

        {menupop ?

          <div className="menupop" style={{ top: `${topcord}px`, left: `${leftcord}px` }}>
            {values?.projMap?.get(selectedProject?.pId) === 'N' ? <>
              <span></span><span>Member</span><span><img alt="option" src={options} height="16px" /></span>
            </> : <>
              <span><img alt="admin" src={adminpic} height="16px" onClick={() => setMenupop(false)} />
              </span><span>Admin</span><span><img alt="options" src={options} height="16px" /></span>
            </>}
            <img alt="edit" src={editpic} height="16px" onClick={() => { navigate("/edit-project", { replace: true, state: { data: selectedProject }, }) }} />
            <span onClick={() => { setMenupop(false); navigate("/edit-project", { replace: true, state: { data: selectedProject }, }) }}>Edit</span><span></span>
            {values?.projMap?.get(selectedProject?.pId) ? <> <img alt="exit" src={exitpic} height="16px"
              onClick={() => { setMenupop(false); setValues({ ...values, screen9: true, fetched: selectedProject }) }} />
              <span onClick={() => {
                setMenupop(false); setValues({ ...values, screen9: true, fetched: selectedProject })
              }} >Leave</span><span></span>
            </> : ''}

            {values?.projMap?.get(selectedProject?.pId) === 'N' ? null : <><img alt="delete" src={deletepic} height="16px" onClick={() => {
              setMenupop(false);
              setValues({ ...values, screen8: true, fetched: selectedProject })
            }} />
              <span onClick={() => {
                setMenupop(false);
                setValues({ ...values, screen8: true, fetched: selectedProject })
              }}>Delete</span><span></span></>}
          </div>
          : null}
        <div className="contentContainer" onClick={closemenu}>
          <div className="leftContentContainer">
            <div className="userDetailsView">
              {!profileimage ? (
                <div className="imageBlankView"></div>
              ) : (
                <img
                  className="imageView"
                  src={profileimage}
                  alt="profileimage"
                />
              )}
              <div className="userInfoContentRow">
                <h4
                  style={{
                    fontWeight: 100,
                    color: "gray",
                    marginBottom: 0,
                  }}
                >
                  {isAuth().fullname}
                </h4>
                {user_occupation === "" ? (
                  <p></p>
                ) : (
                  <p
                    style={{
                      color: "gray",
                      fontSize: 12,
                      marginBottom: 0,
                    }}
                  >
                    {user_occupation} / {user_location}
                  </p>
                )}
                {user_bio === "" ? (
                  <p></p>
                ) : (
                  <p
                    style={{
                      color: "gray",
                      fontSize: 12,
                      marginTop: 10,
                    }}
                  >
                    {user_bio}
                  </p>
                )}
              </div>
            </div>
            <button
              className="editButton"
              onClick={() => navigate("/profileupdate", { replace: false })}
            >
              <i className="fas fa-pencil" style={{ color: "#5B5E52" }}></i>
            </button>
            {userCheck() === true ?
              <div style={{ height: 160, marginTop: 30, width: "600px", border: "1px solid gray", borderRadius: 20 }}>
                <div style={{ height: 35, marginTop: -20, marginLeft: 10, background: "#C7CCB2", width: "300px", alignItems: "center", justifyContent: "center", border: "1px solid gray", borderRadius: "15px", display: "flex", color: "gray", fontWeight: "bold" }}>
                  Change Website Password
                </div>
                <div style={{ height: 50, width: "100%", border: "0px solid black", marginTop: 20, display: "flex" }}>
                  <div style={{ flexGrow: 1, border: "0px solid black", display: "flex", justifyContent: "flex-end" }}>
                    <input
                      type={isPasswordEye ? "password" : "text"}
                      value={sitePassword}
                      onChange={(e) => setSitePassword(e.target.value)}
                      style={{ width: "80%", height: "100%", background: "transparent", border: "1px solid gray", borderRadius: "15px", padding: "0px 15px", fontSize: "x-large", color: "gray" }}
                    />
                  </div>
                  <div style={{ flexGrow: 0.5, border: "0px solid black", justifyContent: "flex-start", display: "flex" }}>
                    <button style={{ background: "transparent", border: "0px solid gray" }} onClick={() => setIsPasswordEye(!isPasswordEye)}>
                      <i className={isPasswordEye ? "fas fa-eye" : "fas fa-eye-slash"} style={{ color: "#5B5E52" }}></i>
                    </button>
                  </div>
                </div>
                <div style={{ height: 40, marginTop: 20, border: "0px solid black", justifyContent: "center", display: "flex" }}>
                  {isPasswordLoading ?
                    <div className="globalLoader"></div>
                    :
                    <button className="UpdateButton" onClick={() => updateSitePassword()}>
                      Update
                    </button>
                  }
                </div>
              </div>
              : null}
          </div>
          <div className="rightContentContainer">
            <button
              className="startNewProjectButtonView"
              onClick={() => navigate("/projectsetup", { replace: false })}
            >
              <i className="fas fa-plus" style={{ color: "#5B5E52" }}></i>
              <h3 className="buttonHeadingText">NEW</h3>
            </button>
            {projects?.data?.length === 0 ? (
              <div className="projectDetailsView">
                <h4 className="text-center">
                  {" "}
                  <i
                    className="fas fa-empty-set"
                    style={{ fontSize: "20px" }}
                  ></i>{" "}
                  No Projects{" "}
                </h4>
              </div>
            ) : (
              <>
                {projects?.data?.map(
                  (info) => (
                    (

                      <div className="projectDetailsView" key={info.pId}>
                        <div className="projectDetailRow">
                          <div className="d-flex justify-content-between">
                            <h4
                              style={{
                                fontWeight: 100,
                                color: "gray",
                              }}
                            >
                              {info.project_title}
                            </h4>
                            <div className="d-flex" onClick={(e) => onmenuclick(e, info)} >
                              {values?.projMap?.get(info?.pId) === 'N' ?
                                '' : <img alt="download" src={adminpic} height="16px" />}

                              <img alt="menu" src={options} style={{ marginLeft: "10px" }} height="16px" />
                            </div>
                          </div>
                          <NavLink
                            className="projectLink"
                            to={"/expand-edit-project"}
                            state={{ data: info, source: "add_Project" }}
                          >
                            <p
                              style={{
                                color: "gray",
                                fontSize: 12,
                                marginBottom: 5,
                              }}
                            >
                              LAST UPDATED:{" "}
                              {moment(`${info.updatedAt}`).format(" DD-MMMM h:mm a")}
                            </p>
                            <p
                              style={{
                                color: "gray",
                                fontSize: 12,
                                marginBottom: 5,
                              }}
                            >
                              CREATED AT:
                              {moment(`${info.createdAt}`).format(" DD-MMMM h:mm a")}
                            </p>

                            <i
                              className="fas fa-arrow-right"
                              style={{ color: "#5B5E52" }}
                            ></i>
                          </NavLink>
                        </div>
                      </div>
                    )
                  )
                )}{" "}


              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAddNewProject;
