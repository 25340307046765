import toast from "react-hot-toast";
export const classnames = (...args) => {
    return args.join(" ");
};



const errorMessages = {
    DataCallTitle: 'Please enter Data Call Title',
    title: 'Please enter title',
    titleMessage:'project description cannot be empty, please Write project description',
    author: 'Please enter author',
    howWasThisSourced: 'Please enter how was this sourced',
    fieldsData:"Please add alteast one question",
    dataTypes:"Please select atleast one data type",
    image: "Please upload an image",
    drawing: "Please Draw a Prompt",
    password:"Please enter Password",
    consent:"Please provide consent"
  };
  
  const validateInput = (value, fieldName) => {
    if (!value) {
      toast.error(errorMessages[fieldName]);
      return false;
    }
    return true;
  };
  
  export { validateInput, errorMessages };
