import React from 'react'
import {Navigate} from "react-router-dom";
export const ModelRoute = ({children}) => {
  return (

      JSON.parse(localStorage.getItem('statusmodel')) === true ? (
       children
      ) : (
        <Navigate
          to="/models" 
          replace={true}
        />
      ))}