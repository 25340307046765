import axios from "axios";
import { getCookie, isAuth, isId } from "../Storage(Methods)/storage";


const APIURL = `${process.env.REACT_APP_API_URL}/`
const services = {
  post: async (apiname, data) => {
    try {
      const response = await axios({
        method: 'POST',
        url: APIURL + apiname,
        data: data,
        headers: {
          timeout: 1000000000
        }
      })
      const json = await response;
      return json;
    } catch (err) {
      console.log('Error inside api services', err);
      throw err;
    }
  },
  get: async (apiname, project = false, frames = false, data) => {
    const params = project === false ? isAuth().id : isId();
    const val = frames === false ? '' : data
    try {
      const response = await axios({
        method: 'GET',
        url: APIURL + apiname + '/' + params + '/' + val,
        headers: { Authorization: `Bearer ${getCookie('token')}` }
      });
      const json = await response;
      return json;
    } catch (err) {
      throw err;
    }
  },
  getbasic: async (apiname, id, val) => {
    let urlString = APIURL + apiname;
    if (id != null && id !== '' && id !== undefined) {
      urlString = urlString + '/' + id;
    }
    if (val != null && val !== '' && val !== undefined) {
      urlString = urlString + '/' + val;
    }
    try {
      const response = await axios({
        method: 'GET',
        url: urlString,
        headers: { Authorization: `Bearer ${getCookie('token')}` }
      });
      const json = await response;
      return json;
    } catch (err) {
      throw err;
    }
  },
  put: async (apiname, data) => {
    try {
      const response = await axios({
        method: 'PUT',
        url: APIURL + apiname,
        data: data
      });
      const json = await response;
      return json;
    } catch (err) {
      throw err;
    }
  },
  getS3Data: async (apiname, params, data = false) => {
    const param = data === false ? '' : params;
    try {
      const response = await axios({
        method: 'GET',
        url: APIURL + apiname + '/' + param
      });
      const json = await response;
      return json;
    } catch (err) {
      throw err;
    }
  },
  delete: async (apiname, data) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: APIURL + apiname,
        data: data
      });
      const json = await response;
      return json;
    } catch (err) {
      throw err;
    }
  },

  // This function is used for the OPEN API, Those not required to authorized
  unprotectedAPI: async (apiname) => {
    const params = "0"
    try {
      const response = await axios({
        method: 'GET',
        url: APIURL + apiname + '/' + params,
        headers: { Authorization: `Bearer ${getCookie('token')}` }
      });
      const json = await response;
      return json;
    } catch (err) {
      throw err;
    }
  },

  // This function is used to call the Project data for DataSetList Route Screens Only!! Please don't call this function in Public or Protected Routes
  getProjectData: async (apiname, pId) => {
    try {
      const response = await axios({
        method: 'GET',
        url: APIURL + apiname + '/' + pId,
        headers: { Authorization: `Bearer ${getCookie('token')}` }
      });
      const json = await response;
      return json;
    } catch (err) {
      throw err;
    }
  },
 
}



export default services;