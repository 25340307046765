import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import linkicon from "../../Assets/Images/linkicon.png";
import { ComingsoonDialog } from "../comingsoon";

const ChatGPTModel = (props) => {
    const navigate = useNavigate();
    const { closeModel } = props;
    const [alert, setAlert] = useState(false);
    const closeAlert = () => {
        setAlert(false);
    }
    return (
        <>
            {alert && <ComingsoonDialog closeAlert={closeAlert} />}
            <div className="backdrop" onClick={closeModel}>      </div>
            <div className="backdropinner" style={{ height: "700px", width: "94%" }} >
                <div style={{ borderRadius: "23px" }}>
                    <div className="ExpandGridFrame">
                        <p
                            style={{
                                fontSize: "42px",
                                color: "#4E4E4E",
                                textTransform: "uppercase",
                            }}
                        >
                            GPT-3.5-TURBO-1106
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 ">
                            <div className="modelstylegrid">
                                <div >
                                    {" "}
                                    <h5>About</h5>
                                    <p style={{ paddingLeft: "10px", width: "80%", fontSize: "14px" }}>
                                        The GPT-3.5 model is the most capable of OpenAI’s fine-tunable, GPT models. It is able to solve logic problems, cause and effect, intent, produce creative content, and handle complex summarization.

                                    </p>
                                    <p style={{ paddingLeft: "10px", width: "80%", fontSize: "14px" }}>

                                        GPT (generative pre-trained transformer) are models that are highly capable at natural language tasks, able to take in inputs- or prompts- and return complex outputs.
                                    </p>

                                </div>
                                <div>
                                    <h5>ATTRIBUTIONS & contributors</h5>
                                    <div className="chatattribute">

                                        <p style={{ paddingLeft: "10px", width: "40%" }}>

                                            <img
                                                src={linkicon}
                                                height={"10px"}
                                                width={"20px"}
                                                style={{ marginTop: "6px" }}
                                                alt=""
                                            /> {" "}
                                            <a
                                                style={{ color: "rgba(0, 0, 0, 0.7)" }}
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://arxiv.org/pdf/1706.03762.pdf"
                                            >
                                                Attention is All You Need
                                            </a>
                                        </p>

                                        <p style={{ paddingLeft: "10px" }}>

                                        <img
                                            src={linkicon}
                                            height={"10px"}
                                            width={"20px"}
                                            alt="linkicon"
                                            style={{ marginTop: "6px" }}
                                        /> {" "}
                                              OpenAI Fine-Tuning

                                    </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="modelmapdiv">
                                <h5>MODEL MAP</h5>

                                <div className="modelmap">
                                    <p
                                        style={{
                                            fontSize: "20px",
                                            color: "#4E4E4E",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        gpt-3.5-turbo-1106
                                    </p>

                                    <div className="modelmapinner">
                                        <img
                                            src={linkicon}
                                            alt="linkIcon"
                                            height={"10px"}
                                            width={"20px"}
                                            style={{ marginTop: "6px" }}
                                        />
                                        &nbsp;  ?
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <br></br>
                        <button
                            type="button"
                            className="demomodelbutton"
                            onClick={() =>
                                navigate("/chatgpt-demo", {
                                    replace: true,
                                    state: { source: "modelexplorer" },
                                })
                            }
                        >
                            DEMO
                        </button>
                        <button
                            type="button"
                            className="retrainmodelbutton"
                            onClick={() =>
                                navigate("/chatgpt-train", {
                                    replace: true,
                                    state: { source: "modelexplorer" },
                                })
                            }
                        >
                            FINE-TUNE
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChatGPTModel;
