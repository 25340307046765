import React, { useCallback, useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import debounce from "lodash.debounce";
import services from "../../API_SERVICES/services";
import { isAuth } from "../../Storage(Methods)/storage";
import { PopupModal } from "../DataPrivacy/PopupModal";

const ExpandEditproject = () => {
    let location = useLocation();
    let navigate = useNavigate();
    const [contributeVal, setContributeVal] = useState({
        contributor: "",
        loading: false,
        screen2: false,
        invitee: "",
        toggleAdmin: false,
        isAdmin: false,
        screen3: false,
        fetched: "",
        nonAdmin: false,
        spin: false,
        original_proj: '',
        project_title: location?.state?.data?.project_title,
        project_desc: location?.state?.data?.project_desc,
    });
    const { contributor, loading, screen2, invitee, isAdmin, screen3, fetched, nonAdmin, spin, original_proj } = contributeVal;
    const spinCenter = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translateX(-50% -50%)",
    };
    useEffect(() => {
        loadContributor();
    });


    const loadproject = () => {
        setTimeout(() => {
            navigate("/expand-project", { state: { data: original_proj, source: "add_Project" } }, { replace: true })
        }, 1800);
    }
    const DelayApiCall = useCallback(
        debounce(
            (apiname, data) =>
                services
                    .put(apiname, data)
                    .then((response) => {
                        toast.success(response.data.message);
                        // const apiname1 = "fetch-project-id";
                        // services
                        //   .getbasic(apiname1, location?.state?.data?.pId)
                        //   .then((responseproj) => {
                        //     setTimeout(() => {
                        //         navigate("/expand-edit-project", { state: { data: responseproj, source: "add_Project" } }, { replace: true })
                        //       }, 1800); 
                        // }).catch((error) => {
                        //   });             
                    })
                    .catch((error) => {
                        console.log(error);
                        toast.error(error.response.data.error);
                    }),
            1500
        ),
        []
    );

    const handleDataChange = (e) => {
        setContributeVal({ ...contributeVal, [e.target.name]: e.target.value });
        const apiname = "edit-projects"

        if (e.target.name === 'project_title') {
            const data = {
                pId: location?.state?.data?.pId,
                [e.target.name]: e.target.value,
                project_desc: contributeVal.project_desc
            }

            DelayApiCall(apiname, data);
            location.state.data.project_title = e.target.value;

        }
        else if (e.target.name === 'project_desc') {
            const data = {
                pId: location?.state?.data?.pId,
                project_title: contributeVal.project_title,
                [e.target.name]: e.target.value,
            }

            DelayApiCall(apiname, data);
            location.state.data.project_desc = e.target.value;

        }

    };


    const onFileChange = (event) => {
        event.preventDefault();
        var formData = new FormData();
        formData.append('projectUpload', event.target.files[0]);
        formData.append('pId', location?.state?.data?.pId)
        const apiname = "edit-image"
        services.put(apiname, formData)
            .then((response) => {
                toast.success(response.data.message)
            })
            .catch((error) => {
                toast.error(error.response.data.error);
            })
    }
    const loadContributor = () => {
        setContributeVal({ ...contributeVal, spin: true });
        const apiname = "list-collabrator";
        services
            .getbasic(apiname, location?.state?.data?.pId)
            .then((response) => {
                var isnonadmin = false;
                response?.data?.forEach((repo) => {
                    if (repo.collaborator_mailid === isAuth().email) {
                        if (repo.is_admin === 'N') {
                            isnonadmin = true;
                        }
                    }
                });
                if (location?.state?.data?.forked_id == null) {
                    setContributeVal({ ...contributeVal, contributor: response, loading: true, screen3: false, nonAdmin: isnonadmin, spin: false, original_proj: '' });
                }
                else {

                    const apiname1 = "fetch-project-id";
                    services
                        .getbasic(apiname1, location?.state?.data?.forked_id)
                        .then((responsefork) => {
                            if (responsefork === null || responsefork.data == null) {
                                setContributeVal({ ...contributeVal, contributor: response, loading: true, screen3: false, nonAdmin: isnonadmin, spin: false, original_proj: '' });
                            }
                            else {
                                setContributeVal({ ...contributeVal, contributor: response, loading: true, screen3: false, nonAdmin: isnonadmin, spin: false, original_proj: responsefork.data });
                            }
                        }).catch((error) => {
                            setContributeVal({ ...contributeVal, contributor: response, loading: true, screen3: false, nonAdmin: isnonadmin, spin: false });
                        });
                }
            })
            .catch((error) => {
                console.log(error.response.data.error);
            });

    };
    const handleClick = () => {
        setTimeout(() => {

            navigate("/decode",{state:{ data: location?.state?.data, source: "expanddt" }},{ replace: true });
            sessionStorage.setItem(
                "identifier",
                JSON.stringify(location?.state?.data?.pId)
            );
        }, 1800);
    };
    const handleChange = (e) => {
        setContributeVal({ ...contributeVal, [e.target.name]: e.target.value });
    };


    const removeContributor = (contributorId) => {
        const apiname = "remove-collaborator";
        const data = { pId: location?.state?.data?.pId, mail_id: contributorId };
        services
            .delete(apiname, data)
            .then((response) => {
                toast.success(response.data.message);
                setContributeVal({ ...contributeVal, screen3: false });
                setTimeout(() => {
                    loadContributor();
                }, 900);
            })
            .catch((error) => {
                toast.error(error.response.data.error);
            });
    };


    const handleAdminChange = (e) => {
        setContributeVal({
            ...contributeVal,
            isAdmin: !isAdmin,
            toggleAdmin: e.target.checked,
        });

    };

    const inviteCollabrator = () => {

        if (isAuth().email.toLowerCase() !== invitee.toLowerCase()) {
            const apiname = "invite_collabrator";
            setContributeVal({ ...contributeVal, spin: true, screen2: false });

            const data = {
                pid: location?.state?.data?.pId,
                project_name: location?.state?.data?.project_title,
                email_id: invitee,
                admin: isAdmin ? 'Y' : 'N',
                user_name: isAuth().username,
            }
            services.post(apiname, data)
                .then((response) => {
                    toast.success("invited successfully");
                    setContributeVal({ ...contributeVal, screen2: false, isAdmin: false, invitee: "", spin: false })

                })
                .catch((error) => {
                    toast.error(error.response.data.error);
                    setContributeVal({ ...contributeVal, screen2: false, isAdmin: false, invitee: "", spin: false })

                });

        }
        else {
            toast.success("You are owner of this project");
            setContributeVal({ ...contributeVal, screen2: false, isAdmin: false, invitee: "", spin: false })

        }
    };


    let abutton;
    if (location?.state?.source === "add_Project") {
        abutton = <a onClick={handleClick}> EXPAND PROJECT</a>;
    }


    return (
        <>
            {location.state === null ? (
                <h4
                    style={{
                        fontSize: "35px",
                        fontWeight: "lighter",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50% , -50%)",
                    }}
                >
                    404 PAGE NOT FOUND
                </h4>
            ) : (
                <>


                    {spin === true && (
                        <div style={spinCenter}>
                            <div
                                className="spinner-border"
                                style={{ width: "3rem", height: "3rem" }}
                                role="status"
                            ></div>
                        </div>
                    )}
                    <div className="d-flex mt-5 justify-content-between maindiv">

                        <input
                            className='text-uppercase  ms-3 plachetitle'
                            name='project_title'
                            value={contributeVal.project_title}
                            onChange={handleDataChange}
                        ></input>
                        <div>


                            <button type="button" className="publicbtn me-2">
                                {location?.state?.data?.project_privacy}
                            </button>
                        </div>
                    </div>
                    {!screen2 ? null : (
                        <div className="outercontainer">
                            <div className="gridJoin">
                                <div className="grid-joinContributor">
                                    <span className="inviteText mt-4 mb-4"> Invite Collaborators? </span>
                                    <p>Send an invite link to someone you want to contribute to the project.</p>
                                    <input
                                        type="text"
                                        className="inviteBoxView mt-2 mb-2"
                                        placeholder="Enter an Email"
                                        autoComplete="off"
                                        value={invitee}
                                        name="invitee"
                                        onChange={handleChange}
                                    />

                                    <div className="uploadinput " style={{ textAlign: 'left' }}>
                                        <div className="d-flex flex-column">
                                            <div className="mt-3">
                                                <input
                                                    type="checkbox"
                                                    checked={isAdmin}
                                                    onChange={handleAdminChange}
                                                />    Make admin?

                                            </div>

                                            <span className="mt-4">Admin users are granted the same permissions you have. (including deleting the project or inviting others) Admin access can be granted later but not taken away.</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-around mt-4">
                                        <button
                                            type="button" className="joinbtn mr-2"
                                            onClick={() => setContributeVal({ ...contributeVal, screen2: false })}
                                        >SKIP</button>
                                        <button
                                            type="button"
                                            className={invitee === '' ? "invitebtndisabled" : "invitebtn"}
                                            disabled={invitee === ''}

                                            onClick={() => inviteCollabrator()}

                                        >
                                            INVITE
                                        </button></div>
                                </div></div></div>
                    )}
                    {!screen3 ? null : (
                        <PopupModal
                        >
                            <h4>ARE YOU SURE YOU WANT TO REMOVE THIS CONTRIBUTOR?</h4>
                            <div className='d-flex justify-content-between'>
                                <button
                                    type='button'
                                    className='backbtndelete'
                                    onClick={() =>
                                        setContributeVal({ ...contributeVal, screen3: false })
                                    }>
                                    BACK
                                </button>
                                <button
                                    type='button'
                                    className='deletebtn'
                                    onClick={() => { removeContributor(fetched.collaborator_mailid) }}>
                                    EXIT
                                </button>
                            </div>
                        </PopupModal>
                    )}
                    <div className="gridContainer">
                        <div className="grid-item-1">


                            <textarea
                                className='plachetitle2'
                                name='project_desc'
                                value={contributeVal.project_desc}
                                onChange={handleDataChange}
                                placeholder='DESCRIPTION'
                                rows='4'></textarea>
                        </div>

                        {location?.state?.data?.projectimage === null ? (<div
                            className='grid-item-2'
                            style={{ border: '1px solid black', borderRadius: '10px' }}>
                            <label htmlFor='file' id='filelabel'>
                                <i
                                    className='fas fa-camera'
                                    style={{ color: 'black', fontSize: '25px' }}></i>
                            </label>
                            <input
                                type='file'
                                id='file'
                                name='projectUpload'
                                onChange={onFileChange}
                                style={{ display: 'none', visibility: 'none' }}
                            />
                        </div>) : (
                            <div
                                className='grid-item-2'
                                style={{ border: '1px solid black', borderRadius: '10px' }}>
                                <img
                                    src={location?.state?.data?.projectimage}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '10px',
                                    }}
                                    alt='profimage'
                                />
                                <label htmlFor='file' id='filelabel'>
                                    <i
                                        className='fas fa-camera'
                                        style={{ color: 'black', fontSize: '25px' }}></i>
                                </label>
                                <input
                                    type='file'
                                    id='file'
                                    name='projectUpload'
                                    onChange={onFileChange}
                                    style={{ display: 'none', visibility: 'none' }}
                                />
                            </div>)}


                    </div>
                    <div className="gridExpand">

                        <div className="contributor-div">

                            <div className="row">
                                <div className="col-xl-12">
                                    <h6>CONTRIBUTORS</h6>
                                </div>
                                <div className="col-xl-12 my-2">
                                    {nonAdmin ? '' : <i className="fas fa-plus" style={{ color: "#5B5E52" }}
                                        onClick={() => setContributeVal({ ...contributeVal, screen2: true })}
                                    ></i>}

                                </div>

                                <div className="col-xl-12">
                                    <ul className="listcontributor">
                                        {original_proj === '' ? '' : <li onClick={loadproject}> <span style={{ backgroundColor: '#E5FF80', borderRadius: '20px' }}>{original_proj.project_title}</span></li>}

                                        {loading && contributor?.data?.map((d) => (
                                            d.status === 'A' ?
                                                <li key={d.username}>
                                                    {d.collaborator_mailid === isAuth().email ? d.username : d.username}
                                                    ({d.is_admin === 'Y' ? "Admin" : "Contributor"})
                                                    {d.is_admin !== 'Y' && d.collaborator_mailid !== isAuth().email && !nonAdmin ? <i className="fa fa-times me-4 mt-2 " style={{ color: "red", marginLeft: "50px" }}
                                                        onClick={() => setContributeVal({ ...contributeVal, screen3: true, fetched: d })}></i> : ''}</li> : ''
                                        )
                                        )}

                                    </ul></div>
                            </div>
                        </div>

                        <div className="grid-item-2">
                            <Link
                                className="projectLink"
                                to={"/project-dbse"}
                                state={{ data: location?.state?.data, source: "edit" }}
                            >
                                {" "}
                                CALL FOR DATA{" "}
                            </Link>
                        </div>

                        <div className="grid-item-3">{abutton}</div>
                    </div>
                </>
            )}
        </>
    );
};

export default ExpandEditproject;
