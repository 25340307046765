import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { isAuth, isId} from "../../Storage(Methods)/storage";
import services from "../../API_SERVICES/services";

export default function Explore(props) {
  const [userqs, setuserqs] = useState({
    exploreq1: "",
    exploreq2: "",
  });

  useEffect(() => {
    LoadQuestions();
  }, []);
  const handleChange = (e) => {
    setuserqs({ ...userqs, [e.target.name]: e.target.value });
  };
  const { exploreq1, exploreq2 } = userqs;
 const LoadQuestions = () => {
    const apiname = "get-question"
    const project = true
     services.get(apiname, project)
      .then((response) => {
        const {expq1,expq2} = response.data
        setuserqs({...userqs,exploreq1:expq1,exploreq2:expq2})
      })
      .catch((error) => {

          console.log(error);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if ((exploreq1 != null && exploreq1 !== '')||(exploreq2 != null  && exploreq2 !== '') ) {

    const apiname="poieto/exp-query"
    const data = {
      exploreq1:exploreq1,
      exploreq2:exploreq2,
      id:isAuth().id,
      pId:isId()
    }
    services.post(apiname,data)
      .then((response) => {
        toast.success(response.data.message);
        LoadQuestions();
        props.setShow(2);
      })
      .catch((error) => {
        toast.error(error.response.data.error);
      });
    }
    else{
      props.setShow(2);
    }
  };
  return (
    <>
      <div className="container-fluid mt-3">
        <h3
          style={{
            margin: "0px",
            color: "#4E4E4E",
            fontWeight: "normal",
           
          }}
        >
          WHAT DO YOU WANT TO DO? WHAT THOUGHTS OR QUESTIONS
        </h3>
        <h3
          style={{
            color: "#4E4E4E",
            fontWeight: "normal",
           
          }}
        >
          WOULD YOU LIKE YOUR PROJECT TO EXPLORE?
        </h3>
        <p
          style={{
            margin: "0px",
            color: "grey",
            fontSize: "12px",
            fontWeight: "600",
           
          }}
        >
          BE OPEN TO COMING UP WITH BETTER QUESTIONS OR MORE CRITICAL QUESTIONS
          AS YOU MAKE-
        </p>
        <p
          style={{
            margin: "0px",
            color: "grey",
            fontSize: "12px",
            fontWeight: "600",
           
          }}
        >
          YOU WILL BE PROMPTED TO REVISIT THIS QUESTION AND CAN EDIT IT ANYTIME.
          FOR EXAMPLE
        </p>
        <p
          style={{
            margin: "0px",
            color: "grey",
            fontSize: "12px",
            fontWeight: "600",
           
          }}
        >
          SOME PROJECTS AI TO FIND HOW HUMANS CHANGE THEIR THINKING ABOUT
          SOMETHING OVER
        </p>
        <p
          style={{
            color: "grey",
            fontSize: "12px",
            fontWeight: "600",
           
          }}
        >
          TIME, OR ARTISTIC TRENDS, ETC.
        </p>
        <textarea
          className="mt-2"
          rows="4"
          placeholder={!exploreq1 ? "Type Here" : null}
          name="exploreq1"
          value={exploreq1}
          onChange={handleChange}
          style={{
            fontSize: "25px",
            paddingLeft: 10,
            width: "100%",
            borderRadius: 15,
            backgroundColor: "#C7CCB2",
           
          }}
        ></textarea>
        <h3
          className="mt-5"
          style={{
            color: "#4E4E4E",
            fontWeight: "normal",
           
          }}
        >
          DEFINE THE PROJECT SPACE:
        </h3>
        <p
          style={{
            margin: "0px",
            color: "grey",
            fontSize: "12px",
            fontWeight: "600",
           
          }}
        >
          OR RATHER WHERE ARE YOU COMING FROM? AND WHAT TOPICS, THEMES,
          PROFESSIONS, DOES
        </p>
        <p
          style={{
            color: "grey",
            fontSize: "12px",
            fontWeight: "600",
            
          }}
        >
          YOUR PROJECT DRAW FROM.
        </p>
        <textarea
          className="mt-2"
          rows="4"
          name="exploreq2"
          value={exploreq2}
          onChange={handleChange}
          placeholder={!exploreq2 ? "Type Here" : null}
          style={{
            fontSize: "25px",
            paddingLeft: 10,
            width: "100%",
            borderRadius: 15,
            backgroundColor: "#C7CCB2",
            
          }}
        ></textarea>
        <button
          style={{
            marginBottom: 10,
            width: "100%",
            height: 125,
            backgroundColor: "#E5FF80",
            borderRadius: 10,
            border: "none",
            fontSize: "23px",
          }}
          onClick={handleSubmit}
          type="button"
          className="btn-lg btn-block mt-4"
        >
          <i className="fas fa-arrow-right" />
          <br />
          NEXT
        </button>
      </div>
    </>
  );
}
