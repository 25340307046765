import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import linkicon from "../../Assets/Images/linkicon.png";
import { ComingsoonDialog } from "../comingsoon";

const ImgClassifyModel = (props) => {
  const navigate = useNavigate();
 const {closeModel}=props;
  const [alert, setAlert] = useState(false);
const closeAlert=()=>{
setAlert(false);
}
  return (
    <>
          {alert && <ComingsoonDialog closeAlert={closeAlert}/>}

      <div className="backdrop"  onClick={closeModel}>      </div>

        <div className="backdropinner" style={{ height: "700px",width: "94%" }} >
          <div style={{ borderRadius: "23px" }}>
            <div className="ExpandGridFrame">
              <p
                style={{
                  fontSize: "42px",
                  color: "#4E4E4E",
                  textTransform: "uppercase",
                }}
              >
                IMAGE CLASSIFICATION
              </p>
            </div>
            <div className="row">
              <div className="col-sm-6 ">
                <div className="modelstylegrid">
                  <div >
                    {" "}
                    <h5>About</h5>
                    <p style={{ paddingLeft: "10px", width: "80%" }}>                    TRAIN A MODEL TO CLASSIFY IMAGES
</p>
                    <p style={{ paddingLeft: "10px", width: "80%" }}>

MobileNet is a a lightweight deep neural network that uses optimized architecture for running with little computational power. This model has been pretrained for Image Classification.                    </p>
                  </div>
                  <div>
                    <h5>ATTRIBUTIONS & contributors</h5>
                     
                    <p style={{ paddingLeft: "10px", width: "80%" }}>
                     
                      <img
                        src={linkicon}
                        alt="lickIcon"
                        height={"10px"}
                        width={"20px"}
                        style={{ marginTop: "6px" }}
                      /> {" "}
                      <a
                        style={{ color: "rgba(0, 0, 0, 0.7)" }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://arxiv.org/abs/1704.04861"
                      >
                        MobileNets: Efficient Convolutional Neural Networks for Mobile Vision Applications
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="modelmapdiv">
                  <h5>MODEL MAP</h5>

                  <div className="modelmap">
                    <p
                      style={{
                        fontSize: "20px",
                        color: "#4E4E4E",
                        textTransform: "uppercase",
                      }}
                    >
                      VGG-19 CNN
                    </p>

                    <div className="modelmapinner">
                      <img
                        src={linkicon}
                        alt="linkIcon"
                        height={"10px"}
                        width={"20px"}
                        style={{ marginTop: "6px" }}
                      />
                      &nbsp;   <a
                        style={{ color: "rgba(234, 237, 222, 1)" }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.image-net.org/"
                      >
                        MOBILENET
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <br></br>
              <button
                type="button"
                className="demomodelbutton"
                onClick={() => setAlert(true)}

              >
                DEMO
              </button>
              <button
                type="button"
                className="retrainmodelbutton"
                onClick={() =>
                  navigate("/Mobilenetmodel", {
                    replace: true,
                    state: { source: "modelexplorer" },
                  })
                }
              >
                RETRAIN
              </button>
            </div>
          </div>
        </div>
    </>
  );
};

export default ImgClassifyModel;
