import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import services from "../../API_SERVICES/services";
import path from "path";
import eclippse from "../../Assets/Images/Ellipse.png";
import widgetpic from "../../Assets/Images/wizard.png";
import toast from "react-hot-toast";
import { useMenuContext } from "../../Components/Navbar/menuContext";
import UploadData from "../../Components/ProjectDBSEComponents/UploadData";
const DataSetList = () => {
  const { setShowAdditionalMenu, setActiveMenuItem } = useMenuContext();

  const [datacontribute, setDataContribute] = useState({
    projects: "",
    loading: false,
  });
  const [isUploadDataLoading, setIsUploadDataLoading] = React.useState(false);
  const [showUpload, setshowUpload] = useState(false);
  const [uploadData, setuploadData] = useState(null);
  const [activecalls, setActivecalls] = useState([]);
  const [typedisplay, setTypedisplay] = useState(false);
  const [typedatadisplay, setTypeDataDisplay] = useState(false);
  const [query, setQuery] = useState("");
  const [selecedType, setSelectedType] = useState([]);
  const [selecedDataType, setSelectedDataType] = useState([]);
  const [modeltypes, setModeltypes] = useState([
    {
      name: "Sound",
      selected: false,
      type: ["mp3"],
    },
    {
      name: "Text",
      selected: false,
      type: ["txt"],
    },
    {
      name: "Image",
      selected: false,
      type: ["png", "jpg", "jpeg", "gif"],
    },
    {
      name: "Video",
      selected: false,
      type: ["mp4"],
    },
  ]);

  const [dataCallTypes, setDataCallTypes] = useState([
    {
      name: "Sound",
      selected: false,
      type: ["mp3", "sound"],
    },
    {
      name: "Text",
      selected: false,
      type: ["txt"],
    },
    {
      name: "Image",
      selected: false,
      type: ["png", "jpg", "jpeg", "gif", "image"],
    },
    {
      name: "Video",
      selected: false,
      type: ["mp4", "video"],
    },
  ]);

  const [values, setValues] = useState({
    DataCallTitle: "",
    title: "",
    message: "",
    dataTypes: "",
    call_for_data_fields: "",
    consent_and_permissions: "",
    core_question_author_name: "",
    core_question_how_this_sourced: "",
    core_question_where_did_this_come: "",
    optional_question_data: "",
    consent_optional_email: "",
    consent_donation_paid: "",
    pId: "",
  });

  const search_parameters = ["project_title"];
  // function search(data) {
  //   return data.filter(
  //     (item) =>
  //       search_parameters.some((parameter) =>
  //         item[parameter].toString().toLowerCase().includes(query)
  //       )
  //   );
  // }
  const spinCenter = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50% -50%)",
  };

  function search(data) {
    const filteredDATA = data.filter((node) =>
      selecedType.length > 0
        ? selecedType.some((filterTag) =>
            node?.extension.some((r) =>
              filterTag?.type.includes(r.toLowerCase())
            )
          )
        : data
    );
    return filteredDATA.filter((item) =>
      search_parameters.some((parameter) =>
        item[parameter].toString().toLowerCase().includes(query)
      )
    );
  }

  function dataSearch(data) {
    const filteredDATA = data.filter((node) =>
      selecedDataType.length > 0
        ? selecedDataType.some((filterTag) =>
            node?.extension.some((r) =>
              filterTag?.type.includes(r.toLowerCase())
            )
          )
        : data
    );
    return filteredDATA.filter((item) =>
      search_parameters.some((parameter) =>
        item[parameter].toString().toLowerCase().includes(query)
      )
    );
  }

  const handlechange = (index) => {
    setSelectedType([]);
    const newtype = [...modeltypes];
    newtype[index].selected = !modeltypes[index].selected;
    setModeltypes(newtype);
    let arr = [];
    for (let i = 0; i < newtype.length; i++) {
      if (newtype[i].selected) {
        arr.push(newtype[i]);
      }
    }
    setSelectedType(arr);
  };

  const handleDataCallChange = (index) => {
    setSelectedDataType([]);
    const newDatatype = [...dataCallTypes];
    newDatatype[index].selected = !dataCallTypes[index].selected;
    setDataCallTypes(newDatatype);
    let arr = [];
    for (let i = 0; i < newDatatype.length; i++) {
      if (newDatatype[i].selected) {
        arr.push(newDatatype[i]);
      }
    }
    setSelectedDataType(arr);
  };

  const inputStyle = {
    width: "95%",
    borderRadius: "1000px",
    marginLeft: "10px",
    textAlign: "center",
    backgroundColor: "rgba(78, 78, 78, 0.1)",
    color: "#4E4E4E",
    opacity: "70%",
    border: "none",
    height: "40px",
  };

  const Notfound = {
    fontWeight: "bold",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50% , -50%)",
  };

  useEffect(() => {
    setShowAdditionalMenu(true);
    setActiveMenuItem("DATA");
    loadProjects();
    loadactiveCall();
    return () => {
      setShowAdditionalMenu(false);
      setActiveMenuItem(null);
    };
  }, []);

  const { projects, loading } = datacontribute;

  // const loadProjects = async () => {
  //   setDataContribute({
  //     ...datacontribute,
  //     loading: false,
  //   });
  //   let tempproj = [];
  //   const apiname = "fetch-project-public";
  //   try {
  //     let response = await services.unprotectedAPI(apiname);
  //     for (let i = 0; i < response?.data?.length; i++) {
  //       let proj = response.data[i];
  //       try {
  //         let inresponse = await services.getbasic(
  //           "fetch-project-data",
  //           proj.pId
  //         );
  //         let extensions = getExtensionList(inresponse);
  //         proj.extension = extensions;
  //         tempproj.push(proj);
  //       } catch (error) {
  //         console.log("ERROR " + error);
  //       }
  //     }
  //     setDataContribute({
  //       ...datacontribute,
  //       projects: tempproj,
  //       loading: true,
  //     });
  //   } catch (e) {
  //     console.log("ERROR " + e);
  //   }
  // };

  const loadProjects = async () => {
    setDataContribute({
      ...datacontribute,
      loading: false,
    });
    let tempproj = [];
    const apiname = "fetch-project-public";
   
    try {
      let response = await services.unprotectedAPI(apiname);
 
      // Create an array of promises for fetching project data
      let projectPromises = response?.data?.map(async (proj) => {
        try {
          let inresponse = await services.getbasic("fetch-project-data", proj.pId);
          let extensions = getExtensionList(inresponse);
          proj.extension = extensions;
          return proj;
        } catch (error) {
          console.log("ERROR " + error);
          return null;
        }
      });
 
      // Wait for all promises to resolve
      let projectResults = await Promise.all(projectPromises);
     
      // Filter out any null results due to errors
      tempproj = projectResults.filter(proj => proj !== null);
     
      setDataContribute({
        ...datacontribute,
        projects: tempproj,
        loading: true,
      });
    } catch (e) {
      console.log("ERROR " + e);
    }
  };
  

  const loadactiveCall = async () => {
    let tempdataproj = [];
    const apiname = "fetch-project-activecall";
    try {
      let response = await services.getbasic(apiname);
      let i = 0;
      for (i; i < response?.data?.length; i++) {
        let proj = response.data[i];
        try {
          proj.extension = await getDataExtensionList(proj);
          tempdataproj.push(proj);
        } catch (error) {
          console.log(error.response.data.error);
        }
      }
      setActivecalls(tempdataproj);
    } catch (e) {
      console.log(e.response.data.error);
    }
  };

  const getDataExtensionList = async (proj) => {
    const extSet = new Set(); // Using Set to automatically eliminate duplicates
    const callfordata_type = proj.callfordata_type;
    if (callfordata_type) {
      const extensions = callfordata_type
        .split(",")
        .map((ext) => ext.trim().toLowerCase());
      extSet.add(...extensions);
    }
    const extensions = Array.from(extSet); // Convert Set back to Array if needed
    return extensions;
  };

  const getExtensionList = (response) => {
    var ext = [];
    for (var i = 0; i < response?.data?.length; i++) {
      var filename = path.basename(response?.data[i]?.video);
      var extensions = /[.]/.exec(filename)
        ? /[^.]+$/.exec(filename).toString()
        : undefined;

      if (!ext.includes(extensions) && extensions !== undefined) {
        ext.push(extensions);
      }
    }
    return ext;
  };
 // This function is used to upload the image only on S-3 bucket
 const dataUpload = (childStates) => {
  setIsUploadDataLoading(true);
  const apiname = "data_upload";
  var formData = new FormData();
  formData.append("dataUpload", childStates.image);
  formData.append("pId", values?.pId);
  formData.append("user_id", "N/A");
  formData.append("contributor", "UNKNOWN");
  services
    .post(apiname, formData)
    .then((response) => {
      toast.success(response.data.message);
      sessionStorage.setItem(
        "data",
        JSON.stringify(response?.data?.data?.video)
      );
      sessionStorage.setItem(
        "id",
        JSON.stringify(response?.data?.data?.dcId)
      );

      // Call the completeUpload function to upload all the Data.
      completeUpload(childStates, response?.data?.data?.dcId);
    })
    .catch((err) => {
      setIsUploadDataLoading(false);
      toast.error(err.response.data.error);
    });
};

const completeUpload = async (childStates, id) => {
  const apiname = "data-details";
  var per = "";
  if (childStates.permissionOne !== "") {
    per = per + childStates.permissionOne + ";";
  }
  if (childStates.permissionTwo !== "") {
    per = per + childStates.permissionTwo + ";";
  }
  if (childStates.permissionThree !== "") {
    per = per + childStates.permissionThree;
  }
  const data = {
    author: childStates.author ? childStates.author : "",
    q1: childStates.whereDidThisComeFrom
      ? childStates.whereDidThisComeFrom
      : "NA",
    q2: childStates.howWasThisSourced,
    permissions: per,
    id:childStates.ids.length > 0 ? childStates.ids : [] ,
    call_for_data_fields: JSON.stringify(childStates.dataFields),
    optional_question_data: `[]`,
    consent_optional_email: childStates.email,
    consent_donation_paid: childStates.donationOrPaid,
  };
  services
    .post(apiname, data)
    .then((response) => {
      toast.success(response.data.message);
      setIsUploadDataLoading(false);
    })
    .catch((err) => {
      setIsUploadDataLoading(false);
      toast.error(err.response.data.error);
    });
};
  return (
    <>
      {/* <div className="d-flex flex-start  w-100 gap-5 mx-3"> </div> */}
      <div className="activedatapanel" >
        <div className="row">
          <div className="col-auto">
            <button className="featured-call">
              {" "}
              <img
                src={widgetpic}
                alt="."
                style={{
                  marginRight: "10px",
                  height: "20px",
                  width: "20px",
                }}
              ></img>
              Featured Data Calls
            </button>
          </div>
          <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
            {typedatadisplay ? (
              <>
                <div
                  className="Invisible"
                  onClick={() => setTypeDataDisplay(false)}
                ></div>
                <div className="modeltypelist">
                  <span style={{ alignSelf: "center" }}>TYPE</span>
                  {dataCallTypes?.map((mt, index) => {
                    return (
                      <>
                        <div
                          onClick={() => {
                            handleDataCallChange(index);
                          }}
                          key={index}
                          className={
                            mt.selected
                              ? "modeltypesubselected"
                              : "modeltypesub"
                          }
                        >
                          <span>{mt.name}</span>
                          {mt.selected ? (
                            <>
                              <i className="fas fa-check"></i>
                            </>
                          ) : (
                            <>
                              <i className="fas fa-plus"></i>
                            </>
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>
              </>
            ) : (
              <button
                className="dropdown-toggle typeinput"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={() => setTypeDataDisplay(true)}
              >
                TYPE
              </button>
            )}
          </div>
        </div>

        <p className="featured-call-text">
          Active calls for data from POIETO projects.
        </p>
        {loading && activecalls?.length > 0 ? (
          <div className="activecalllist ">
            {dataSearch(activecalls)?.map((d) => (
              // <Link
              //   key={d.pId + 1}
              //   className="projectLink"
              //   to={"/dataSetProjectDataUpload"}
              //   state={{ data: d, source: "expanddt" }}
              // >
                <div className="activecallcard"
                onClick={() => {
                  setuploadData(d);
                  setshowUpload(true);
                  setValues({
                    ...values,
                    DataCallTitle: d?.call_for_data_call_title,
                    title: d?.callfordata_title,
                    message: d?.callfordata_message,
                    dataTypes: d?.callfordata_type,
                    call_for_data_fields: JSON.parse(d?.call_for_data_fields),
                    consent_and_permissions: d?.consent_and_permissions,
                    core_question_author_name: d?.core_question_author_name,
                    core_question_how_this_sourced:
                      d?.core_question_how_this_sourced,
                    core_question_where_did_this_come:
                      d?.core_question_where_did_this_come,
                    optional_question_data: JSON.parse(
                      d?.optional_question_data
                    ),
                    pId: d?.pId,
                    projName: d?.project_title,
                  });
                }}
                >
                  <h4
                    style={{
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "rgba(78, 78, 78, 0.7)",
                      height: "20px",
                    }}
                  >
                    {d.callfordata_type}
                  </h4>
                  <div key={d.pId + 1}>
                    <h4
                      style={{
                        fontSize: "32px",
                        lineHeight: "42px",
                        color: "rgba(78, 78, 78, 0.7)",
                      }}
                    >
                      {d.call_for_data_call_title
                        ? d.call_for_data_call_title.length > 20
                          ? d.call_for_data_call_title.slice(0, 20) + "..."
                          : d.call_for_data_call_title
                        : d.project_title.length > 20
                        ? d.project_title.slice(0, 20) + "..."
                        : d.project_title}
                    </h4>
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "rgba(78, 78, 78, 0.7)",
                      lineHeight: "21px",
                      backgroundColor: " #E5FF80",
                      borderRadius: "1000px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      paddingTop: "4px",
                      paddingBottom: "4px",
                    }}
                  >
                    <img
                      src={eclippse}
                      alt="."
                      style={{
                        marginRight: "10px",
                      }}
                    ></img>
                    {d.project_title}
                  </div>
                  <div>
                    <p
                      style={{
                        color: "#4E4E4EB2",
                        marginTop: "5px",
                        lineHeight: "normal",
                      }}
                    >
                      {d.callfordata_title
                        ? d.callfordata_title.length > 45
                          ? d.callfordata_title.slice(0, 45) + "..."
                          : d.callfordata_title
                        : null}
                    </p>
                    <p
                      style={{
                        color: "#4E4E4EB2",
                        lineHeight: "normal",
                        textAlign: "justify",
                      }}
                    >
                      {d.project_desc
                        ? d.project_desc.length > 85
                          ? d.project_desc.slice(0, 85) + " ..."
                          : d.project_desc
                        : d.callfordata_message.length > 85
                        ? d.callfordata_message.slice(0, 85) + " ..."
                        : d.callfordata_message}
                    </p>
                  </div>
                </div>
              // </Link>
            ))}
          </div>
        ) : null}
          {showUpload && (
          <UploadData
            parentStates={values}
            pId={values.pId}
            completeUploadData={(childStates) => dataUpload(childStates)}
            isLoading={isUploadDataLoading}
            closeModal={(value) => {
              setshowUpload(false);
            }}
            projName={values?.projName}
            marginValue = "50px"
          />
        )}
      </div>

      <div className="row p-3">
        <div className="col-auto">
          <button className="community-ds">
            {" "}
            <img
              src={widgetpic}
              alt="."
              style={{
                marginRight: "10px",
                height: "20px",
                width: "20px",
              }}
            ></img>
            Community Datasets
          </button>
        </div>

        <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
          <input
            type="search"
            placeholder="SEARCH MODELS"
            className="searchinput"
            style={inputStyle}
            onChange={(e) => setQuery(e.target.value)}
          ></input>
        </div>
        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
          {typedisplay ? (
            <>
              <div
                className="Invisible"
                onClick={() => setTypedisplay(false)}
              ></div>
              <div className="modeltypelist">
                <span style={{ alignSelf: "center" }}>TYPE</span>
                {modeltypes?.map((mt, index) => {
                  return (
                    <>
                      <div
                        onClick={() => {
                          handlechange(index);
                        }}
                        key={index}
                        className={
                          mt.selected ? "modeltypesubselected" : "modeltypesub"
                        }
                      >
                        <span>{mt.name}</span>
                        {mt.selected ? (
                          <>
                            <i className="fas fa-check"></i>
                          </>
                        ) : (
                          <>
                            <i className="fas fa-plus"></i>
                          </>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          ) : (
            <button
              className="dropdown-toggle typeinput"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={() => setTypedisplay(true)}
            >
              TYPE
            </button>
          )}
        </div>
      </div>
      {loading && projects?.length > 0 ? (
        <div className="dataList">
          {search(projects)?.map((d) => (
            <Link
              key={d.pId + 1}
              className="projectLink"
              to={"/dataSetProjectDataUpload"}
              state={{ data: d, source: "expanddt" }}
            >
              <div className="datacard">
                <h4
                  style={{
                    fontSize: "16px",
                    lineHeight: "21px",
                    color: "rgba(78, 78, 78, 0.7)",
                    height: "20px",
                  }}
                >
                  {d.extension?.join(", ")}
                </h4>

                <div key={d.pId + 1}>
                  <h4
                    style={{
                      fontSize: "32px",
                      lineHeight: "42px",
                      color: "rgba(78, 78, 78, 0.7)",
                    }}
                  >
                    {d.project_title}
                  </h4>
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "rgba(78, 78, 78, 0.7)",
                    lineHeight: "21px",
                    backgroundColor: " #E5FF80",
                    borderRadius: "1000px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                  }}
                >
                  <img
                    src={eclippse}
                    alt="."
                    style={{
                      marginRight: "10px",
                    }}
                  ></img>
                  {d.project_title}
                </div>
              </div>
            </Link>
          ))}
        </div>
      ) : loading && projects?.length === 0 ? (
        <p className="text-center" style={Notfound}>
          <i className="fas fa-empty-set"></i> No Projects Found
        </p>
      ) : (
        <div style={spinCenter}>
          <div
            className="spinner-border"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          ></div>
        </div>
      )}
    </>
  );
};

export default DataSetList;
